import {
  Route as Model,
  Service as KnativeService,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import { Service as KubernetesService } from 'kubernetes-models/v1';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import { conditionsFromResource, prioritizedConditions } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList } from '../Condition';
import type {
  IRoute,
  IService as IKnativeService,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import type { IService as IKubernetesService } from 'kubernetes-models/v1';

const conditionsLabels = {
  Ready: { True: 'Ready', False: 'Not Ready', Unknown: 'Ready Status Unknown' },
  AllTrafficAssigned: {
    True: 'All Traffic Assigned',
    False: 'Not All Traffic Assigned',
    Unknown: 'Traffic Assignment Unknown',
  },
  IngressReady: {
    True: 'Ingress Ready',
    False: 'Ingress Not Ready',
    Unknown: 'Ingress Status Unknown',
  },
  CertificateProvisioned: {
    True: 'Certificate Provisioned',
    False: 'Certificate Not Provisioned',
    Unknown: 'Certificate Status Unknown',
  },
};

export class Route extends AbstractModel<IRoute> {
  public static readonly humanType = 'Knative Route';

  public static readonly typeMeta = Model;

  public classRef = Route;

  public constructor(
    resource: IRoute,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    return prioritizedConditions(
      conditionsFromResource(this.resource, conditionsLabels),
    );
  }

  public get owners(): (
    | MappedResource<IKnativeService>
    | MappedResource<IKubernetesService>
  )[] {
    return compact([this.knativeService, this.kubernetesService]);
  }

  public get knativeService(): MappedResource<IKnativeService> | undefined {
    return this.repository.ownerOfType<IKnativeService>(this, KnativeService);
  }

  public get kubernetesService():
    | MappedResource<IKubernetesService>
    | undefined {
    return this.repository.ownerOfType<IKubernetesService>(
      this,
      KubernetesService,
    );
  }
}
