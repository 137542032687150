import { ClusterStack as Model } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import { AbstractModel } from '../AbstractModel';
import type { IClusterStack } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import type { IRepository } from '../../repository';
import type { IStatus } from '../BaseTypes';

export interface ClusterStackSpec {
  buildImage: {
    image: string;
  };
  id: string;
  runImage: {
    image: string;
  };
  serviceAccountRef: {
    name: string;
    namespace: string;
  };
}

export interface ClusterStackStatus
  extends Omit<IStatus, 'resources' | 'supplyChainRef'> {
  buildImage: {
    image: string;
    latestImage: string;
  };
  groupId: number;
  id: string;
  runImage: {
    image: string;
    latestImage: string;
  };
  userId: string;
}

export class ClusterStack extends AbstractModel<IClusterStack> {
  public static readonly humanType = 'Kpack Builder';

  public static readonly typeMeta = Model;

  public classRef = ClusterStack;

  public constructor(
    resource: IClusterStack,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get spec(): ClusterStackSpec | undefined {
    // @ts-expect-error: Explicit typecast. `spec` is defined but `IClusterBuilder` lacks the declaration
    return this.resource.spec as ClusterStackSpec | undefined;
  }

  public get status(): ClusterStackStatus | undefined {
    // @ts-expect-error: Explicit typecast. `status` is defined but `IClusterBuilder` lacks the declaration
    return this.resource.status as ClusterStackStatus | undefined;
  }
}
