import { Namespace as Model } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { INamespace } from 'kubernetes-models/v1';

export class Namespace extends AbstractModel<INamespace> {
  public static readonly humanType = 'Namespace';

  public static readonly typeMeta = Model;

  public classRef = Namespace;

  public constructor(
    resource: INamespace,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
