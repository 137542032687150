import { HorizontalPodAutoscaler as Model } from 'kubernetes-models/autoscaling/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { IHorizontalPodAutoscaler } from 'kubernetes-models/autoscaling/v1';

export class HorizontalPodAutoscaler extends AbstractModel<IHorizontalPodAutoscaler> {
  public static readonly humanType = 'Horizontal Pod Autoscaler';

  public static readonly typeMeta = Model;

  public classRef = HorizontalPodAutoscaler;

  public constructor(
    resource: IHorizontalPodAutoscaler,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
