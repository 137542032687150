import { Secret as Model } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { ISecret } from 'kubernetes-models/v1';

export class Secret extends AbstractModel<ISecret> {
  public static readonly humanType = 'Secret';

  public static readonly typeMeta = Model;

  public classRef = Secret;

  public constructor(
    resource: ISecret,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
