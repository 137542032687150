import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.Workload';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['Workload'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        build: {
          properties: {
            env: {
              items: {
                properties: {
                  name: {
                    type: 'string',
                  },
                  value: {
                    type: 'string',
                    nullable: true,
                  },
                  valueFrom: {
                    properties: {
                      configMapKeyRef: {
                        properties: {
                          key: {
                            type: 'string',
                          },
                          name: {
                            type: 'string',
                            nullable: true,
                          },
                          optional: {
                            type: 'boolean',
                            nullable: true,
                          },
                        },
                        required: ['key'],
                        type: 'object',
                        nullable: true,
                      },
                      fieldRef: {
                        properties: {
                          apiVersion: {
                            type: 'string',
                            nullable: true,
                          },
                          fieldPath: {
                            type: 'string',
                          },
                        },
                        required: ['fieldPath'],
                        type: 'object',
                        nullable: true,
                      },
                      resourceFieldRef: {
                        properties: {
                          containerName: {
                            type: 'string',
                            nullable: true,
                          },
                          divisor: {
                            oneOf: [
                              {
                                anyOf: [
                                  {
                                    type: 'integer',
                                  },
                                  {
                                    type: 'string',
                                  },
                                ],
                                pattern:
                                  '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                              },
                              {
                                type: 'null',
                              },
                            ],
                          },
                          resource: {
                            type: 'string',
                          },
                        },
                        required: ['resource'],
                        type: 'object',
                        nullable: true,
                      },
                      secretKeyRef: {
                        properties: {
                          key: {
                            type: 'string',
                          },
                          name: {
                            type: 'string',
                            nullable: true,
                          },
                          optional: {
                            type: 'boolean',
                            nullable: true,
                          },
                        },
                        required: ['key'],
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                },
                required: ['name'],
                type: 'object',
              },
              type: 'array',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        env: {
          items: {
            properties: {
              name: {
                type: 'string',
              },
              value: {
                type: 'string',
                nullable: true,
              },
              valueFrom: {
                properties: {
                  configMapKeyRef: {
                    properties: {
                      key: {
                        type: 'string',
                      },
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                      optional: {
                        type: 'boolean',
                        nullable: true,
                      },
                    },
                    required: ['key'],
                    type: 'object',
                    nullable: true,
                  },
                  fieldRef: {
                    properties: {
                      apiVersion: {
                        type: 'string',
                        nullable: true,
                      },
                      fieldPath: {
                        type: 'string',
                      },
                    },
                    required: ['fieldPath'],
                    type: 'object',
                    nullable: true,
                  },
                  resourceFieldRef: {
                    properties: {
                      containerName: {
                        type: 'string',
                        nullable: true,
                      },
                      divisor: {
                        oneOf: [
                          {
                            anyOf: [
                              {
                                type: 'integer',
                              },
                              {
                                type: 'string',
                              },
                            ],
                            pattern:
                              '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                          },
                          {
                            type: 'null',
                          },
                        ],
                      },
                      resource: {
                        type: 'string',
                      },
                    },
                    required: ['resource'],
                    type: 'object',
                    nullable: true,
                  },
                  secretKeyRef: {
                    properties: {
                      key: {
                        type: 'string',
                      },
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                      optional: {
                        type: 'boolean',
                        nullable: true,
                      },
                    },
                    required: ['key'],
                    type: 'object',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        image: {
          type: 'string',
          nullable: true,
        },
        params: {
          items: {
            properties: {
              name: {
                type: 'string',
              },
              value: {},
            },
            required: ['name', 'value'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        resources: {
          properties: {
            limits: {
              additionalProperties: {
                anyOf: [
                  {
                    type: 'integer',
                  },
                  {
                    type: 'string',
                  },
                ],
                pattern:
                  '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
              },
              type: 'object',
              properties: {},
              nullable: true,
            },
            requests: {
              additionalProperties: {
                anyOf: [
                  {
                    type: 'integer',
                  },
                  {
                    type: 'string',
                  },
                ],
                pattern:
                  '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
              },
              type: 'object',
              properties: {},
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        serviceAccountName: {
          type: 'string',
          nullable: true,
        },
        serviceClaims: {
          items: {
            properties: {
              name: {
                type: 'string',
              },
              ref: {
                properties: {
                  apiVersion: {
                    type: 'string',
                  },
                  kind: {
                    type: 'string',
                  },
                  name: {
                    type: 'string',
                  },
                },
                required: ['apiVersion', 'kind', 'name'],
                type: 'object',
                nullable: true,
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        source: {
          properties: {
            git: {
              properties: {
                ref: {
                  properties: {
                    branch: {
                      type: 'string',
                      nullable: true,
                    },
                    commit: {
                      type: 'string',
                      nullable: true,
                    },
                    tag: {
                      type: 'string',
                      nullable: true,
                    },
                  },
                  type: 'object',
                  nullable: true,
                },
                url: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
            image: {
              type: 'string',
              nullable: true,
            },
            subPath: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
      },
      type: 'object',
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        resources: {
          items: {
            properties: {
              inputs: {
                items: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              name: {
                type: 'string',
              },
              outputs: {
                items: {
                  properties: {
                    digest: {
                      type: 'string',
                    },
                    lastTransitionTime: {
                      format: 'date-time',
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                    preview: {
                      type: 'string',
                    },
                  },
                  required: ['digest', 'lastTransitionTime', 'name', 'preview'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              stampedRef: {
                properties: {
                  apiVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  fieldPath: {
                    type: 'string',
                    nullable: true,
                  },
                  kind: {
                    type: 'string',
                    nullable: true,
                  },
                  name: {
                    type: 'string',
                    nullable: true,
                  },
                  namespace: {
                    type: 'string',
                    nullable: true,
                  },
                  resourceVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  uid: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              templateRef: {
                properties: {
                  apiVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  fieldPath: {
                    type: 'string',
                    nullable: true,
                  },
                  kind: {
                    type: 'string',
                    nullable: true,
                  },
                  name: {
                    type: 'string',
                    nullable: true,
                  },
                  namespace: {
                    type: 'string',
                    nullable: true,
                  },
                  resourceVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  uid: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        supplyChainRef: {
          properties: {
            apiVersion: {
              type: 'string',
              nullable: true,
            },
            kind: {
              type: 'string',
              nullable: true,
            },
            name: {
              type: 'string',
              nullable: true,
            },
            namespace: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IWorkload {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'Workload';
  metadata: IObjectMeta;
  /**
   * Spec describes the workload. More info: https://cartographer.sh/docs/latest/reference/workload/#workload
   */
  spec: {
    /**
     * Build configuration, for the build resources in the supply chain
     */
    build?: {
      /**
       * Env is an array of environment variables to propagate to build resources in the supply chain. See https://kubernetes.io/docs/tasks/inject-data-application/environment-variable-expose-pod-information/
       */
      env?: Array<{
        /**
         * Name of the environment variable. Must be a C_IDENTIFIER.
         */
        name: string;
        /**
         * Variable references $(VAR_NAME) are expanded using the previously defined environment variables in the container and any service environment variables. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless of whether the variable exists or not. Defaults to "".
         */
        value?: string;
        /**
         * Source for the environment variable's value. Cannot be used if value is not empty.
         */
        valueFrom?: {
          /**
           * Selects a key of a ConfigMap.
           */
          configMapKeyRef?: {
            /**
             * The key to select.
             */
            key: string;
            /**
             * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
             */
            name?: string;
            /**
             * Specify whether the ConfigMap or its key must be defined
             */
            optional?: boolean;
          };
          /**
           * Selects a field of the pod: supports metadata.name, metadata.namespace, `metadata.labels['<KEY>']`, `metadata.annotations['<KEY>']`, spec.nodeName, spec.serviceAccountName, status.hostIP, status.podIP, status.podIPs.
           */
          fieldRef?: {
            /**
             * Version of the schema the FieldPath is written in terms of, defaults to "v1".
             */
            apiVersion?: string;
            /**
             * Path of the field to select in the specified API version.
             */
            fieldPath: string;
          };
          /**
           * Selects a resource of the container: only resources limits and requests (limits.cpu, limits.memory, limits.ephemeral-storage, requests.cpu, requests.memory and requests.ephemeral-storage) are currently supported.
           */
          resourceFieldRef?: {
            /**
             * Container name: required for volumes, optional for env vars
             */
            containerName?: string;
            /**
             * Specifies the output format of the exposed resources, defaults to "1"
             */
            divisor?: number | string;
            /**
             * Required: resource to select
             */
            resource: string;
          };
          /**
           * Selects a key of a secret in the pod's namespace
           */
          secretKeyRef?: {
            /**
             * The key of the secret to select from.  Must be a valid secret key.
             */
            key: string;
            /**
             * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
             */
            name?: string;
            /**
             * Specify whether the Secret or its key must be defined
             */
            optional?: boolean;
          };
        };
      }>;
    };
    /**
     * Environment variables to be passed to the main container running the application. See https://kubernetes.io/docs/tasks/inject-data-application/environment-variable-expose-pod-information/
     */
    env?: Array<{
      /**
       * Name of the environment variable. Must be a C_IDENTIFIER.
       */
      name: string;
      /**
       * Variable references $(VAR_NAME) are expanded using the previously defined environment variables in the container and any service environment variables. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless of whether the variable exists or not. Defaults to "".
       */
      value?: string;
      /**
       * Source for the environment variable's value. Cannot be used if value is not empty.
       */
      valueFrom?: {
        /**
         * Selects a key of a ConfigMap.
         */
        configMapKeyRef?: {
          /**
           * The key to select.
           */
          key: string;
          /**
           * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
           */
          name?: string;
          /**
           * Specify whether the ConfigMap or its key must be defined
           */
          optional?: boolean;
        };
        /**
         * Selects a field of the pod: supports metadata.name, metadata.namespace, `metadata.labels['<KEY>']`, `metadata.annotations['<KEY>']`, spec.nodeName, spec.serviceAccountName, status.hostIP, status.podIP, status.podIPs.
         */
        fieldRef?: {
          /**
           * Version of the schema the FieldPath is written in terms of, defaults to "v1".
           */
          apiVersion?: string;
          /**
           * Path of the field to select in the specified API version.
           */
          fieldPath: string;
        };
        /**
         * Selects a resource of the container: only resources limits and requests (limits.cpu, limits.memory, limits.ephemeral-storage, requests.cpu, requests.memory and requests.ephemeral-storage) are currently supported.
         */
        resourceFieldRef?: {
          /**
           * Container name: required for volumes, optional for env vars
           */
          containerName?: string;
          /**
           * Specifies the output format of the exposed resources, defaults to "1"
           */
          divisor?: number | string;
          /**
           * Required: resource to select
           */
          resource: string;
        };
        /**
         * Selects a key of a secret in the pod's namespace
         */
        secretKeyRef?: {
          /**
           * The key of the secret to select from.  Must be a valid secret key.
           */
          key: string;
          /**
           * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
           */
          name?: string;
          /**
           * Specify whether the Secret or its key must be defined
           */
          optional?: boolean;
        };
      };
    }>;
    /**
     * Image refers to a pre-built image in a registry. It is an alternative to specifying the location of source code for the workload. Specify one of `spec.source` or `spec.image`.
     */
    image?: string;
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * Name of the parameter. Should match a blueprint or template parameter name.
       */
      name: string;
      /**
       * Value of the parameter.
       */
      value: any;
    }>;
    /**
     * Resource constraints for the application. See https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
     */
    resources?: {
      /**
       * Limits describes the maximum amount of compute resources allowed. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
       */
      limits?: {
        [key: string]: number | string;
      };
      /**
       * Requests describes the minimum amount of compute resources required. If Requests is omitted for a container, it defaults to Limits if that is explicitly specified, otherwise to an implementation-defined value. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
       */
      requests?: {
        [key: string]: number | string;
      };
    };
    /**
     * ServiceAccountName refers to the Service account with permissions to create resources submitted by the supply chain.
     *  If not set, Cartographer will use serviceAccountName from supply chain.
     *  If that is also not set, Cartographer will use the default service account in the workload's namespace.
     */
    serviceAccountName?: string;
    /**
     * ServiceClaims to be bound through ServiceBindings.
     */
    serviceClaims?: Array<{
      name: string;
      ref?: {
        apiVersion: string;
        kind: string;
        name: string;
      };
    }>;
    /**
     * The location of the source code for the workload. Specify one of `spec.source` or `spec.image`
     */
    source?: {
      /**
       * Source code location in a git repository.
       */
      git?: {
        ref?: {
          branch?: string;
          commit?: string;
          tag?: string;
        };
        url?: string;
      };
      /**
       * OCI Image in a repository, containing the source code to be used throughout the supply chain.
       */
      image?: string;
      /**
       * Subpath inside the Git repository or Image to treat as the root of the application. Defaults to the root if left empty.
       */
      subPath?: string;
    };
  };
  /**
   * Status conforms to the Kubernetes conventions: https://github.com/kubernetes/community/blob/master/contributors/devel/sig-architecture/api-conventions.md#typical-status-properties
   */
  status?: {
    /**
     * Conditions describing this resource's reconcile state. The top level condition is of type `Ready`, and follows these Kubernetes conventions: https://github.com/kubernetes/community/blob/master/contributors/devel/sig-architecture/api-conventions.md#typical-status-properties
     */
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    /**
     * ObservedGeneration refers to the metadata.Generation of the spec that resulted in the current `status`.
     */
    observedGeneration?: number;
    /**
     * Resources contain references to the objects created by the Supply Chain and the templates used to create them. It also contains Inputs and Outputs that were passed between the templates as the Supply Chain was processed.
     */
    resources?: Array<{
      /**
       * Inputs are references to resources that were used to template the object in StampedRef
       */
      inputs?: Array<{
        /**
         * Name is the name of the resource in the blueprint whose output the resource consumes as an input
         */
        name: string;
      }>;
      /**
       * Name is the name of the resource in the blueprint
       */
      name: string;
      /**
       * Outputs are values from the object in StampedRef that can be consumed by other resources
       */
      outputs?: Array<{
        /**
         * Digest is a sha256 of the full value of the output
         */
        digest: string;
        /**
         * LastTransitionTime is a timestamp of the last time the value changed
         */
        lastTransitionTime: string;
        /**
         * Name is the output type generated from the resource [url, revision, image or config]
         */
        name: string;
        /**
         * Preview is a preview of the value of the output
         */
        preview: string;
      }>;
      /**
       * StampedRef is a reference to the object that was created by the resource
       */
      stampedRef?: {
        /**
         * API version of the referent.
         */
        apiVersion?: string;
        /**
         * If referring to a piece of an object instead of an entire object, this string should contain a valid JSON/Go field access statement, such as desiredState.manifest.containers[2]. For example, if the object reference is to a container within a pod, this would take on a value like: "spec.containers{name}" (where "name" refers to the name of the container that triggered the event) or if no container name is specified "spec.containers[2]" (container with index 2 in this pod). This syntax is chosen only to have some well-defined way of referencing a part of an object. TODO: this design is not final and this field is subject to change in the future.
         */
        fieldPath?: string;
        /**
         * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
         */
        kind?: string;
        /**
         * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names
         */
        name?: string;
        /**
         * Namespace of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces/
         */
        namespace?: string;
        /**
         * Specific resourceVersion to which this reference is made, if any. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
         */
        resourceVersion?: string;
        /**
         * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#uids
         */
        uid?: string;
      };
      /**
       * TemplateRef is a reference to the template used to create the object in StampedRef
       */
      templateRef?: {
        /**
         * API version of the referent.
         */
        apiVersion?: string;
        /**
         * If referring to a piece of an object instead of an entire object, this string should contain a valid JSON/Go field access statement, such as desiredState.manifest.containers[2]. For example, if the object reference is to a container within a pod, this would take on a value like: "spec.containers{name}" (where "name" refers to the name of the container that triggered the event) or if no container name is specified "spec.containers[2]" (container with index 2 in this pod). This syntax is chosen only to have some well-defined way of referencing a part of an object. TODO: this design is not final and this field is subject to change in the future.
         */
        fieldPath?: string;
        /**
         * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
         */
        kind?: string;
        /**
         * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names
         */
        name?: string;
        /**
         * Namespace of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces/
         */
        namespace?: string;
        /**
         * Specific resourceVersion to which this reference is made, if any. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
         */
        resourceVersion?: string;
        /**
         * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#uids
         */
        uid?: string;
      };
    }>;
    /**
     * SupplyChainRef is the Supply Chain resource that was used when this status was set.
     */
    supplyChainRef?: {
      apiVersion?: string;
      kind?: string;
      name?: string;
      namespace?: string;
    };
  };
}

export class Workload extends Model<IWorkload> implements IWorkload {
  'apiVersion': IWorkload['apiVersion'];
  'kind': IWorkload['kind'];
  'metadata': IWorkload['metadata'];
  'spec': IWorkload['spec'];
  'status'?: IWorkload['status'];

  static apiVersion: IWorkload['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IWorkload['kind'] = 'Workload';
  static is = createTypeMetaGuard<IWorkload>(Workload);

  constructor(data?: ModelData<IWorkload>) {
    super({
      apiVersion: Workload.apiVersion,
      kind: Workload.kind,
      ...data,
    } as IWorkload);
  }
}

setSchema(Workload, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
