import { PipelineRun as Model } from '../../../kubernetes-additional-crds/gen/tekton.dev/v1';
import { PipelineRun } from '../PipelineRun';
import type { IPipelineRun } from '../../../kubernetes-additional-crds/gen/tekton.dev/v1';
import type { IRepository } from '../../../repository';

export class PipelineRunV1 extends PipelineRun<IPipelineRun> {
  public static readonly typeMeta = Model;

  public classRef = PipelineRunV1;

  public constructor(
    resource: IPipelineRun,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
