import { useQueries } from '@tanstack/react-query';
import { useCallback } from 'react';
import { fetchKubernetesData } from '../../apis/fetchKubernetesData';
import type { ICluster } from './types';
import type { KubernetesLocalProxyApi } from '../../apis';
import { FetchingResult, ProxyResponse } from '../../types';

interface Props {
  kubernetesLocalProxyApi: KubernetesLocalProxyApi;
  resourcePath: string;
  project: string;
  space: string;
  clusters: ICluster[];
}

export const useKubernetesData = ({
  kubernetesLocalProxyApi,
  resourcePath,
  clusters,
  project,
  space,
}: Props): FetchingResult => {
  const queries = clusters.flatMap((c: ICluster) => {
    return resourcePath.split(',').map(r => ({
      cluster: c,
      path: r,
    }));
  });

  const dataResult = useQueries<ProxyResponse[]>({
    queries: queries.map(query => {
      return {
        queryKey: [
          'cluster',
          query.cluster.name,
          query.path,
          kubernetesLocalProxyApi,
          project,
          space,
        ],
        queryFn: async () =>
          fetchKubernetesData(
            kubernetesLocalProxyApi,
            query.path,
            query.cluster,
            project,
            space,
          ),
        retry: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  const refetchAll = useCallback(() => {
    dataResult.map(async result => result.refetch());
  }, [dataResult]);

  const dataUpdatedAt = dataResult.map(value => value.dataUpdatedAt);

  if (dataResult.some(dR => dR.status === 'loading')) {
    return {
      isLoading: true,
      data: [],
      refresh: refetchAll,
      lastRefreshed: new Date(dataUpdatedAt[0]),
    };
  }

  const data = dataResult.map(value => value.data as ProxyResponse);

  return {
    isLoading: false,
    data: data.length ? data : [],
    refresh: refetchAll,
    lastRefreshed: new Date(dataUpdatedAt[0]),
  };
};
