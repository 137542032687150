import { ClusterDeploymentTemplate as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterDeploymentTemplate } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterDeploymentTemplate extends AbstractModel<IClusterDeploymentTemplate> {
  public static readonly humanType = 'Cartographer Cluster Deployment Template';

  public static readonly typeMeta = Model;

  public classRef = ClusterDeploymentTemplate;

  public constructor(
    resource: IClusterDeploymentTemplate,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
