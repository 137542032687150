import type { FetchResponse } from '@backstage/plugin-kubernetes-common';
import { ProxyResponse } from '../../types';

export const emptyProxyResponse: ProxyResponse = {
  type: '' as FetchResponse['type'],
  apiVersion: '',
  items: [],
  kind: '',
  metadata: { continue: '', resourceVersion: '' },
  cluster: '',
};
