import React from 'react';
import {
  AppPluginInterface,
  AppRouteSurface,
  EntityPageSurface,
} from '@vmware-tanzu/core-frontend';
import { TapDoraPage } from '@tpb/dora-plugin';
import { EntityLayout } from '@backstage/plugin-catalog';

export const DORAPlugin: AppPluginInterface = () => context => {
  context.applyWithDependency(
    AppRouteSurface,
    EntityPageSurface,
    // @ts-ignore routes that is not used
    (routes, surface) => {
      surface.servicePage.addTab(
        <EntityLayout.Route path="/dora" title="DORA">
          <TapDoraPage />
        </EntityLayout.Route>,
      );
    },
  );
};
