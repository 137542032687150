import { ICluster } from '../../proxy/hook/types';
import { ProxyResponse, responseTypes, v1ResponseTypes } from '../../types';

export const formatSuccessProxyResponse = (
  cluster: ICluster,
  data: ProxyResponse,
): ProxyResponse => {
  data.cluster = cluster.name;
  data.type = extractTypeFromResponse(data);

  return {
    ...data,
    cluster: cluster.name,
    items: data.items ?? [data],
  };
};

const extractTypeFromResponse = (data: ProxyResponse) => {
  const responseType = data.kind.replace('List', 's').toLowerCase();
  const dataType =
    responseTypes.includes(responseType) ||
    v1ResponseTypes.includes(responseType)
      ? responseType
      : 'customresources';

  return dataType as ProxyResponse['type'];
};
