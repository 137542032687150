import { Project as Model } from '../../kubernetes-additional-crds/gen/ucp.tanzu.vmware.com/v1/';
import { AbstractModel } from '../AbstractModel';
import type { IProject } from '../../kubernetes-additional-crds/gen/ucp.tanzu.vmware.com/v1';
import type { IRepository } from '../../repository';

export class Project extends AbstractModel<IProject> {
  public static readonly humanType = 'UCP Project';

  public static readonly typeMeta = Model;

  public classRef = Project;

  public constructor(
    resource: IProject,
    repository: IRepository,
    space: string,
  ) {
    super(resource, repository, space, Model);
  }
}
