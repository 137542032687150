import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { PromotionAcceleratorAPIResponse, PromotionApi } from '../types';

/**
 * The API Client to interact with the app accelerator backend promotion endpoints
 *
 * @public
 */
export class PromotionApiClient implements PromotionApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getAcceleratorPromoted(
    namespace: string,
    name: string,
  ): Promise<boolean> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'accelerator',
    )}/${namespace}/${name}/promoted`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
    });
    if (response.ok) {
      const responseData = await response.json();
      if (responseData && responseData.promoted) {
        return true;
      }
    }
    return false;
  }
  async promoteAccelerator(
    namespace: string,
    name: string,
  ): Promise<PromotionAcceleratorAPIResponse> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'accelerator',
    )}/${namespace}/${name}/promoted`;
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
    });
    const responseData = await response.json();
    const success = response.ok && responseData && responseData.success;
    const resp: PromotionAcceleratorAPIResponse = {
      success,
    };
    return resp;
  }
  async demoteAccelerator(
    namespace: string,
    name: string,
  ): Promise<PromotionAcceleratorAPIResponse> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'accelerator',
    )}/${namespace}/${name}/promoted`;
    const response = await this.fetchApi.fetch(url, {
      method: 'DELETE',
    });
    const responseData = await response.json();
    const success = response.ok && responseData && responseData.success;
    const resp: PromotionAcceleratorAPIResponse = {
      success,
    };
    return resp;
  }
}
