import get from 'lodash.get';
import { set } from 'lodash';
import binaryExtensions from 'binary-extensions';

const FILE_EXTENSION = {
  js: 'javascript',
  ts: 'typescript',
  md: 'markdown',
  kt: 'kotlin',
  rb: 'ruby',
  kts: 'kotlin',
  gradle: 'groovy',
  gitignore: 'git',
  cmd: 'bash',
  java: 'java',
  go: 'go',
  xml: 'xml',
  yaml: 'yaml',
  yml: 'yaml',
  properties: 'properties',
  groovy: 'groovy',
  txt: 'md',
  json: 'json',
  rest: 'json',
  cs: 'csharp',
  csproj: 'xml',
  fsproj: 'xml',
  fs: 'fsharp',
  props: 'properties',
  targets: 'properties',
  dotsettings: 'xml',
};

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
  const b64DataUp = b64Data.replace('data:application/zip;base64,', '');
  const byteCharacters = atob(b64DataUp);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getLanguage = (file: string): string => {
  if (!file.includes(`.`)) {
    return 'text';
  }
  const extension = file.split(`.`).pop() || '';
  const type = get(FILE_EXTENSION, extension, null);
  if (!type && binaryExtensions.indexOf(extension) === -1) {
    return 'text';
  }
  return type || '';
};

export const createTree = (
  files: any,
  path: string,
  fileName: string,
  zip: any,
): Promise<any> =>
  new Promise(resolve => {
    const filesArray: any = {};
    const recursive = (
      pfiles: any,
      ppath: any,
      pfileName: any,
      pzip: any,
      pdepth: any,
    ) => {
      const type = pfiles[ppath].dir ? 'folder' : 'file';
      const item = {
        type,
        filename: pfileName,
        path: `/${ppath}`,
        hidden: pdepth === 1 && type === 'folder' ? true : null,
        children: [],
        language: '',
        content: null,
      };
      if (type === 'folder') {
        const children: Array<any> = [];
        pzip.folder(ppath).forEach((relativePath: any, file: any) => {
          const pathArray = relativePath.split('/');
          if (pathArray.length === 1 || (file.dir && pathArray.length === 2)) {
            children.push(
              recursive(
                pfiles,
                ppath + relativePath,
                relativePath,
                pzip,
                pdepth + 1,
              ),
            );
          }
        });

        set(
          item,
          'children',
          children.sort((a, b) => (a.path > b.path ? 1 : -1)),
        );
        // item.children = children.sort((a, b) => (a.path > b.path ? 1 : -1));
        item.filename = pfileName.substring(0, pfileName.length - 1);
      } else {
        item.language = getLanguage(item.filename);
        if (item.language) {
          pfiles[ppath].async('string').then((content: any) => {
            item.content = content;
          });
        }
        filesArray[`${item.path}`] = item;
      }
      return item;
    };
    const tree = recursive(files, path, fileName, zip, 0);
    const selected: any = tree.children.find(
      (item: any) =>
        [
          'pom.xml',
          'build.gradle',
          'build.gradle.kts',
          'Gemfile',
          'main.go',
        ].indexOf(item.filename) > -1 ||
        item.filename.toLowerCase().startsWith('readme.'),
    );
    if (selected) {
      const x = get(selected, 'path', '').substring(1);
      files[x].async('string').then((content: any) => {
        set(selected, 'content', content);
        // selected.content = content;
        resolve({ tree, selected, files: filesArray });
      });
    } else {
      resolve({ tree, selected: null, files: filesArray });
    }
  });

export const findRoot = (zip: any): string => {
  const root = Object.keys(zip.files).filter(filename => {
    const pathArray = filename.split('/').filter((path: string) => path !== '');
    if (zip.files[filename].dir && pathArray.length === 1) {
      return true;
    }
    return false;
  });
  if (!root) {
    return '';
  }
  return root[0].replace('/', '');
};
