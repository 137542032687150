import {
  Configuration as Model,
  Revision,
  Service,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import { isString } from 'lodash';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import {
  ConditionCategory,
  conditionsFromResource,
  prioritizedConditions,
} from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList, Condition } from '../Condition';
import type {
  IConfiguration,
  IService,
  IRevision,
} from '@kubernetes-models/knative/serving.knative.dev/v1';

const conditionsLabels = {
  Ready: { True: 'Ready', False: 'Not Ready', Unknown: 'Ready Status Unknown' },
};

export class Configuration extends AbstractModel<IConfiguration> {
  public static readonly humanType = 'Knative Configuration';

  public static readonly typeMeta = Model;

  public classRef = Configuration;

  public constructor(
    resource: IConfiguration,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    const { status } = this.resource;

    const latestRevision = this.getLatestRevisionCondition(status);
    const conditions = compact(
      [
        conditionsFromResource(this.resource, conditionsLabels),
        latestRevision,
      ].flat(),
    );

    return prioritizedConditions(conditions);
  }

  public get owners(): MappedResource<IService>[] {
    return compact([this.service]);
  }

  public get revisions(): MappedResource<IRevision>[] {
    return this.repository.ownedOfType<IRevision>(this, Revision);
  }

  public get service(): MappedResource<IService> | undefined {
    return this.repository.ownerOfType<IService>(this, Service);
  }

  private getLatestRevisionCondition(
    status: IConfiguration['status'] | undefined,
  ): Condition | undefined {
    if (!isString(status?.latestCreatedRevisionName)) {
      return undefined;
    }
    return {
      category:
        status?.latestCreatedRevisionName === status?.latestReadyRevisionName
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: this.resource.status?.latestCreatedRevisionName ?? '',
      type: 'Latest Revision',
      title: 'Latest Revision',
    };
  }
}
