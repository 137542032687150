import { Build as Model } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import { AbstractModel } from '../AbstractModel';
import type { IBuild } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import type { IRepository } from '../../repository';
import type { ImagePullSecret, ISource, IStatus } from '../BaseTypes';

export type BuildStatus = IStatus & {
  podName?: string;
};

export interface IBuildSpec {
  tags: string[];
  serviceAccountName?: string;
  builder: {
    image: string;
    imagePullSecrets?: ImagePullSecret[];
  };
  source: ISource;
}

export class Build extends AbstractModel<IBuild> {
  public static readonly humanType = 'Kpack Build';

  public static readonly typeMeta = Model;

  public classRef = Build;

  public constructor(
    resource: IBuild,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get spec(): IBuildSpec | undefined {
    // @ts-expect-error: Explicit typecast. `spec` is sometimes defined but `IBuild` lacks the declaration
    return this.resource.spec as IBuildSpec | undefined;
  }

  public get buildStatus(): BuildStatus | undefined {
    // @ts-expect-error: Explicit typecast. `status` is sometimes defined but `IBuild` lacks the declaration
    return this.resource.status as BuildStatus | undefined;
  }
}
