import { ICluster } from '../../proxy/hook/types';
import { networkErrorTypes } from '../networkErrorTypes';
import { emptyProxyResponse } from '../../proxy/hook/emptyProxyResponse';
import { NotFoundProxyResponse } from '../../types';

export const formatNotFoundProxyResponseWithData = (
  cluster: ICluster,
  resourcePath: string,
  data: NotFoundProxyResponse,
): NotFoundProxyResponse => {
  return {
    ...emptyProxyResponse,
    cluster: cluster.name,
    error: {
      message: `path ${resourcePath}: ${data.message}`,
      status: `${data.code}`,
      statusCode: data.code,
      errorType:
        data.reason && data.reason in networkErrorTypes
          ? networkErrorTypes[data.reason]
          : networkErrorTypes.NotFound,
      resourcePath: resourcePath,
    },
  };
};
