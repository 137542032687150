import { StatusCodes } from 'http-status-codes';
import { ICluster } from '../../proxy/hook/types';
import { networkErrorTypes } from '../networkErrorTypes';
import { emptyProxyResponse } from '../../proxy/hook/emptyProxyResponse';
import { ErrorProxyResponse, ProxyException } from '../../types';

export const formatErrorProxyResponseWithData = (
  cluster: ICluster,
  resourcePath: string,
  data: ErrorProxyResponse,
): ErrorProxyResponse => {
  const response = data.response;
  const reason = (response as ProxyException).reason;

  return {
    ...emptyProxyResponse,
    cluster: cluster.name,
    error: {
      message: data.error.message,
      status: response
        ? response.statusCode.toString()
        : StatusCodes.BAD_REQUEST.toString(),
      statusCode: response ? response.statusCode : StatusCodes.BAD_REQUEST,
      errorType:
        response && reason && reason in networkErrorTypes
          ? networkErrorTypes[reason]
          : networkErrorTypes.BadRequest,
      resourcePath: resourcePath,
    },
  };
};
