import {
  MetadataStoreApi,
  Package,
  PaginatedResponse,
  Vulnerability,
  VulnerabilityAnalysisUIDResponse,
  VulnerabilityAnalysisResponse,
  VulnerabilitySeverity,
  SearchReportResponse,
  ReportResponse,
  EntityType,
} from '../api/MetadataStoreApi';
import { parsePckgVulns } from '../api/MetadataStoreApiClient';

const fakeAnalysesResponse: PaginatedResponse<VulnerabilityAnalysisResponse> = {
  Count: 0,
  CurrentPage: 1,
  PageSize: 1,
  LastPage: 1,
  Results: [],
};

const fakeSearchReportResponse: PaginatedResponse<SearchReportResponse> = {
  Count: 0,
  CurrentPage: 1,
  PageSize: 1,
  LastPage: 1,
  Results: [],
};

const fakeReportResponse: ReportResponse = {
  entity: {
    artifact_groups: [],
    digest: '',
    host: '',
    name: '',
    organization: '',
    packages: [],
    registry: '',
    repository: '',
    sha: '',
    type: EntityType.IMAGE,
  },
  generated_at: '',
  original_location: '',
  original_location_file_path: '',
  tool: {
    name: '',
    vendor: '',
    version: '',
  },
  uid: '',
};

const fakeReportsResponse: PaginatedResponse<ReportResponse> = {
  Count: 0,
  CurrentPage: 1,
  PageSize: 1,
  LastPage: 1,
  Results: [],
};

export const useMockMetadataStoreApi = () => {
  const mockMetadataStoreApi: MetadataStoreApi = {
    getPackageWithVulnerabilities: () => Promise.resolve({} as Package),
    getVulnerabilityByID: () => Promise.resolve({} as Vulnerability),
    getSourceVulnerabilities: () => Promise.resolve([]),
    getImageScanVulnerabilities: () => Promise.resolve([]),
    parseVulnerabilities: () => [],
    parsePackageVulnerabilities: parsePckgVulns,
    getSeverity: () => VulnerabilitySeverity.UNKNOWN,
    getArtifactsGroups: () => Promise.resolve([]),
    getVulnerabilitiesArtifactGroups: () => Promise.resolve([]),
    getVulnerabilities: () => Promise.resolve([]),
    getHighestReachVulnerabilities: () => Promise.resolve([]),
    getImageSBOM: () => Promise.resolve({}),
    getSourceSBOM: () => Promise.resolve({}),
    createVulnerabilityAnalysis: () =>
      Promise.resolve({} as VulnerabilityAnalysisUIDResponse),
    getVulnerabilityAnalyses: () => Promise.resolve(fakeAnalysesResponse),
    getReports: () => Promise.resolve(fakeSearchReportResponse),
    getReportById: () => Promise.resolve(fakeReportResponse),
    getReportByIds: () => Promise.resolve(fakeReportsResponse),
    getLatestReports: () => Promise.resolve(fakeReportsResponse),
    getReportSeverity: () => VulnerabilitySeverity.UNKNOWN,
    getSBOMByReport: () => Promise.resolve({}),
    parseReportPackageVulnerabilities: () => [],
    sortBySeverity: () => [],
  };
  return mockMetadataStoreApi;
};
