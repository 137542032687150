import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterDelivery';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterDelivery'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        params: {
          items: {
            properties: {
              default: {
                oneOf: [
                  {},
                  {
                    type: 'null',
                  },
                ],
              },
              name: {
                type: 'string',
              },
              value: {
                oneOf: [
                  {},
                  {
                    type: 'null',
                  },
                ],
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        resources: {
          items: {
            properties: {
              configs: {
                items: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                    resource: {
                      type: 'string',
                    },
                  },
                  required: ['name', 'resource'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              deployment: {
                properties: {
                  resource: {
                    type: 'string',
                  },
                },
                required: ['resource'],
                type: 'object',
                nullable: true,
              },
              name: {
                type: 'string',
              },
              params: {
                items: {
                  properties: {
                    default: {
                      oneOf: [
                        {},
                        {
                          type: 'null',
                        },
                      ],
                    },
                    name: {
                      type: 'string',
                    },
                    value: {
                      oneOf: [
                        {},
                        {
                          type: 'null',
                        },
                      ],
                    },
                  },
                  required: ['name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              sources: {
                items: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                    resource: {
                      type: 'string',
                    },
                  },
                  required: ['name', 'resource'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              templateRef: {
                properties: {
                  kind: {
                    enum: [
                      'ClusterSourceTemplate',
                      'ClusterDeploymentTemplate',
                      'ClusterTemplate',
                      'ClusterConfigTemplate',
                    ],
                    type: 'string',
                  },
                  name: {
                    minLength: 1,
                    type: 'string',
                    nullable: true,
                  },
                  options: {
                    items: {
                      properties: {
                        name: {
                          minLength: 1,
                          type: 'string',
                        },
                        selector: {
                          properties: {
                            matchExpressions: {
                              items: {
                                properties: {
                                  key: {
                                    type: 'string',
                                  },
                                  operator: {
                                    type: 'string',
                                  },
                                  values: {
                                    items: {
                                      type: 'string',
                                    },
                                    type: 'array',
                                    nullable: true,
                                  },
                                },
                                required: ['key', 'operator'],
                                type: 'object',
                              },
                              type: 'array',
                              nullable: true,
                            },
                            matchFields: {
                              items: {
                                properties: {
                                  key: {
                                    minLength: 1,
                                    type: 'string',
                                  },
                                  operator: {
                                    enum: [
                                      'In',
                                      'NotIn',
                                      'Exists',
                                      'DoesNotExist',
                                    ],
                                    type: 'string',
                                  },
                                  values: {
                                    items: {
                                      type: 'string',
                                    },
                                    type: 'array',
                                    nullable: true,
                                  },
                                },
                                required: ['key', 'operator'],
                                type: 'object',
                              },
                              type: 'array',
                              nullable: true,
                            },
                            matchLabels: {
                              additionalProperties: {
                                type: 'string',
                              },
                              type: 'object',
                              properties: {},
                              nullable: true,
                            },
                          },
                          type: 'object',
                        },
                      },
                      required: ['name', 'selector'],
                      type: 'object',
                    },
                    minItems: 2,
                    type: 'array',
                    nullable: true,
                  },
                },
                required: ['kind'],
                type: 'object',
              },
            },
            required: ['name', 'templateRef'],
            type: 'object',
          },
          type: 'array',
        },
        selector: {
          additionalProperties: {
            type: 'string',
          },
          type: 'object',
          properties: {},
          nullable: true,
        },
        selectorMatchExpressions: {
          items: {
            properties: {
              key: {
                type: 'string',
              },
              operator: {
                type: 'string',
              },
              values: {
                items: {
                  type: 'string',
                },
                type: 'array',
                nullable: true,
              },
            },
            required: ['key', 'operator'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        selectorMatchFields: {
          items: {
            properties: {
              key: {
                minLength: 1,
                type: 'string',
              },
              operator: {
                enum: ['In', 'NotIn', 'Exists', 'DoesNotExist'],
                type: 'string',
              },
              values: {
                items: {
                  type: 'string',
                },
                type: 'array',
                nullable: true,
              },
            },
            required: ['key', 'operator'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        serviceAccountRef: {
          properties: {
            name: {
              type: 'string',
            },
            namespace: {
              type: 'string',
              nullable: true,
            },
          },
          required: ['name'],
          type: 'object',
          nullable: true,
        },
      },
      required: ['resources'],
      type: 'object',
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterDelivery {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterDelivery';
  metadata: IObjectMeta;
  /**
   * Spec describes the delivery. More info: https://cartographer.sh/docs/latest/reference/deliverable/#clusterdelivery
   */
  spec: {
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner does not specify this parameter, this value is used.
       */
      default?: any;
      /**
       * Name of the parameter. Should match a template parameter name.
       */
      name: string;
      /**
       * Value of the parameter. If specified, owner properties are ignored.
       */
      value?: any;
    }>;
    /**
     * Resources that are responsible for deploying and validating the deliverable
     */
    resources: Array<{
      /**
       * Configs is a list of references to other 'config' resources in this list. A config resource has the kind ClusterConfigTemplate
       *  In a template, configs can be consumed as: $(configs.<name>.config)$
       *  If there is only one image, it can be consumed as: $(config)$
       */
      configs?: Array<{
        name: string;
        resource: string;
      }>;
      /**
       * Deployment is a reference to a 'deployment' resource. A deployment resource has the kind ClusterDeploymentTemplate
       *  In a template, the deployment can be consumed as: $(deployment.url)$ and $(deployment.revision)$
       */
      deployment?: {
        resource: string;
      };
      /**
       * Name of the resource. Used as a reference for inputs, as well as being the name presented in deliverable statuses to identify this resource.
       */
      name: string;
      /**
       * Params are a list of parameters to provide to the template in TemplateRef Template params do not have to be specified here, unless you want to force a particular value, or add a default value.
       *  Parameters are consumed in a template with the syntax: $(params.<name>)$
       */
      params?: Array<{
        /**
         * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner does not specify this parameter, this value is used.
         */
        default?: any;
        /**
         * Name of the parameter. Should match a template parameter name.
         */
        name: string;
        /**
         * Value of the parameter. If specified, owner properties are ignored.
         */
        value?: any;
      }>;
      /**
       * Sources is a list of references to other 'source' resources in this list. A source resource has the kind ClusterSourceTemplate or ClusterDeploymentTemplate
       *  In a template, sources can be consumed as: $(sources.<name>.url)$ and $(sources.<name>.revision)$
       *  If there is only one source, it can be consumed as: $(source.url)$ and $(source.revision)$
       */
      sources?: Array<{
        name: string;
        resource: string;
      }>;
      /**
       * TemplateRef identifies the template used to produce this resource
       */
      templateRef: {
        /**
         * Kind of the template to apply
         */
        kind:
          | 'ClusterSourceTemplate'
          | 'ClusterDeploymentTemplate'
          | 'ClusterTemplate'
          | 'ClusterConfigTemplate';
        /**
         * Name of the template to apply
         */
        name?: string;
        /**
         * Options is a list of template names and Selector. The templates must all be of type Kind. A template will be selected if the deliverable matches the specified selector. Only one template can be selected. Only one of Name and Options can be specified.
         */
        options?: Array<{
          /**
           * Name of the template to apply
           */
          name: string;
          /**
           * Selector is a criteria to match against  a workload or deliverable resource.
           */
          selector: {
            /**
             * matchExpressions is a list of label selector requirements. The requirements are ANDed.
             */
            matchExpressions?: Array<{
              /**
               * key is the label key that the selector applies to.
               */
              key: string;
              /**
               * operator represents a key's relationship to a set of values. Valid operators are In, NotIn, Exists and DoesNotExist.
               */
              operator: string;
              /**
               * values is an array of string values. If the operator is In or NotIn, the values array must be non-empty. If the operator is Exists or DoesNotExist, the values array must be empty. This array is replaced during a strategic merge patch.
               */
              values?: Array<string>;
            }>;
            /**
             * MatchFields is a list of field selector requirements. The requirements are ANDed.
             */
            matchFields?: Array<{
              /**
               * Key is the JSON path in the workload to match against. e.g. for workload: "workload.spec.source.git.url", e.g. for deliverable: "deliverable.spec.source.git.url"
               */
              key: string;
              /**
               * Operator represents a key's relationship to a set of values. Valid operators are In, NotIn, Exists and DoesNotExist.
               */
              operator: 'In' | 'NotIn' | 'Exists' | 'DoesNotExist';
              /**
               * Values is an array of string values. If the operator is In or NotIn, the values array must be non-empty. If the operator is Exists or DoesNotExist, the values array must be empty.
               */
              values?: Array<string>;
            }>;
            /**
             * matchLabels is a map of {key,value} pairs. A single {key,value} in the matchLabels map is equivalent to an element of matchExpressions, whose key field is "key", the operator is "In", and the values array contains only "value". The requirements are ANDed.
             */
            matchLabels?: {
              [key: string]: string;
            };
          };
        }>;
      };
    }>;
    /**
     * Specifies the label key-value pairs used to select owners See: https://cartographer.sh/docs/v0.1.0/architecture/#selectors
     */
    selector?: {
      [key: string]: string;
    };
    /**
     * Specifies the requirements used to select owners based on their labels See: https://cartographer.sh/docs/v0.1.0/architecture/#selectors
     */
    selectorMatchExpressions?: Array<{
      /**
       * key is the label key that the selector applies to.
       */
      key: string;
      /**
       * operator represents a key's relationship to a set of values. Valid operators are In, NotIn, Exists and DoesNotExist.
       */
      operator: string;
      /**
       * values is an array of string values. If the operator is In or NotIn, the values array must be non-empty. If the operator is Exists or DoesNotExist, the values array must be empty. This array is replaced during a strategic merge patch.
       */
      values?: Array<string>;
    }>;
    /**
     * Specifies the requirements used to select owners based on their fields See: https://cartographer.sh/docs/v0.1.0/architecture/#selectors
     */
    selectorMatchFields?: Array<{
      /**
       * Key is the JSON path in the workload to match against. e.g. for workload: "workload.spec.source.git.url", e.g. for deliverable: "deliverable.spec.source.git.url"
       */
      key: string;
      /**
       * Operator represents a key's relationship to a set of values. Valid operators are In, NotIn, Exists and DoesNotExist.
       */
      operator: 'In' | 'NotIn' | 'Exists' | 'DoesNotExist';
      /**
       * Values is an array of string values. If the operator is In or NotIn, the values array must be non-empty. If the operator is Exists or DoesNotExist, the values array must be empty.
       */
      values?: Array<string>;
    }>;
    /**
     * ServiceAccountName refers to the Service account with permissions to create resources submitted by the supply chain.
     *  If not set, Cartographer will use serviceAccountName from supply chain.
     *  If that is also not set, Cartographer will use the default service account in the workload's namespace.
     */
    serviceAccountRef?: {
      /**
       * Name of the service account being referred to
       */
      name: string;
      /**
       * Namespace of the service account being referred to if omitted, the Owner's namespace is used.
       */
      namespace?: string;
    };
  };
  /**
   * Status conforms to the Kubernetes conventions: https://github.com/kubernetes/community/blob/master/contributors/devel/sig-architecture/api-conventions.md#typical-status-properties
   */
  status?: {
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    observedGeneration?: number;
  };
}

export class ClusterDelivery
  extends Model<IClusterDelivery>
  implements IClusterDelivery
{
  'apiVersion': IClusterDelivery['apiVersion'];
  'kind': IClusterDelivery['kind'];
  'metadata': IClusterDelivery['metadata'];
  'spec': IClusterDelivery['spec'];
  'status'?: IClusterDelivery['status'];

  static apiVersion: IClusterDelivery['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IClusterDelivery['kind'] = 'ClusterDelivery';
  static is = createTypeMetaGuard<IClusterDelivery>(ClusterDelivery);

  constructor(data?: ModelData<IClusterDelivery>) {
    super({
      apiVersion: ClusterDelivery.apiVersion,
      kind: ClusterDelivery.kind,
      ...data,
    } as IClusterDelivery);
  }
}

setSchema(ClusterDelivery, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
