import { ICluster } from '../../proxy/hook/types';
import { networkErrorTypes } from '../networkErrorTypes';
import { emptyProxyResponse } from '../../proxy/hook/emptyProxyResponse';
import { StatusCodes } from 'http-status-codes';
import { ErrorProxyResponse } from '../../types';

export const formatJSONParseError = (
  cluster: ICluster,
  resourcePath: string,
  data: TypeError,
): ErrorProxyResponse => {
  return {
    ...emptyProxyResponse,
    cluster: cluster.name,
    error: {
      message: `path ${resourcePath}: ${data.message}`,
      status: StatusCodes.UNPROCESSABLE_ENTITY.toString(),
      statusCode: StatusCodes.UNPROCESSABLE_ENTITY,
      errorType: networkErrorTypes.ParseError,
      resourcePath: resourcePath,
    },
  };
};
