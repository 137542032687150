import React, { useEffect } from 'react';
import { LoginSurface, ProviderConfig } from './LoginSurface';
import {
  configApiRef,
  ConfigApi,
  SignInPageProps,
  useApi,
} from '@backstage/core-plugin-api';
import { Link, SignInPage, UserIdentity } from '@backstage/core-components';

export type SignInPageWrapperProps = SignInPageProps & {
  loginSurface: LoginSurface;
};

function isGuestProviderOnly(enabledProviders: ProviderConfig[]): boolean {
  return enabledProviders.length === 1 && enabledProviders[0] === 'guest';
}

function shouldSkipLoginPage(configApi: ConfigApi): boolean {
  const skip = configApi.getOptionalBoolean('auth.skipLoginPageForGuestAccess');
  return skip === undefined ? false : skip.valueOf();
}

export function SignInPageWrapper(props: SignInPageWrapperProps) {
  const configApi = useApi(configApiRef);
  const enabledProviders = props.loginSurface.enabledProviders(configApi);
  const signInGuestAutomatically =
    isGuestProviderOnly(enabledProviders) && shouldSkipLoginPage(configApi);

  useEffect(() => {
    if (signInGuestAutomatically) {
      props.onSignInSuccess(UserIdentity.createGuest());
    }
  }, [props, signInGuestAutomatically]);

  if (enabledProviders.length > 0) {
    return <SignInPage {...props} providers={enabledProviders} />;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      No configured authentication providers. Please configure at least one.
      Refer to{' '}
      <Link to="https://docs.vmware.com/en/VMware-Tanzu-Application-Platform/1.6/tap/tap-gui-auth.html">
        the documentation
      </Link>{' '}
      for configuring authentication providers.
    </div>
  );
}
