import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'kpack.io.v1alpha2.ClusterBuilder';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['kpack.io/v1alpha2'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterBuilder'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IClusterBuilder {
  apiVersion: 'kpack.io/v1alpha2';
  kind: 'ClusterBuilder';
  metadata?: IObjectMeta;
}

export class ClusterBuilder
  extends Model<IClusterBuilder>
  implements IClusterBuilder
{
  'apiVersion': IClusterBuilder['apiVersion'];
  'kind': IClusterBuilder['kind'];
  'metadata'?: IClusterBuilder['metadata'];

  static apiVersion: IClusterBuilder['apiVersion'] = 'kpack.io/v1alpha2';
  static kind: IClusterBuilder['kind'] = 'ClusterBuilder';
  static is = createTypeMetaGuard<IClusterBuilder>(ClusterBuilder);

  constructor(data?: ModelData<IClusterBuilder>) {
    super({
      apiVersion: ClusterBuilder.apiVersion,
      kind: ClusterBuilder.kind,
      ...data,
    } as IClusterBuilder);
  }
}

setSchema(ClusterBuilder, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
