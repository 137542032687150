import { merge } from 'lodash';
import { v4 as uuid } from 'uuid';
import type {
  IDeliverable,
  IWorkload,
} from '../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IApp } from '../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import type {
  V1Pod,
  V1Deployment,
  V1ReplicaSet,
  V1Service,
} from '@kubernetes/client-node';
import type { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import type {
  IService,
  IRevision,
  IRoute,
  IConfiguration,
} from '@kubernetes-models/knative/serving.knative.dev/v1';

export enum ResourcesEnum {
  Pod = 'Pod',
  Deployment = 'Deployment',
  ReplicaSet = 'ReplicaSet',
  Service = 'Service',
  KntService = 'KntService',
  Revision = 'Revision',
  Configuration = 'Configuration',
  Route = 'Route',
  Workload = 'Workload',
  Deliverable = 'Deliverable',
  Kapp = 'Kapp',
}

type K8SResources =
  | IApp
  | IConfiguration
  | IDeliverable
  | IRevision
  | IRoute
  | IService
  | IWorkload
  | V1Deployment
  | V1Pod
  | V1ReplicaSet
  | V1Service;

interface GenericResource<T, K, U> {
  apiVersion: string;
  kind: string;
  metadata: U;
  spec?: T;
  status?: K;
}

interface CustomProps<T, K, U> {
  uid: string;
  labels?: IObjectMeta['labels'];
  ownerReferences?: IObjectMeta['ownerReferences'];
  metadataParam?: U;
  namespace?: string;
  spec?: T;
  status?: K;
}

const resources = {
  Pod: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'v1',
      kind: 'Pod',
      metadata: {
        name: `pod-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Deployment: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'apps/v1',
      kind: 'Deployment',
      metadata: {
        name: `deployment-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  ReplicaSet: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'apps/v1',
      kind: 'ReplicaSet',
      metadata: {
        name: `replicaSet-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };
    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Service: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'v1',
      kind: 'Service',
      metadata: {
        name: `service-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  KntService: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'serving.knative.dev/v1',
      kind: 'Service',
      metadata: {
        name: `knservice-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Revision: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'serving.knative.dev/v1',
      kind: 'Revision',
      metadata: {
        name: `revision-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Configuration: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'serving.knative.dev/v1',
      kind: 'Configuration',
      metadata: {
        name: `configuration-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Route: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'serving.knative.dev/v1',
      kind: 'Route',
      metadata: {
        name: `route-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Workload: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'carto.run/v1alpha1',
      kind: 'Workload',
      metadata: {
        name: `workload-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Deliverable: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'carto.run/v1alpha1',
      kind: 'Deliverable',
      metadata: {
        name: `deliverable-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
  Kapp: <T extends K8SResources>({
    uid,
    labels,
    spec,
    status,
    ownerReferences,
    namespace,
    metadataParam,
  }: CustomProps<T['spec'], T['status'], T['metadata']>): GenericResource<
    T['spec'],
    T['status'],
    T['metadata']
  > => {
    const base = {
      apiVersion: 'kappctrl.k14s.io/v1alpha1',
      kind: 'App',
      metadata: {
        name: `app-test-${uid}`,
        uid,
        namespace: namespace ?? 'test',
      },
    };

    const metadata = merge(metadataParam, { labels }, { ownerReferences });
    return merge(base, { metadata, spec, status });
  },
};

export const resourceGenerator = <T extends K8SResources>(
  type: ResourcesEnum,
  props?: Partial<CustomProps<T['spec'], T['status'], T['metadata']>>,
): GenericResource<T['spec'], T['status'], T['metadata']> => {
  const UID = uuid();
  const uid = props?.uid ?? UID;
  const resourceFunction = resources[type];
  const resource = resourceFunction<T>({ uid, ...props });
  return resource;
};
