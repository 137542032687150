import { SharedUnionFieldsDeep } from 'type-fest';
import { IGitRepository as IGitRepositoryV1 } from '../../kubernetes-additional-crds/gen/source.toolkit.fluxcd.io/v1';
import { IGitRepository as IGitRepositoryV1Beta1 } from '../../kubernetes-additional-crds/gen/source.toolkit.fluxcd.io/v1beta1';
import { IGitRepository as IGitRepositoryV1Beta2 } from '../../kubernetes-additional-crds/gen/source.toolkit.fluxcd.io/v1beta2';
import { AbstractModel } from '../AbstractModel';
import { IRepository } from '../../repository';

type GitRepositoryCommon = SharedUnionFieldsDeep<
  IGitRepositoryV1 | IGitRepositoryV1Beta1 | IGitRepositoryV1Beta2
>;

export abstract class GitRepository<
  T extends GitRepositoryCommon = GitRepositoryCommon,
> extends AbstractModel<T> {
  public static readonly humanType = 'Flux CD GitRepository';

  public constructor(
    resource: T,
    repository: IRepository,
    cluster: string,
    concreteModel: T,
  ) {
    super(resource, repository, cluster, concreteModel);
  }
}
