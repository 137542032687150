import { AbstractModel } from '../AbstractModel';
import type { IPipelineRunStatus } from './models';
import type { IPipelineRun as IPipelineRunV1 } from '../../kubernetes-additional-crds/gen/tekton.dev/v1/PipelineRun';
import type { IPipelineRun as IPipelineRunV1Beta1 } from '../../kubernetes-additional-crds/gen/tekton.dev/v1beta1/PipelineRun';
import type { IRepository } from '../../repository';
import type { SharedUnionFieldsDeep } from 'type-fest';

type PipelineRunCommon = SharedUnionFieldsDeep<
  IPipelineRunV1 | IPipelineRunV1Beta1
>;

export abstract class PipelineRun<
  T extends PipelineRunCommon = PipelineRunCommon,
> extends AbstractModel<T> {
  public static readonly humanType = 'Tekton PipelineRun';

  public constructor(
    resource: T,
    repository: IRepository,
    cluster: string,
    concreteModel: T,
  ) {
    super(resource, repository, cluster, concreteModel);
  }

  public get pipelineRunStatus(): IPipelineRunStatus | undefined {
    // @ts-expect-error: Explicit typecast. `status` is sometimes defined but `IPipelineRun` lacks the declaration
    return this.resource.status as IPipelineRunStatus | undefined;
  }
}
