import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import type { IconComponent } from '@backstage/core-plugin-api';

const useStyles = makeStyles(() => {
  return {
    supplyChain: {
      stroke: 'currentColor',
      fill: 'currentColor',
      width: 16,
      height: 16,
    },
  };
});

export const SupplyChain: IconComponent = (props): React.ReactElement => {
  const styles = useStyles();

  return (
    <SvgIcon {...props} className={styles.supplyChain} viewBox="0 0 16 16">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill=""
      >
        <circle cx="8" cy="8" r="2.5" stroke="" fill="none" />
        <circle cx="8" cy="8" r="2.5" stroke="" fill="none" />
        <rect x="6.5" y="0.5" width="3" height="3" fill="" stroke="" />
        <rect x="6.5" y="12.5" width="3" height="3" fill="" stroke="" />
        <rect x="0.5" y="6.5" width="3" height="3" fill="" stroke="" />
        <rect x="12.5" y="6.5" width="3" height="3" fill="" stroke="" />
      </svg>
    </SvgIcon>
  );
};
