import { ScanTemplate as Model } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1';
import { AbstractModel } from '../AbstractModel';
import type { IScanTemplate } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1';
import type { IRepository } from '../../repository';

export class ScanTemplate extends AbstractModel<IScanTemplate> {
  public static readonly humanType = 'Scanning ScanTemplate';

  public static readonly typeMeta = Model;

  public classRef = ScanTemplate;

  public constructor(
    resource: IScanTemplate,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
