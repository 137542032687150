import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterRunTemplate';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterRunTemplate'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        outputs: {
          additionalProperties: {
            type: 'string',
          },
          type: 'object',
          properties: {},
          nullable: true,
        },
        template: {
          type: 'object',
          properties: {},
        },
      },
      required: ['template'],
      type: 'object',
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterRunTemplate {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterRunTemplate';
  metadata: IObjectMeta;
  /**
   * Spec describes the run template. More info: https://cartographer.sh/docs/latest/reference/runnable/#clusterruntemplate
   */
  spec: {
    /**
     * Outputs are a named list of jsonPaths that are used to gather results from the last successful object stamped by the template. E.g: 	my-output: .status.results[?(@.name=="IMAGE-DIGEST")].value Note: outputs are only filled on the runnable when the templated object has a Succeeded condition with a Status of True E.g:     status.conditions[?(@.type=="Succeeded")].status == True a runnable creating an object without a Succeeded condition (like a Job or ConfigMap) will never display an output
     */
    outputs?: {
      [key: string]: string;
    };
    /**
     * Template defines a resource template for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    template: {};
  };
}

export class ClusterRunTemplate
  extends Model<IClusterRunTemplate>
  implements IClusterRunTemplate
{
  'apiVersion': IClusterRunTemplate['apiVersion'];
  'kind': IClusterRunTemplate['kind'];
  'metadata': IClusterRunTemplate['metadata'];
  'spec': IClusterRunTemplate['spec'];

  static apiVersion: IClusterRunTemplate['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IClusterRunTemplate['kind'] = 'ClusterRunTemplate';
  static is = createTypeMetaGuard<IClusterRunTemplate>(ClusterRunTemplate);

  constructor(data?: ModelData<IClusterRunTemplate>) {
    super({
      apiVersion: ClusterRunTemplate.apiVersion,
      kind: ClusterRunTemplate.kind,
      ...data,
    } as IClusterRunTemplate);
  }
}

setSchema(ClusterRunTemplate, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
