import { PersistentVolume as Model } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { IPersistentVolume } from 'kubernetes-models/v1';

export class PersistentVolume extends AbstractModel<IPersistentVolume> {
  public static readonly humanType = 'Persistent Volume';

  public static readonly typeMeta = Model;

  public classRef = PersistentVolume;

  public constructor(
    resource: IPersistentVolume,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
