import type {
  KubernetesApi,
  KubernetesAuthProvidersApi,
} from '@backstage/plugin-kubernetes';
import type { KubernetesRequestBody } from '@backstage/plugin-kubernetes-common';

export const fetchAuthMetadata = async (
  clusterName: string,
  kubernetesApi: KubernetesApi,
  kubernetesAuthProvidersApi: KubernetesAuthProvidersApi,
): Promise<string> => {
  const cluster = (await kubernetesApi.getClusters()).find(
    c => c.name === clusterName,
  );

  if (!cluster) {
    throw new Error(`Cluster '${clusterName}' could not be found`);
  }

  let subOidcToken = '';
  if (cluster.oidcTokenProvider !== undefined && cluster.oidcTokenProvider) {
    subOidcToken = `.${cluster.oidcTokenProvider}`;
  }
  const authProvider = cluster.authProvider + subOidcToken;

  const dummyRequest =
    await kubernetesAuthProvidersApi.decorateRequestBodyForAuth(
      authProvider,
      {} as KubernetesRequestBody,
    );

  return JSON.stringify(dummyRequest.auth ?? {});
};
