import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterImageTemplate';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterImageTemplate'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        imagePath: {
          type: 'string',
        },
        params: {
          items: {
            properties: {
              default: {},
              name: {
                type: 'string',
              },
            },
            required: ['default', 'name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        template: {
          type: 'object',
          properties: {},
          nullable: true,
        },
        ytt: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['imagePath'],
      type: 'object',
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterImageTemplate {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterImageTemplate';
  metadata: IObjectMeta;
  /**
   * Spec describes the image template. More info: https://cartographer.sh/docs/latest/reference/template/#clusterimagetemplate
   */
  spec: {
    /**
     * ImagePath is a path into the templated object's data that contains a valid image digest. This might be a URL or in some cases just a repository path and digest. The final spec for this field may change as we implement RFC-0016 https://github.com/vmware-tanzu/cartographer/blob/main/rfc/rfc-0016-validate-template-outputs.md ImagePath is specified in jsonpath format, eg: .status.artifact.image_digest
     */
    imagePath: string;
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner or Template does not specify this parameter, this value is used.
       */
      default: any;
      /**
       * Name of a parameter the template accepts from the Blueprint or Owner.
       */
      name: string;
    }>;
    /**
     * Template defines a resource template for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    template?: {};
    /**
     * Ytt defines a resource template written in `ytt` for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    ytt?: string;
  };
}

export class ClusterImageTemplate
  extends Model<IClusterImageTemplate>
  implements IClusterImageTemplate
{
  'apiVersion': IClusterImageTemplate['apiVersion'];
  'kind': IClusterImageTemplate['kind'];
  'metadata': IClusterImageTemplate['metadata'];
  'spec': IClusterImageTemplate['spec'];

  static apiVersion: IClusterImageTemplate['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IClusterImageTemplate['kind'] = 'ClusterImageTemplate';
  static is = createTypeMetaGuard<IClusterImageTemplate>(ClusterImageTemplate);

  constructor(data?: ModelData<IClusterImageTemplate>) {
    super({
      apiVersion: ClusterImageTemplate.apiVersion,
      kind: ClusterImageTemplate.kind,
      ...data,
    } as IClusterImageTemplate);
  }
}

setSchema(ClusterImageTemplate, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
