import { Trait as Model } from '../../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { ITrait } from '../../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import type { IRepository } from '../../repository';

export class Trait extends AbstractModel<ITrait> {
  public static readonly humanType = 'Tanzu Trait';

  public static readonly typeMeta = Model;

  public classRef = Trait;

  public constructor(resource: ITrait, repository: IRepository) {
    super(resource, repository, 'TBD', Model);
  }
}
