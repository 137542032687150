import { StatusCodes } from 'http-status-codes';
import type {
  KubernetesFetchError,
  StatusError,
  RawFetchError,
} from '@backstage/plugin-kubernetes-common';

export class RepositoryError {
  public readonly cluster: string;

  public readonly error: KubernetesFetchError;

  public constructor(cluster: string, error: KubernetesFetchError) {
    this.cluster = cluster;
    this.error = error;
  }
}

enum ErrorType {
  'StatusError' = 'StatusError',
  'RawFetchError' = 'RawFetchError',
}

/**
 * We want to make sure we correctly classify each possible error code the backend can produce. This list of codes is
 * subject to change with new versions, though, so we need to make sure our comparisons are exhaustive.
 *
 * We rely on two mechanisms:
 * - The switch-exhaustiveness-check ESLint rule. (https://typescript-eslint.io/rules/switch-exhaustiveness-check/)
 * - The exhaustive guard statement technique. (https://www.meticulous.ai/blog/safer-exhaustive-switch-statements-in-typescript)
 *
 * Try it: delete one of the case statements below and you should get a compiler error.
 */
const classifyError = (error: KubernetesFetchError): ErrorType => {
  const _exhaustiveGuard = (_err: never): never => {
    throw new Error(`Unanticipated error received: ${error.errorType}`);
  };

  switch (error.errorType) {
    case 'BAD_REQUEST':
    case 'NOT_FOUND':
    case 'SYSTEM_ERROR':
    case 'UNAUTHORIZED_ERROR':
    case 'UNKNOWN_ERROR':
      return ErrorType.StatusError;
    case 'FETCH_ERROR':
      return ErrorType.RawFetchError;
    default:
      return _exhaustiveGuard(error);
  }
};

export function isStatusError(
  error: KubernetesFetchError,
): error is StatusError {
  return classifyError(error) === ErrorType.StatusError;
}

export function isRawFetchError(
  error: KubernetesFetchError,
): error is RawFetchError {
  return classifyError(error) === ErrorType.RawFetchError;
}

export const UNDEFINED_RESOURCE_PATH = 'UNDEFINED_RESOURCE_PATH';

export function resolveDefaultStatusError(error: StatusError): {
  resourcePath: string;
  statusCode: number;
} {
  return {
    resourcePath: error.resourcePath ?? UNDEFINED_RESOURCE_PATH,
    statusCode: error.statusCode ?? StatusCodes.INTERNAL_SERVER_ERROR,
  };
}
