import { SourceScan as Model } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1/SourceScan';
import { AbstractModel } from '../AbstractModel';
import type { ISourceScan } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1/SourceScan';
import type { IRepository } from '../../repository';

export class SourceScan extends AbstractModel<ISourceScan> {
  public static readonly humanType = 'Scanning SourceScan';

  public static readonly typeMeta = Model;

  public classRef = SourceScan;

  public constructor(
    resource: ISourceScan,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
