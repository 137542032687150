import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const useCustomization = () => {
  const configApi = useApi(configApiRef);
  const appTitle =
    configApi.getOptional('app.title') || 'Tanzu Application Platform';
  const orgName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';
  const catalogDocLink =
    'https://docs.vmware.com/en/VMware-Tanzu-Application-Platform/1.7/tap/tap-gui-catalog-catalog-operations.html';

  return {
    appTitle,
    catalogDocLink,
    orgName,
  };
};
