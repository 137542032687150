import { compact } from 'lodash';
import get from 'lodash/get';
import type { ObjectMap } from '../../ClassMap';
import { createMapGuard, getMappedCtor } from '../../ClassMap';
import {
  ConfigMap,
  CronJob,
  CustomResourceDefinition,
  DaemonSet,
  Deployment,
  Event,
  HorizontalPodAutoscaler,
  Ingress,
  Job,
  LimitRange,
  Namespace,
  PersistentVolume,
  Pod,
  PodTemplate,
  ReplicaSet,
  Secret,
  Service,
  ServiceAccount,
  StatefulSet,
} from '../../model/kubernetes';
import { RepositoryProxyError } from './RepositoryProxyError';
import type { IRepositoryProxy, ProxyResponse } from '../../types';
import type { IModel } from '../../model';
import type { TypeMeta } from '@kubernetes-models/base';

export interface BuildResult {
  objectMap: ObjectMap;
  errors: RepositoryProxyError[];
}

export interface IFactoryProxy {
  build: (response: ProxyResponse[]) => BuildResult;
}

const isTypeMeta = (value?: unknown): value is TypeMeta => {
  return (
    value !== undefined &&
    typeof get(value, 'apiVersion') === 'string' &&
    typeof get(value, 'kind') === 'string'
  );
};

const typeMetaFromResponse = (
  response: ProxyResponse,
): TypeMeta | undefined => {
  const rawTypeMeta = (): unknown => {
    switch (response.type) {
      case 'configmaps':
        return ConfigMap.typeMeta;
      case 'deployments':
        return Deployment.typeMeta;
      case 'horizontalpodautoscalers':
        return HorizontalPodAutoscaler.typeMeta;
      case 'ingresses':
        return Ingress.typeMeta;
      case 'pods':
        return Pod.typeMeta;
      case 'limitranges':
        return LimitRange.typeMeta;
      case 'namespaces':
        return Namespace.typeMeta;
      case 'events':
        return Event.typeMeta;
      case 'podtemplates':
        return PodTemplate.typeMeta;
      case 'secrets':
        return Secret.typeMeta;
      case 'serviceaccounts':
        return ServiceAccount.typeMeta;
      case 'persistentvolumes':
        return PersistentVolume.typeMeta;
      case 'replicasets':
        return ReplicaSet.typeMeta;
      case 'services':
        return Service.typeMeta;
      case 'customresources': {
        return response.items[0];
      }
      case 'daemonsets':
        return DaemonSet.typeMeta;
      case 'jobs':
        return Job.typeMeta;
      case 'statefulsets':
        return StatefulSet.typeMeta;
      case 'cronjobs':
        return CronJob.typeMeta;
      case 'customresourcedefinition':
        return CustomResourceDefinition.typeMeta;
      default:
        return undefined;
    }
  };

  const r = rawTypeMeta();

  if (!isTypeMeta(r)) {
    return undefined;
  }

  return {
    apiVersion: r.apiVersion,
    kind: r.kind,
  };
};

export class ModelFactoryProxy implements IFactoryProxy {
  private readonly repository: IRepositoryProxy;

  public constructor(repository: IRepositoryProxy) {
    this.repository = repository;
  }

  private static readonly flattenedResources = (
    response: ProxyResponse[],
  ): { clusterName: string; resource: TypeMeta }[] => {
    return response.flatMap(rsp => {
      const typeMeta = typeMetaFromResponse(rsp);

      if (typeMeta === undefined) return [];

      return rsp.items.map((resource: unknown) => {
        return {
          clusterName: rsp.cluster,
          resource: Object.assign({}, typeMeta, resource),
        };
      });
    });
  };

  public build(response: ProxyResponse[]): BuildResult {
    return {
      objectMap: this.buildObjectMap(response),
      errors: this.buildErrors(response),
    };
  }

  private buildObjectMap(response: ProxyResponse[]): ObjectMap {
    const flattenResources = compact(
      ModelFactoryProxy.flattenedResources(response).map(rawResource => {
        return this.buildResource(
          rawResource.resource,
          rawResource.clusterName,
        );
      }),
    );

    return flattenResources.reduce<ObjectMap>(
      (objectMap: ObjectMap, mappedResource: IModel) => {
        const apiVersion = mappedResource.apiVersion as keyof typeof objectMap;
        if (objectMap[apiVersion] === undefined) {
          objectMap[apiVersion] = {};
        }

        const kind =
          mappedResource.kind as keyof (typeof objectMap)[keyof typeof objectMap];

        if (objectMap[apiVersion]![kind] === undefined) {
          objectMap[apiVersion]![kind] = {} as never;
        }

        if (objectMap[apiVersion]![kind][mappedResource.id] !== undefined) {
          // Commenting this out due to some issues regards the use of the proxy along with RBAC
          // throw Error(
          //   `Encountered duplicate UID: ${mappedResource.apiVersion}/${mappedResource.kind}#${mappedResource.id}`,
          // )
          return objectMap;
        }

        objectMap[apiVersion]![kind]![mappedResource.id] =
          mappedResource as never;

        return objectMap;
      },
      {},
    );
  }

  private buildResource<T extends TypeMeta>(
    resource: T,
    clusterName: string,
  ): IModel<T> | undefined {
    const Ctor = getMappedCtor(resource);

    const mappedResource: IModel<T> = new Ctor(
      resource,
      this.repository,
      clusterName,
    ) as IModel<T>;

    if (!createMapGuard(resource)(mappedResource)) {
      throw Error(
        `Resource ${resource.apiVersion}/${resource.kind} mapped incorrectly`,
      );
    }

    return mappedResource;
  }

  private buildErrors(response: ProxyResponse[]): RepositoryProxyError[] {
    return compact(
      response.flatMap(
        rsp => rsp.error && new RepositoryProxyError(rsp.cluster, rsp.error),
      ),
    );
  }
}
