import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'conventions.carto.run.v1alpha1.ClusterPodConvention';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['conventions.carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterPodConvention'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        priority: {
          type: 'string',
          nullable: true,
        },
        selectors: {
          items: {
            properties: {
              matchExpressions: {
                items: {
                  properties: {
                    key: {
                      type: 'string',
                    },
                    operator: {
                      type: 'string',
                    },
                    values: {
                      items: {
                        type: 'string',
                      },
                      type: 'array',
                      nullable: true,
                    },
                  },
                  required: ['key', 'operator'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              matchLabels: {
                additionalProperties: {
                  type: 'string',
                },
                type: 'object',
                properties: {},
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        webhook: {
          properties: {
            certificate: {
              properties: {
                name: {
                  type: 'string',
                },
                namespace: {
                  type: 'string',
                },
              },
              required: ['name', 'namespace'],
              type: 'object',
              nullable: true,
            },
            clientConfig: {
              properties: {
                caBundle: {
                  format: 'byte',
                  type: 'string',
                  nullable: true,
                },
                service: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                    namespace: {
                      type: 'string',
                    },
                    path: {
                      type: 'string',
                      nullable: true,
                    },
                    port: {
                      format: 'int32',
                      type: 'integer',
                      nullable: true,
                    },
                  },
                  required: ['name', 'namespace'],
                  type: 'object',
                  nullable: true,
                },
                url: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
            },
          },
          required: ['clientConfig'],
          type: 'object',
          nullable: true,
        },
      },
      type: 'object',
    },
  },
  required: ['spec', 'apiVersion', 'kind'],
};

export interface IClusterPodConvention {
  apiVersion: 'conventions.carto.run/v1alpha1';
  kind: 'ClusterPodConvention';
  metadata?: IObjectMeta;
  spec: {
    priority?: string;
    selectors?: Array<{
      matchExpressions?: Array<{
        key: string;
        operator: string;
        values?: Array<string>;
      }>;
      matchLabels?: {
        [key: string]: string;
      };
    }>;
    webhook?: {
      certificate?: {
        name: string;
        namespace: string;
      };
      clientConfig: {
        caBundle?: string;
        service?: {
          name: string;
          namespace: string;
          path?: string;
          port?: number;
        };
        url?: string;
      };
    };
  };
}

export class ClusterPodConvention
  extends Model<IClusterPodConvention>
  implements IClusterPodConvention
{
  'apiVersion': IClusterPodConvention['apiVersion'];
  'kind': IClusterPodConvention['kind'];
  'metadata'?: IClusterPodConvention['metadata'];
  'spec': IClusterPodConvention['spec'];

  static apiVersion: IClusterPodConvention['apiVersion'] =
    'conventions.carto.run/v1alpha1';
  static kind: IClusterPodConvention['kind'] = 'ClusterPodConvention';
  static is = createTypeMetaGuard<IClusterPodConvention>(ClusterPodConvention);

  constructor(data?: ModelData<IClusterPodConvention>) {
    super({
      apiVersion: ClusterPodConvention.apiVersion,
      kind: ClusterPodConvention.kind,
      ...data,
    } as IClusterPodConvention);
  }
}

setSchema(ClusterPodConvention, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
