import { createApiRef } from '@backstage/core-plugin-api';

export interface ArtifactsGroupsRequest {
  cveId?: string;
  shas: string[];
  digests: string[];
  packageName?: string;
  page?: number;
  pageSize?: number;
  all?: boolean;
}

export interface ArtifactsGroupsVulnerabilityReq
  extends Omit<ArtifactsGroupsRequest, 'cveId'> {
  artifactGroupId?: string;
}

export enum SBOMFormat {
  SPDX = 'spdx2.2',
  CYCLONEDX = 'cyclonedx',
}

export enum SBOMFileFormat {
  JSON = 'application/json',
  XML = 'application/xml',
}

export enum VulnerabilityType {
  IMAGE_SCAN,
  SOURCE_SCAN,
}

export interface PaginatedResponse<T> {
  Count: number;
  CurrentPage: number;
  PageSize: number;
  LastPage: number;
  Results: T[];
}

export interface ArtifactGroup {
  uid: string;
  labels?: Labels;
  entities?: Entity[];
}

export interface ArtifactGroupVulnerability {
  ID: number;
  CVEID: string;
  URL: string;
  Description: string;
  CNA: string;
  Ratings: VulnerabilityRatings[];
  References: string[];
  Packages: Package[];
  ArtifactGroups: ArtifactGroup[];
}

export interface Labels {
  env: string;
  name: string;
  namespace: string;
}

export interface Entity {
  digest?: string;
  host?: string;
  id: number;
  name?: string;
  organization?: string;
  packages?: Package[];
  registry?: string;
  repository?: string;
  sha?: string;
  type: string;
}

export interface Vulnerability {
  ID: number;
  CVEID: string;
  URL: string;
  Description: string;
  Ratings: VulnerabilityRatings[];
  Severity: VulnerabilitySeverity;
  Packages: VulnerabilityPackage[];
  ArtifactGroups: ArtifactGroup[];
}

export interface Package {
  Homepage: string;
  ID: number;
  Name: string;
  PackageManager: string;
  Version: string;
  Vulnerabilities: Vulnerability[] | null;
}

export interface Image {
  Digest: string;
  ID: number;
  Name: string;
  Packages: string[];
  Registry: string;
  Sources: Source[];
}

export interface Source {
  DeletedAt: null;
  Host: string;
  ID: number;
  Images: string[];
  Organization: string;
  Packages: string[];
  Repository: string;
  Sha: string;
}

export interface VulnerabilityDisplay {
  id: string;
  vulnerabilityId: string;
  cveId: string;
  url: string;
  severity: VulnerabilitySeverity | string;
  package: string;
  version: string;
  description: string;
  impactedWorkloadsCount: number;
}

export interface VulnerabilityPackage {
  ID: number;
  Name: string;
  Version: string;
}
export interface VulnerabilityRatings {
  ID: string;
  Score: number;
  Severity: VulnerabilitySeverity;
  MethodTypeID: number;
}

export enum VulnerabilitySeverity {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  CRITICAL = 'Critical',
  UNKNOWN = 'Unknown',
}

export interface HighestReachVulnerabilityRequest {
  all?: boolean;
  digests: string[];
  page?: number;
  pageSize?: number;
  shas: string[];
  severities?: string[];
}

export interface HighestReachVulnerability {
  ArtifactGroupCount: number;
  Vulnerability: {
    CNA: string;
    CVEID: string;
    Description: string;
    ID: number;
    Ratings: {
      ID: number;
      MethodTypeID: number;
      Score: number;
      Severity: VulnerabilitySeverity | string;
      Vector: string;
    }[];
    References: string[] | null;
    URL: string;
  };
}

export interface VulnerabilityAnalysisPackageDependency {
  name: string;
  version: string;
}

export interface VulnerabilityAnalysisImageDependency {
  digest: string;
  registry?: string;
}

export interface VulnerabilityAnalysisSourceDependency {
  sha: string;
  repository?: string;
  organization?: string;
}

export interface AnalysisRequest {
  state: string;
  justification?: string;
  response?: string[];
  comments?: string;
}

export interface VulnerabilityAnalysisUpsertRequest {
  artifact_group_uid?: string;
  created_by?: string;
  image?: VulnerabilityAnalysisImageDependency;
  source?: VulnerabilityAnalysisSourceDependency;
  analysis?: AnalysisRequest;
  package: VulnerabilityAnalysisPackageDependency;
  vulnerability: {
    cve_id: string;
  };
}

export interface VulnerabilityAnalysisUIDResponse {
  uid: string;
}

export interface GetAnalysesRequestParams {
  digest?: string;
  registry?: string;
  commit?: string;
  repo?: string;
  org?: string;
  page?: number;
  pageSize?: number;
  all?: boolean;
  [k: string]: any | undefined;
}

export interface VulnerabilityAnalysisResponse {
  uid: string;
  artifact_group_uid?: string;
  created_by?: string;
  image?: {
    digest: string;
    name: string;
    registry?: string;
  };
  source?: VulnerabilityAnalysisSourceDependency;
  analysis: {
    state: string;
    justification?: string;
    response?: string[];
    comments?: string;
    created_by?: string;
  }[];
  package: {
    name: string;
    version: string;
    package_manager: string;
  };
  vulnerability: {
    cve_id: string;
    description?: string;
  };
}

export interface ReportToolResponse {
  name: string;
  vendor: string;
  version: string;
}

export interface SearchReportResponse {
  artifact_group: ArtifactGroup;
  entity_type: string;
  entity_uid: string;
  generated_at: string;
  original_location: string;
  original_location_file_path: string;
  tool: ReportToolResponse;
  uid: string;
}

export interface ReportArtifactGroupResponse {
  labels: {
    name?: string;
    namespace?: string;
    description?: string;
  };
  uid: string;
}

export enum EntityType {
  IMAGE = 'image',
  SOURCE = 'source',
}

export interface ReportRatingResponse {
  Score: number;
  Severity: string;
  Vector: string;
  method_type: string;
}

export interface ReportVulnerabilityResponse {
  CNA: string;
  CVEID: string;
  Description: string;
  References: string[];
  URL: string;
  ratings: ReportRatingResponse[];
}

export interface ReportPackageResponse {
  HomePage: string;
  Name: string;
  PackageManager: string;
  Version: string;
  vulnerabilities: ReportVulnerabilityResponse[];
}

export interface ReportEntityResponse {
  artifact_groups: ReportArtifactGroupResponse[];
  digest: string;
  host: string;
  name: string;
  organization: string;
  packages: ReportPackageResponse[];
  registry: string;
  repository: string;
  sha: string;
  type: EntityType;
}

export interface ReportResponse {
  entity: ReportEntityResponse;
  generated_at: string;
  original_location: string;
  original_location_file_path: string;
  tool: ReportToolResponse;
  uid: string;
}

export interface ReportParams {
  originalLocation?: string;
  digest?: string;
  sha?: string;
  artifactGroupUid?: string;
  pageSize?: number;
}

export interface LatestReportSearchParam {
  artifact_group_uid: string;
  sha?: string;
  digest?: string;
}

export interface LatestReportsRequestParams {
  all?: boolean;
  latest_reports_search_params?: LatestReportSearchParam[];
  page?: number;
  page_size?: number;
  report_uids?: string[];
}

export interface MetadataStoreApi {
  getSourceVulnerabilities: (params: any) => Promise<Vulnerability[]>;
  getImageScanVulnerabilities: (params: any) => Promise<Vulnerability[]>;
  getVulnerabilityByID: (id: string) => Promise<Vulnerability>;
  getPackageWithVulnerabilities: (packageId: string) => Promise<Package>;
  parseVulnerabilities: (
    vulnerabilities: Vulnerability[],
  ) => VulnerabilityDisplay[];
  parsePackageVulnerabilities: (pckg: Package) => VulnerabilityDisplay[];
  getArtifactsGroups: (
    artGrpReq: ArtifactsGroupsRequest,
  ) => Promise<ArtifactGroup[]>;
  getVulnerabilitiesArtifactGroups: (
    req: ArtifactsGroupsVulnerabilityReq,
  ) => Promise<ArtifactGroupVulnerability[]>;
  getSeverity: (ratings: VulnerabilityRatings[]) => VulnerabilitySeverity;
  getVulnerabilities: (
    req: ArtifactsGroupsVulnerabilityReq,
  ) => Promise<Vulnerability[]>;
  getHighestReachVulnerabilities: (
    req: HighestReachVulnerabilityRequest,
  ) => Promise<HighestReachVulnerability[]>;
  getImageSBOM: (
    imageSha: string,
    sbomFormat: SBOMFormat,
    fileFormat: SBOMFileFormat,
  ) => Promise<any>;
  getSourceSBOM: (
    sourceHash: string,
    sbomFormat: SBOMFormat,
    fileFormat: SBOMFileFormat,
  ) => Promise<any>;
  createVulnerabilityAnalysis: (
    request: VulnerabilityAnalysisUpsertRequest,
  ) => Promise<VulnerabilityAnalysisUIDResponse>;
  getVulnerabilityAnalyses: (
    params: GetAnalysesRequestParams,
  ) => Promise<PaginatedResponse<VulnerabilityAnalysisResponse>>;
  getReports: (
    params: string,
  ) => Promise<PaginatedResponse<SearchReportResponse>>;
  getReportById: (reportId: string) => Promise<ReportResponse>;
  getReportByIds: (
    reportIds: string[],
  ) => Promise<PaginatedResponse<ReportResponse>>;
  getLatestReports: (
    params: LatestReportsRequestParams,
  ) => Promise<PaginatedResponse<ReportResponse>>;
  getReportSeverity: (ratings: ReportRatingResponse[]) => string;
  parseReportPackageVulnerabilities: (
    pckg: ReportPackageResponse,
  ) => VulnerabilityDisplay[];
  sortBySeverity: (
    vulnerabilities: VulnerabilityDisplay[],
  ) => VulnerabilityDisplay[];
  getSBOMByReport: (
    reportId: string,
    sbomFormat: SBOMFormat,
    fileFormat: SBOMFileFormat,
  ) => Promise<any>;
}

export const metadataStoreApiRef = createApiRef<MetadataStoreApi>({
  id: 'plugin.metadata-store.service',
});
