import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { TreeView, TreeItem } from '@material-ui/lab';
import JSZip from 'jszip';
import React, { useCallback, useEffect, useState } from 'react';
import { findRoot, createTree, b64toBlob } from './zipUtils';
import FileSaver from 'file-saver';
import Code from './Code';
import get from 'lodash.get';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  makeStyles,
  Theme,
  createStyles,
  styled,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export type ExploreDialogProps = {
  open: boolean;
  projectName: string;
  close: any;
  linkZip?: any;
};

const TreeItemStyled = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& >div`]: {
    backgroundColor: theme.palette.background.default,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      '& >:nth-child(2)': {
        backgroundColor: theme.palette.action.hover,
        color: 'white',
      },
    },
  },
  '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
    [`& >div`]: {
      backgroundColor: `var(--tree-view-bg-color)`,
      color: 'var(--tree-view-color)',
      '&:hover >:nth-child(2)': {
        color: 'var(--tree-view-color)',
      },
    },
  },
}));

const folderTree = (item: any) => {
  if (!item) {
    return <></>;
  }
  if (item.type === 'file') {
    const label = item.selected
      ? `Selected file ${item.filename}`
      : `File ${item.filename}`;
    return (
      <TreeItemStyled
        key={item.path}
        nodeId={item.path}
        label={item.filename}
        aria-label={label}
        icon={<InsertDriveFile />}
      />
    );
  }
  return (
    <TreeItemStyled
      aria-label={`Folder ${item.filename}`}
      nodeId={item.path}
      label={item.filename}
      key={item.path}
    >
      {item.children.map((child: any) => {
        return folderTree(child);
      })}
    </TreeItemStyled>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    },
    code: {
      position: 'relative',
      minHeight: '80vh',
      maxHeight: '80vh',
      margin: '-8px',
      overflow: 'auto',
    },
    right: {
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.palette.divider,
      paddingLeft: 0,
      backgroundColor: theme.palette.background.default,
      position: 'relative',
    },
    left: {
      overflow: 'auto',
      minHeight: '80vh',
      maxHeight: '80vh',
      backgroundColor: theme.palette.background.default,
    },
    loading: {
      textAlign: 'center',
      padding: '60px 0px',
    },
    titlePaneRight: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
      padding: '8px',
      height: '42px',
      overflow: 'hidden',
    },
    titlePaneLeft: {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
      padding: '8px',
      height: '42px',
      margin: '-8px',
      marginBottom: '5px',
    },
    titlePaneText: {
      fontWeight: 'bold',
      display: 'block',
      lineHeight: '26px',
      paddingLeft: '4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
      '& h2': {
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.palette.text,
      },
    },
    actions: {
      '& button': {
        padding: '3px 9px',
        fontSize: '10px',
        lineHeight: '10px',
        height: '26px',
      },
    },
    button: {
      marginRight: '6px',
    },
  }),
);

export const ExploreDialog = (props: ExploreDialogProps) => {
  const [tree, setTree] = useState<any>(null);
  const [filesZip, setFilesZip] = useState<any>(null);

  const [button, setButton] = useState('Copy');
  const [selected, setSelected] = useState<any>(null);
  const classes = useStyles();

  const onClose = useCallback(() => {
    props.close();
    setTimeout(() => {
      setTree(null);
      setSelected(null);
    }, 200);
  }, [props]);

  useEffect(() => {
    const load = async () => {
      const zipJs = new JSZip();
      // Browser fetch supports both http(s) and data URLs... But node-fetch, which is used
      // in unit tests for the component, doesn't. So stick with a different code path depending on the kind of URL.
      const blob = props.linkZip.startsWith('data')
        ? b64toBlob(props.linkZip)
        : await fetch(props.linkZip).then(r => r.blob());
      const { files } = await zipJs.loadAsync(blob).catch(() => {
        onClose();
        throw Error(`Could not load the ZIP project.`);
      });
      const path = `${findRoot({ files })}/`;
      const result = await createTree(files, path, path, zipJs).catch(() => {
        onClose();
        throw Error(`Could not read the ZIP project.`);
      });
      setFilesZip(result.files);
      setTree(result.tree);
      setSelected(result.selected);
    };

    if (props.linkZip) {
      load();
    }
  }, [props.linkZip, setTree, setFilesZip, setSelected, onClose]);

  const onCopy = () => {
    setButton('Copied');
    setTimeout(() => {
      setButton('Copy');
    }, 3000);
  };

  const onDownload = (file: any) => {
    const blobFile = new Blob([file.content], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(blobFile, file.filename);
  };

  // const downloadZip = () => {
  //   FileSaver.saveAs(blob, props.projectName);
  // };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth={!tree ? 'xs' : 'lg'}
        open={props.open}
        onClose={() => {
          onClose();
        }}
        role="dialog"
        aria-labelledby="exploreProjectDialog"
      >
        {tree ? (
          <>
            <DialogTitle id="exploreProjectDialog" className={classes.title}>
              Explore Project
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} className={classes.container}>
                <Grid item md={3} xs={6} className={classes.left}>
                  <div className={classes.titlePaneLeft}>
                    <strong className={classes.titlePaneText}>
                      {props.projectName}
                    </strong>
                  </div>
                  <TreeView
                    defaultExpanded={[...Object.keys(filesZip)]}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    onNodeSelect={(_node: object, nodeIds: string) => {
                      const nd = filesZip[nodeIds];
                      setSelected(nd);
                    }}
                  >
                    {tree?.children?.map((item: any) => folderTree(item))}
                  </TreeView>
                </Grid>
                <Grid item md={9} xs={6} className={classes.right}>
                  {selected ? (
                    <div className={classes.code}>
                      <div className={classes.titlePaneRight}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid item>
                            <strong
                              aria-label={`File ${selected.filename}`}
                              className={classes.titlePaneText}
                            >
                              {selected.filename}
                            </strong>
                          </Grid>
                          <Grid item>
                            <div className={classes.actions}>
                              <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={() => {
                                  onDownload(selected);
                                }}
                              >
                                Download
                              </Button>
                              <CopyToClipboard
                                onCopy={onCopy}
                                text={get(selected, 'content', '')}
                              >
                                <Button
                                  variant="outlined"
                                  size="small"
                                  className={classes.button}
                                >
                                  {button}
                                </Button>
                              </CopyToClipboard>
                              {get(selected, 'language') === 'markdown' && (
                                <>
                                  <Button
                                    variant="outlined"
                                    className={classes.button}
                                    size="small"
                                    onClick={() => {
                                      const newSelected = { ...selected };
                                      newSelected.force = !get(
                                        selected,
                                        'force',
                                        false,
                                      );
                                      setSelected(newSelected);
                                    }}
                                  >
                                    {get(selected, 'force', false)
                                      ? 'Preview'
                                      : 'View source'}
                                  </Button>
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <Code item={selected} />
                    </div>
                  ) : (
                    <div className={classes.code} />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
};
