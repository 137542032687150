import { Pod as RawPod } from 'kubernetes-models/v1';
import { compact } from 'lodash';
import get from 'lodash/get';
import { createMapGuard, getMappedCtor } from '../ClassMap';
import {
  ConfigMap,
  Deployment,
  HorizontalPodAutoscaler,
  Ingress,
  Job,
  CronJob,
  Pod,
  ReplicaSet,
  StatefulSet,
  DaemonSet,
  Service,
  LimitRange,
} from '../model/kubernetes';
import { RepositoryError } from './RepositoryError';
import type { IRepository } from './Repository';
import type { ObjectMap } from '../ClassMap';
import type { IModel } from '../model';
import type {
  PodFetchResponse,
  ClientPodStatus,
  FetchResponse,
  ObjectsByEntityResponse,
} from '@backstage/plugin-kubernetes-common';
import type { V1Pod } from '@kubernetes/client-node';
import type { TypeMeta } from '@kubernetes-models/base';
import type { IDaemonSet } from 'kubernetes-models/apps/v1';
import type { ILimitRange } from 'kubernetes-models/v1';

export interface BuildResult {
  objectMap: ObjectMap;
  errors: RepositoryError[];
}

interface LimitRangeFetchResponse {
  type: 'limitranges';
  resources: ILimitRange[];
}

interface DaemonSetFetchResponse {
  type: 'daemonsets';
  resources: IDaemonSet[];
}

interface MetricInformation {
  cluster: string;
  uid?: string;
  metrics: Partial<ClientPodStatus>;
}

type FetchResponseLocal =
  | DaemonSetFetchResponse
  | FetchResponse
  | LimitRangeFetchResponse;
export type PodMetrics = Partial<ClientPodStatus> | null;

export interface IFactory {
  build: (response: ObjectsByEntityResponse) => BuildResult;
}

const isTypeMeta = (value?: unknown): value is TypeMeta => {
  // eslint-disable-next-line
  return (
    value !== undefined &&
    typeof get(value, 'apiVersion') === 'string' &&
    typeof get(value, 'kind') === 'string'
  );
};

// Backstage strips off the apiVersion/kind from their fetch response, so we reintroduce it
const typeMetaFromResponse = (
  response: FetchResponseLocal,
): TypeMeta | undefined => {
  const rawTypeMeta = (): unknown => {
    switch (response.type) {
      // eslint-disable-next-line
      // TODO FHA Check what should be returned for case podstatus
      case 'podstatus':
        return undefined;
      // return PodStatus.???
      case 'configmaps':
        return ConfigMap.typeMeta;
      case 'deployments':
        return Deployment.typeMeta;
      case 'horizontalpodautoscalers':
        return HorizontalPodAutoscaler.typeMeta;
      case 'ingresses':
        return Ingress.typeMeta;
      case 'pods':
        return Pod.typeMeta;
      case 'limitranges':
        return LimitRange.typeMeta;
      case 'replicasets':
        return ReplicaSet.typeMeta;
      case 'services':
        return Service.typeMeta;
      case 'customresources': {
        return response.resources[0];
      }
      case 'daemonsets':
        return DaemonSet.typeMeta;
      case 'jobs':
        return Job.typeMeta;
      case 'statefulsets':
        return StatefulSet.typeMeta;
      case 'cronjobs':
        return CronJob.typeMeta;
      default:
        return undefined;
    }
  };

  const r = rawTypeMeta();

  if (!isTypeMeta(r)) {
    return undefined;
  }

  return {
    apiVersion: r.apiVersion,
    kind: r.kind,
  };
};

const isPodFetchResponse = (fr: FetchResponse): fr is PodFetchResponse =>
  fr.type === 'pods';

export class ModelFactory implements IFactory {
  private readonly repository: IRepository;

  private readonly podBelongsToComponent: (pod: V1Pod) => boolean;

  public constructor(repository: IRepository, labelSelector?: string) {
    this.repository = repository;

    this.podBelongsToComponent = (pod: V1Pod): boolean => {
      const keyValuePairLength = 2;
      return (
        labelSelector?.split(',')?.reduce<boolean>((newValue, item) => {
          const matcher = item.split('=');
          if (matcher.length !== keyValuePairLength) return false;

          return (
            newValue && get(pod.metadata?.labels, matcher[0]) === matcher[1]
          );
        }, true) ?? true
      );
    };
  }

  private static readonly flattenedResources = (
    response: ObjectsByEntityResponse,
    podBelongsToComponent: (pod: V1Pod) => boolean,
  ): { clusterName: string; resource: TypeMeta }[] =>
    response.items.flatMap(clusterObj => {
      const podUIDs =
        clusterObj.resources
          .find(isPodFetchResponse)
          ?.resources.map(pod => pod.metadata?.uid) ?? [];

      const podsFromMetrics: PodFetchResponse = {
        type: 'pods',
        resources: clusterObj.podMetrics
          .map(item => item.pod)
          .filter(podBelongsToComponent)
          .filter(pod => !podUIDs.includes(pod.metadata?.uid)),
      };
      clusterObj.resources.push(podsFromMetrics);

      const items = clusterObj.resources.flatMap(fetchResponse => {
        const typeMeta = typeMetaFromResponse(fetchResponse);

        if (typeMeta === undefined) {
          return [];
        }

        return fetchResponse.resources.map((resource: unknown) => {
          return {
            clusterName: clusterObj.cluster.name,
            resource: Object.assign({}, typeMeta, resource),
          };
        });
      });

      return items;
    });

  public build(response: ObjectsByEntityResponse): BuildResult {
    return {
      objectMap: this.buildObjectMap(response),
      errors: this.buildErrors(response),
    };
  }

  private getPodMetric<T extends TypeMeta>(
    resource: T,
    clusterName: string,
    metricsData: MetricInformation[],
  ): Partial<ClientPodStatus> | null {
    if (!RawPod.is(resource)) {
      return null;
    }

    return (
      metricsData.find(
        item =>
          item.cluster === clusterName && resource.metadata?.uid === item.uid,
      )?.metrics ?? null
    );
  }

  private buildObjectMap(response: ObjectsByEntityResponse): ObjectMap {
    const metricsData = response.items.flatMap(item =>
      item.podMetrics.flatMap(podMetric => ({
        uid: podMetric.pod.metadata?.uid,
        cluster: item.cluster.name,
        metrics: {
          cpu: podMetric.cpu,
          memory: podMetric.memory,
          containers: podMetric.containers,
        },
      })),
    );

    const flattenResources = compact(
      ModelFactory.flattenedResources(response, this.podBelongsToComponent).map(
        rawResource =>
          this.buildResource(
            rawResource.resource,
            rawResource.clusterName,
            this.getPodMetric(
              rawResource.resource,
              rawResource.clusterName,
              metricsData,
            ),
          ),
      ),
    );

    return flattenResources.reduce<ObjectMap>(
      (objectMap: ObjectMap, mappedResource: IModel) => {
        // Encourage TypeScript to let us build up this ObjectMap with maybe-present keys
        /* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-condition */
        const apiVersion = mappedResource.apiVersion as keyof typeof objectMap;
        if (objectMap[apiVersion] === undefined) {
          objectMap[apiVersion] = {};
        }

        const kind =
          mappedResource.kind as keyof (typeof objectMap)[keyof typeof objectMap];

        if (objectMap[apiVersion]![kind] === undefined) {
          objectMap[apiVersion]![kind] = {} as never;
        }

        if (objectMap[apiVersion]![kind][mappedResource.id] !== undefined) {
          throw Error(
            `Encountered duplicate UID: ${mappedResource.apiVersion}/${mappedResource.kind}#${mappedResource.id}`,
          );
        }

        objectMap[apiVersion]![kind]![mappedResource.id] =
          mappedResource as never;

        return objectMap;
      },
      {},
    );
    /* eslint-enable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-condition */
  }

  private buildResource<T extends TypeMeta>(
    resource: T,
    clusterName: string,
    podMetric: PodMetrics,
  ): IModel<T> | undefined {
    const Ctor = getMappedCtor(resource);

    const mappedResource: IModel<T> =
      typeof Ctor === typeof Pod
        ? (new Ctor(
            resource,
            this.repository,
            clusterName,
            podMetric,
          ) as IModel<T>)
        : (new Ctor(resource, this.repository, clusterName) as IModel<T>);

    if (!createMapGuard(resource)(mappedResource)) {
      // This error indicates the class itself is mapped incorrectly
      throw Error(
        `Resource ${resource.apiVersion}/${resource.kind} mapped incorrectly`,
      );
    }

    return mappedResource;
  }

  private buildErrors(response: ObjectsByEntityResponse): RepositoryError[] {
    return response.items.flatMap(clusterObj =>
      clusterObj.errors.flatMap(error => {
        return new RepositoryError(clusterObj.cluster.name, error);
      }),
    );
  }
}
