import { DaemonSet, ReplicaSet, StatefulSet } from 'kubernetes-models/apps/v1';
import { Job } from 'kubernetes-models/batch/v1';
import { LimitRange, Pod as Model } from 'kubernetes-models/v1';
import { isUndefined } from 'lodash';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import {
  ConditionCategory,
  conditionsFromResource,
  prioritizedConditions,
} from '../Condition';
import { CPUMetric } from '../metrics/CPUMetric';
import { MemoryMetric } from '../metrics/MemoryMetric';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { PodMetrics } from '../../repository/ModelFactory';
import type { ConditionList, Condition } from '../Condition';
import type { IMetric, MetricConstructor } from '../metrics/IMetric';
import type { IQuantity } from '@kubernetes-models/apimachinery/api/resource';
import type {
  IReplicaSet,
  IStatefulSet,
  IDaemonSet,
} from 'kubernetes-models/apps/v1';
import type { IJob } from 'kubernetes-models/batch/v1';
import type {
  ILimitRange,
  IPod,
  IResourceRequirements,
  IPodStatus,
} from 'kubernetes-models/v1';

const conditionsLabels = {
  ContainersReady: {
    True: 'Containers Ready',
    False: 'Containers Not Ready',
    Unknown: 'Containers Status Undetermined',
  },
  PodScheduled: {
    True: 'Scheduled',
    False: 'Not Scheduled',
    Unknown: 'Unknown Scheduled Status',
  },
  Initialized: {
    True: 'Initialized',
    False: 'Not Initialized',
    Unknown: 'Initialized Status Undetermined',
  },
  Ready: { True: 'Ready', False: 'Not Ready', Unknown: 'Ready Status Unknown' },
};

export type PodOwnersTypes = IDaemonSet | IJob | IReplicaSet | IStatefulSet;

export class Pod extends AbstractModel<IPod> {
  public static readonly humanType = 'Pod';

  public static readonly typeMeta = Model;

  public classRef = Pod;

  private readonly _metrics: PodMetrics | undefined;

  public constructor(
    resource: IPod,
    repository: IRepository,
    cluster: string,
    metrics?: PodMetrics,
  ) {
    super(resource, repository, cluster, Model);
    this._metrics = metrics;
  }

  public get metrics(): PodMetrics | undefined {
    return this._metrics;
  }

  public get owners(): MappedResource<PodOwnersTypes>[] {
    return compact([
      this.replicaSet,
      this.job,
      this.statefulSet,
      this.daemonSet,
    ]);
  }

  public get replicaSet(): MappedResource<IReplicaSet> | undefined {
    return this.repository.ownerOfType<IReplicaSet>(this, ReplicaSet);
  }

  public get daemonSet(): MappedResource<IDaemonSet> | undefined {
    return this.repository.ownerOfType<IDaemonSet>(this, DaemonSet);
  }

  public get job(): MappedResource<IJob> | undefined {
    return this.repository.ownerOfType<IJob>(this, Job);
  }

  public get statefulSet(): MappedResource<IStatefulSet> | undefined {
    return this.repository.ownerOfType<IStatefulSet>(this, StatefulSet);
  }

  public get conditions(): ConditionList {
    const { status } = this.resource;
    const phase = this.getPhaseCondition(status);

    if (phase !== undefined) {
      return {
        primary: phase,
        other: conditionsFromResource(this.resource, conditionsLabels),
      };
    }
    return prioritizedConditions(
      conditionsFromResource(this.resource, conditionsLabels),
      'Ready',
    );
  }

  public get limitRanges(): Record<string, IQuantity> {
    const namespace = this.resource.metadata?.namespace ?? 'default';
    const limitRangeLimits = this.repository
      .ofType<ILimitRange>(LimitRange)
      .find(
        lr =>
          lr.cluster === this.cluster &&
          lr.resource.metadata?.namespace === namespace,
      )?.resource.spec?.limits;

    const limitCPUMax =
      limitRangeLimits?.find(l => l.type === 'Container')?.max?.cpu ?? '';
    const limitMemoryMax =
      limitRangeLimits?.find(l => l.type === 'Container')?.max?.memory ?? '';
    return {
      limitCPUMax,
      limitMemoryMax,
    };
  }

  public get resources(): Required<IResourceRequirements> {
    const resources = compact(
      this.resource.spec?.containers.map(c => c.resources),
    );
    return {
      limits: {
        cpu: this.totalCPU(resources.map(r => r.limits?.cpu ?? '')),
        memory: this.totalMemory(resources.map(r => r.limits?.memory ?? '')),
      },
      requests: {
        cpu: this.totalCPU(resources.map(r => r.requests?.cpu ?? '')),
        memory: this.totalMemory(resources.map(r => r.requests?.memory ?? '')),
      },
      claims: [],
    };
  }

  private totalCPU(resources: IQuantity[]): IQuantity {
    return this.totalResources(resources, CPUMetric);
  }

  private totalMemory(resources: IQuantity[]): IQuantity {
    return this.totalResources(resources, MemoryMetric);
  }

  private totalResources<T extends IMetric<T>>(
    resources: IQuantity[],
    ctor: MetricConstructor<T>,
  ): IQuantity {
    if (resources.some(r => r === '')) return 'Unlimited';
    // eslint-disable-next-line new-cap
    const magnitude = resources.map(r => new ctor(r));
    if (magnitude.some((bt: T) => typeof bt.value === 'number' && bt.value < 0))
      return 'Unlimited';
    const totalBytes = magnitude.reduce((acc, current) => acc.add(current));
    return totalBytes.format();
  }

  private getPhaseCondition(
    status: IPodStatus | undefined,
  ): Condition | undefined {
    if (isUndefined(status) || isUndefined(status.phase)) {
      return undefined;
    }

    const _category = (): ConditionCategory => {
      switch (status.phase) {
        case 'Pending':
          return ConditionCategory.warning;
        case 'Failed':
          return ConditionCategory.error;
        case 'Unknown':
          return ConditionCategory.unknown;
        default:
          return ConditionCategory.healthy;
      }
    };

    return {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status.phase}`,
      type: `${status.phase}`,
      title: `${status.phase}`,
    };
  }
}
