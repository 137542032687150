import { SidebarItem } from '@backstage/core-components';
import { configApiRef, createApiFactory } from '@backstage/core-plugin-api';
import { ToggleFeature, ToggleRoute } from '@tpb/feature-flags';
import {
  MetadataStoreApiClient,
  metadataStoreApiRef,
} from '@tpb/metadata-store-interface';
// import {
//   kubernetesLocalProxyApiRef,
//   KubernetesLocalProxyClient,
// } from '@tpb/kubernetes-orm';
import {
  ApiSurface,
  AppPluginInterface,
  AppRouteSurface,
  SidebarItemSurface,
} from '@vmware-tanzu/core-frontend';
import React from 'react';
import { SupplyChainIcon, SupplyChainPage } from '../plugin';

export const SupplyChainPlugin: AppPluginInterface = () => context => {
  context.applyTo(AppRouteSurface, routes => {
    routes.add(
      <ToggleRoute
        feature="customize.features.supplyChain.enabled"
        path="/supply-chain"
        element={<SupplyChainPage />}
      />,
    );
  });
  context.applyTo(ApiSurface, surface => {
    surface.add(
      createApiFactory({
        api: metadataStoreApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => new MetadataStoreApiClient({ configApi }),
      }),
    );
    // surface.add(
    //   createApiFactory({
    //     api: kubernetesLocalProxyApiRef,
    //     deps: {
    //       discoveryApi: discoveryApiRef,
    //       identityApi: identityApiRef,
    //       kubernetesAuthProvidersApi: kubernetesAuthProvidersApiRef,
    //     },
    //     factory: ({ discoveryApi, identityApi, kubernetesAuthProvidersApi }) =>
    //       new KubernetesLocalProxyClient({
    //         discoveryApi,
    //         identityApi,
    //         kubernetesAuthProvidersApi,
    //       }),
    //   })
    // );
  });
  context.applyTo(SidebarItemSurface, sidebar =>
    sidebar.addMainItem(
      <ToggleFeature feature="customize.features.supplyChain.showInSidebar">
        <SidebarItem
          icon={SupplyChainIcon}
          to="supply-chain"
          text="Supply Chain"
        />
      </ToggleFeature>,
    ),
  );
};
