import { compact } from 'lodash';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import moment from 'moment';

/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum ConditionCategory {
  healthy = 0,
  neutral = 1,
  warning = 2,
  error = 3,
  unknown = 4,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

export interface Condition {
  category: ConditionCategory;
  lastTransitionTime?: moment.Moment;
  message?: string;
  reason?: string;
  status: string;
  type: string;
  title?: string | undefined;
}

export interface KubernetesCondition {
  lastTransitionTime?: string;
  message?: string;
  reason?: string;
  severity?: string;
  status: string;
  type: string;
}

export interface ConditionList {
  primary?: Condition;
  other: Condition[];
}

export type IScanCondition = KubernetesCondition & {
  status?: string;
  error?: string;
  reason: string;
  observedGeneration?: number;
};

export type StatusMapping = Record<string, Record<string, string>>;

export interface ConditionHavingResource {
  status?: {
    conditions?: KubernetesCondition[];
  };
}

export const timeFromStr = (input?: string): moment.Moment | undefined => {
  if (input === undefined) {
    return undefined;
  }

  const m = moment.parseZone(input);

  return m.isValid() ? m : undefined;
};

export const categoryFromKubernetesCondition = (
  condition: KubernetesCondition,
): ConditionCategory => {
  if (condition.status === 'True') {
    return ConditionCategory.healthy;
  }

  if (condition.status === 'Unknown') {
    return ConditionCategory.neutral;
  }

  return condition.severity === 'Info'
    ? ConditionCategory.neutral
    : ConditionCategory.error;
};

export const conditionFromKubernetesCondition = (
  condition?: KubernetesCondition,
  statusMapping?: StatusMapping,
): Condition | undefined => {
  if (condition === undefined) {
    return undefined;
  }

  return {
    ...condition,
    category: categoryFromKubernetesCondition(condition),
    lastTransitionTime: timeFromStr(condition.lastTransitionTime),
    title: get(
      statusMapping,
      [condition.type, condition.status],
      startCase(condition.type),
    ),
  };
};

export const prioritizedConditions = (
  conditions: Condition[],
  primaryName = 'Ready',
): ConditionList => {
  return {
    primary: conditions.find(c => c.type === primaryName),
    other: conditions
      .filter(c => c.type !== primaryName)
      .sort((a, b) => a.type.localeCompare(b.type)),
  };
};

export const conditionsFromResource = (
  model: ConditionHavingResource,
  statusMapping?: StatusMapping,
): Condition[] =>
  compact(
    model.status?.conditions?.map(c =>
      conditionFromKubernetesCondition(c, statusMapping),
    ),
  );
