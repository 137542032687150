import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterSourceTemplate';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterSourceTemplate'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        params: {
          items: {
            properties: {
              default: {},
              name: {
                type: 'string',
              },
            },
            required: ['default', 'name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        revisionPath: {
          type: 'string',
        },
        template: {
          type: 'object',
          properties: {},
          nullable: true,
        },
        urlPath: {
          type: 'string',
        },
        ytt: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['revisionPath', 'urlPath'],
      type: 'object',
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterSourceTemplate {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterSourceTemplate';
  metadata: IObjectMeta;
  /**
   * Spec describes the source template. More info: https://cartographer.sh/docs/latest/reference/template/#clustersourcetemplate
   */
  spec: {
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner or Template does not specify this parameter, this value is used.
       */
      default: any;
      /**
       * Name of a parameter the template accepts from the Blueprint or Owner.
       */
      name: string;
    }>;
    /**
     * RevisionPath is a path into the templated object's data that contains a revision. The revision, along with the URL, represents the output of the Template. RevisionPath is specified in jsonpath format, eg: .status.artifact.revision
     */
    revisionPath: string;
    /**
     * Template defines a resource template for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    template?: {};
    /**
     * URLPath is a path into the templated object's data that contains a URL. The URL, along with the revision, represents the output of the Template. URLPath is specified in jsonpath format, eg: .status.artifact.url
     */
    urlPath: string;
    /**
     * Ytt defines a resource template written in `ytt` for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    ytt?: string;
  };
}

export class ClusterSourceTemplate
  extends Model<IClusterSourceTemplate>
  implements IClusterSourceTemplate
{
  'apiVersion': IClusterSourceTemplate['apiVersion'];
  'kind': IClusterSourceTemplate['kind'];
  'metadata': IClusterSourceTemplate['metadata'];
  'spec': IClusterSourceTemplate['spec'];

  static apiVersion: IClusterSourceTemplate['apiVersion'] =
    'carto.run/v1alpha1';
  static kind: IClusterSourceTemplate['kind'] = 'ClusterSourceTemplate';
  static is = createTypeMetaGuard<IClusterSourceTemplate>(
    ClusterSourceTemplate,
  );

  constructor(data?: ModelData<IClusterSourceTemplate>) {
    super({
      apiVersion: ClusterSourceTemplate.apiVersion,
      kind: ClusterSourceTemplate.kind,
      ...data,
    } as IClusterSourceTemplate);
  }
}

setSchema(ClusterSourceTemplate, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
