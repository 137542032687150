import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const useCustomization = () => {
  const config = useApi(configApiRef);
  const customLogo = config.getOptionalString('customize.custom_logo');
  const customName = config.getOptionalString('customize.custom_name');
  // maybe we need an additional custom parameter instead of this very specific variable ?
  const hasTapLogo = customLogo && customName;

  return {
    customLogo,
    customName,
    hasTapLogo,
  };
};
