import type { ProxyException } from '../../types';

export class RepositoryProxyError {
  public readonly cluster: string;

  public readonly error: ProxyException;

  public constructor(cluster: string, error: ProxyException) {
    this.cluster = cluster;
    this.error = error;
  }
}
