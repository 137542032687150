/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { Box, Button as MUIButton } from '@material-ui/core';
import React, { useState } from 'react';
import { ScaffolderTaskOutput } from '../../types';
import { useRouteRef, useApi, errorApiRef } from '@backstage/core-plugin-api';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FileSaver from 'file-saver';
import { ExploreDialog, b64toBlob } from '../ExploreDialog';
import { Button, Link } from '@backstage/core-components';
import { parseEntityRef } from '@backstage/catalog-model';
import { scaffolderApiRef } from '../../api';

type TaskPageLinksProps = {
  output: ScaffolderTaskOutput;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: '30px',
      width: '100%',
    },
    buttonPrimary: {
      background: theme.palette.primary.main,
      marginTop: theme.spacing(1),
      marginRight: '30px',
      width: '100%',
    },
  }),
);

export const TaskPageLinks = ({ output }: TaskPageLinksProps) => {
  const { entityRef: entityRefOutput, remoteUrl } = output;
  let { links = [] } = output;
  const entityRoute = useRouteRef(entityRouteRef);
  const classes = useStyles();
  const [explore, setExplore] = useState(false);
  const [linkZip, setLinkZip] = useState<any>(null);
  const [projectName, setProjectName] = useState('');
  const scaffolderApi = useApi(scaffolderApiRef);
  const errorApi = useApi(errorApiRef);

  if (remoteUrl) {
    links = [{ url: remoteUrl as string, title: 'Repo' }, ...links];
  }

  if (entityRefOutput) {
    links = [
      {
        entityRef: entityRefOutput as string,
        title: 'Open in catalog',
        icon: 'catalog',
      },
      ...links,
    ];
  }

  const handleDownload = (
    name: string,
    downloadLink: string,
    acceleratorName: string | undefined,
    createdGitRepo: boolean = false,
    uuid: string | undefined,
  ) => {
    const data: Blob | string = downloadLink.startsWith('data')
      ? b64toBlob(downloadLink)
      : downloadLink;
    FileSaver.saveAs(data, name);
    if (acceleratorName) {
      scaffolderApi
        .postInvoked(
          acceleratorName,
          createdGitRepo ? 'gitrepo' : 'download',
          uuid,
        )
        .catch(err => {
          errorApi.post(
            new Error(`error getting downloaded endpoint ${err?.message}`),
          );
        });
    }
  };

  const handleExplore = (
    name: string,
    downloadLink: string,
    acceleratorName: string | undefined,
    uuid: string | undefined,
  ) => {
    setProjectName(name);
    setLinkZip(downloadLink);
    setExplore(true);
    if (acceleratorName) {
      scaffolderApi.postInvoked(acceleratorName, 'explore', uuid).catch(err => {
        errorApi.post(
          new Error(`error getting downloaded endpoint ${err?.message}`),
        );
      });
    }
  };

  return (
    <>
      <Box px={3} pb={3}>
        {links
          .filter(({ url, entityRef }) => url || entityRef)
          .map(
            ({
              url,
              entityRef,
              title,
              icon,
              download,
              acceleratorName,
              createdGitRepo,
              uuid,
            }) => {
              if (entityRef) {
                // (mstergianis): go over the, poorly documented,
                // functionality of parseEntityRef that causes it to throw
                // exceptions
                const entityName = parseEntityRef(entityRef);
                const target = entityRoute(entityName);
                return { title, icon, url: target };
              }
              return {
                title,
                download,
                icon,
                url: url!,
                acceleratorName: acceleratorName,
                createdGitRepo: createdGitRepo,
                uuid: uuid,
              };
            },
          )
          .map(
            (
              { url, title, download, acceleratorName, createdGitRepo, uuid },
              i,
            ) => {
              if (download) {
                return (
                  <span key={`output-link-${i}-span`}>
                    <MUIButton
                      className={classes.button}
                      variant="outlined"
                      key={`output-link-${i}-explore`}
                      onClick={() => {
                        handleExplore(download, url, acceleratorName, uuid);
                      }}
                    >
                      Explore Zip File
                    </MUIButton>
                    <MUIButton
                      className={classes.buttonPrimary}
                      variant="contained"
                      disableElevation
                      key={`output-link-${i}-download`}
                      onClick={() => {
                        handleDownload(
                          download,
                          url,
                          acceleratorName,
                          createdGitRepo,
                          uuid,
                        );
                      }}
                    >
                      Download Zip File
                    </MUIButton>
                  </span>
                );
              }
              return (
                <Button
                  className={classes.button}
                  key={`output-link-${i}`}
                  download={download}
                  component={Link}
                  to={url}
                  target="_blank"
                >
                  {title}
                </Button>
              );
            },
          )}
      </Box>

      <ExploreDialog
        open={explore}
        projectName={projectName}
        linkZip={linkZip}
        close={() => {
          setExplore(false);
          setLinkZip(null);
        }}
      />
    </>
  );
};
