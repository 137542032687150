import { Ingress as Model } from 'kubernetes-models/networking.k8s.io/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { IIngress } from 'kubernetes-models/networking.k8s.io/v1';

export class Ingress extends AbstractModel<IIngress> {
  public static readonly humanType = 'Ingress';

  public static readonly typeMeta = Model;

  public classRef = Ingress;

  public constructor(
    resource: IIngress,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
