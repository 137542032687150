import { ClusterSupplyChain as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterSupplyChain } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterSupplyChain extends AbstractModel<IClusterSupplyChain> {
  public static readonly humanType = 'Cartographer Cluster Supply Chain';

  public static readonly typeMeta = Model;

  public classRef = ClusterSupplyChain;

  public constructor(
    resource: IClusterSupplyChain,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
