import { AbstractModel } from '../AbstractModel';
import type { TaskRunStatus } from './models';
import type { ITaskRun as ITaskRunV1 } from '../../kubernetes-additional-crds/gen/tekton.dev/v1';
import type { ITaskRun as ITaskRunV1Beta1 } from '../../kubernetes-additional-crds/gen/tekton.dev/v1beta1';
import type { IRepository } from '../../repository';
import type { SharedUnionFieldsDeep } from 'type-fest';

type TaskRunCommon = SharedUnionFieldsDeep<ITaskRunV1 | ITaskRunV1Beta1>;
export abstract class TaskRun<
  T extends TaskRunCommon = TaskRunCommon,
> extends AbstractModel<T> {
  public static readonly humanType = 'Tekton TaskRun';

  public constructor(
    resource: T,
    repository: IRepository,
    cluster: string,
    concreteModel: T,
  ) {
    super(resource, repository, cluster, concreteModel);
  }

  public get taskRunStatus(): TaskRunStatus | undefined {
    // @ts-expect-error: Explicit typecast. `status` is sometimes defined but `ITaskRun` lacks the declaration
    return this.resource.status as TaskRunStatus | undefined;
  }
}
