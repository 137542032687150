import values from 'lodash/values';
import { ModelFactory } from './ModelFactory';
import type { RepositoryError } from './RepositoryError';
import type { MappedResource, MappedTypeMeta, ObjectMap } from '../ClassMap';
import type { Guid, IModel } from '../model';
import type { ObjectsByEntityResponse } from '@backstage/plugin-kubernetes-common';
import type { IOwnerReference } from '@kubernetes-models/apimachinery/apis/meta/v1';
import type { TypeMeta } from '@kubernetes-models/base';

export interface IRepository {
  readonly errors: RepositoryError[];
  readonly resourceCount: number;

  byID: <T extends TypeMeta>(
    uid: Guid,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T> | undefined;
  firstOwnedOfType: <T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T> | undefined;
  ofType: <T extends TypeMeta>(type: MappedTypeMeta<T>) => MappedResource<T>[];
  ownedOfType: <T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T>[];
  ownerOfType: <T extends TypeMeta>(
    owned: IModel,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T> | undefined;
  resourcesOfNameKindVersion: (
    name: string,
    kind: string,
    version: string,
  ) => IModel[];
}

export class Repository implements IRepository {
  public readonly errors: RepositoryError[];

  public readonly resourceCount: number;

  private readonly objectMap: ObjectMap;

  public constructor(
    response: ObjectsByEntityResponse,
    labelSelector?: string,
  ) {
    const { objectMap, errors } = new ModelFactory(this, labelSelector).build(
      response,
    );

    this.errors = errors;
    this.objectMap = objectMap;
    this.resourceCount = this.calculateResourceCount();
  }

  public byID<T extends TypeMeta>(
    uid: Guid,
    type: MappedTypeMeta<T>,
  ): MappedResource<T> | undefined {
    return this.mapOfType(type)[uid];
  }

  public firstOwnedOfType<T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ): MappedResource<T> | undefined {
    return this.ownedOfType(owner, type)[0];
  }

  public ofType<T extends TypeMeta>(
    type: MappedTypeMeta<T>,
  ): MappedResource<T>[] {
    return values(this.mapOfType(type));
  }

  public ownedOfType<T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ): MappedResource<T>[] {
    return this.ofType(type).filter(
      resource =>
        this.ownerRefFromResource(
          resource as IModel,
          owner.id,
          owner.classRef.typeMeta,
        ) !== undefined,
    );
  }

  public ownerOfType<T extends TypeMeta>(
    owned: IModel,
    type: MappedTypeMeta<T>,
  ): MappedResource<T> | undefined {
    return this.ofType(type).find(
      resource =>
        this.ownerRefFromResource(
          owned,
          (resource as IModel).id,
          (resource as IModel).classRef.typeMeta,
        ) !== undefined,
    );
  }

  public resourcesOfNameKindVersion(
    name: string,
    kind: string,
    version: string,
  ): IModel[] {
    const type = { apiVersion: version, kind };
    return this.ofType(type).filter(mappedResource => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return name === mappedResource.resource.metadata?.name;
    });
  }

  private mapOfType<T extends TypeMeta>(
    type: MappedTypeMeta<T>,
  ): Record<Guid, MappedResource<T>> {
    if (!(type.apiVersion in this.objectMap)) {
      return {};
    }

    const apiVersion = this.objectMap[type.apiVersion as keyof ObjectMap];
    if (apiVersion === undefined || !(type.kind in apiVersion)) {
      return {};
    }

    return apiVersion[type.kind as keyof typeof apiVersion];
  }

  private ownerRefFromResource(
    resource: IModel,
    ownerUid: Guid,
    ownerType: TypeMeta,
  ): IOwnerReference | undefined {
    return resource.resource.metadata?.ownerReferences?.find(
      (ownerRef: IOwnerReference) =>
        ownerRef.apiVersion === ownerType.apiVersion &&
        ownerRef.kind === ownerType.kind &&
        ownerRef.uid === ownerUid,
    );
  }

  private calculateResourceCount(): number {
    return values(this.objectMap)
      .flatMap(kind => values(kind))
      .reduce((cnt, resources) => cnt + values(resources).length, 0);
  }
}
