import {
  createExternalRouteRef,
  createRouteRef,
  createSubRouteRef,
  SubRouteRef,
} from '@backstage/core-plugin-api';

export type AnyParams = { [param in string]: string } | undefined;

export type SupplyChainRouteParams = AnyParams & {
  supplyChainName: string;
  namespace: string;
  stageName: string;
  clusterName: string;
};

export type SupplyChainDeployerRouteParams = AnyParams & {
  clusterName: string;
  namespace: string;
  supplyChainName: string;
  deliverableCluster: string;
};

export const rootRouteRef = createRouteRef({
  id: 'supply-chain',
});

export const vulnerabilityLinkRouteRef = createExternalRouteRef({
  id: 'vulnerability-link',
  optional: true,
  params: ['id'],
});

export const packageLinkRouteRef = createExternalRouteRef({
  id: 'package-link',
  optional: true,
  params: ['packageName'],
});

export const supplyChainRouteRef = createSubRouteRef({
  id: 'Workloads',
  path: '/supply-chain',
  parent: rootRouteRef,
});

export const supplyChainInstanceRef: SubRouteRef<SupplyChainRouteParams> =
  createSubRouteRef({
    id: 'supply-chain',
    path: '/supply-chain/:clusterName/:namespace/:supplyChainName/:stageName',
    parent: rootRouteRef,
  });

export const supplyChainDeployerRef: SubRouteRef<SupplyChainDeployerRouteParams> =
  createSubRouteRef({
    id: 'supply-chain',
    path: '/supply-chain/:clusterName/:namespace/:supplyChainName/:deliverableCluster/deployer',
    parent: rootRouteRef,
  });
