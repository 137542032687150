import { CronJob as Model, Job } from 'kubernetes-models/batch/v1';
import { AbstractModel } from '../AbstractModel';
import { ConditionCategory } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList } from '../Condition';
import type { ICronJob, IJob } from 'kubernetes-models/batch/v1';

export class CronJob extends AbstractModel<ICronJob> {
  public static readonly humanType = 'CronJob';

  public static readonly typeMeta = Model;

  public classRef = CronJob;

  public constructor(
    resource: ICronJob,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    const isSuspended = this.resource.spec?.suspend ?? false;

    return {
      primary: {
        category: isSuspended
          ? ConditionCategory.warning
          : ConditionCategory.healthy,
        lastTransitionTime: undefined,
        status: '',
        type: 'suspended',
        title: isSuspended ? 'Suspended' : 'Running',
      },
      other: [
        {
          category: ConditionCategory.healthy,
          lastTransitionTime: undefined,
          status: `${this.resource.status?.active?.length ?? 0}`,
          type: 'active',
          title: 'Active Jobs',
        },
      ],
    };
  }

  public get jobs(): MappedResource<IJob>[] {
    return this.repository.ownedOfType<IJob>(this, Job);
  }
}
