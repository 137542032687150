import { ClusterBuilder as Model } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import { AbstractModel } from '../AbstractModel';
import type { IClusterBuilder } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import type { IRepository } from '../../repository';
import type { IStatus } from '../BaseTypes';

export interface BuildPackGroup {
  group: [{ id: string }];
}

export interface BuilderMetadata {
  id: string;
  version: string;
  homepage?: string;
}
export interface ClusterBuilderSpec {
  order: BuildPackGroup[];
  serviceAccountRef: {
    name: string;
    namespace: string;
  };
  stack: {
    kind: string;
    name: string;
  };
  tag: string;
}

export interface ClusterBuilderStatus
  extends Omit<IStatus, 'resources' | 'supplyChainRef'> {
  builderMetadata: BuilderMetadata[];
  latestImage: string;
  observedStackGeneration: number;
  order: BuildPackGroup[];
  os: string;
  stack: {
    id: string;
    runImage: string;
  };
}
export class ClusterBuilder extends AbstractModel<IClusterBuilder> {
  public static readonly humanType = 'Kpack Builder';

  public static readonly typeMeta = Model;

  public classRef = ClusterBuilder;

  public constructor(
    resource: IClusterBuilder,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get spec(): ClusterBuilderSpec | undefined {
    // @ts-expect-error: Explicit typecast. `spec` is defined but `IClusterBuilder` lacks the declaration
    return this.resource.spec as ClusterBuilderSpec | undefined;
  }

  public get status(): ClusterBuilderStatus | undefined {
    // @ts-expect-error: Explicit typecast. `status` is defined but `IClusterBuilder` lacks the declaration
    return this.resource.status as ClusterBuilderStatus | undefined;
  }
}
