import { Service } from '@kubernetes-models/knative/serving.knative.dev/v1';
import { Deployment } from 'kubernetes-models/apps/v1';
import { ConfigMap, Service as KubernetesService } from 'kubernetes-models/v1';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { Deliverable } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { App as Model } from '../../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IModel } from '..';
import type { MappedResource } from '../../ClassMap';
import type { IDeliverable } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IApp } from '../../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import type { IRepository } from '../../repository';
import type { IService } from '@kubernetes-models/knative/serving.knative.dev/v1';
import type { IDeployment } from 'kubernetes-models/apps/v1';
import type {
  IConfigMap,
  IService as IKubernetesService,
} from 'kubernetes-models/v1';

export class App extends AbstractModel<IApp> {
  public static readonly humanType = 'Carvel App Controller';

  public static readonly typeMeta = Model;

  public classRef = App;

  public constructor(resource: IApp, repository: IRepository, cluster: string) {
    super(resource, repository, cluster, Model);
  }

  public get owners(): MappedResource<IDeliverable>[] {
    return compact([this.deliverable]);
  }

  public get deliverable(): MappedResource<IDeliverable> | undefined {
    return this.repository.ownerOfType<IDeliverable>(this, Deliverable);
  }

  public get topResource():
    | MappedResource<IDeployment>
    | MappedResource<IKubernetesService>
    | MappedResource<IService>
    | undefined {
    if (!this.numberId) return undefined;

    const service = this.repository
      .ofType<IService>(Service)
      .find(knservice => this.isChild(knservice, this.numberId));
    if (service !== undefined) return service;

    const deployment = this.repository
      .ofType<IDeployment>(Deployment)
      .find(dp => this.isChild(dp, this.numberId));
    if (deployment !== undefined) return deployment;

    return this.repository
      .ofType<IKubernetesService>(KubernetesService)
      .find(k8ssrv => this.isChild(k8ssrv, this.numberId));
  }

  public get numberId(): string {
    const configMaps = this.repository.ofType<IConfigMap>(ConfigMap);
    const appCM = configMaps.find(
      cm =>
        cm.resource.metadata?.name ===
        `${this.resource.metadata?.name ?? ''}-ctrl`,
    );

    if (!appCM) {
      return '';
    }

    return (
      JSON.parse(appCM.resource.data?.spec ?? '{}') as Record<string, string>
    ).labelValue;
  }

  public isChild(model: IModel, appId: string): boolean {
    return (
      get(model, ['resource', 'metadata', 'labels', 'kapp.k14s.io/app']) ===
      appId
    );
  }
}
