import * as model from './model/GenericClass';
import * as appScanning from './model/appScanning';
import * as carto from './model/carto';
import * as conventions from './model/conventions';
import * as fluxcd from './model/fluxcd';
import * as kappController from './model/kappController';
import * as knative from './model/knative';
import * as kpack from './model/kpack';
import * as kubernetes from './model/kubernetes';
import * as scanning from './model/scanning';
import * as services from './model/services';
import * as source from './model/source';
import * as tekton from './model/tekton';
import type { Guid, IModel } from './model';
import type { IRepository } from './repository';
import type { TypeMeta } from '@kubernetes-models/base';

/* Explicitly map each supported apiVersion/kind to one of our model constructors.
 *
 * n.b. how the type of ClassMap is inferred, not declared. You might be inclined to do something like:
 *
 * const ClassMap: Record<string, Record<string, new (...args: any) => any>> = { ... }
 *
 * But then later, when you attempted to look up the constructor from its apiVersion/kind, Typescript would give you the
 * high-level abstract type you've defined instead of the particular, mapped constructor.
 *
 * So we leave the type of ClassMap unspecified because we need TS to resolve to the particular, mapped constructors in
 * order to access their resource-specific fields. This allows us to use the full typedefs provided by the
 * @kubernetes-models package.
 */
const ClassMap = {
  'apps/v1': {
    Deployment: kubernetes.Deployment,
    ReplicaSet: kubernetes.ReplicaSet,
    StatefulSet: kubernetes.StatefulSet,
    DaemonSet: kubernetes.DaemonSet,
  },
  'batch/v1': {
    Job: kubernetes.Job,
    CronJob: kubernetes.CronJob,
  },
  'autoscaling.internal.knative.dev/v1alpha1': {
    PodAutoscaler: knative.PodAutoscaler,
  },
  'autoscaling/v1': {
    HorizontalPodAutoscaler: kubernetes.HorizontalPodAutoscaler,
  },
  'carto.run/v1alpha1': {
    ClusterConfigTemplate: carto.ClusterConfigTemplate,
    ClusterDelivery: carto.ClusterDelivery,
    ClusterDeploymentTemplate: carto.ClusterDeploymentTemplate,
    ClusterImageTemplate: carto.ClusterImageTemplate,
    ClusterRunTemplate: carto.ClusterRunTemplate,
    ClusterSourceTemplate: carto.ClusterSourceTemplate,
    ClusterSupplyChain: carto.ClusterSupplyChain,
    ClusterTemplate: carto.ClusterTemplate,
    Deliverable: carto.Deliverable,
    Runnable: carto.Runnable,
    Workload: carto.Workload,
  },
  'kappctrl.k14s.io/v1alpha1': {
    App: kappController.App,
  },
  'networking.internal.knative.dev/v1alpha1': {
    ServerlessService: knative.ServerlessService,
  },
  'networking.k8s.io/v1beta1': {
    Ingress: kubernetes.Ingress,
  },
  'serving.knative.dev/v1': {
    Configuration: knative.Configuration,
    Revision: knative.Revision,
    Route: knative.Route,
    Service: knative.Service,
  },
  v1: {
    ConfigMap: kubernetes.ConfigMap,
    Pod: kubernetes.Pod,
    Service: kubernetes.Service,
    LimitRange: kubernetes.LimitRange,
    Namespace: kubernetes.Namespace,
    Event: kubernetes.Event,
    PodTemplate: kubernetes.PodTemplate,
    Secret: kubernetes.Secret,
    ServiceAccount: kubernetes.ServiceAccount,
    PersistentVolume: kubernetes.PersistentVolume,
  },
  'tekton.dev/v1': {
    PipelineRun: tekton.PipelineRunV1,
    TaskRun: tekton.TaskRunV1,
  },
  'tekton.dev/v1beta1': {
    PipelineRun: tekton.PipelineRunV1Beta1,
    TaskRun: tekton.TaskRunV1Beta1,
  },
  'source.toolkit.fluxcd.io/v1beta1': {
    GitRepository: fluxcd.GitRepositoryV1Beta1,
  },
  'source.toolkit.fluxcd.io/v1beta2': {
    GitRepository: fluxcd.GitRepositoryV1Beta2,
  },
  'source.toolkit.fluxcd.io/v1': {
    GitRepository: fluxcd.GitRepositoryV1,
  },
  'scanning.apps.tanzu.vmware.com/v1beta1': {
    SourceScan: scanning.SourceScan,
    ImageScan: scanning.ImageScan,
    ScanPolicy: scanning.ScanPolicy,
    ScanTemplate: scanning.ScanTemplate,
  },
  'kpack.io/v1alpha2': {
    Image: kpack.Image,
    Build: kpack.Build,
    ClusterBuilder: kpack.ClusterBuilder,
    ClusterStack: kpack.ClusterStack,
  },
  'conventions.carto.run/v1alpha1': {
    PodIntent: conventions.PodIntent,
  },
  'source.apps.tanzu.vmware.com/v1alpha1': {
    ImageRepository: source.ImageRepository,
    MavenArtifact: source.MavenArtifact,
  },
  'app-scanning.apps.tanzu.vmware.com/v1alpha1': {
    ImageVulnerabilityScan: appScanning.ImageVulnerabilityScan,
  },
  'apiextensions.k8s.io/v1': {
    CustomResourceDefinition: kubernetes.CustomResourceDefinition,
  },
  'services.apps.tanzu.vmware.com/v1alpha1': {
    ClassClaim: services.ClassClaim,
    ClusterInstanceClass: services.ClusterInstanceClass,
  },
};

// Given a TypeMeta, find the type of the corresponding constructor from the map.
type Ctor = new (...args: unknown[]) => unknown;
type UnmappedTypeMeta = never;
type MappedCtor<T extends TypeMeta> =
  T['apiVersion'] extends keyof typeof ClassMap
    ? T['kind'] extends keyof (typeof ClassMap)[T['apiVersion']]
      ? Ctor & // Need to remind TS that each of the entries in ClassMap is indeed a constructor
          (typeof ClassMap)[T['apiVersion']][T['kind']]
      : UnmappedTypeMeta
    : UnmappedTypeMeta;

// Confirm that a TypeMeta is mapped.
export type MappedTypeMeta<T extends TypeMeta> =
  MappedCtor<T> extends UnmappedTypeMeta ? TypeMeta : Pick<T, keyof TypeMeta>;

// Given a model constructor, find the corresponding instance type.
// Equivalent to InstanceType<T> but with a tighter constraint on the constructor args.
type UnmappedInstanceType = never;
type MappedInstanceType<T extends TypeMeta, C> = C extends new (
  resource: T,
  repository: IRepository,
  cluster: string,
) => infer R
  ? R
  : UnmappedInstanceType;

// Given a TypeMeta, find the corresponding instance type from the map.
export type MappedResource<T extends TypeMeta> =
  MappedCtor<T> extends UnmappedTypeMeta
    ? IModel<T>
    : MappedInstanceType<T, MappedCtor<T>>;

// Mirrors the shape of the ClassMap but stores a map of instances (keyed by ID) at each leaf instead of a constructor.
// Used for O(1) lookup in the Repository.
export type ObjectMap = Partial<{
  [ApiVersion in keyof typeof ClassMap]: Partial<{
    [Kind in keyof (typeof ClassMap)[ApiVersion]]: Record<
      Guid,
      | MappedResource<{
          apiVersion: ApiVersion;
          // Kind is (number | string | symbol) without an explicit index signature on ClassMap, and we can't add an index
          // signature to ClassMap.
          kind: Kind extends string ? Kind : never;
        }>
      | undefined
    >;
  }>;
}>;

// Given a TypeMeta, return a reference to the corresponding constructor from the map.
export const getMappedCtor = <T extends TypeMeta>(
  resource: T,
): MappedCtor<T> => {
  if (!(resource.apiVersion in ClassMap)) {
    return model.GenericClass as never;
  }

  const apiVersion = ClassMap[resource.apiVersion as keyof typeof ClassMap];

  if (!(resource.kind in apiVersion)) {
    return model.GenericClass as never;
  }

  return apiVersion[resource.kind as keyof typeof apiVersion];
};

// Confirm than an object is of the expected mapped type.
export const createMapGuard = <T extends TypeMeta>(
  meta: T,
): ((value: unknown) => value is MappedResource<T>) => {
  const expectedCtor = getMappedCtor(meta);

  return (value: unknown): value is MappedResource<T> =>
    value instanceof expectedCtor;
};
