import Highlight, { Prism, Language } from 'prism-react-renderer';
import React from 'react';
import get from 'lodash.get';
import ReactMarkdown from 'react-markdown';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

if (typeof global !== 'undefined') {
  // @ts-ignore: Unreachable code error
  global.Prism = Prism; // eslint-disable-linea
  require('prismjs/components/prism-java'); // eslint-disable-line
  require('prismjs/components/prism-kotlin'); // eslint-disable-line
  require('prismjs/components/prism-properties'); // eslint-disable-line
  require('prismjs/components/prism-groovy'); // eslint-disable-line
  require('prismjs/components/prism-git'); // eslint-disable-line
  require('prismjs/components/prism-typescript'); // eslint-disable-line
  require('prismjs/components/prism-yaml'); // eslint-disable-line
  require('prismjs/components/prism-xml-doc'); // eslint-disable-line
  require('prismjs/components/prism-markdown'); // eslint-disable-line
  require('prismjs/components/prism-bash'); // eslint-disable-line
  require('prismjs/components/prism-ruby'); // eslint-disable-line
  require('prismjs/components/prism-go'); // eslint-disable-line
  require('prismjs/components/prism-json'); // eslint-disable-line
  require('prismjs/components/prism-fsharp'); // eslint-disable-line
  require('prismjs/components/prism-csharp'); // eslint-disable-line
}

type CodeProps = {
  item: {
    content: string;
    force: boolean;
    language: string;
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prism: {
      margin: 0,
      padding: 0,
      lineHeight: '24px',
      fontSize: '14px',
      position: 'absolute',
      top: '42px',
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
      fontFamily: "Monaco, Menlo, Consolas, 'courier new', monospace",
      '& .token.comment, & .token.prolog, & .token.doctype, & .token.cdata': {
        color: '#ADBBC4',
        fontStyle: 'italic',
      },
      '& .token.namespace': {
        opacity: 0.7,
      },
      '& .token.string, & .token.attr-value': {
        color: '#e3116c',
      },
      '& .token.punctuation, & .token.operator': {
        color: '#ADBBC4',
      },
      '& .token.entity, & .token.url, & .token.symbol, & .token.number, & .token.boolean, & .token.variable, & .token.constant, & .token.property, & .token.regex, & .token.inserted':
        {
          color: '#36acaa',
        },
      '& .token.atrule, & .token.keyword, & .token.attr-name, & .language-autohotkey .token.selector':
        {
          color: '#00bfff',
        },
      '& .token.function, & .token.deleted, & .language-autohotkey .token.tag':
        {
          color: '#f14f00',
        },
      '& .token.tag, & .token.selector, & .language-autohotkey .token.keyword':
        {
          color: '#00bfff',
        },
      '& .token.italic': {
        fontStyle: 'italic',
      },
      '& .language-java .token.annotation': {
        color: '#e3116c',
      },
      '& .token.important, & .token.function, & .token.bold': {
        fontWeight: 'bold',
      },
    },
    markdown: {
      position: 'absolute',
      top: '42px',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '4px 26px',
      '& code[class*="language-"]': {
        color: theme.palette.text.hint,
        fontSize: '14px',
      },
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
      },
      '& a:hover': {
        textDecoration: 'none',
      },
    },
    numberBg: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'block',
      width: '30px',
      content: '',
      background: darken(theme.palette.background.default, 0.1),
    },
    tokenLine: {
      height: '25px',
      '&:before': {
        position: 'absolute',
        top: '0',
        bottom: '-10px',
        display: 'block',
        width: '35px',
        content: '',
        background: darken(theme.palette.background.default, 0.1),
      },
    },
    number: {
      position: 'relative',
      display: 'inline-block',
      width: '25px',
      paddingRight: '10px',
      textAlign: 'right',
      marginRight: '20px',
      fontSize: '11px',
      height: '25px',
      '&:before': {
        display: 'block',
        content: 'attr(data-value)',
        position: 'absolute',
        width: '30px',
        paddingRight: '5px',
        left: '0',
        top: '9px',
        height: '25px',
        zIndex: '19',
        background: darken(theme.palette.background.default, 0.1),
        color: '#888',
      },
    },
  }),
);
/*

    */

export const Code = ({ item }: CodeProps) => {
  const classes = useStyles();
  const code = get(item, 'content', '')?.replace(/\t/g, '  ');
  if (!code) {
    return <></>;
  }
  const language = get(item, 'language');
  if (language === 'markdown' && !get(item, 'force', false)) {
    return (
      <div className={classes.markdown}>
        <ReactMarkdown linkTarget="_blank">{code}</ReactMarkdown>
      </div>
    );
  }
  return (
    <Highlight Prism={Prism} code={code} language={language as Language}>
      {({ style, tokens, getLineProps, getTokenProps }) => {
        // let groupLine = tokens.length > 9 ? '2' : '1';
        // groupLine = tokens.length > 99 ? '3' : groupLine;
        // groupLine = tokens.length > 999 ? '4' : groupLine;
        return (
          <pre className={classes.prism} style={style}>
            <div className={classes.numberBg} />
            {tokens.map((line: any, i: number) => {
              const props = getLineProps({ line, key: i });
              return (
                <div key={get(props, 'key')} className={classes.tokenLine}>
                  <span
                    data-value={i + 1}
                    className={classes.number}
                    role="none"
                  />
                  {line.map((token: any, key: string) => {
                    const props2 = getTokenProps({ token, key });
                    return (
                      <span
                        className={get(props2, 'className')}
                        style={get(props2, 'style')}
                        key={get(props2, 'key')}
                      >
                        {get(props2, 'children')}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </pre>
        );
      }}
    </Highlight>
  );
};

// Code.defaultProps = {
//   item: {
//     content: '',
//     force: false,
//     language: 'md',
//   },
// }

// Code.propTypes = {
//   item: PropTypes.shape({
//     content: PropTypes.string,
//     force: PropTypes.bool,
//     language: PropTypes.string,
//   }),
// }

export default Code;
