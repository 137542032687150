import { ClusterDelivery as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterDelivery } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterDelivery extends AbstractModel<IClusterDelivery> {
  public static readonly humanType = 'Cartographer Cluster Delivery';

  public static readonly typeMeta = Model;

  public classRef = ClusterDelivery;

  public constructor(
    resource: IClusterDelivery,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
