import '@backstage/cli/asset-types';
import { AppRuntime } from '@tpb/runtime';
import { plugin as scmPlugin } from '@tpb/plugin-scm';
import { plugin as catalogPlugin } from '@tpb/plugin-catalog';
import { plugin as techdocsPlugin } from '@tpb/plugin-techdocs';
import { plugin as searchPlugin } from '@tpb/plugin-search';
import { plugin as apiDocsPlugin } from '@tpb/plugin-api-docs';
import { plugin as loginPlugin } from '@vmware-tanzu/tdp-plugin-login';
import { plugin as themePlugin } from '@tpb/plugin-clarity-theming';
import { plugin as rrvPlugin } from '@tpb/plugin-rrv';
import { plugin as appLiveViewPlugin } from '@tpb/plugin-app-live-view';
import { plugin as oktaAuthPlugin } from '@tpb/plugin-okta-auth';
import { plugin as oidcAuthPlugin } from '@tpb/plugin-oidc-auth';
import { plugin as azureAuthPlugin } from '@tpb/plugin-azure-auth';
import { plugin as githubAuthPlugin } from '@tpb/plugin-github-auth';
import { plugin as gitlabAuthPlugin } from '@tpb/plugin-gitlab-auth';
import { plugin as googleAuthPlugin } from '@tpb/plugin-google-auth';
import { plugin as auth0AuthPlugin } from '@tpb/plugin-auth0-auth';
import { plugin as bitbucketAuthPlugin } from '@tpb/plugin-bitbucket-auth';
import { plugin as oneLoginAuthPlugin } from '@tpb/plugin-onelogin-auth';
import { plugin as guestAuthPlugin } from '@tpb/plugin-guest-auth';
import { plugin as vmwareCloudServicesAuthPlugin } from '@tpb/plugin-vmware-cloud-services-auth';
import { plugin as appAcceleratorPlugin } from '@tpb/plugin-app-accelerator-scaffolder';
import { plugin as appAcceleratorPromotionPlugin } from '@tpb/plugin-app-accelerator-promotion';
import { plugin as supplyChainPlugin } from '@tpb/plugin-supply-chain';
import { plugin as securityAnalysisPlugin } from '@tpb/plugin-security-analysis-frontend';
import { plugin as doraPlugin } from '@tpb/dora';
import { plugin as kubernetesOrmPlugin } from '@tpb/plugin-kubernetes-orm';
// Commented out to keep default permission policy running in the core repo
// import { plugin as rbacPlugin } from '@vmware-tanzu/tdp-plugin-rbac';

import '@tpb/plugin-clarity-theming/style/clarity.css';

new AppRuntime([
  searchPlugin(),
  catalogPlugin(),
  techdocsPlugin(),
  apiDocsPlugin(),
  loginPlugin(),
  scmPlugin(),
  themePlugin(),
  appAcceleratorPlugin(),
  appAcceleratorPromotionPlugin(),
  appLiveViewPlugin(),
  supplyChainPlugin(),
  securityAnalysisPlugin(),
  kubernetesOrmPlugin(),
  rrvPlugin(),
  oktaAuthPlugin(),
  oidcAuthPlugin(),
  azureAuthPlugin(),
  githubAuthPlugin(),
  gitlabAuthPlugin(),
  auth0AuthPlugin(),
  bitbucketAuthPlugin(),
  googleAuthPlugin(),
  oneLoginAuthPlugin(),
  guestAuthPlugin(),
  vmwareCloudServicesAuthPlugin(),
  doraPlugin(),
  // Commented out to keep default permission policy running in the core repo
  // rbacPlugin(),
]).render();
