import { CustomResourceDefinition as Model } from 'kubernetes-models/apiextensions.k8s.io/v1';

import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type {
  ICustomResourceDefinition,
  ICustomResourceColumnDefinition as V1ColumnDefinition,
} from 'kubernetes-models/apiextensions.k8s.io/v1';
import type { ICustomResourceColumnDefinition as V1Beta1ColumnDefinition } from 'kubernetes-models/apiextensions.k8s.io/v1beta1';

export type PrinterColumn = {
  jsonPath?: V1ColumnDefinition['jsonPath'];
  JSONPath?: V1Beta1ColumnDefinition['JSONPath'];
} & (V1Beta1ColumnDefinition | V1ColumnDefinition);

export class CustomResourceDefinition extends AbstractModel<ICustomResourceDefinition> {
  public static readonly humanType = 'Custom Resource Definition';

  public static readonly typeMeta = Model;

  public classRef = CustomResourceDefinition;

  public constructor(
    resource: ICustomResourceDefinition,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get printerColumns(): PrinterColumn[] {
    const apiVersionTokens = this.apiVersion.split('/');
    const version = apiVersionTokens.pop();

    const {
      spec: { versions },
    } = this.resource;

    const versionDefinition = versions.find(v => v.name === version);
    const printerColumns: PrinterColumn[] =
      versionDefinition?.additionalPrinterColumns ?? [];

    return printerColumns;
  }
}
