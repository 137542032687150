import {
  createRouteRef,
  createSubRouteRef,
  SubRouteRef,
  RouteRef,
} from '@backstage/core-plugin-api';

export type AnyParams = { [param in string]: string } | undefined;

export type CVEDetailsRouteParams = AnyParams & {
  id: string;
};

export type PackageDetailsRouteParams = AnyParams & {
  packageName: string;
};

export type SeverityDetailsRouteParams = AnyParams & {
  severity: string;
};

export const rootRouteRef: RouteRef = createRouteRef({
  id: '/',
  params: ['security-analysis'],
});

export const cveDetailsRouteRef: SubRouteRef<CVEDetailsRouteParams> =
  createSubRouteRef({
    id: 'vulnerability',
    parent: rootRouteRef,
    path: '/vulnerability/:id',
  });

export const packagePageRouteRef: SubRouteRef<PackageDetailsRouteParams> =
  createSubRouteRef({
    id: 'Package',
    path: '/package/:packageName',
    parent: rootRouteRef,
  });

export const severitiesRouteRef: SubRouteRef<SeverityDetailsRouteParams> =
  createSubRouteRef({
    id: 'severity',
    path: '/severity/:severity',
    parent: rootRouteRef,
  });
