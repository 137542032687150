import {
  Configuration,
  Route,
  Service as Model,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import { sum } from 'lodash';
import compact from 'lodash/compact';
import { App } from '../../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import { conditionsFromResource, prioritizedConditions } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IApp } from '../../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import type { IRepository } from '../../repository';
import type { ConditionList } from '../Condition';
import type { Pod } from '../kubernetes';
import type {
  IService,
  IConfiguration,
  IRoute,
} from '@kubernetes-models/knative/serving.knative.dev/v1';

const conditionsLabels = {
  Ready: { True: 'Ready', False: 'Not Ready', Unknown: 'Ready Status Unknown' },
  ConfigurationsReady: {
    True: 'Configuration Ready',
    False: 'Configuration Not Ready',
    Unknown: 'Configuration Status Unknown',
  },
  RoutesReady: {
    True: 'Routes Ready',
    False: 'Routes Not Ready',
    Unknown: 'Routes Status Unknown',
  },
};

export class Service extends AbstractModel<IService> {
  public static readonly humanType = 'Knative Service';

  public static readonly typeMeta = Model;

  public classRef = Service;

  public constructor(
    resource: IService,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    return prioritizedConditions(
      conditionsFromResource(this.resource, conditionsLabels),
    );
  }

  public get owners(): MappedResource<IApp>[] {
    return compact([this.app]);
  }

  public get app(): MappedResource<IApp> | undefined {
    return this.repository
      .ofType<IApp>(App)
      .find(app =>
        app.resource.metadata?.labels && this.resource.metadata?.labels
          ? app.resource.metadata.labels['carto.run/deliverable-name'] ===
            this.resource.metadata.labels['carto.run/workload-name']
          : undefined,
      );
  }

  public get configuration(): MappedResource<IConfiguration> | undefined {
    return this.repository.firstOwnedOfType<IConfiguration>(
      this,
      Configuration,
    );
  }

  public get routes(): MappedResource<IRoute>[] {
    return this.repository.ownedOfType<IRoute>(this, Route);
  }

  public get desiredPodsQty(): number {
    return sum(
      this.configuration?.revisions
        .flatMap(r => r.deployments)
        .map(d => d.resource.spec?.replicas),
    );
  }

  public get pods(): Pod[] {
    return (
      this.configuration?.revisions
        .flatMap(r => r.deployments)
        .flatMap(d => d.replicaSets)
        .flatMap(rs => rs.pods) ?? []
    );
  }
}
