import { createApiRef } from '@backstage/core-plugin-api';
import type { ICluster } from '../proxy/hook/types';

export interface KubernetesLocalProxyApi {
  proxy: (options: {
    cluster: ICluster;
    path: string;
    init?: RequestInit;
  }) => Promise<Response>;
}

export const kubernetesLocalProxyApiRef = createApiRef<KubernetesLocalProxyApi>(
  {
    id: 'api.local-proxy.service',
  },
);
