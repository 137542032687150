import { ICluster } from '../../proxy/hook/types';
import { StatusCodes } from 'http-status-codes';
import { networkErrorTypes } from '../networkErrorTypes';
import { emptyProxyResponse } from '../../proxy/hook/emptyProxyResponse';
import { Ok } from 'neverthrow';
import { ErrorProxyResponse } from '../../types';

export const formatUnknownProxyResponse = (
  cluster: ICluster,
  resourcePath: string,
  parseResult: Ok<any, ErrorProxyResponse>,
): ErrorProxyResponse => {
  return {
    ...emptyProxyResponse,
    cluster: cluster.name,
    error: {
      message: parseResult.value,
      status: StatusCodes.INTERNAL_SERVER_ERROR.toString(),
      statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
      errorType: networkErrorTypes.Unknown,
      resourcePath: resourcePath,
    },
  };
};
