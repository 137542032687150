import React, { createContext, ReactElement, useContext } from 'react';
import { IContextProxy } from '../../types';
import { RepositoryProxy } from '../repository';
import type { FetchResponse } from '@backstage/plugin-kubernetes-common';
import type { KubernetesLocalProxyApi } from '../../apis';
import {
  useRepositoryProxyContext,
  UseRepositoryProxyContextProps,
} from './useRepositoryProxyContext';

const placeholderRepositoryProxy = new RepositoryProxy([
  {
    type: '' as unknown as FetchResponse['type'],
    apiVersion: '',
    items: [],
    kind: '',
    metadata: { continue: '', resourceVersion: '' },
    cluster: '',
  },
]);

type RepositoryProviderProps = Omit<
  UseRepositoryProxyContextProps,
  'clusters' | 'resourcePath'
> & {
  children: ReactElement;
  kubernetesLocalProxyApi: KubernetesLocalProxyApi;
};

export const ProxyContext = createContext<IContextProxy>({
  repository: placeholderRepositoryProxy,
  isLoading: false,
  isLoadingSpacesData: false,
  errors: [],
  getResource: () => {},
  ucpProjects: [],
  ucpSpaces: [],
  refresh: () => {},
  availableClusters: [],
});

export const ProxyContextProvider = ({
  children,
  kubernetesApi,
  kubernetesLocalProxyApi,
}: RepositoryProviderProps): ReactElement => {
  const context = useRepositoryProxyContext({
    kubernetesApi,
    kubernetesLocalProxyApi,
  });

  return (
    <ProxyContext.Provider value={context}>{children}</ProxyContext.Provider>
  );
};

export const useRepositoryProxy = (): IContextProxy => useContext(ProxyContext);
