import compact from 'lodash/compact';
import {
  Deliverable as Model,
  Workload,
} from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { App } from '../../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import { conditionsFromResource, prioritizedConditions } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type {
  IDeliverable,
  IWorkload,
} from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IApp } from '../../kubernetes-additional-crds/gen/kappctrl.k14s.io/v1alpha1';
import type { IRepository } from '../../repository';
import type { NestedResource } from '../BaseTypes';
import type { ConditionList } from '../Condition';

export class Deliverable extends AbstractModel<IDeliverable> {
  public static readonly humanType = 'Cartographer Deliverable';

  public static readonly typeMeta = Model;

  public classRef = Deliverable;

  public constructor(
    resource: IDeliverable,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList | undefined {
    return prioritizedConditions(conditionsFromResource(this.resource));
  }

  public get owners(): MappedResource<IWorkload>[] {
    return compact([this.workload]);
  }

  public get app(): MappedResource<IApp> | undefined {
    return this.repository.firstOwnedOfType<IApp>(this, App);
  }

  public get workload(): MappedResource<IWorkload> | undefined {
    return this.repository
      .ofType<IWorkload>(Workload)
      .find(workload =>
        (workload.resource.metadata.name ?? '') && this.resource.metadata.labels
          ? workload.resource.metadata.name ===
            this.resource.metadata.labels['carto.run/workload-name']
          : undefined,
      );
  }

  public get name(): string | undefined {
    return this.resource.metadata.name;
  }

  public get resources(): NestedResource[] {
    return this.resource.status?.resources ?? [];
  }
}
