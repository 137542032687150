import { z } from 'zod';
import type { KubernetesLocalProxyApi } from './KubernetesLocalProxyApi';
import type { ICluster } from '../proxy/hook/types';
import { emptyProxyResponse } from '../proxy/hook/emptyProxyResponse';
import { fromPromise, Result } from 'neverthrow';
import { StatusCodes } from 'http-status-codes';
import { formatNotFoundProxyResponseWithText } from './helpers/formatNotFoundProxyResponseWithText';
import { formatBadRequestProxyResponse } from './helpers/formatBadRequestProxyResponse';
import { formatErrorProxyResponseWithData } from './helpers/formatErrorProxyResponseWithData';
import { formatSuccessProxyResponse } from './helpers/formatSuccessProxyResponse';
import { formatJSONParseError } from './helpers/formatJSONParseError';
import { formatUnknownProxyResponse } from './helpers/formatUnknownProxyResponse';
import { formatNotFoundProxyResponseWithData } from './helpers/formatNotFoundProxyResponseWithData';
import {
  ErrorProxyResponse,
  NotFoundProxyResponse,
  ProxyResponse,
  v1ResponseTypes,
} from '../types';

const LAST_POSITION = -1;
const FIRST_POSITION = 0;
const UCP_AUTH_PROVIDER_ID = 'tae';
const KubernetesV1Group = 'v1';

const dataSchema = z.record(z.string(), z.any());

export async function fetchKubernetesData(
  kubernetesLocalProxyApi: KubernetesLocalProxyApi,
  path: string,
  cluster: ICluster,
  project: string,
  space: string,
): Promise<ProxyResponse> {
  if (!path) {
    return emptyProxyResponse;
  }

  const fullPath = makeFullPath(path, cluster.authProvider, project, space);

  const proxyFetchResult: Result<Response, ErrorProxyResponse> =
    await fromPromise(
      kubernetesLocalProxyApi.proxy({
        cluster,
        path: fullPath,
      }),
      (error: unknown) => {
        return formatBadRequestProxyResponse(cluster, path, error);
      },
    );

  if (proxyFetchResult.isErr()) {
    return proxyFetchResult.error;
  }

  const response = proxyFetchResult.value;
  const statusCode = response.status;

  const responseBody = await response.text();

  try {
    const parsedData = JSON.parse(responseBody);
    if (dataSchema.safeParse(parsedData).success) {
      // `ok` can range from status 200 to 299
      // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
      if (response.ok && statusCode === StatusCodes.OK) {
        return formatSuccessProxyResponse(cluster, parsedData as ProxyResponse);
      }

      return 'response' in parsedData
        ? formatErrorProxyResponseWithData(
            cluster,
            path,
            parsedData as ErrorProxyResponse,
          )
        : formatNotFoundProxyResponseWithData(
            cluster,
            path,
            parsedData as NotFoundProxyResponse,
          );
    }

    // to satisfy all remaining code paths
    return formatUnknownProxyResponse(cluster, path, parsedData);
  } catch (error: unknown) {
    return !response.ok && statusCode === StatusCodes.NOT_FOUND
      ? formatNotFoundProxyResponseWithText(cluster, path, responseBody)
      : formatJSONParseError(cluster, path, error as TypeError);
  }
}

function makeFullPath(
  path: string,
  clusterProvider: string,
  project: string,
  space: string,
): string {
  let resourceRequested = path.split('/').at(LAST_POSITION) ?? '';
  let fullPath: string;
  const kindRequested = path.split('/').at(FIRST_POSITION) ?? '';
  resourceRequested = resourceRequested.includes('?')
    ? resourceRequested.split('?')[0]
    : resourceRequested;

  if (resourceRequested === 'services') {
    if (kindRequested !== KubernetesV1Group) {
      return `/apis/${path}`;
    }
  }

  fullPath = v1ResponseTypes.includes(resourceRequested)
    ? `/api/${path}`
    : `/apis/${path}`;

  if (clusterProvider === UCP_AUTH_PROVIDER_ID) {
    fullPath = `/project/${project}/space/${space}/${fullPath}`;
  }
  return fullPath;
}
