import { ImageVulnerabilityScan as Model } from '../../kubernetes-additional-crds/gen/app-scanning.apps.tanzu.vmware.com/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IImageVulnerabilityScan } from '../../kubernetes-additional-crds/gen/app-scanning.apps.tanzu.vmware.com/v1alpha1';
import type { IRepository } from '../../repository';

export class ImageVulnerabilityScan extends AbstractModel<IImageVulnerabilityScan> {
  public static readonly humanType = 'Scanning ImageVulnerabilityScan';

  public static readonly typeMeta = Model;

  public classRef = ImageVulnerabilityScan;

  public constructor(
    resource: IImageVulnerabilityScan,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
