import { SidebarItem } from '@backstage/core-components';
import {
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { kubernetesAuthProvidersApiRef } from '@backstage/plugin-kubernetes';
import { ToggleFeature, ToggleRoute } from '@tpb/feature-flags';
import {
  kubernetesLocalProxyApiRef,
  KubernetesLocalProxyClient,
} from '@tpb/kubernetes-orm';
import {
  MetadataStoreApiClient,
  metadataStoreApiRef,
} from '@tpb/metadata-store-interface';
import {
  ApiSurface,
  AppPluginInterface,
  AppRouteSurface,
  SidebarItemSurface,
} from '@vmware-tanzu/core-frontend';
import React from 'react';
import { SecurityAnalysisIcon } from '../icons/SecurityAnalysisIcon';
import { SecurityAnalysisPage } from '../plugin';

export const SecurityAnalysisPlugin: AppPluginInterface = () => context => {
  context.applyTo(ApiSurface, surface => {
    surface.add(
      createApiFactory({
        api: metadataStoreApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => new MetadataStoreApiClient({ configApi }),
      }),
    );
    surface.add(
      createApiFactory({
        api: kubernetesLocalProxyApiRef,
        deps: {
          discoveryApi: discoveryApiRef,
          identityApi: identityApiRef,
          kubernetesAuthProvidersApi: kubernetesAuthProvidersApiRef,
        },
        factory: ({ discoveryApi, identityApi, kubernetesAuthProvidersApi }) =>
          new KubernetesLocalProxyClient({
            discoveryApi,
            identityApi,
            kubernetesAuthProvidersApi,
          }),
      }),
    );
  });

  context.applyTo(AppRouteSurface, routes => {
    routes.add(
      <ToggleRoute
        feature="customize.features.securityAnalysis.enabled"
        path="/security-analysis"
        element={<SecurityAnalysisPage />}
      />,
    );
  });
  context.applyTo(SidebarItemSurface, sidebar =>
    sidebar.addMainItem(
      <ToggleFeature feature="customize.features.securityAnalysis.showInSidebar">
        <SidebarItem
          icon={SecurityAnalysisIcon}
          to="security-analysis"
          text="Security Analysis"
        />
      </ToggleFeature>,
    ),
  );
};
