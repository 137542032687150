import { Runnable as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IRunnable } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class Runnable extends AbstractModel<IRunnable> {
  public static readonly humanType = 'Cartographer Runnable';

  public static readonly typeMeta = Model;

  public classRef = Runnable;

  public constructor(
    resource: IRunnable,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
