import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'scanning.apps.tanzu.vmware.com.v1beta1.ImageScan';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['scanning.apps.tanzu.vmware.com/v1beta1'],
    },
    kind: {
      type: 'string',
      enum: ['ImageScan'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        registry: {
          properties: {
            image: {
              type: 'string',
            },
            imagePullSecret: {
              type: 'string',
              nullable: true,
            },
          },
          required: ['image'],
          type: 'object',
        },
        scanPolicy: {
          type: 'string',
          nullable: true,
        },
        scanTemplate: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['registry'],
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        artifact: {
          properties: {
            registry: {
              properties: {
                image: {
                  type: 'string',
                },
                imagePullSecret: {
                  type: 'string',
                  nullable: true,
                },
              },
              required: ['image'],
              type: 'object',
            },
          },
          required: ['registry'],
          type: 'object',
          nullable: true,
        },
        compliantArtifact: {
          properties: {
            registry: {
              properties: {
                image: {
                  type: 'string',
                },
                imagePullSecret: {
                  type: 'string',
                  nullable: true,
                },
              },
              required: ['image'],
              type: 'object',
            },
          },
          required: ['registry'],
          type: 'object',
          nullable: true,
        },
        conditions: {
          items: {
            properties: {
              error: {
                type: 'string',
                nullable: true,
              },
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
                nullable: true,
              },
              message: {
                type: 'string',
                nullable: true,
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                nullable: true,
              },
              reason: {
                type: 'string',
              },
              status: {
                type: 'string',
                nullable: true,
              },
              type: {
                type: 'string',
              },
            },
            required: ['reason', 'type'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        cveCount: {
          properties: {
            critical: {
              default: 0,
              type: 'integer',
              nullable: true,
            },
            high: {
              default: 0,
              type: 'integer',
              nullable: true,
            },
            low: {
              default: 0,
              type: 'integer',
              nullable: true,
            },
            medium: {
              default: 0,
              type: 'integer',
              nullable: true,
            },
            total: {
              default: 0,
              type: 'integer',
              nullable: true,
            },
            unknown: {
              default: 0,
              type: 'integer',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        metadataURL: {
          type: 'string',
          nullable: true,
        },
        nonCompliantArtifact: {
          properties: {
            registry: {
              properties: {
                image: {
                  type: 'string',
                },
                imagePullSecret: {
                  type: 'string',
                  nullable: true,
                },
              },
              required: ['image'],
              type: 'object',
            },
          },
          required: ['registry'],
          type: 'object',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        observedPolicyGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        observedPolicyUID: {
          type: 'string',
          nullable: true,
        },
        observedTemplateGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        observedTemplateUID: {
          type: 'string',
          nullable: true,
        },
        phase: {
          type: 'string',
          nullable: true,
        },
        scannedAt: {
          format: 'date-time',
          type: 'string',
          nullable: true,
        },
        scannedBy: {
          properties: {
            db: {
              properties: {
                version: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
            scanner: {
              properties: {
                name: {
                  type: 'string',
                  nullable: true,
                },
                vendor: {
                  type: 'string',
                  nullable: true,
                },
                version: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IImageScan {
  apiVersion: 'scanning.apps.tanzu.vmware.com/v1beta1';
  kind: 'ImageScan';
  metadata?: IObjectMeta;
  spec?: {
    registry: {
      image: string;
      imagePullSecret?: string;
    };
    scanPolicy?: string;
    scanTemplate?: string;
  };
  status?: {
    artifact?: {
      registry: {
        image: string;
        imagePullSecret?: string;
      };
    };
    compliantArtifact?: {
      registry: {
        image: string;
        imagePullSecret?: string;
      };
    };
    conditions?: Array<{
      error?: string;
      lastTransitionTime?: string;
      message?: string;
      observedGeneration?: number;
      reason: string;
      status?: string;
      type: string;
    }>;
    cveCount?: {
      critical?: number;
      high?: number;
      low?: number;
      medium?: number;
      total?: number;
      unknown?: number;
    };
    metadataURL?: string;
    nonCompliantArtifact?: {
      registry: {
        image: string;
        imagePullSecret?: string;
      };
    };
    observedGeneration?: number;
    observedPolicyGeneration?: number;
    observedPolicyUID?: string;
    observedTemplateGeneration?: number;
    observedTemplateUID?: string;
    phase?: string;
    scannedAt?: string;
    scannedBy?: {
      db?: {
        version?: string;
      };
      scanner?: {
        name?: string;
        vendor?: string;
        version?: string;
      };
    };
  };
}

export class ImageScan extends Model<IImageScan> implements IImageScan {
  'apiVersion': IImageScan['apiVersion'];
  'kind': IImageScan['kind'];
  'metadata'?: IImageScan['metadata'];
  'spec'?: IImageScan['spec'];
  'status'?: IImageScan['status'];

  static apiVersion: IImageScan['apiVersion'] =
    'scanning.apps.tanzu.vmware.com/v1beta1';
  static kind: IImageScan['kind'] = 'ImageScan';
  static is = createTypeMetaGuard<IImageScan>(ImageScan);

  constructor(data?: ModelData<IImageScan>) {
    super({
      apiVersion: ImageScan.apiVersion,
      kind: ImageScan.kind,
      ...data,
    } as IImageScan);
  }
}

setSchema(ImageScan, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
