import { ClusterTemplate as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterTemplate } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterTemplate extends AbstractModel<IClusterTemplate> {
  public static readonly humanType = 'Cartographer Cluster Template';

  public static readonly typeMeta = Model;

  public classRef = ClusterTemplate;

  public constructor(
    resource: IClusterTemplate,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
