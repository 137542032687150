import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterConfigTemplate';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterConfigTemplate'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        configPath: {
          type: 'string',
        },
        params: {
          items: {
            properties: {
              default: {},
              name: {
                type: 'string',
              },
            },
            required: ['default', 'name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        template: {
          type: 'object',
          properties: {},
          nullable: true,
        },
        ytt: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['configPath'],
      type: 'object',
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterConfigTemplate {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterConfigTemplate';
  metadata: IObjectMeta;
  /**
   * Spec describes the config template. More info: https://cartographer.sh/docs/latest/reference/template/#clusterconfigtemplate
   */
  spec: {
    /**
     * ConfigPath is a path into the templated object's data that contains valid yaml. This is typically the information that will configure the components of the deployable image. ConfigPath is specified in jsonpath format, eg: .data
     */
    configPath: string;
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner or Template does not specify this parameter, this value is used.
       */
      default: any;
      /**
       * Name of a parameter the template accepts from the Blueprint or Owner.
       */
      name: string;
    }>;
    /**
     * Template defines a resource template for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    template?: {};
    /**
     * Ytt defines a resource template written in `ytt` for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    ytt?: string;
  };
}

export class ClusterConfigTemplate
  extends Model<IClusterConfigTemplate>
  implements IClusterConfigTemplate
{
  'apiVersion': IClusterConfigTemplate['apiVersion'];
  'kind': IClusterConfigTemplate['kind'];
  'metadata': IClusterConfigTemplate['metadata'];
  'spec': IClusterConfigTemplate['spec'];

  static apiVersion: IClusterConfigTemplate['apiVersion'] =
    'carto.run/v1alpha1';
  static kind: IClusterConfigTemplate['kind'] = 'ClusterConfigTemplate';
  static is = createTypeMetaGuard<IClusterConfigTemplate>(
    ClusterConfigTemplate,
  );

  constructor(data?: ModelData<IClusterConfigTemplate>) {
    super({
      apiVersion: ClusterConfigTemplate.apiVersion,
      kind: ClusterConfigTemplate.kind,
      ...data,
    } as IClusterConfigTemplate);
  }
}

setSchema(ClusterConfigTemplate, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
