import { useEffect, useReducer } from 'react';
import { clustersReducer, initialState } from './clustersReducer';
import type { ClusterData, ICluster } from './types';
import type { KubernetesApi } from '@backstage/plugin-kubernetes';

const getClusters = async (
  kubernetesApi: KubernetesApi,
): Promise<ICluster[]> => {
  const clustersData = await kubernetesApi.getClusters();
  return clustersData;
};

interface Props {
  kubernetesApi: KubernetesApi;
}

export const useGetClusters = ({ kubernetesApi }: Props): ClusterData => {
  const [data, dispatch] = useReducer(clustersReducer, initialState);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const clusterData = await getClusters(kubernetesApi);
        dispatch({ type: 'FETCH_SUCCESS', payload: clusterData });
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: [] });
      }
    };
    if (data.clusters.length === 0) {
      void getData();
    }
  }, [kubernetesApi, data.clusters.length]);

  return data;
};
