import { Image as Model } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import { AbstractModel } from '../AbstractModel';
import type { IImage } from '../../kubernetes-additional-crds/gen/kpack.io/v1alpha2';
import type { IRepository } from '../../repository';
import type { ISource, IStatus } from '../BaseTypes';

export interface ImageSpec {
  builder: {
    kind: string;
    name: string;
  };
  tag: string;
  additionalTags?: string[];
  source: ISource;
  serviceAccountName?: string;
  imageTaggingStrategy?: string;
  successBuildHistoryLimit?: number;
  failedBuildHistoryLimit?: number;
}

export class Image extends AbstractModel<IImage> {
  public static readonly humanType = 'Kpack Image';

  public static readonly typeMeta = Model;

  public classRef = Image;

  public constructor(
    resource: IImage,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get spec(): ImageSpec | undefined {
    // @ts-expect-error: Explicit typecast. `spec` is sometimes defined but `IImage` lacks the declaration
    return this.resource.spec as ImageSpec | undefined;
  }

  public get imageStatus(): IStatus | undefined {
    // @ts-expect-error: Explicit typecast. `status` is sometimes defined but `IImage` lacks the declaration
    return this.resource.status as IStatus | undefined;
  }
}
