import { createTypeMetaGuard } from '@kubernetes-models/base';
import { Namespace } from 'kubernetes-models/v1';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import type { ConditionList } from './Condition';
import type { Guid, IModel, IModelClass } from './index';
import type { MappedTypeMeta } from '../ClassMap';
import type { IRepository } from '../repository';
import type { TypeMeta } from '@kubernetes-models/base';
import type { INamespace } from 'kubernetes-models/v1';

export abstract class AbstractModel<T extends TypeMeta> implements IModel<T> {
  public readonly cluster: string;

  public readonly resource: T;

  public readonly id: Guid;

  protected readonly repository: IRepository;

  public abstract classRef: IModelClass;

  protected constructor(
    resource: MappedTypeMeta<T>,
    repository: IRepository,
    cluster: string,
    expectedType: TypeMeta,
  ) {
    // The ModelFactory ought never attempt to instantiate the wrong class; this is defense in depth.
    if (!createTypeMetaGuard<T>(expectedType)(resource)) {
      throw Error(
        `Attempted to instantiate a resource of type ${
          expectedType.apiVersion
        }/${expectedType.kind} with an object of type ${
          get(resource, 'apiVersion') as string
        }/${get(resource, 'kind') as string}`,
      );
    }

    this.cluster = cluster;
    this.repository = repository;
    this.resource = resource;
    this.id = AbstractModel.provisionId(resource);
  }

  public get apiVersion(): T['apiVersion'] {
    return this.classRef.typeMeta.apiVersion;
  }

  public get kind(): T['kind'] {
    return this.classRef.typeMeta.kind;
  }

  public get humanType(): string {
    return this.classRef.humanType;
  }

  public get conditions(): ConditionList | undefined {
    return undefined;
  }

  public get owners(): IModel[] {
    return [];
  }

  public get space(): string | undefined {
    const ns: string | undefined = get(this.resource, [
      'metadata',
      'namespace',
    ]) as string;
    const resourceNamespace: IModel | undefined = this.repository
      .ofType<INamespace>(Namespace)
      .find(n => n.resource.metadata?.name === ns);

    if (!resourceNamespace) {
      return undefined;
    }

    const TANZU_UCP_CLUSTER_PATH = 'agent.tanzu.vmware.com/clusterpath';

    const clusterPathComponents = (
      resourceNamespace.resource.metadata?.annotations?.[
        TANZU_UCP_CLUSTER_PATH
      ] ?? ''
    ).split(':');

    return clusterPathComponents.length > 1
      ? clusterPathComponents[clusterPathComponents.length - 1]
      : undefined;
  }

  // A Kubernetes resource *ought* to always have a .metadata.id but there's no formal guarantee of it.
  private static provisionId(resource: TypeMeta): Guid {
    const id: unknown = get(resource, ['metadata', 'uid']);
    if (typeof id === 'string') {
      return id;
    }

    return uuid();
  }
}
