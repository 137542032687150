import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import type { Guid, IModel } from '.';
import type { ConditionList } from './Condition';
import type { IRepository } from '../repository';
import type { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1';
import type { TypeMeta } from '@kubernetes-models/base';

export class GenericClass<T extends TypeMeta>
  implements Omit<IModel, 'classRef' | 'humanType' | 'owners' | 'space'>
{
  public readonly cluster: string;

  public readonly resource: T & { metadata?: IObjectMeta };

  public readonly id: Guid;

  protected readonly repository: IRepository;

  protected constructor(resource: T, repository: IRepository, cluster: string) {
    this.cluster = cluster;
    this.repository = repository;
    this.resource = resource;
    this.id = GenericClass.provisionId(resource);
  }

  public get apiVersion(): T['apiVersion'] {
    return this.resource.apiVersion;
  }

  public get kind(): T['kind'] {
    return this.resource.kind;
  }

  public get conditions(): ConditionList | undefined {
    return undefined;
  }

  public get owners(): TypeMeta[] | undefined {
    return this.resource.metadata?.ownerReferences;
  }

  private static provisionId(resource: TypeMeta): Guid {
    const id: unknown = get(resource, ['metadata', 'uid']);
    if (typeof id === 'string') {
      return id;
    }

    return uuid();
  }
}
