import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'source.apps.tanzu.vmware.com.v1alpha1.ImageRepository';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['source.apps.tanzu.vmware.com/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ImageRepository'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        image: {
          type: 'string',
        },
        imagePullSecrets: {
          items: {
            properties: {
              name: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        interval: {
          type: 'string',
          nullable: true,
        },
        serviceAccountName: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['image'],
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        artifact: {
          properties: {
            checksum: {
              type: 'string',
              nullable: true,
            },
            lastUpdateTime: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
            path: {
              type: 'string',
            },
            revision: {
              type: 'string',
              nullable: true,
            },
            url: {
              type: 'string',
            },
          },
          required: ['path', 'url'],
          type: 'object',
          nullable: true,
        },
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                type: 'string',
                nullable: true,
              },
              message: {
                type: 'string',
                nullable: true,
              },
              reason: {
                type: 'string',
                nullable: true,
              },
              severity: {
                type: 'string',
                nullable: true,
              },
              status: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
            },
            required: ['status', 'type'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        url: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

/**
 * ImageRepository is the Schema for the imagerepositories API
 */
export interface IImageRepository {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'source.apps.tanzu.vmware.com/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ImageRepository';
  metadata?: IObjectMeta;
  /**
   * ImageRepositorySpec defines the desired state of ImageRepository
   */
  spec?: {
    /**
     * Image is a reference to an image in a remote repository
     */
    image: string;
    /**
     * ImagePullSecrets contains the names of the Kubernetes Secrets containing registry login information to resolve image metadata.
     */
    imagePullSecrets?: Array<{
      /**
       * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
       */
      name?: string;
    }>;
    /**
     * The interval at which to check for repository updates.
     */
    interval?: string;
    /**
     * ServiceAccountName is the name of the Kubernetes ServiceAccount used to authenticate the image pull if the service account has attached pull secrets. For more information: https://kubernetes.io/docs/tasks/configure-pod-container/configure-service-account/#add-imagepullsecrets-to-a-service-account
     */
    serviceAccountName?: string;
  };
  /**
   * ImageRepositoryStatus defines the observed state of ImageRepository
   */
  status?: {
    /**
     * Artifact represents the output of the last successful repository sync.
     */
    artifact?: {
      /**
       * Checksum is the SHA1 checksum of the artifact.
       */
      checksum?: string;
      /**
       * LastUpdateTime is the timestamp corresponding to the last update of this artifact.
       */
      lastUpdateTime?: string;
      /**
       * Path is the relative file path of this artifact.
       */
      path: string;
      /**
       * Revision is a human readable identifier traceable in the origin source system. It can be a Git commit SHA, Git tag, a Helm index timestamp, a Helm chart version, etc.
       */
      revision?: string;
      /**
       * URL is the HTTP address of this artifact.
       */
      url: string;
    };
    /**
     * Conditions the latest available observations of a resource's current state.
     */
    conditions?: Array<{
      /**
       * LastTransitionTime is the last time the condition transitioned from one status to another. We use VolatileTime in place of metav1.Time to exclude this from creating equality.Semantic differences (all other things held constant).
       */
      lastTransitionTime?: string;
      /**
       * A human readable message indicating details about the transition.
       */
      message?: string;
      /**
       * The reason for the condition's last transition.
       */
      reason?: string;
      /**
       * Severity with which to treat failures of this type of condition. When this is not specified, it defaults to Error.
       */
      severity?: string;
      /**
       * Status of the condition, one of True, False, Unknown.
       */
      status: string;
      /**
       * Type of condition.
       */
      type: string;
    }>;
    /**
     * ObservedGeneration is the 'Generation' of the resource that was last processed by the controller.
     */
    observedGeneration?: number;
    /**
     * URL is the download link for the artifact output of the last repository sync.
     */
    url?: string;
  };
}

/**
 * ImageRepository is the Schema for the imagerepositories API
 */
export class ImageRepository
  extends Model<IImageRepository>
  implements IImageRepository
{
  'apiVersion': IImageRepository['apiVersion'];
  'kind': IImageRepository['kind'];
  'metadata'?: IImageRepository['metadata'];
  'spec'?: IImageRepository['spec'];
  'status'?: IImageRepository['status'];

  static apiVersion: IImageRepository['apiVersion'] =
    'source.apps.tanzu.vmware.com/v1alpha1';
  static kind: IImageRepository['kind'] = 'ImageRepository';
  static is = createTypeMetaGuard<IImageRepository>(ImageRepository);

  constructor(data?: ModelData<IImageRepository>) {
    super({
      apiVersion: ImageRepository.apiVersion,
      kind: ImageRepository.kind,
      ...data,
    } as IImageRepository);
  }
}

setSchema(ImageRepository, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
