import { useQuery } from '@tanstack/react-query';
import { fetchUcpProjects } from '../../apis/fetchUcpProjects';
import type { KubernetesLocalProxyApi } from '../../apis';
import type { Project } from '../../model/ucp';
import type { ICluster } from '../../proxy/hook/types';

interface ProjectResponse {
  isLoadingProjects: boolean;
  projects: Project[];
}

interface Props {
  kubernetesLocalProxyApi: KubernetesLocalProxyApi;
  clusters: ICluster[];
}

export const useUcpProjects = ({
  kubernetesLocalProxyApi,
  clusters,
}: Props): ProjectResponse => {
  const ucpCluster = clusters.find(c => c.authProvider === 'tae') ?? {
    name: 'no-ucp',
    authProvider: 'serviceAccount',
  };

  const { data, isLoading } = useQuery({
    queryKey: [
      `cluster-${ucpCluster.name}-projects`,
      ucpCluster,
      kubernetesLocalProxyApi,
    ],
    queryFn: async () => fetchUcpProjects(kubernetesLocalProxyApi, ucpCluster),
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isLoading) {
    return {
      isLoadingProjects: true,
      projects: [],
    };
  }

  if (!data || data.length === 0) {
    return {
      isLoadingProjects: false,
      projects: [],
    };
  }

  return {
    isLoadingProjects: false,
    projects: data,
  };
};
