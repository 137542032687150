import { DaemonSet as Model } from 'kubernetes-models/apps/v1';
import { Pod } from 'kubernetes-models/v1';
import { isUndefined } from 'lodash';
import { AbstractModel } from '../AbstractModel';
import { ConditionCategory } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList, Condition } from '../Condition';
import type { IDaemonSet, IDaemonSetStatus } from 'kubernetes-models/apps/v1';
import type { IPod } from 'kubernetes-models/v1';

export class DaemonSet extends AbstractModel<IDaemonSet> {
  public static readonly humanType = 'DaemonSet';

  public static readonly typeMeta = Model;

  public classRef = DaemonSet;

  public constructor(
    resource: IDaemonSet,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    const { status } = this.resource;

    const numberOfNodes = this.getNumberOfNodesCondition(status);

    const readyPods = this.getReadyPodsCondition(status);

    const availablePods = this.getAvailablePodsCondition(status);

    const misscheduledPods = this.getMisscheduledPodsCondition(status);

    const unavailablePods = this.getUnavailablePodsCondition(status);

    const otherConditions = [
      readyPods,
      availablePods,
      misscheduledPods,
      unavailablePods,
    ];

    return {
      primary: numberOfNodes,
      other: otherConditions,
    };
  }

  public get pods(): MappedResource<IPod>[] {
    return this.repository.ownedOfType<IPod>(this, Pod);
  }

  private getNumberOfNodesCondition(
    status: IDaemonSetStatus | undefined,
  ): Condition {
    const condition = {
      category: ConditionCategory.error,
      lastTransitionTime: undefined,
      status: `${status?.currentNumberScheduled ?? 0} / ${
        status?.desiredNumberScheduled ?? 0
      }`,
      type: 'numberOfNodes',
      title: 'Nodes Scheduled / Desired',
      message: 'Some daemon pods are running',
    };

    if (status?.currentNumberScheduled === status?.desiredNumberScheduled) {
      condition.category =
        status?.desiredNumberScheduled === 0
          ? ConditionCategory.warning
          : ConditionCategory.healthy;
      condition.message =
        status?.desiredNumberScheduled === 0
          ? 'No daemon pods are scheduled'
          : 'All expected daemon pods are running';
    }
    return condition;
  }

  private getReadyPodsCondition(
    status: IDaemonSetStatus | undefined,
  ): Condition {
    const condition = {
      category:
        status?.numberReady === status?.desiredNumberScheduled
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: `${status?.numberReady ?? 0}`,
      type: 'readyPods',
      title: 'Ready Pods',
      message:
        status?.numberReady === status?.desiredNumberScheduled
          ? 'All expected daemon pods are ready'
          : 'No daemon pods are ready',
    };

    if (
      !isUndefined(status) &&
      !isUndefined(status.numberReady) &&
      status.numberReady !== status.desiredNumberScheduled
    ) {
      condition.category =
        status.numberReady > 0
          ? ConditionCategory.warning
          : ConditionCategory.error;
      condition.message =
        status.numberReady > 0
          ? 'Some daemon pods are not ready'
          : 'No daemon pods are ready';
    }

    return condition;
  }

  private getAvailablePodsCondition(
    status: IDaemonSetStatus | undefined,
  ): Condition {
    const condition = {
      category:
        status?.numberAvailable === status?.desiredNumberScheduled
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: `${status?.numberAvailable ?? 0}`,
      type: 'availablePods',
      title: 'Available',
      message:
        status?.numberAvailable === status?.desiredNumberScheduled
          ? 'All expected daemon pods are available'
          : 'No daemon pods are available',
    };

    if (
      !isUndefined(status) &&
      !isUndefined(status.numberAvailable) &&
      status.numberAvailable !== status.desiredNumberScheduled
    ) {
      condition.category =
        status.numberAvailable > 0
          ? ConditionCategory.warning
          : ConditionCategory.error;
      condition.message =
        status.numberAvailable > 0
          ? 'Some daemon pods are not available'
          : 'No daemon pods are available';
    }
    return condition;
  }

  private getMisscheduledPodsCondition(
    status: IDaemonSetStatus | undefined,
  ): Condition {
    const condition = {
      category: ConditionCategory.healthy,
      lastTransitionTime: undefined,
      status: `${status?.numberMisscheduled ?? 0}`,
      type: 'misscheduledPods',
      title: 'Misschedule',
    };

    if (!isUndefined(status) && !isUndefined(status.numberMisscheduled)) {
      condition.category =
        status.numberMisscheduled > 0
          ? ConditionCategory.error
          : ConditionCategory.healthy;
    }
    return condition;
  }

  private getUnavailablePodsCondition(
    status: IDaemonSetStatus | undefined,
  ): Condition {
    const condition = {
      category: ConditionCategory.healthy,
      lastTransitionTime: undefined,
      status: `${status?.numberUnavailable ?? 0}`,
      type: 'unavailablePods',
      title: 'Unavailable',
    };
    if (!isUndefined(status) && !isUndefined(status.numberUnavailable)) {
      condition.category =
        status.numberUnavailable === 0
          ? ConditionCategory.healthy
          : ConditionCategory.error;
    }
    return condition;
  }
}
