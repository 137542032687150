import { ServiceAccount as Model } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { IServiceAccount } from 'kubernetes-models/v1';

export class ServiceAccount extends AbstractModel<IServiceAccount> {
  public static readonly humanType = 'Service Account';

  public static readonly typeMeta = Model;

  public classRef = ServiceAccount;

  public constructor(
    resource: IServiceAccount,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
