import type { ClusterData, ICluster } from './types';

type ClusterAction =
  | {
      type: 'FETCH_ERROR';
      payload: ICluster[];
    }
  | {
      type: 'FETCH_SUCCESS';
      payload: ICluster[];
    };

export const initialState = {
  loading: true,
  clusters: [],
  error: '',
};

export const clustersReducer = (
  state: ClusterData,
  action: ClusterAction,
): ClusterData => {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return {
        loading: false,
        clusters: action.payload,
        error: '',
      };
    case 'FETCH_ERROR':
      return {
        loading: false,
        clusters: [],
        error: 'Error while fetching the clusters',
      };
    default:
      return state;
  }
};
