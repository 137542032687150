import { isUndefined } from 'lodash';
import type { IQuantity } from '@kubernetes-models/apimachinery/api/resource';

export type MetricConstructor<T extends IMetric<T>> = new (
  quantity: IQuantity,
) => T;

export enum MetricType {
  // When the metric contains an undefined value
  Invalid = 'INVALID',

  // Used as special case when the metric doesn't have a value but its meaning refers to infinity
  Unlimited = 'UNLIMITED',
}

export interface IMetric<T> {
  readonly value: MetricType.Invalid | MetricType.Unlimited | number;
  format: () => string;
  add: (n: T) => T;
}

export const resolveLimitValue = (
  limitValue?: IQuantity | undefined,
): IQuantity | MetricType.Unlimited => {
  if (isUndefined(limitValue)) {
    return MetricType.Unlimited;
  }

  return [0, '0'].includes(limitValue) ? MetricType.Unlimited : limitValue;
};
