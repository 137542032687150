import { StatusCodes } from 'http-status-codes';
import { Space as RawSpace } from '../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import { Space } from '../model/spaces';
import { Repository } from '../repository';
import type { KubernetesLocalProxyApi } from './KubernetesLocalProxyApi';
import type { ICluster } from '../proxy/hook/types';
import { ProxyResponse } from '../types';

export async function fetchUcpSpaces(
  kubernetesLocalProxyApi: KubernetesLocalProxyApi,
  cluster: ICluster,
  projects: string[],
): Promise<Space[]> {
  const spaces: Space[][] = [];

  if (cluster.name === 'no-ucp') {
    return [];
  }

  for (const project of projects) {
    const path = `/project/${project}/apis/spaces.tanzu.vmware.com/v1alpha1/spaces`;

    try {
      const response = await kubernetesLocalProxyApi.proxy({ cluster, path });
      const ucpSpace = await normalizeResponse(response, cluster, project);
      spaces.push(ucpSpace);
    } catch (e: unknown) {
      return [];
    }
  }
  return spaces.flat();
}

async function normalizeResponse(
  response: Response,
  cluster: ICluster,
  project: string,
): Promise<Space[]> {
  const spaces: Space[] = [];
  const emptyRepository = new Repository({ items: [] });
  if ((response.status as StatusCodes) !== StatusCodes.OK) {
    return spaces;
  }

  const data = (await response.json()) as ProxyResponse;

  if (!('items' in data)) {
    return spaces;
  }

  for (const rawSpace of data.items as RawSpace[]) {
    // Disabled intentionally, thinking that we could get a response from a different Kind than Space
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (rawSpace.kind !== RawSpace.kind) {
      break;
    }
    const space = new Space(rawSpace, emptyRepository, cluster.name, project);
    spaces.push(space);
  }

  return spaces;
}
