import {
  useSidebarOpenState,
  useSidebarPinState,
} from '@backstage/core-components';
import { useCallback, useEffect } from 'react';

export const useSidebar = () => {
  const { isOpen, setOpen } = useSidebarOpenState();
  const { isMobile, isPinned, toggleSidebarPinState } = useSidebarPinState();

  const toggleSidebar = useCallback(() => {
    if ((isPinned && isOpen) || (!isPinned && !isOpen)) {
      toggleSidebarPinState();
    }
    setOpen(!isOpen);
  }, [isPinned, isOpen, setOpen, toggleSidebarPinState]);

  useEffect(() => setOpen(!isMobile), [isMobile, setOpen]);

  return {
    isDesktop: !isMobile,
    toggleSidebar,
    isOpened: isOpen,
  };
};
