type NetworkErrorKeys =
  | 'NotFound'
  | 'Unauthorized'
  | 'SystemError'
  | 'Unknown'
  | 'FetchError'
  | 'BadRequest'
  | 'ParseError'
  | string;

export const networkErrorTypes: Record<NetworkErrorKeys, string> = {
  NotFound: 'NOT_FOUND',
  Unauthorized: 'UNAUTHORIZED_ERROR',
  SystemError: 'SYSTEM_ERROR',
  Unknown: 'UNKNOWN_ERROR',
  FetchError: 'FETCH_ERROR',
  BadRequest: 'BAD_REQUEST',
  ParseError: 'PARSE_ERROR',
};
