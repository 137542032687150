import { MavenArtifact as Model } from '../../kubernetes-additional-crds/gen/source.apps.tanzu.vmware.com/v1alpha1/MavenArtifact';
import { AbstractModel } from '../AbstractModel';
import type { IMavenArtifact } from '../../kubernetes-additional-crds/gen/source.apps.tanzu.vmware.com/v1alpha1/MavenArtifact';
import type { IRepository } from '../../repository';

export class MavenArtifact extends AbstractModel<IMavenArtifact> {
  public static readonly humanType = 'Source ImageRepository';

  public static readonly typeMeta = Model;

  public classRef = MavenArtifact;

  public constructor(
    resource: IMavenArtifact,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
