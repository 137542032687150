import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId =
  'app-scanning.apps.tanzu.vmware.com.v1alpha1.ImageVulnerabilityScan';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['app-scanning.apps.tanzu.vmware.com/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ImageVulnerabilityScan'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        activeKeychains: {
          items: {
            properties: {
              name: {
                type: 'string',
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        image: {
          type: 'string',
        },
        scanResults: {
          properties: {
            location: {
              type: 'string',
            },
          },
          required: ['location'],
          type: 'object',
        },
        serviceAccountNames: {
          properties: {
            publisher: {
              default: 'default',
              type: 'string',
            },
            scanner: {
              default: 'default',
              type: 'string',
            },
          },
          required: ['publisher', 'scanner'],
          type: 'object',
          nullable: true,
        },
        steps: {
          items: {
            properties: {
              args: {
                items: {
                  type: 'string',
                },
                type: 'array',
                nullable: true,
              },
              command: {
                items: {
                  type: 'string',
                },
                type: 'array',
                nullable: true,
              },
              env: {
                items: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                    value: {
                      type: 'string',
                      nullable: true,
                    },
                    valueFrom: {
                      properties: {
                        configMapKeyRef: {
                          properties: {
                            key: {
                              type: 'string',
                            },
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                            optional: {
                              type: 'boolean',
                              nullable: true,
                            },
                          },
                          required: ['key'],
                          type: 'object',
                          nullable: true,
                        },
                        fieldRef: {
                          properties: {
                            apiVersion: {
                              type: 'string',
                              nullable: true,
                            },
                            fieldPath: {
                              type: 'string',
                            },
                          },
                          required: ['fieldPath'],
                          type: 'object',
                          nullable: true,
                        },
                        resourceFieldRef: {
                          properties: {
                            containerName: {
                              type: 'string',
                              nullable: true,
                            },
                            divisor: {
                              oneOf: [
                                {
                                  anyOf: [
                                    {
                                      type: 'integer',
                                    },
                                    {
                                      type: 'string',
                                    },
                                  ],
                                  pattern:
                                    '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                                },
                                {
                                  type: 'null',
                                },
                              ],
                            },
                            resource: {
                              type: 'string',
                            },
                          },
                          required: ['resource'],
                          type: 'object',
                          nullable: true,
                        },
                        secretKeyRef: {
                          properties: {
                            key: {
                              type: 'string',
                            },
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                            optional: {
                              type: 'boolean',
                              nullable: true,
                            },
                          },
                          required: ['key'],
                          type: 'object',
                          nullable: true,
                        },
                      },
                      type: 'object',
                      nullable: true,
                    },
                  },
                  required: ['name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              envFrom: {
                items: {
                  properties: {
                    configMapRef: {
                      properties: {
                        name: {
                          type: 'string',
                          nullable: true,
                        },
                        optional: {
                          type: 'boolean',
                          nullable: true,
                        },
                      },
                      type: 'object',
                      nullable: true,
                    },
                    prefix: {
                      type: 'string',
                      nullable: true,
                    },
                    secretRef: {
                      properties: {
                        name: {
                          type: 'string',
                          nullable: true,
                        },
                        optional: {
                          type: 'boolean',
                          nullable: true,
                        },
                      },
                      type: 'object',
                      nullable: true,
                    },
                  },
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              image: {
                type: 'string',
                nullable: true,
              },
              imagePullPolicy: {
                type: 'string',
                nullable: true,
              },
              lifecycle: {
                properties: {
                  postStart: {
                    properties: {
                      exec: {
                        properties: {
                          command: {
                            items: {
                              type: 'string',
                            },
                            type: 'array',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                      httpGet: {
                        properties: {
                          host: {
                            type: 'string',
                            nullable: true,
                          },
                          httpHeaders: {
                            items: {
                              properties: {
                                name: {
                                  type: 'string',
                                },
                                value: {
                                  type: 'string',
                                },
                              },
                              required: ['name', 'value'],
                              type: 'object',
                            },
                            type: 'array',
                            nullable: true,
                          },
                          path: {
                            type: 'string',
                            nullable: true,
                          },
                          port: {
                            anyOf: [
                              {
                                type: 'integer',
                              },
                              {
                                type: 'string',
                              },
                            ],
                          },
                          scheme: {
                            type: 'string',
                            nullable: true,
                          },
                        },
                        required: ['port'],
                        type: 'object',
                        nullable: true,
                      },
                      tcpSocket: {
                        properties: {
                          host: {
                            type: 'string',
                            nullable: true,
                          },
                          port: {
                            anyOf: [
                              {
                                type: 'integer',
                              },
                              {
                                type: 'string',
                              },
                            ],
                          },
                        },
                        required: ['port'],
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  preStop: {
                    properties: {
                      exec: {
                        properties: {
                          command: {
                            items: {
                              type: 'string',
                            },
                            type: 'array',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                      httpGet: {
                        properties: {
                          host: {
                            type: 'string',
                            nullable: true,
                          },
                          httpHeaders: {
                            items: {
                              properties: {
                                name: {
                                  type: 'string',
                                },
                                value: {
                                  type: 'string',
                                },
                              },
                              required: ['name', 'value'],
                              type: 'object',
                            },
                            type: 'array',
                            nullable: true,
                          },
                          path: {
                            type: 'string',
                            nullable: true,
                          },
                          port: {
                            anyOf: [
                              {
                                type: 'integer',
                              },
                              {
                                type: 'string',
                              },
                            ],
                          },
                          scheme: {
                            type: 'string',
                            nullable: true,
                          },
                        },
                        required: ['port'],
                        type: 'object',
                        nullable: true,
                      },
                      tcpSocket: {
                        properties: {
                          host: {
                            type: 'string',
                            nullable: true,
                          },
                          port: {
                            anyOf: [
                              {
                                type: 'integer',
                              },
                              {
                                type: 'string',
                              },
                            ],
                          },
                        },
                        required: ['port'],
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              livenessProbe: {
                properties: {
                  exec: {
                    properties: {
                      command: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  failureThreshold: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  grpc: {
                    properties: {
                      port: {
                        format: 'int32',
                        type: 'integer',
                      },
                      service: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  httpGet: {
                    properties: {
                      host: {
                        type: 'string',
                        nullable: true,
                      },
                      httpHeaders: {
                        items: {
                          properties: {
                            name: {
                              type: 'string',
                            },
                            value: {
                              type: 'string',
                            },
                          },
                          required: ['name', 'value'],
                          type: 'object',
                        },
                        type: 'array',
                        nullable: true,
                      },
                      path: {
                        type: 'string',
                        nullable: true,
                      },
                      port: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                      },
                      scheme: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  initialDelaySeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  periodSeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  successThreshold: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  tcpSocket: {
                    properties: {
                      host: {
                        type: 'string',
                        nullable: true,
                      },
                      port: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  terminationGracePeriodSeconds: {
                    format: 'int64',
                    type: 'integer',
                    nullable: true,
                  },
                  timeoutSeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              name: {
                type: 'string',
              },
              onError: {
                type: 'string',
                nullable: true,
              },
              ports: {
                items: {
                  properties: {
                    containerPort: {
                      format: 'int32',
                      type: 'integer',
                    },
                    hostIP: {
                      type: 'string',
                      nullable: true,
                    },
                    hostPort: {
                      format: 'int32',
                      type: 'integer',
                      nullable: true,
                    },
                    name: {
                      type: 'string',
                      nullable: true,
                    },
                    protocol: {
                      default: 'TCP',
                      type: 'string',
                      nullable: true,
                    },
                  },
                  required: ['containerPort'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              readinessProbe: {
                properties: {
                  exec: {
                    properties: {
                      command: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  failureThreshold: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  grpc: {
                    properties: {
                      port: {
                        format: 'int32',
                        type: 'integer',
                      },
                      service: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  httpGet: {
                    properties: {
                      host: {
                        type: 'string',
                        nullable: true,
                      },
                      httpHeaders: {
                        items: {
                          properties: {
                            name: {
                              type: 'string',
                            },
                            value: {
                              type: 'string',
                            },
                          },
                          required: ['name', 'value'],
                          type: 'object',
                        },
                        type: 'array',
                        nullable: true,
                      },
                      path: {
                        type: 'string',
                        nullable: true,
                      },
                      port: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                      },
                      scheme: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  initialDelaySeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  periodSeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  successThreshold: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  tcpSocket: {
                    properties: {
                      host: {
                        type: 'string',
                        nullable: true,
                      },
                      port: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  terminationGracePeriodSeconds: {
                    format: 'int64',
                    type: 'integer',
                    nullable: true,
                  },
                  timeoutSeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              resources: {
                properties: {
                  limits: {
                    additionalProperties: {
                      anyOf: [
                        {
                          type: 'integer',
                        },
                        {
                          type: 'string',
                        },
                      ],
                      pattern:
                        '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                    },
                    type: 'object',
                    properties: {},
                    nullable: true,
                  },
                  requests: {
                    additionalProperties: {
                      anyOf: [
                        {
                          type: 'integer',
                        },
                        {
                          type: 'string',
                        },
                      ],
                      pattern:
                        '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                    },
                    type: 'object',
                    properties: {},
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              script: {
                type: 'string',
                nullable: true,
              },
              securityContext: {
                properties: {
                  allowPrivilegeEscalation: {
                    type: 'boolean',
                    nullable: true,
                  },
                  capabilities: {
                    properties: {
                      add: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                      drop: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  privileged: {
                    type: 'boolean',
                    nullable: true,
                  },
                  procMount: {
                    type: 'string',
                    nullable: true,
                  },
                  readOnlyRootFilesystem: {
                    type: 'boolean',
                    nullable: true,
                  },
                  runAsGroup: {
                    format: 'int64',
                    type: 'integer',
                    nullable: true,
                  },
                  runAsNonRoot: {
                    type: 'boolean',
                    nullable: true,
                  },
                  runAsUser: {
                    format: 'int64',
                    type: 'integer',
                    nullable: true,
                  },
                  seLinuxOptions: {
                    properties: {
                      level: {
                        type: 'string',
                        nullable: true,
                      },
                      role: {
                        type: 'string',
                        nullable: true,
                      },
                      type: {
                        type: 'string',
                        nullable: true,
                      },
                      user: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  seccompProfile: {
                    properties: {
                      localhostProfile: {
                        type: 'string',
                        nullable: true,
                      },
                      type: {
                        type: 'string',
                      },
                    },
                    required: ['type'],
                    type: 'object',
                    nullable: true,
                  },
                  windowsOptions: {
                    properties: {
                      gmsaCredentialSpec: {
                        type: 'string',
                        nullable: true,
                      },
                      gmsaCredentialSpecName: {
                        type: 'string',
                        nullable: true,
                      },
                      hostProcess: {
                        type: 'boolean',
                        nullable: true,
                      },
                      runAsUserName: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              startupProbe: {
                properties: {
                  exec: {
                    properties: {
                      command: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  failureThreshold: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  grpc: {
                    properties: {
                      port: {
                        format: 'int32',
                        type: 'integer',
                      },
                      service: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  httpGet: {
                    properties: {
                      host: {
                        type: 'string',
                        nullable: true,
                      },
                      httpHeaders: {
                        items: {
                          properties: {
                            name: {
                              type: 'string',
                            },
                            value: {
                              type: 'string',
                            },
                          },
                          required: ['name', 'value'],
                          type: 'object',
                        },
                        type: 'array',
                        nullable: true,
                      },
                      path: {
                        type: 'string',
                        nullable: true,
                      },
                      port: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                      },
                      scheme: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  initialDelaySeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  periodSeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  successThreshold: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                  tcpSocket: {
                    properties: {
                      host: {
                        type: 'string',
                        nullable: true,
                      },
                      port: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                      },
                    },
                    required: ['port'],
                    type: 'object',
                    nullable: true,
                  },
                  terminationGracePeriodSeconds: {
                    format: 'int64',
                    type: 'integer',
                    nullable: true,
                  },
                  timeoutSeconds: {
                    format: 'int32',
                    type: 'integer',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              stderrConfig: {
                properties: {
                  path: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              stdin: {
                type: 'boolean',
                nullable: true,
              },
              stdinOnce: {
                type: 'boolean',
                nullable: true,
              },
              stdoutConfig: {
                properties: {
                  path: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              terminationMessagePath: {
                type: 'string',
                nullable: true,
              },
              terminationMessagePolicy: {
                type: 'string',
                nullable: true,
              },
              timeout: {
                type: 'string',
                nullable: true,
              },
              tty: {
                type: 'boolean',
                nullable: true,
              },
              volumeDevices: {
                items: {
                  properties: {
                    devicePath: {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['devicePath', 'name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              volumeMounts: {
                items: {
                  properties: {
                    mountPath: {
                      type: 'string',
                    },
                    mountPropagation: {
                      type: 'string',
                      nullable: true,
                    },
                    name: {
                      type: 'string',
                    },
                    readOnly: {
                      type: 'boolean',
                      nullable: true,
                    },
                    subPath: {
                      type: 'string',
                      nullable: true,
                    },
                    subPathExpr: {
                      type: 'string',
                      nullable: true,
                    },
                  },
                  required: ['mountPath', 'name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              workingDir: {
                type: 'string',
                nullable: true,
              },
              workspaces: {
                items: {
                  properties: {
                    mountPath: {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['mountPath', 'name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
        },
        workspace: {
          properties: {
            bindings: {
              items: {
                properties: {
                  configMap: {
                    properties: {
                      defaultMode: {
                        format: 'int32',
                        type: 'integer',
                        nullable: true,
                      },
                      items: {
                        items: {
                          properties: {
                            key: {
                              type: 'string',
                            },
                            mode: {
                              format: 'int32',
                              type: 'integer',
                              nullable: true,
                            },
                            path: {
                              type: 'string',
                            },
                          },
                          required: ['key', 'path'],
                          type: 'object',
                        },
                        type: 'array',
                        nullable: true,
                      },
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                      optional: {
                        type: 'boolean',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  emptyDir: {
                    properties: {
                      medium: {
                        type: 'string',
                        nullable: true,
                      },
                      sizeLimit: {
                        oneOf: [
                          {
                            anyOf: [
                              {
                                type: 'integer',
                              },
                              {
                                type: 'string',
                              },
                            ],
                            pattern:
                              '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                          },
                          {
                            type: 'null',
                          },
                        ],
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  name: {
                    type: 'string',
                  },
                  secret: {
                    properties: {
                      defaultMode: {
                        format: 'int32',
                        type: 'integer',
                        nullable: true,
                      },
                      items: {
                        items: {
                          properties: {
                            key: {
                              type: 'string',
                            },
                            mode: {
                              format: 'int32',
                              type: 'integer',
                              nullable: true,
                            },
                            path: {
                              type: 'string',
                            },
                          },
                          required: ['key', 'path'],
                          type: 'object',
                        },
                        type: 'array',
                        nullable: true,
                      },
                      optional: {
                        type: 'boolean',
                        nullable: true,
                      },
                      secretName: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                },
                required: ['name'],
                type: 'object',
              },
              type: 'array',
              nullable: true,
            },
            size: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
      },
      required: ['image', 'scanResults', 'steps'],
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        pipelineRun: {
          type: 'string',
          nullable: true,
        },
        scanResult: {
          type: 'string',
          nullable: true,
        },
        scannedImage: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

/**
 * ImageVulnerabilityScan is the Schema for the imagevulnerabilityscans API.
 */
export interface IImageVulnerabilityScan {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'app-scanning.apps.tanzu.vmware.com/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ImageVulnerabilityScan';
  metadata?: IObjectMeta;
  /**
   * ImageVulnerabilityScanSpec defines the desired state of ImageVulnerabilityScan.
   */
  spec?: {
    activeKeychains?: Array<{
      name: string;
    }>;
    image: string;
    scanResults: {
      location: string;
    };
    serviceAccountNames?: {
      publisher: string;
      scanner: string;
    };
    steps: Array<{
      /**
       * Arguments to the entrypoint. The image's CMD is used if this is not provided. Variable references $(VAR_NAME) are expanded using the container's environment. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless of whether the variable exists or not. Cannot be updated. More info: https://kubernetes.io/docs/tasks/inject-data-application/define-command-argument-container/#running-a-command-in-a-shell
       */
      args?: Array<string>;
      /**
       * Entrypoint array. Not executed within a shell. The image's ENTRYPOINT is used if this is not provided. Variable references $(VAR_NAME) are expanded using the container's environment. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless of whether the variable exists or not. Cannot be updated. More info: https://kubernetes.io/docs/tasks/inject-data-application/define-command-argument-container/#running-a-command-in-a-shell
       */
      command?: Array<string>;
      /**
       * List of environment variables to set in the container. Cannot be updated.
       */
      env?: Array<{
        /**
         * Name of the environment variable. Must be a C_IDENTIFIER.
         */
        name: string;
        /**
         * Variable references $(VAR_NAME) are expanded using the previously defined environment variables in the container and any service environment variables. If a variable cannot be resolved, the reference in the input string will be unchanged. Double $$ are reduced to a single $, which allows for escaping the $(VAR_NAME) syntax: i.e. "$$(VAR_NAME)" will produce the string literal "$(VAR_NAME)". Escaped references will never be expanded, regardless of whether the variable exists or not. Defaults to "".
         */
        value?: string;
        /**
         * Source for the environment variable's value. Cannot be used if value is not empty.
         */
        valueFrom?: {
          /**
           * Selects a key of a ConfigMap.
           */
          configMapKeyRef?: {
            /**
             * The key to select.
             */
            key: string;
            /**
             * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
             */
            name?: string;
            /**
             * Specify whether the ConfigMap or its key must be defined
             */
            optional?: boolean;
          };
          /**
           * Selects a field of the pod: supports metadata.name, metadata.namespace, `metadata.labels['<KEY>']`, `metadata.annotations['<KEY>']`, spec.nodeName, spec.serviceAccountName, status.hostIP, status.podIP, status.podIPs.
           */
          fieldRef?: {
            /**
             * Version of the schema the FieldPath is written in terms of, defaults to "v1".
             */
            apiVersion?: string;
            /**
             * Path of the field to select in the specified API version.
             */
            fieldPath: string;
          };
          /**
           * Selects a resource of the container: only resources limits and requests (limits.cpu, limits.memory, limits.ephemeral-storage, requests.cpu, requests.memory and requests.ephemeral-storage) are currently supported.
           */
          resourceFieldRef?: {
            /**
             * Container name: required for volumes, optional for env vars
             */
            containerName?: string;
            /**
             * Specifies the output format of the exposed resources, defaults to "1"
             */
            divisor?: number | string;
            /**
             * Required: resource to select
             */
            resource: string;
          };
          /**
           * Selects a key of a secret in the pod's namespace
           */
          secretKeyRef?: {
            /**
             * The key of the secret to select from.  Must be a valid secret key.
             */
            key: string;
            /**
             * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
             */
            name?: string;
            /**
             * Specify whether the Secret or its key must be defined
             */
            optional?: boolean;
          };
        };
      }>;
      /**
       * List of sources to populate environment variables in the container. The keys defined within a source must be a C_IDENTIFIER. All invalid keys will be reported as an event when the container is starting. When a key exists in multiple sources, the value associated with the last source will take precedence. Values defined by an Env with a duplicate key will take precedence. Cannot be updated.
       */
      envFrom?: Array<{
        /**
         * The ConfigMap to select from
         */
        configMapRef?: {
          /**
           * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
           */
          name?: string;
          /**
           * Specify whether the ConfigMap must be defined
           */
          optional?: boolean;
        };
        /**
         * An optional identifier to prepend to each key in the ConfigMap. Must be a C_IDENTIFIER.
         */
        prefix?: string;
        /**
         * The Secret to select from
         */
        secretRef?: {
          /**
           * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
           */
          name?: string;
          /**
           * Specify whether the Secret must be defined
           */
          optional?: boolean;
        };
      }>;
      /**
       * Image reference name to run for this Step. More info: https://kubernetes.io/docs/concepts/containers/images
       */
      image?: string;
      /**
       * Image pull policy. One of Always, Never, IfNotPresent. Defaults to Always if :latest tag is specified, or IfNotPresent otherwise. Cannot be updated. More info: https://kubernetes.io/docs/concepts/containers/images#updating-images
       */
      imagePullPolicy?: string;
      /**
       * Deprecated. This field will be removed in a future release. Actions that the management system should take in response to container lifecycle events. Cannot be updated.
       */
      lifecycle?: {
        /**
         * PostStart is called immediately after a container is created. If the handler fails, the container is terminated and restarted according to its restart policy. Other management of the container blocks until the hook completes. More info: https://kubernetes.io/docs/concepts/containers/container-lifecycle-hooks/#container-hooks
         */
        postStart?: {
          /**
           * Exec specifies the action to take.
           */
          exec?: {
            /**
             * Command is the command line to execute inside the container, the working directory for the command  is root ('/') in the container's filesystem. The command is simply exec'd, it is not run inside a shell, so traditional shell instructions ('|', etc) won't work. To use a shell, you need to explicitly call out to that shell. Exit status of 0 is treated as live/healthy and non-zero is unhealthy.
             */
            command?: Array<string>;
          };
          /**
           * HTTPGet specifies the http request to perform.
           */
          httpGet?: {
            /**
             * Host name to connect to, defaults to the pod IP. You probably want to set "Host" in httpHeaders instead.
             */
            host?: string;
            /**
             * Custom headers to set in the request. HTTP allows repeated headers.
             */
            httpHeaders?: Array<{
              /**
               * The header field name
               */
              name: string;
              /**
               * The header field value
               */
              value: string;
            }>;
            /**
             * Path to access on the HTTP server.
             */
            path?: string;
            /**
             * Name or number of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
             */
            port: number | string;
            /**
             * Scheme to use for connecting to the host. Defaults to HTTP.
             */
            scheme?: string;
          };
          /**
           * Deprecated. TCPSocket is NOT supported as a LifecycleHandler and kept for the backward compatibility. There are no validation of this field and lifecycle hooks will fail in runtime when tcp handler is specified.
           */
          tcpSocket?: {
            /**
             * Optional: Host name to connect to, defaults to the pod IP.
             */
            host?: string;
            /**
             * Number or name of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
             */
            port: number | string;
          };
        };
        /**
         * PreStop is called immediately before a container is terminated due to an API request or management event such as liveness/startup probe failure, preemption, resource contention, etc. The handler is not called if the container crashes or exits. The Pod's termination grace period countdown begins before the PreStop hook is executed. Regardless of the outcome of the handler, the container will eventually terminate within the Pod's termination grace period (unless delayed by finalizers). Other management of the container blocks until the hook completes or until the termination grace period is reached. More info: https://kubernetes.io/docs/concepts/containers/container-lifecycle-hooks/#container-hooks
         */
        preStop?: {
          /**
           * Exec specifies the action to take.
           */
          exec?: {
            /**
             * Command is the command line to execute inside the container, the working directory for the command  is root ('/') in the container's filesystem. The command is simply exec'd, it is not run inside a shell, so traditional shell instructions ('|', etc) won't work. To use a shell, you need to explicitly call out to that shell. Exit status of 0 is treated as live/healthy and non-zero is unhealthy.
             */
            command?: Array<string>;
          };
          /**
           * HTTPGet specifies the http request to perform.
           */
          httpGet?: {
            /**
             * Host name to connect to, defaults to the pod IP. You probably want to set "Host" in httpHeaders instead.
             */
            host?: string;
            /**
             * Custom headers to set in the request. HTTP allows repeated headers.
             */
            httpHeaders?: Array<{
              /**
               * The header field name
               */
              name: string;
              /**
               * The header field value
               */
              value: string;
            }>;
            /**
             * Path to access on the HTTP server.
             */
            path?: string;
            /**
             * Name or number of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
             */
            port: number | string;
            /**
             * Scheme to use for connecting to the host. Defaults to HTTP.
             */
            scheme?: string;
          };
          /**
           * Deprecated. TCPSocket is NOT supported as a LifecycleHandler and kept for the backward compatibility. There are no validation of this field and lifecycle hooks will fail in runtime when tcp handler is specified.
           */
          tcpSocket?: {
            /**
             * Optional: Host name to connect to, defaults to the pod IP.
             */
            host?: string;
            /**
             * Number or name of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
             */
            port: number | string;
          };
        };
      };
      /**
       * Deprecated. This field will be removed in a future release. Periodic probe of container liveness. Step will be restarted if the probe fails. Cannot be updated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
       */
      livenessProbe?: {
        /**
         * Exec specifies the action to take.
         */
        exec?: {
          /**
           * Command is the command line to execute inside the container, the working directory for the command  is root ('/') in the container's filesystem. The command is simply exec'd, it is not run inside a shell, so traditional shell instructions ('|', etc) won't work. To use a shell, you need to explicitly call out to that shell. Exit status of 0 is treated as live/healthy and non-zero is unhealthy.
           */
          command?: Array<string>;
        };
        /**
         * Minimum consecutive failures for the probe to be considered failed after having succeeded. Defaults to 3. Minimum value is 1.
         */
        failureThreshold?: number;
        /**
         * GRPC specifies an action involving a GRPC port. This is a beta field and requires enabling GRPCContainerProbe feature gate.
         */
        grpc?: {
          /**
           * Port number of the gRPC service. Number must be in the range 1 to 65535.
           */
          port: number;
          /**
           * Service is the name of the service to place in the gRPC HealthCheckRequest (see https://github.com/grpc/grpc/blob/master/doc/health-checking.md).
           *  If this is not specified, the default behavior is defined by gRPC.
           */
          service?: string;
        };
        /**
         * HTTPGet specifies the http request to perform.
         */
        httpGet?: {
          /**
           * Host name to connect to, defaults to the pod IP. You probably want to set "Host" in httpHeaders instead.
           */
          host?: string;
          /**
           * Custom headers to set in the request. HTTP allows repeated headers.
           */
          httpHeaders?: Array<{
            /**
             * The header field name
             */
            name: string;
            /**
             * The header field value
             */
            value: string;
          }>;
          /**
           * Path to access on the HTTP server.
           */
          path?: string;
          /**
           * Name or number of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
           */
          port: number | string;
          /**
           * Scheme to use for connecting to the host. Defaults to HTTP.
           */
          scheme?: string;
        };
        /**
         * Number of seconds after the container has started before liveness probes are initiated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
         */
        initialDelaySeconds?: number;
        /**
         * How often (in seconds) to perform the probe. Default to 10 seconds. Minimum value is 1.
         */
        periodSeconds?: number;
        /**
         * Minimum consecutive successes for the probe to be considered successful after having failed. Defaults to 1. Must be 1 for liveness and startup. Minimum value is 1.
         */
        successThreshold?: number;
        /**
         * TCPSocket specifies an action involving a TCP port.
         */
        tcpSocket?: {
          /**
           * Optional: Host name to connect to, defaults to the pod IP.
           */
          host?: string;
          /**
           * Number or name of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
           */
          port: number | string;
        };
        /**
         * Optional duration in seconds the pod needs to terminate gracefully upon probe failure. The grace period is the duration in seconds after the processes running in the pod are sent a termination signal and the time when the processes are forcibly halted with a kill signal. Set this value longer than the expected cleanup time for your process. If this value is nil, the pod's terminationGracePeriodSeconds will be used. Otherwise, this value overrides the value provided by the pod spec. Value must be non-negative integer. The value zero indicates stop immediately via the kill signal (no opportunity to shut down). This is a beta field and requires enabling ProbeTerminationGracePeriod feature gate. Minimum value is 1. spec.terminationGracePeriodSeconds is used if unset.
         */
        terminationGracePeriodSeconds?: number;
        /**
         * Number of seconds after which the probe times out. Defaults to 1 second. Minimum value is 1. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
         */
        timeoutSeconds?: number;
      };
      /**
       * Name of the Step specified as a DNS_LABEL. Each Step in a Task must have a unique name.
       */
      name: string;
      /**
       * OnError defines the exiting behavior of a container on error can be set to [ continue | stopAndFail ]
       */
      onError?: string;
      /**
       * Deprecated. This field will be removed in a future release. List of ports to expose from the Step's container. Exposing a port here gives the system additional information about the network connections a container uses, but is primarily informational. Not specifying a port here DOES NOT prevent that port from being exposed. Any port which is listening on the default "0.0.0.0" address inside a container will be accessible from the network. Cannot be updated.
       */
      ports?: Array<{
        /**
         * Number of port to expose on the pod's IP address. This must be a valid port number, 0 < x < 65536.
         */
        containerPort: number;
        /**
         * What host IP to bind the external port to.
         */
        hostIP?: string;
        /**
         * Number of port to expose on the host. If specified, this must be a valid port number, 0 < x < 65536. If HostNetwork is specified, this must match ContainerPort. Most containers do not need this.
         */
        hostPort?: number;
        /**
         * If specified, this must be an IANA_SVC_NAME and unique within the pod. Each named port in a pod must have a unique name. Name for the port that can be referred to by services.
         */
        name?: string;
        /**
         * Protocol for port. Must be UDP, TCP, or SCTP. Defaults to "TCP".
         */
        protocol?: string;
      }>;
      /**
       * Deprecated. This field will be removed in a future release. Periodic probe of container service readiness. Step will be removed from service endpoints if the probe fails. Cannot be updated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
       */
      readinessProbe?: {
        /**
         * Exec specifies the action to take.
         */
        exec?: {
          /**
           * Command is the command line to execute inside the container, the working directory for the command  is root ('/') in the container's filesystem. The command is simply exec'd, it is not run inside a shell, so traditional shell instructions ('|', etc) won't work. To use a shell, you need to explicitly call out to that shell. Exit status of 0 is treated as live/healthy and non-zero is unhealthy.
           */
          command?: Array<string>;
        };
        /**
         * Minimum consecutive failures for the probe to be considered failed after having succeeded. Defaults to 3. Minimum value is 1.
         */
        failureThreshold?: number;
        /**
         * GRPC specifies an action involving a GRPC port. This is a beta field and requires enabling GRPCContainerProbe feature gate.
         */
        grpc?: {
          /**
           * Port number of the gRPC service. Number must be in the range 1 to 65535.
           */
          port: number;
          /**
           * Service is the name of the service to place in the gRPC HealthCheckRequest (see https://github.com/grpc/grpc/blob/master/doc/health-checking.md).
           *  If this is not specified, the default behavior is defined by gRPC.
           */
          service?: string;
        };
        /**
         * HTTPGet specifies the http request to perform.
         */
        httpGet?: {
          /**
           * Host name to connect to, defaults to the pod IP. You probably want to set "Host" in httpHeaders instead.
           */
          host?: string;
          /**
           * Custom headers to set in the request. HTTP allows repeated headers.
           */
          httpHeaders?: Array<{
            /**
             * The header field name
             */
            name: string;
            /**
             * The header field value
             */
            value: string;
          }>;
          /**
           * Path to access on the HTTP server.
           */
          path?: string;
          /**
           * Name or number of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
           */
          port: number | string;
          /**
           * Scheme to use for connecting to the host. Defaults to HTTP.
           */
          scheme?: string;
        };
        /**
         * Number of seconds after the container has started before liveness probes are initiated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
         */
        initialDelaySeconds?: number;
        /**
         * How often (in seconds) to perform the probe. Default to 10 seconds. Minimum value is 1.
         */
        periodSeconds?: number;
        /**
         * Minimum consecutive successes for the probe to be considered successful after having failed. Defaults to 1. Must be 1 for liveness and startup. Minimum value is 1.
         */
        successThreshold?: number;
        /**
         * TCPSocket specifies an action involving a TCP port.
         */
        tcpSocket?: {
          /**
           * Optional: Host name to connect to, defaults to the pod IP.
           */
          host?: string;
          /**
           * Number or name of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
           */
          port: number | string;
        };
        /**
         * Optional duration in seconds the pod needs to terminate gracefully upon probe failure. The grace period is the duration in seconds after the processes running in the pod are sent a termination signal and the time when the processes are forcibly halted with a kill signal. Set this value longer than the expected cleanup time for your process. If this value is nil, the pod's terminationGracePeriodSeconds will be used. Otherwise, this value overrides the value provided by the pod spec. Value must be non-negative integer. The value zero indicates stop immediately via the kill signal (no opportunity to shut down). This is a beta field and requires enabling ProbeTerminationGracePeriod feature gate. Minimum value is 1. spec.terminationGracePeriodSeconds is used if unset.
         */
        terminationGracePeriodSeconds?: number;
        /**
         * Number of seconds after which the probe times out. Defaults to 1 second. Minimum value is 1. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
         */
        timeoutSeconds?: number;
      };
      /**
       * Compute Resources required by this Step. Cannot be updated. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
       */
      resources?: {
        /**
         * Limits describes the maximum amount of compute resources allowed. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
         */
        limits?: {
          [key: string]: number | string;
        };
        /**
         * Requests describes the minimum amount of compute resources required. If Requests is omitted for a container, it defaults to Limits if that is explicitly specified, otherwise to an implementation-defined value. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
         */
        requests?: {
          [key: string]: number | string;
        };
      };
      /**
       * Script is the contents of an executable file to execute.
       *  If Script is not empty, the Step cannot have an Command and the Args will be passed to the Script.
       */
      script?: string;
      /**
       * SecurityContext defines the security options the Step should be run with. If set, the fields of SecurityContext override the equivalent fields of PodSecurityContext. More info: https://kubernetes.io/docs/tasks/configure-pod-container/security-context/
       */
      securityContext?: {
        /**
         * AllowPrivilegeEscalation controls whether a process can gain more privileges than its parent process. This bool directly controls if the no_new_privs flag will be set on the container process. AllowPrivilegeEscalation is true always when the container is: 1) run as Privileged 2) has CAP_SYS_ADMIN Note that this field cannot be set when spec.os.name is windows.
         */
        allowPrivilegeEscalation?: boolean;
        /**
         * The capabilities to add/drop when running containers. Defaults to the default set of capabilities granted by the container runtime. Note that this field cannot be set when spec.os.name is windows.
         */
        capabilities?: {
          /**
           * Added capabilities
           */
          add?: Array<string>;
          /**
           * Removed capabilities
           */
          drop?: Array<string>;
        };
        /**
         * Run container in privileged mode. Processes in privileged containers are essentially equivalent to root on the host. Defaults to false. Note that this field cannot be set when spec.os.name is windows.
         */
        privileged?: boolean;
        /**
         * procMount denotes the type of proc mount to use for the containers. The default is DefaultProcMount which uses the container runtime defaults for readonly paths and masked paths. This requires the ProcMountType feature flag to be enabled. Note that this field cannot be set when spec.os.name is windows.
         */
        procMount?: string;
        /**
         * Whether this container has a read-only root filesystem. Default is false. Note that this field cannot be set when spec.os.name is windows.
         */
        readOnlyRootFilesystem?: boolean;
        /**
         * The GID to run the entrypoint of the container process. Uses runtime default if unset. May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. Note that this field cannot be set when spec.os.name is windows.
         */
        runAsGroup?: number;
        /**
         * Indicates that the container must run as a non-root user. If true, the Kubelet will validate the image at runtime to ensure that it does not run as UID 0 (root) and fail to start the container if it does. If unset or false, no such validation will be performed. May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence.
         */
        runAsNonRoot?: boolean;
        /**
         * The UID to run the entrypoint of the container process. Defaults to user specified in image metadata if unspecified. May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. Note that this field cannot be set when spec.os.name is windows.
         */
        runAsUser?: number;
        /**
         * The SELinux context to be applied to the container. If unspecified, the container runtime will allocate a random SELinux context for each container.  May also be set in PodSecurityContext.  If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. Note that this field cannot be set when spec.os.name is windows.
         */
        seLinuxOptions?: {
          /**
           * Level is SELinux level label that applies to the container.
           */
          level?: string;
          /**
           * Role is a SELinux role label that applies to the container.
           */
          role?: string;
          /**
           * Type is a SELinux type label that applies to the container.
           */
          type?: string;
          /**
           * User is a SELinux user label that applies to the container.
           */
          user?: string;
        };
        /**
         * The seccomp options to use by this container. If seccomp options are provided at both the pod & container level, the container options override the pod options. Note that this field cannot be set when spec.os.name is windows.
         */
        seccompProfile?: {
          /**
           * localhostProfile indicates a profile defined in a file on the node should be used. The profile must be preconfigured on the node to work. Must be a descending path, relative to the kubelet's configured seccomp profile location. Must only be set if type is "Localhost".
           */
          localhostProfile?: string;
          /**
           * type indicates which kind of seccomp profile will be applied. Valid options are:
           *  Localhost - a profile defined in a file on the node should be used. RuntimeDefault - the container runtime default profile should be used. Unconfined - no profile should be applied.
           */
          type: string;
        };
        /**
         * The Windows specific settings applied to all containers. If unspecified, the options from the PodSecurityContext will be used. If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence. Note that this field cannot be set when spec.os.name is linux.
         */
        windowsOptions?: {
          /**
           * GMSACredentialSpec is where the GMSA admission webhook (https://github.com/kubernetes-sigs/windows-gmsa) inlines the contents of the GMSA credential spec named by the GMSACredentialSpecName field.
           */
          gmsaCredentialSpec?: string;
          /**
           * GMSACredentialSpecName is the name of the GMSA credential spec to use.
           */
          gmsaCredentialSpecName?: string;
          /**
           * HostProcess determines if a container should be run as a 'Host Process' container. This field is alpha-level and will only be honored by components that enable the WindowsHostProcessContainers feature flag. Setting this field without the feature flag will result in errors when validating the Pod. All of a Pod's containers must have the same effective HostProcess value (it is not allowed to have a mix of HostProcess containers and non-HostProcess containers).  In addition, if HostProcess is true then HostNetwork must also be set to true.
           */
          hostProcess?: boolean;
          /**
           * The UserName in Windows to run the entrypoint of the container process. Defaults to the user specified in image metadata if unspecified. May also be set in PodSecurityContext. If set in both SecurityContext and PodSecurityContext, the value specified in SecurityContext takes precedence.
           */
          runAsUserName?: string;
        };
      };
      /**
       * Deprecated. This field will be removed in a future release. DeprecatedStartupProbe indicates that the Pod this Step runs in has successfully initialized. If specified, no other probes are executed until this completes successfully. If this probe fails, the Pod will be restarted, just as if the livenessProbe failed. This can be used to provide different probe parameters at the beginning of a Pod's lifecycle, when it might take a long time to load data or warm a cache, than during steady-state operation. This cannot be updated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
       */
      startupProbe?: {
        /**
         * Exec specifies the action to take.
         */
        exec?: {
          /**
           * Command is the command line to execute inside the container, the working directory for the command  is root ('/') in the container's filesystem. The command is simply exec'd, it is not run inside a shell, so traditional shell instructions ('|', etc) won't work. To use a shell, you need to explicitly call out to that shell. Exit status of 0 is treated as live/healthy and non-zero is unhealthy.
           */
          command?: Array<string>;
        };
        /**
         * Minimum consecutive failures for the probe to be considered failed after having succeeded. Defaults to 3. Minimum value is 1.
         */
        failureThreshold?: number;
        /**
         * GRPC specifies an action involving a GRPC port. This is a beta field and requires enabling GRPCContainerProbe feature gate.
         */
        grpc?: {
          /**
           * Port number of the gRPC service. Number must be in the range 1 to 65535.
           */
          port: number;
          /**
           * Service is the name of the service to place in the gRPC HealthCheckRequest (see https://github.com/grpc/grpc/blob/master/doc/health-checking.md).
           *  If this is not specified, the default behavior is defined by gRPC.
           */
          service?: string;
        };
        /**
         * HTTPGet specifies the http request to perform.
         */
        httpGet?: {
          /**
           * Host name to connect to, defaults to the pod IP. You probably want to set "Host" in httpHeaders instead.
           */
          host?: string;
          /**
           * Custom headers to set in the request. HTTP allows repeated headers.
           */
          httpHeaders?: Array<{
            /**
             * The header field name
             */
            name: string;
            /**
             * The header field value
             */
            value: string;
          }>;
          /**
           * Path to access on the HTTP server.
           */
          path?: string;
          /**
           * Name or number of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
           */
          port: number | string;
          /**
           * Scheme to use for connecting to the host. Defaults to HTTP.
           */
          scheme?: string;
        };
        /**
         * Number of seconds after the container has started before liveness probes are initiated. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
         */
        initialDelaySeconds?: number;
        /**
         * How often (in seconds) to perform the probe. Default to 10 seconds. Minimum value is 1.
         */
        periodSeconds?: number;
        /**
         * Minimum consecutive successes for the probe to be considered successful after having failed. Defaults to 1. Must be 1 for liveness and startup. Minimum value is 1.
         */
        successThreshold?: number;
        /**
         * TCPSocket specifies an action involving a TCP port.
         */
        tcpSocket?: {
          /**
           * Optional: Host name to connect to, defaults to the pod IP.
           */
          host?: string;
          /**
           * Number or name of the port to access on the container. Number must be in the range 1 to 65535. Name must be an IANA_SVC_NAME.
           */
          port: number | string;
        };
        /**
         * Optional duration in seconds the pod needs to terminate gracefully upon probe failure. The grace period is the duration in seconds after the processes running in the pod are sent a termination signal and the time when the processes are forcibly halted with a kill signal. Set this value longer than the expected cleanup time for your process. If this value is nil, the pod's terminationGracePeriodSeconds will be used. Otherwise, this value overrides the value provided by the pod spec. Value must be non-negative integer. The value zero indicates stop immediately via the kill signal (no opportunity to shut down). This is a beta field and requires enabling ProbeTerminationGracePeriod feature gate. Minimum value is 1. spec.terminationGracePeriodSeconds is used if unset.
         */
        terminationGracePeriodSeconds?: number;
        /**
         * Number of seconds after which the probe times out. Defaults to 1 second. Minimum value is 1. More info: https://kubernetes.io/docs/concepts/workloads/pods/pod-lifecycle#container-probes
         */
        timeoutSeconds?: number;
      };
      /**
       * Stores configuration for the stderr stream of the step.
       */
      stderrConfig?: {
        /**
         * Path to duplicate stdout stream to on container's local filesystem.
         */
        path?: string;
      };
      /**
       * Deprecated. This field will be removed in a future release. Whether this container should allocate a buffer for stdin in the container runtime. If this is not set, reads from stdin in the container will always result in EOF. Default is false.
       */
      stdin?: boolean;
      /**
       * Deprecated. This field will be removed in a future release. Whether the container runtime should close the stdin channel after it has been opened by a single attach. When stdin is true the stdin stream will remain open across multiple attach sessions. If stdinOnce is set to true, stdin is opened on container start, is empty until the first client attaches to stdin, and then remains open and accepts data until the client disconnects, at which time stdin is closed and remains closed until the container is restarted. If this flag is false, a container processes that reads from stdin will never receive an EOF. Default is false
       */
      stdinOnce?: boolean;
      /**
       * Stores configuration for the stdout stream of the step.
       */
      stdoutConfig?: {
        /**
         * Path to duplicate stdout stream to on container's local filesystem.
         */
        path?: string;
      };
      /**
       * Deprecated. This field will be removed in a future release and can't be meaningfully used.
       */
      terminationMessagePath?: string;
      /**
       * Deprecated. This field will be removed in a future release and can't be meaningfully used.
       */
      terminationMessagePolicy?: string;
      /**
       * Timeout is the time after which the step times out. Defaults to never. Refer to Go's ParseDuration documentation for expected format: https://golang.org/pkg/time/#ParseDuration
       */
      timeout?: string;
      /**
       * Deprecated. This field will be removed in a future release. Whether this container should allocate a DeprecatedTTY for itself, also requires 'stdin' to be true. Default is false.
       */
      tty?: boolean;
      /**
       * volumeDevices is the list of block devices to be used by the Step.
       */
      volumeDevices?: Array<{
        /**
         * devicePath is the path inside of the container that the device will be mapped to.
         */
        devicePath: string;
        /**
         * name must match the name of a persistentVolumeClaim in the pod
         */
        name: string;
      }>;
      /**
       * Volumes to mount into the Step's filesystem. Cannot be updated.
       */
      volumeMounts?: Array<{
        /**
         * Path within the container at which the volume should be mounted.  Must not contain ':'.
         */
        mountPath: string;
        /**
         * mountPropagation determines how mounts are propagated from the host to container and the other way around. When not set, MountPropagationNone is used. This field is beta in 1.10.
         */
        mountPropagation?: string;
        /**
         * This must match the Name of a Volume.
         */
        name: string;
        /**
         * Mounted read-only if true, read-write otherwise (false or unspecified). Defaults to false.
         */
        readOnly?: boolean;
        /**
         * Path within the volume from which the container's volume should be mounted. Defaults to "" (volume's root).
         */
        subPath?: string;
        /**
         * Expanded path within the volume from which the container's volume should be mounted. Behaves similarly to SubPath but environment variable references $(VAR_NAME) are expanded using the container's environment. Defaults to "" (volume's root). SubPathExpr and SubPath are mutually exclusive.
         */
        subPathExpr?: string;
      }>;
      /**
       * Step's working directory. If not specified, the container runtime's default will be used, which might be configured in the container image. Cannot be updated.
       */
      workingDir?: string;
      /**
       * This is an alpha field. You must set the "enable-api-fields" feature flag to "alpha" for this field to be supported.
       *  Workspaces is a list of workspaces from the Task that this Step wants exclusive access to. Adding a workspace to this list means that any other Step or Sidecar that does not also request this Workspace will not have access to it.
       */
      workspaces?: Array<{
        /**
         * MountPath is the path that the workspace should be mounted to inside the Step or Sidecar, overriding any MountPath specified in the Task's WorkspaceDeclaration.
         */
        mountPath: string;
        /**
         * Name is the name of the workspace this Step or Sidecar wants access to.
         */
        name: string;
      }>;
    }>;
    workspace?: {
      bindings?: Array<{
        /**
         * ConfigMap represents a configMap that should populate this workspace.
         */
        configMap?: {
          /**
           * defaultMode is optional: mode bits used to set permissions on created files by default. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. Defaults to 0644. Directories within the path are not affected by this setting. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set.
           */
          defaultMode?: number;
          /**
           * items if unspecified, each key-value pair in the Data field of the referenced ConfigMap will be projected into the volume as a file whose name is the key and content is the value. If specified, the listed keys will be projected into the specified paths, and unlisted keys will not be present. If a key is specified which is not present in the ConfigMap, the volume setup will error unless it is marked optional. Paths must be relative and may not contain the '..' path or start with '..'.
           */
          items?: Array<{
            /**
             * key is the key to project.
             */
            key: string;
            /**
             * mode is Optional: mode bits used to set permissions on this file. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. If not specified, the volume defaultMode will be used. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set.
             */
            mode?: number;
            /**
             * path is the relative path of the file to map the key to. May not be an absolute path. May not contain the path element '..'. May not start with the string '..'.
             */
            path: string;
          }>;
          /**
           * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
           */
          name?: string;
          /**
           * optional specify whether the ConfigMap or its keys must be defined
           */
          optional?: boolean;
        };
        /**
         * Represents an empty directory for a pod. Empty directory volumes support ownership management and SELinux relabeling.
         */
        emptyDir?: {
          /**
           * medium represents what type of storage medium should back this directory. The default is "" which means to use the node's default medium. Must be an empty string (default) or Memory. More info: https://kubernetes.io/docs/concepts/storage/volumes#emptydir
           */
          medium?: string;
          /**
           * sizeLimit is the total amount of local storage required for this EmptyDir volume. The size limit is also applicable for memory medium. The maximum usage on memory medium EmptyDir would be the minimum value between the SizeLimit specified here and the sum of memory limits of all containers in a pod. The default is nil which means that the limit is undefined. More info: http://kubernetes.io/docs/user-guide/volumes#emptydir
           */
          sizeLimit?: number | string;
        };
        /**
         * Name is the name of the workspace populated by the volume.
         */
        name: string;
        /**
         * Secret represents a secret that should populate this workspace.
         */
        secret?: {
          /**
           * defaultMode is Optional: mode bits used to set permissions on created files by default. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. Defaults to 0644. Directories within the path are not affected by this setting. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set.
           */
          defaultMode?: number;
          /**
           * items If unspecified, each key-value pair in the Data field of the referenced Secret will be projected into the volume as a file whose name is the key and content is the value. If specified, the listed keys will be projected into the specified paths, and unlisted keys will not be present. If a key is specified which is not present in the Secret, the volume setup will error unless it is marked optional. Paths must be relative and may not contain the '..' path or start with '..'.
           */
          items?: Array<{
            /**
             * key is the key to project.
             */
            key: string;
            /**
             * mode is Optional: mode bits used to set permissions on this file. Must be an octal value between 0000 and 0777 or a decimal value between 0 and 511. YAML accepts both octal and decimal values, JSON requires decimal values for mode bits. If not specified, the volume defaultMode will be used. This might be in conflict with other options that affect the file mode, like fsGroup, and the result can be other mode bits set.
             */
            mode?: number;
            /**
             * path is the relative path of the file to map the key to. May not be an absolute path. May not contain the path element '..'. May not start with the string '..'.
             */
            path: string;
          }>;
          /**
           * optional field specify whether the Secret or its keys must be defined
           */
          optional?: boolean;
          /**
           * secretName is the name of the secret in the pod's namespace to use. More info: https://kubernetes.io/docs/concepts/storage/volumes#secret
           */
          secretName?: string;
        };
      }>;
      size?: string;
    };
  };
  /**
   * ImageVulnerabilityScanStatus defines the observed state of ImageVulnerabilityScan.
   */
  status?: {
    /**
     * Conditions the latest available observations of a resource's current state.
     */
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    /**
     * ObservedGeneration is the 'Generation' of the resource that was last processed by the controller.
     */
    observedGeneration?: number;
    pipelineRun?: string;
    scanResult?: string;
    scannedImage?: string;
  };
}

/**
 * ImageVulnerabilityScan is the Schema for the imagevulnerabilityscans API.
 */
export class ImageVulnerabilityScan
  extends Model<IImageVulnerabilityScan>
  implements IImageVulnerabilityScan
{
  'apiVersion': IImageVulnerabilityScan['apiVersion'];
  'kind': IImageVulnerabilityScan['kind'];
  'metadata'?: IImageVulnerabilityScan['metadata'];
  'spec'?: IImageVulnerabilityScan['spec'];
  'status'?: IImageVulnerabilityScan['status'];

  static apiVersion: IImageVulnerabilityScan['apiVersion'] =
    'app-scanning.apps.tanzu.vmware.com/v1alpha1';
  static kind: IImageVulnerabilityScan['kind'] = 'ImageVulnerabilityScan';
  static is = createTypeMetaGuard<IImageVulnerabilityScan>(
    ImageVulnerabilityScan,
  );

  constructor(data?: ModelData<IImageVulnerabilityScan>) {
    super({
      apiVersion: ImageVulnerabilityScan.apiVersion,
      kind: ImageVulnerabilityScan.kind,
      ...data,
    } as IImageVulnerabilityScan);
  }
}

setSchema(ImageVulnerabilityScan, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
