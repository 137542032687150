import { PodAutoscaler } from '@kubernetes-models/knative/autoscaling.internal.knative.dev/v1alpha1';
import { ServerlessService as Model } from '@kubernetes-models/knative/networking.internal.knative.dev/v1alpha1';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { IPodAutoscaler } from '@kubernetes-models/knative/autoscaling.internal.knative.dev/v1alpha1';
import type { IServerlessService } from '@kubernetes-models/knative/networking.internal.knative.dev/v1alpha1';

export class ServerlessService extends AbstractModel<IServerlessService> {
  public static readonly humanType = 'Knative Serverless Service';

  public static readonly typeMeta = Model;

  public classRef = ServerlessService;

  public constructor(
    resource: IServerlessService,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get owners(): MappedResource<IPodAutoscaler>[] {
    return compact([this.podAutoscaler]);
  }

  public get podAutoscaler(): MappedResource<IPodAutoscaler> | undefined {
    return this.repository.ownerOfType<IPodAutoscaler>(this, PodAutoscaler);
  }
}
