import React from 'react';
import {
  RepositoryProviderProps,
  useRepositoryContext,
} from '../hook/useRepositoryContext';
import { Context } from './Context';
import type { ReactElement } from 'react';

export const ContextProvider = ({
  authProvidersApi,
  autoRefreshMs,
  children,
  entity,
  kubernetesApi,
}: RepositoryProviderProps): ReactElement => {
  const context = useRepositoryContext({
    authProvidersApi,
    autoRefreshMs,
    entity,
    kubernetesApi,
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
