import moment from 'moment';
import { IRepository, Repository, RepositoryFactory } from '../repository';
import { useAutoRefresh } from './useAutoRefresh';
import { IContext } from '../context';
import type {
  KubernetesApi,
  KubernetesAuthProvidersApi,
} from '@backstage/plugin-kubernetes';
import type { Entity } from '@backstage/catalog-model';
import type { ReactElement } from 'react';

export interface UseRepositoryContextProps {
  authProvidersApi: KubernetesAuthProvidersApi;
  autoRefreshMs?: number | false;
  entity: Entity;
  kubernetesApi: KubernetesApi;
}

export type RepositoryProviderProps = UseRepositoryContextProps & {
  children: ReactElement;
};

const placeholderRepository = new Repository({ items: [] });

export const useRepositoryContext = ({
  authProvidersApi,
  autoRefreshMs,
  entity,
  kubernetesApi,
}: UseRepositoryContextProps): IContext => {
  const factory = new RepositoryFactory(
    kubernetesApi,
    authProvidersApi,
    entity,
  );

  const { lastRefreshed, refresh, status, value } = useAutoRefresh<IRepository>(
    async () => factory.build(),
    autoRefreshMs,
  );

  return {
    status: status,
    refresh: refresh,
    repository: value ?? placeholderRepository,
    lastRefreshedTime: moment(lastRefreshed),
  };
};
