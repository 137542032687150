import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterTemplate';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterTemplate'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        params: {
          items: {
            properties: {
              default: {},
              name: {
                type: 'string',
              },
            },
            required: ['default', 'name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        template: {
          type: 'object',
          properties: {},
          nullable: true,
        },
        ytt: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterTemplate {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterTemplate';
  metadata: IObjectMeta;
  /**
   * Spec describes the template. More info: https://cartographer.sh/docs/latest/reference/template/#clustertemplate
   */
  spec: {
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner or Template does not specify this parameter, this value is used.
       */
      default: any;
      /**
       * Name of a parameter the template accepts from the Blueprint or Owner.
       */
      name: string;
    }>;
    /**
     * Template defines a resource template for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    template?: {};
    /**
     * Ytt defines a resource template written in `ytt` for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    ytt?: string;
  };
}

export class ClusterTemplate
  extends Model<IClusterTemplate>
  implements IClusterTemplate
{
  'apiVersion': IClusterTemplate['apiVersion'];
  'kind': IClusterTemplate['kind'];
  'metadata': IClusterTemplate['metadata'];
  'spec': IClusterTemplate['spec'];

  static apiVersion: IClusterTemplate['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IClusterTemplate['kind'] = 'ClusterTemplate';
  static is = createTypeMetaGuard<IClusterTemplate>(ClusterTemplate);

  constructor(data?: ModelData<IClusterTemplate>) {
    super({
      apiVersion: ClusterTemplate.apiVersion,
      kind: ClusterTemplate.kind,
      ...data,
    } as IClusterTemplate);
  }
}

setSchema(ClusterTemplate, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
