import { Revision } from '@kubernetes-models/knative/serving.knative.dev/v1';
import { Deployment as Model, ReplicaSet } from 'kubernetes-models/apps/v1';
import { flatten, isUndefined } from 'lodash';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import {
  ConditionCategory,
  conditionsFromResource,
  prioritizedConditions,
} from '../Condition';
import type { Pod } from './Pod';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList, Condition } from '../Condition';
import type { IRevision } from '@kubernetes-models/knative/serving.knative.dev/v1';
import type {
  IDeployment,
  IReplicaSet,
  IDeploymentStatus,
} from 'kubernetes-models/apps/v1';

const conditionsLabels = {
  Available: {
    True: 'Available',
    False: 'Unavailable',
    Unknown: 'Availability Unknown',
  },
  Progressing: {
    True: 'Progressing',
    False: 'Not Progressing',
    Unknown: 'Progressing Status Unknown',
  },
  ReplicaFailure: {
    True: '	ReplicaSet Failed',
    False: 'ReplicaSet Ready',
    Unknown: 'ReplicaSet Failure Unknown',
  },
};

export class Deployment extends AbstractModel<IDeployment> {
  public static readonly humanType = 'Deployment';

  public static readonly typeMeta = Model;

  public classRef = Deployment;

  public constructor(
    resource: IDeployment,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get owners(): MappedResource<IRevision>[] {
    return compact([this.revision]);
  }

  public get revision(): MappedResource<IRevision> | undefined {
    return this.repository.ownerOfType<IRevision>(this, Revision);
  }

  public get replicaSets(): MappedResource<IReplicaSet>[] {
    return this.repository.ownedOfType<IReplicaSet>(this, ReplicaSet);
  }

  public get conditions(): ConditionList {
    const { status } = this.resource;

    const conditions = flatten([
      conditionsFromResource(this.resource, conditionsLabels),
      this.getTotalReplicasCondition(status),
      this.getAvailableReplicasCondition(status),
      this.getReplicasReadyCondition(status),
      this.getUpdatedReplicasCondition(status),
      this.getUnavailableReplicasCondition(status),
    ]);

    return prioritizedConditions(conditions, 'Available');
  }

  public get desiredPodsQty(): number {
    return this.resource.spec?.replicas ?? 0;
  }

  public get pods(): Pod[] {
    return this.replicaSets.flatMap(rs => rs.pods);
  }

  private getTotalReplicasCondition(
    status: IDeploymentStatus | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (status?.replicas === 0 || isUndefined(status?.replicas)) {
        return ConditionCategory.error;
      }
      return ConditionCategory.healthy;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.replicas ?? 0}`,
      type: 'TotalReplicas',
      title: 'Total Replicas',
    };

    return condition;
  }

  private getAvailableReplicasCondition(
    status: IDeploymentStatus | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (status?.availableReplicas !== status?.replicas) {
        return ConditionCategory.error;
      }
      return ConditionCategory.healthy;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.availableReplicas ?? 0}`,
      type: 'AvailableReplicas',
      title: 'Available Replicas',
    };

    return condition;
  }

  private getReplicasReadyCondition(
    status: IDeploymentStatus | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (status?.readyReplicas !== status?.replicas) {
        return ConditionCategory.error;
      }
      return ConditionCategory.healthy;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.readyReplicas ?? 0}`,
      type: 'ReplicasReady',
      title: 'Replicas Ready',
    };

    return condition;
  }

  private getUpdatedReplicasCondition(
    status: IDeploymentStatus | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (status?.updatedReplicas !== status?.replicas) {
        return ConditionCategory.error;
      }
      return ConditionCategory.healthy;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.updatedReplicas ?? 0}`,
      type: 'UpdatedReplicas',
      title: 'Updated Replicas',
    };

    return condition;
  }

  private getUnavailableReplicasCondition(
    status: IDeploymentStatus | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (
        !isUndefined(status) &&
        !isUndefined(status.unavailableReplicas) &&
        status.unavailableReplicas > 0
      ) {
        return ConditionCategory.error;
      }
      return ConditionCategory.healthy;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.unavailableReplicas ?? 0}`,
      type: 'UnavailableReplicas',
      title: 'Unavailable Replicas',
    };

    return condition;
  }
}
