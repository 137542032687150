import { ScanPolicy as Model } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1';
import { AbstractModel } from '../AbstractModel';
import type { IScanPolicy } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1';
import type { IRepository } from '../../repository';
import type { IStatus } from '../BaseTypes';

export class ScanPolicy extends AbstractModel<IScanPolicy> {
  public static readonly humanType = 'Scanning ScanPolicy';

  public static readonly typeMeta = Model;

  public classRef = ScanPolicy;

  public constructor(
    resource: IScanPolicy,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get status(): IStatus | undefined {
    return this.resource.status;
  }
}
