/**
 * Inspired from backstage source code: https://github.com/backstage/backstage/blob/master/packages/core-components/src/layout/Sidebar/localStorage.ts
 */
enum LocalStorageKeys {
  SIDEBAR_STATE = 'sidebarState',
}

export enum SidebarState {
  Opened,
  Closed,
}

export const LocalStorage = {
  getSidebarState(): SidebarState {
    try {
      const value = window.localStorage.getItem(LocalStorageKeys.SIDEBAR_STATE);
      if (value === 'closed') {
        return SidebarState.Closed;
      }
    } catch {
      return SidebarState.Opened;
    }
    return SidebarState.Opened;
  },
  setSidebarState(state: SidebarState) {
    return window.localStorage.setItem(
      LocalStorageKeys.SIDEBAR_STATE,
      state === SidebarState.Opened ? 'opened' : 'closed',
    );
  },
};
