import { Deployment, ReplicaSet as Model } from 'kubernetes-models/apps/v1';
import { Pod } from 'kubernetes-models/v1';
import { isUndefined, compact } from 'lodash';
import { AbstractModel } from '../AbstractModel';
import { ConditionCategory } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { Condition, ConditionList } from '../Condition';
import type {
  IDeployment,
  IReplicaSet,
  IReplicaSetStatus,
} from 'kubernetes-models/apps/v1';
import type { IPod } from 'kubernetes-models/v1';

export class ReplicaSet extends AbstractModel<IReplicaSet> {
  public static readonly humanType = 'ReplicaSet';

  public static readonly typeMeta = Model;

  public classRef = ReplicaSet;

  public constructor(
    resource: IReplicaSet,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    const { status } = this.resource;

    const replicasReady = this.getReplicasReadyCondition(status);
    const availableReplicas = this.getAvailableReplicasCondition(status);
    const fullyLabeledReplicas = this.getFullyLabeledReplicasCondition(status);
    const replicas = this.getReplicasCondition(status);

    const otherConditions: Condition[] = compact([
      availableReplicas,
      fullyLabeledReplicas,
      replicas,
    ]);

    return {
      primary: replicasReady,
      other: otherConditions,
    };
  }

  public get owners(): MappedResource<IDeployment>[] {
    return compact([this.deployment]);
  }

  public get pods(): MappedResource<IPod>[] {
    return this.repository.ownedOfType<IPod>(this, Pod);
  }

  public get deployment(): MappedResource<IDeployment> | undefined {
    return this.repository.ownerOfType<IDeployment>(this, Deployment);
  }

  private getReplicasReadyCondition(
    status: IReplicaSetStatus | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (status?.readyReplicas === status?.replicas && status?.replicas !== 0)
        return ConditionCategory.healthy;

      if (status?.replicas === 0) return ConditionCategory.warning;

      return ConditionCategory.error;
    };
    return {
      category: _category(),
      lastTransitionTime: undefined,
      status: (this.resource.status?.readyReplicas ?? 0).toString(),
      type: 'readyReplicas',
      title: 'Replicas Ready',
    };
  }

  private getAvailableReplicasCondition(
    status: IReplicaSetStatus | undefined,
  ): Condition | undefined {
    if (isUndefined(status?.availableReplicas)) return undefined;
    return {
      category:
        (status?.availableReplicas ?? 0) > 0
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: String(this.resource.status?.availableReplicas),
      type: 'availableReplicas',
      title: 'Available Replicas',
    };
  }

  private getFullyLabeledReplicasCondition(
    status: IReplicaSetStatus | undefined,
  ): Condition | undefined {
    if (isUndefined(status?.fullyLabeledReplicas)) return undefined;
    return {
      category:
        (status?.fullyLabeledReplicas ?? 0) > 0
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: String(this.resource.status?.fullyLabeledReplicas),
      type: 'fullyLabeledReplicas',
      title: 'Matching Pods',
    };
  }

  private getReplicasCondition(
    status: IReplicaSetStatus | undefined,
  ): Condition | undefined {
    if (isUndefined(status?.replicas)) return undefined;
    return {
      category:
        (status?.replicas ?? 0) > 0
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: String(this.resource.status?.replicas),
      type: 'replicas',
      title: 'Total Replicas',
    };
  }
}
