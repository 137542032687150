import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { SVGProps } from 'react';

const useStyles = makeStyles(() => {
  return {
    securityAnalysis: {
      width: 16,
      height: 16,
    },
  };
});

export const SecurityAnalysisIcon: React.FunctionComponent = (
  props: SVGProps<SVGSVGElement>,
): React.ReactElement => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.securityAnalysis} viewBox="0 0 16 16">
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="m15.697 14.667-1.39-1.378a3.418 3.418 0 1 0-.622.622l1.386 1.387a.444.444 0 0 0 .486.096.444.444 0 0 0 .274-.412.447.447 0 0 0-.134-.315Zm-4.08-.938a2.516 2.516 0 1 1 2.511-2.51 2.522 2.522 0 0 1-2.51 2.528v-.018Z"
          fill="currentColor"
        />
        <path
          d="M11.555 6.946V3.151L6.222.889.889 3.15v5.662a4.951 4.951 0 0 0 1.83 3.813l3.503 2.93 2.013-1.672a4.24 4.24 0 0 1-.91-2.626 4.293 4.293 0 0 1 4.23-4.312ZM6.08 8.916a.444.444 0 0 1-.582.044L3.11 7.173a.444.444 0 1 1 .529-.711L5.715 8 9 4.618a.444.444 0 0 1 .635.617L6.08 8.915Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
