import { PodIntent as Model } from '../../kubernetes-additional-crds/gen/conventions.carto.run/v1alpha1/';
import { AbstractModel } from '../AbstractModel';
import type { IPodIntent } from '../../kubernetes-additional-crds/gen/conventions.carto.run/v1alpha1/';
import type { IRepository } from '../../repository';

export class PodIntent extends AbstractModel<IPodIntent> {
  public static readonly humanType = 'Conventions PodIntent';

  public static readonly typeMeta = Model;

  public classRef = PodIntent;

  public constructor(
    resource: IPodIntent,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
