import { CronJob, Job as Model } from 'kubernetes-models/batch/v1';
import { Pod } from 'kubernetes-models/v1';
import { isUndefined } from 'lodash';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import { ConditionCategory, prioritizedConditions } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { Condition, ConditionList } from '../Condition';
import type { ICronJob, IJob } from 'kubernetes-models/batch/v1';
import type { IPod } from 'kubernetes-models/v1';

export class Job extends AbstractModel<IJob> {
  public static readonly humanType = 'Job';

  public static readonly typeMeta = Model;

  public classRef = Job;

  public constructor(resource: IJob, repository: IRepository, cluster: string) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    return prioritizedConditions(
      compact([
        this._suspendedStatus(this.resource.spec?.suspend),
        this._completionsStatus(
          this.resource.status?.succeeded,
          this.resource.spec?.completions,
        ),
        this._podStatus(
          this.resource.status?.succeeded,
          this.resource.status?.failed,
          this.resource.status?.active,
        ),
      ]),
      'suspend',
    );
  }

  public get owners(): MappedResource<ICronJob>[] {
    return compact([this.cronjob]);
  }

  public get cronjob(): MappedResource<ICronJob> | undefined {
    return this.repository.ownerOfType<ICronJob>(this, CronJob);
  }

  public get pods(): MappedResource<IPod>[] {
    return this.repository.ownedOfType<IPod>(this, Pod);
  }

  private _completionsStatus(
    numSucceed?: number,
    numCompletions?: number,
  ): Condition | undefined {
    if (isUndefined(numSucceed) || isUndefined(numCompletions)) {
      return undefined;
    }
    const completionsMatchesSucceeded = numSucceed === numCompletions;

    return {
      category: completionsMatchesSucceeded
        ? ConditionCategory.healthy
        : ConditionCategory.error,
      status: completionsMatchesSucceeded
        ? `${numCompletions}`
        : `${numSucceed} / ${numCompletions}`,
      type: 'completions',
      title: 'Completions',
    };
  }

  private _podStatus(
    succeeded?: number,
    failed?: number,
    active?: number,
  ): Condition {
    const activePods = active ?? 0;
    const succededPods = succeeded ?? 0;
    const failedPods = failed ?? 0;

    let podsCategory = ConditionCategory.healthy;

    if (succededPods > 0 && failedPods > 0) {
      podsCategory = ConditionCategory.warning;
    } else if (succededPods === 0 && failedPods > 0) {
      podsCategory = ConditionCategory.error;
    }

    return {
      category: podsCategory,
      status: `${activePods} / ${succededPods} / ${failedPods}`,
      type: 'podStatus',
      title: 'Pod Status (active / succeeded / failed)',
    };
  }

  private _suspendedStatus(suspended?: boolean): Condition {
    const isSuspended = suspended ?? false;
    return {
      category: isSuspended
        ? ConditionCategory.warning
        : ConditionCategory.healthy,
      lastTransitionTime: undefined,
      status: '',
      type: 'suspend',
      title: isSuspended ? 'Suspended' : 'Running',
    };
  }
}
