import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'scanning.apps.tanzu.vmware.com.v1beta1.ScanPolicy';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['scanning.apps.tanzu.vmware.com/v1beta1'],
    },
    kind: {
      type: 'string',
      enum: ['ScanPolicy'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        regoFile: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
    status: {
      type: 'object',
      properties: {},
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IScanPolicy {
  apiVersion: 'scanning.apps.tanzu.vmware.com/v1beta1';
  kind: 'ScanPolicy';
  metadata?: IObjectMeta;
  spec?: {
    regoFile?: string;
  };
  status?: {};
}

export class ScanPolicy extends Model<IScanPolicy> implements IScanPolicy {
  'apiVersion': IScanPolicy['apiVersion'];
  'kind': IScanPolicy['kind'];
  'metadata'?: IScanPolicy['metadata'];
  'spec'?: IScanPolicy['spec'];
  'status'?: IScanPolicy['status'];

  static apiVersion: IScanPolicy['apiVersion'] =
    'scanning.apps.tanzu.vmware.com/v1beta1';
  static kind: IScanPolicy['kind'] = 'ScanPolicy';
  static is = createTypeMetaGuard<IScanPolicy>(ScanPolicy);

  constructor(data?: ModelData<IScanPolicy>) {
    super({
      apiVersion: ScanPolicy.apiVersion,
      kind: ScanPolicy.kind,
      ...data,
    } as IScanPolicy);
  }
}

setSchema(ScanPolicy, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
