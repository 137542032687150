import { StatusCodes } from 'http-status-codes';
import { ICluster } from '../../proxy/hook/types';
import { networkErrorTypes } from '../networkErrorTypes';
import { emptyProxyResponse } from '../../proxy/hook/emptyProxyResponse';
import { ErrorProxyResponse, ProxyException } from '../../types';

export const formatBadRequestProxyResponse = (
  cluster: ICluster,
  resourcePath: string,
  error: unknown,
): ErrorProxyResponse => {
  const reason = (error as ProxyException).reason;
  return {
    ...emptyProxyResponse,
    cluster: cluster.name,
    error: {
      errorType:
        reason && reason in networkErrorTypes
          ? networkErrorTypes[reason]
          : networkErrorTypes.BadRequest,
      resourcePath: resourcePath,
      message: (error as ProxyException).message,
      status: StatusCodes.BAD_REQUEST.toString(),
      statusCode: StatusCodes.BAD_REQUEST,
    },
  };
};
