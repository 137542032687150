import type { IGitRepository } from '../../../kubernetes-additional-crds/gen/source.toolkit.fluxcd.io/v1beta2';
import { GitRepository as Model } from '../../../kubernetes-additional-crds/gen/source.toolkit.fluxcd.io/v1beta2';
import { AbstractModel } from '../../AbstractModel';
import type { IRepository } from '../../../repository';

export class GitRepositoryV1Beta2 extends AbstractModel<IGitRepository> {
  public static readonly humanType = 'Flux CD GitRepository v1beta2';

  public static readonly typeMeta = Model;

  public classRef = GitRepositoryV1Beta2;

  public constructor(
    resource: IGitRepository,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
