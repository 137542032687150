import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'services.apps.tanzu.vmware.com.v1alpha1.ClusterInstanceClass';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['services.apps.tanzu.vmware.com/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterInstanceClass'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        pool: {
          properties: {
            fieldSelector: {
              type: 'string',
              nullable: true,
            },
            group: {
              type: 'string',
              nullable: true,
            },
            kind: {
              type: 'string',
            },
            labelSelector: {
              properties: {
                matchExpressions: {
                  items: {
                    properties: {
                      key: {
                        type: 'string',
                      },
                      operator: {
                        type: 'string',
                      },
                      values: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    required: ['key', 'operator'],
                    type: 'object',
                  },
                  type: 'array',
                  nullable: true,
                },
                matchLabels: {
                  additionalProperties: {
                    type: 'string',
                  },
                  type: 'object',
                  properties: {},
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
          },
          required: ['kind'],
          type: 'object',
          nullable: true,
        },
        provisioner: {
          properties: {
            crossplane: {
              properties: {
                compositeResourceDefinition: {
                  type: 'string',
                },
                compositionRef: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['name'],
                  type: 'object',
                  nullable: true,
                },
                compositionSelector: {
                  properties: {
                    matchLabels: {
                      additionalProperties: {
                        type: 'string',
                      },
                      type: 'object',
                      properties: {},
                      nullable: true,
                    },
                  },
                  type: 'object',
                  nullable: true,
                },
                compositionUpdatePolicy: {
                  enum: ['Automatic', 'Manual'],
                  type: 'string',
                  nullable: true,
                },
              },
              required: ['compositeResourceDefinition'],
              type: 'object',
            },
          },
          required: ['crossplane'],
          type: 'object',
          nullable: true,
        },
      },
      required: ['description'],
      type: 'object',
    },
    status: {
      properties: {
        claimParameters: {
          properties: {
            openAPIV3Schema: {
              type: 'object',
              properties: {},
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        instanceType: {
          properties: {
            group: {
              type: 'string',
              nullable: true,
            },
            kind: {
              type: 'string',
            },
            version: {
              type: 'string',
            },
          },
          required: ['kind', 'version'],
          type: 'object',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['spec', 'apiVersion', 'kind'],
};

export interface IClusterInstanceClass {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'services.apps.tanzu.vmware.com/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterInstanceClass';
  metadata?: IObjectMeta;
  spec: {
    /**
     * Description of the Instance this class is for.
     */
    description: {
      short: string;
    };
    /**
     * Pool defines a pool of resources that belong to this class. Cannot be set if Provisioner is provided.
     */
    pool?: {
      /**
       * FieldSelector specifies a set of fields that MUST match certain conditions. See https://kubernetes.io/docs/concepts/overview/working-with-objects/field-selectors/
       */
      fieldSelector?: string;
      /**
       * Group specifies the API group for the resources belonging to this class.
       */
      group?: string;
      /**
       * Kind specifies the API Kind for the resources belonging to this class.
       */
      kind: string;
      /**
       * LabelSelector specifies a set of labels that MUST match. See https://kubernetes.io/docs/concepts/overview/working-with-objects/labels/#label-selectors
       */
      labelSelector?: {
        /**
         * matchExpressions is a list of label selector requirements. The requirements are ANDed.
         */
        matchExpressions?: Array<{
          /**
           * key is the label key that the selector applies to.
           */
          key: string;
          /**
           * operator represents a key's relationship to a set of values. Valid operators are In, NotIn, Exists and DoesNotExist.
           */
          operator: string;
          /**
           * values is an array of string values. If the operator is In or NotIn, the values array must be non-empty. If the operator is Exists or DoesNotExist, the values array must be empty. This array is replaced during a strategic merge patch.
           */
          values?: Array<string>;
        }>;
        /**
         * matchLabels is a map of {key,value} pairs. A single {key,value} in the matchLabels map is equivalent to an element of matchExpressions, whose key field is "key", the operator is "In", and the values array contains only "value". The requirements are ANDed.
         */
        matchLabels?: {
          [key: string]: string;
        };
      };
    };
    /**
     * Provisioner defines that this class should provision resources when a ClassClaim is created that references this class. Cannot be set if Pool is provided.
     */
    provisioner?: {
      /**
       * Crossplane configures provisioning using Crossplane (https://www.crossplane.io/).
       */
      crossplane: {
        /**
         * CompositeResourceDefinition refers to a Composite Resource Definition (an "XRD" in Crossplane parlance) by name. For example, "xpostgresqlinstances.database.example.org".
         */
        compositeResourceDefinition: string;
        /**
         * CompositionRef specifies which Composition this XR will use to compose resources when it is created, updated, or deleted. This can be omitted and will be set automatically if the XRD has a default or enforced composition reference, or if the below composition selector is set.
         */
        compositionRef?: {
          /**
           * Name specifies the name of the Composition
           */
          name: string;
        };
        /**
         * The compositionSelector allows you to match a Composition by labels rather than naming one explicitly. It is used to set the compositionRef if none is specified explicitly.
         */
        compositionSelector?: {
          /**
           * MatchLabels specifies a set of labels that must be present on a Composition
           */
          matchLabels?: {
            [key: string]: string;
          };
        };
        /**
         * CompositionUpdatePolicy specifies how existing XRs should be updated to new revisions of their underlying composition.
         */
        compositionUpdatePolicy?: 'Automatic' | 'Manual';
      };
    };
  };
  /**
   * ClusterInstanceClassStatus is the status of a ClusterInstanceClass.
   */
  status?: {
    /**
     * ClaimParameters contains the OpenAPIV3Schema used to configure claims for this Class.
     */
    claimParameters?: {
      /**
       * OpenAPIV3Schema is the OpenAPI v3 schema to be used for validation and pruning of parameters defined by ClassClaims referring to this class.
       */
      openAPIV3Schema?: {};
    };
    /**
     * Conditions the latest available observations of a resource's current state.
     */
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    /**
     * InstanceType contains information selected by this Class
     */
    instanceType?: {
      /**
       * Group specifies the API group.
       */
      group?: string;
      /**
       * Kind specifies the API Kind.
       */
      kind: string;
      /**
       * Version specifies the API version.
       */
      version: string;
    };
    /**
     * ObservedGeneration is the 'Generation' of the resource that was last processed by the controller.
     */
    observedGeneration?: number;
  };
}

export class ClusterInstanceClass
  extends Model<IClusterInstanceClass>
  implements IClusterInstanceClass
{
  'apiVersion': IClusterInstanceClass['apiVersion'];
  'kind': IClusterInstanceClass['kind'];
  'metadata'?: IClusterInstanceClass['metadata'];
  'spec': IClusterInstanceClass['spec'];
  'status'?: IClusterInstanceClass['status'];

  static apiVersion: IClusterInstanceClass['apiVersion'] =
    'services.apps.tanzu.vmware.com/v1alpha1';
  static kind: IClusterInstanceClass['kind'] = 'ClusterInstanceClass';
  static is = createTypeMetaGuard<IClusterInstanceClass>(ClusterInstanceClass);

  constructor(data?: ModelData<IClusterInstanceClass>) {
    super({
      apiVersion: ClusterInstanceClass.apiVersion,
      kind: ClusterInstanceClass.kind,
      ...data,
    } as IClusterInstanceClass);
  }
}

setSchema(ClusterInstanceClass, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
