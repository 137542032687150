import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.Runnable';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['Runnable'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        inputs: {
          additionalProperties: {},
          type: 'object',
          properties: {},
          nullable: true,
        },
        retentionPolicy: {
          default: {
            maxFailedRuns: 10,
            maxSuccessfulRuns: 10,
          },
          properties: {
            maxFailedRuns: {
              format: 'int64',
              type: 'integer',
              minimum: 1,
            },
            maxSuccessfulRuns: {
              format: 'int64',
              type: 'integer',
              minimum: 1,
            },
          },
          required: ['maxFailedRuns', 'maxSuccessfulRuns'],
          type: 'object',
          nullable: true,
        },
        runTemplateRef: {
          properties: {
            kind: {
              type: 'string',
              nullable: true,
            },
            name: {
              minLength: 1,
              type: 'string',
            },
          },
          required: ['name'],
          type: 'object',
        },
        selector: {
          properties: {
            matchingLabels: {
              additionalProperties: {
                type: 'string',
              },
              type: 'object',
              properties: {},
            },
            resource: {
              properties: {
                apiVersion: {
                  type: 'string',
                  nullable: true,
                },
                kind: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
            },
          },
          required: ['matchingLabels', 'resource'],
          type: 'object',
          nullable: true,
        },
        serviceAccountName: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['runTemplateRef'],
      type: 'object',
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        outputs: {
          additionalProperties: {},
          type: 'object',
          properties: {},
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IRunnable {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'Runnable';
  metadata: IObjectMeta;
  /**
   * Spec describes the runnable. More info: https://cartographer.sh/docs/latest/reference/runnable/#runnable
   */
  spec: {
    /**
     * Inputs are key/values providing inputs to the templated object created for this runnable. Reference inputs in the template using the jsonPath: $(runnable.spec.inputs.<key>)$
     */
    inputs?: {
      [key: string]: any;
    };
    /**
     * RetentionPolicy specifies how many successful and failed runs should be retained. Runs older than this (ordered by creation time) will be deleted. Setting higher values will increase memory footprint.
     */
    retentionPolicy?: {
      /**
       * MaxFailedRuns is the number of failed runs to retain.
       */
      maxFailedRuns: number;
      /**
       * MaxSuccessfulRuns is the number of successful runs to retain.
       */
      maxSuccessfulRuns: number;
    };
    /**
     * RunTemplateRef identifies the run template used to produce resources for this runnable.
     */
    runTemplateRef: {
      kind?: string;
      name: string;
    };
    /**
     * Selector refers to an additional object that the template can refer to using: $(selected)$.
     */
    selector?: {
      /**
       * MatchingLabels must match on a single target object, making the object available in the template as $(selected)$
       */
      matchingLabels: {
        [key: string]: string;
      };
      /**
       * Resource is the GVK that must match the selected object.
       */
      resource: {
        apiVersion?: string;
        kind?: string;
      };
    };
    /**
     * ServiceAccountName refers to the Service account with permissions to create resources submitted by the ClusterRunTemplate.
     *  If not set, Cartographer will use the default service account in the runnable's namespace.
     */
    serviceAccountName?: string;
  };
  /**
   * Status conforms to the Kubernetes conventions: https://github.com/kubernetes/community/blob/master/contributors/devel/sig-architecture/api-conventions.md#typical-status-properties
   */
  status?: {
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    observedGeneration?: number;
    /**
     * Note: outputs are only filled on the runnable when the templated object has a Succeeded condition with a Status of True E.g:     status.conditions[?(@.type=="Succeeded")].status == True a runnable creating an object without a Succeeded condition (like a Job or ConfigMap) will never display an output
     */
    outputs?: {
      [key: string]: any;
    };
  };
}

export class Runnable extends Model<IRunnable> implements IRunnable {
  'apiVersion': IRunnable['apiVersion'];
  'kind': IRunnable['kind'];
  'metadata': IRunnable['metadata'];
  'spec': IRunnable['spec'];
  'status'?: IRunnable['status'];

  static apiVersion: IRunnable['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IRunnable['kind'] = 'Runnable';
  static is = createTypeMetaGuard<IRunnable>(Runnable);

  constructor(data?: ModelData<IRunnable>) {
    super({
      apiVersion: Runnable.apiVersion,
      kind: Runnable.kind,
      ...data,
    } as IRunnable);
  }
}

setSchema(Runnable, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
