import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';

export interface IDoraApi {
  workloadsMetricsByCorrelationIDs: (
    correlationIDs: string[],
    start: string,
    end: string,
    environment: string,
    timezone: string,
  ) => Promise<IDoraApiResponse>;
  environments: () => Promise<IEnvironments>;
  configuration: () => Promise<IConfiguration>;
}

export const doraApiRef = createApiRef<IDoraApi>({
  id: 'plugin.tap-dora-metrics.service',
});

export interface IDoraApiResponse {
  currentLeadTime: number;
  currentDeployments: number;
  currentFailures: number;
  currentPerDate: IPerDateResponse[];
  previousPerDate: IPerDateResponse[];
  currentPerCorrelationID: IPerCorrelationIDResponse[];
}

export interface IPerDateResponse {
  date: string;
  averageLeadTime: number;
  deployments: number;
  failures: number;
}

export interface IPerCorrelationIDResponse {
  correlationID: string;
  averageLeadTime: number;
  deployments: number;
  failures: number;
}

export interface IEnvironments {
  environments: IEnvironment[];
}

export interface IEnvironment {
  name: string;
}

export interface IConfiguration {
  changeFailureRate: {
    terminationCount: number;
    terminationInterval: number;
  };
}

export class DoraApiClient implements IDoraApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async workloadsMetricsByCorrelationIDs(
    correlationIDs: string[],
    start: string,
    end: string,
    environment: string,
    timezone: string,
  ): Promise<IDoraApiResponse> {
    const url = new URL(await this.discoveryApi.getBaseUrl('dora'));

    return this.fetchApi
      .fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          correlationIDs,
          start,
          end,
          environment,
          timezone,
        }),
      })
      .then(async resp => {
        if (resp.status >= 400)
          throw new Error(
            `received response (status ${resp.status}): ${await resp.text()}`,
          );
        return resp;
      })
      .then(resp => resp.json());
  }

  async environments(): Promise<IEnvironments> {
    const url = new URL(await this.discoveryApi.getBaseUrl('dora'));
    url.pathname = `${url.pathname}/environments`;

    return this.fetchApi
      .fetch(url, {
        method: 'GET',
      })
      .then(async resp => {
        if (resp.status >= 400)
          throw new Error(
            `received response (status ${resp.status}): ${await resp.text()}`,
          );
        return resp;
      })
      .then(resp => resp.json());
  }

  async configuration(): Promise<IConfiguration> {
    const url = new URL(await this.discoveryApi.getBaseUrl('dora'));
    url.pathname = `${url.pathname}/configuration`;

    return this.fetchApi
      .fetch(url, {
        method: 'GET',
      })
      .then(async resp => {
        if (resp.status >= 400)
          throw new Error(
            `received response (status ${resp.status}): ${await resp.text()}`,
          );
        return resp;
      })
      .then(resp => resp.json());
  }
}
