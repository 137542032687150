import { TaskRun as Model } from '../../../kubernetes-additional-crds/gen/tekton.dev/v1';
import { TaskRun } from '../TaskRun';
import type { ITaskRun } from '../../../kubernetes-additional-crds/gen/tekton.dev/v1';
import type { IRepository } from '../../../repository';

export class TaskRunV1 extends TaskRun<ITaskRun> {
  public static readonly typeMeta = Model;

  public classRef = TaskRunV1;

  public constructor(
    resource: ITaskRun,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
