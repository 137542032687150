import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'tekton.dev.v1.Task';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['tekton.dev/v1'],
    },
    kind: {
      type: 'string',
      enum: ['Task'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface ITask {
  apiVersion: 'tekton.dev/v1';
  kind: 'Task';
  metadata?: IObjectMeta;
}

export class Task extends Model<ITask> implements ITask {
  'apiVersion': ITask['apiVersion'];
  'kind': ITask['kind'];
  'metadata'?: ITask['metadata'];

  static apiVersion: ITask['apiVersion'] = 'tekton.dev/v1';
  static kind: ITask['kind'] = 'Task';
  static is = createTypeMetaGuard<ITask>(Task);

  constructor(data?: ModelData<ITask>) {
    super({
      apiVersion: Task.apiVersion,
      kind: Task.kind,
      ...data,
    } as ITask);
  }
}

setSchema(Task, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
