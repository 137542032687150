import {
  Configuration,
  Revision as Model,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import { Deployment } from 'kubernetes-models/apps/v1';
import compact from 'lodash/compact';
import isUndefined from 'lodash/isUndefined';
import sum from 'lodash/sum';
import { AbstractModel } from '../AbstractModel';
import {
  ConditionCategory,
  conditionsFromResource,
  prioritizedConditions,
} from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList, Condition } from '../Condition';
import type {
  IRevision,
  IConfiguration,
  IRoute,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import type { IDeployment } from 'kubernetes-models/apps/v1';

const conditionsLabels = {
  Ready: { True: 'Ready', False: 'Not Ready', Unknown: 'Ready Status Unknown' },
  Active: {
    True: 'Active',
    False: 'Inactive',
    Unknown: 'Active Status Unknown',
  },
  ContainerHealthy: {
    True: 'Container Healthy',
    False: 'Container Unhealthy',
    Unknown: `Container Health Unknown`,
  },
  ResourcesAvailable: {
    True: 'Resources Available',
    False: 'Resources Unavailable',
    Unknown: 'Resources Availability Unknown',
  },
};

export class Revision extends AbstractModel<IRevision> {
  public static readonly humanType = 'Knative Revision';

  public static readonly typeMeta = Model;

  public classRef = Revision;

  public constructor(
    resource: IRevision,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    const { status } = this.resource;

    const actualReplicas = this.getActualReplicasCondition(status);

    const conditions = [
      conditionsFromResource(this.resource, conditionsLabels),
      actualReplicas,
    ].flat();

    return prioritizedConditions(conditions);
  }

  public get owners(): MappedResource<IConfiguration>[] {
    return compact([this.configuration]);
  }

  public get configuration(): MappedResource<IConfiguration> | undefined {
    return this.repository.ownerOfType<IConfiguration>(this, Configuration);
  }

  public get deployments(): MappedResource<IDeployment>[] {
    return this.repository.ownedOfType<IDeployment>(this, Deployment);
  }

  public get routes(): MappedResource<IRoute>[] {
    return (
      this.configuration?.service?.routes.filter(route =>
        route.resource.status?.traffic?.find(
          traffic => traffic.revisionName === this.resource.metadata?.name,
        ),
      ) ?? []
    );
  }

  public getTraffic = (): string | undefined => {
    const revisionName = this.resource.metadata?.name;
    const configurationName = this.configuration?.resource.metadata?.name;
    const service = this.configuration?.service;
    let traffic = service?.resource.status?.traffic?.filter(
      target => target.revisionName === revisionName,
    );

    if (traffic === undefined || traffic.length === 0) {
      // Note: This case was never seen but is added for the sake of robustness
      traffic = service?.resource.status?.traffic?.filter(
        target => target.configurationName === configurationName,
      );
    }

    const value = sum(traffic?.map(item => item.percent));

    if (isUndefined(value)) {
      return undefined;
    }

    return `${value}%`;
  };

  public getTags = (): string[] => {
    const revisionName = this.resource.metadata?.name;
    const configurationName = this.configuration?.resource.metadata?.name;
    const service = this.configuration?.service;

    const traffic = service?.resource.status?.traffic?.filter(
      target =>
        target.revisionName === revisionName ||
        target.configurationName === configurationName,
    );

    return compact(traffic?.map(item => item.tag));
  };

  public getPodsDescription = (): string => {
    const pods = this.deployments.flatMap(deployment =>
      deployment.replicaSets.flatMap(replicaSet => replicaSet.pods),
    );
    const numActivePods = pods.filter(
      pod => pod.conditions.primary?.category === ConditionCategory.healthy,
    ).length;
    return `${numActivePods}/${pods.length}`;
  };

  private getActualReplicasCondition(
    status: IRevision['status'] | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (
        !isUndefined(status?.actualReplicas) &&
        status?.actualReplicas === status?.desiredReplicas
      ) {
        return ConditionCategory.healthy;
      }

      return ConditionCategory.error;
    };

    return {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.actualReplicas ?? 0}/${status?.desiredReplicas ?? 0}`,
      type: 'Actual Replicas',
      title: 'Actual Replicas',
    };
  }
}
