/* eslint-disable @typescript-eslint/no-magic-numbers */
import { MetricType } from './IMetric';
import type { IMetric } from './IMetric';
import type { IQuantity } from '@kubernetes-models/apimachinery/api/resource';

const conversionMetrics: Record<string, number> = {
  '': 1,
  K: 1000,
  M: 1000 ** 2,
  G: 1000 ** 3,
  T: 1000 ** 4,
  P: 1000 ** 5,
  E: 1000 ** 6,
  Ki: 1024,
  Mi: 1024 ** 2,
  Gi: 1024 ** 3,
  Ti: 1024 ** 4,
  Pi: 1024 ** 5,
  Ei: 1024 ** 6,
};

export class MemoryMetric implements IMetric<MemoryMetric> {
  public readonly value: IMetric<MemoryMetric>['value'];

  public constructor(n?: IQuantity) {
    this.value = this.parseValue(n);
  }

  public format(): string {
    if (this.value === MetricType.Invalid) return 'NOT FOUND';
    if (this.value === MetricType.Unlimited) return 'Unlimited';

    const units = ['', 'Ki', 'Mi', 'Gi', 'Ti', 'Pi', 'Ei'];
    let l = 0;
    let n = this.value;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)}${units[l]}`;
  }

  public add(n: MemoryMetric): MemoryMetric {
    if (this.value === MetricType.Invalid || n.value === MetricType.Invalid) {
      return new MemoryMetric(MetricType.Invalid);
    }
    if (
      this.value === MetricType.Unlimited ||
      n.value === MetricType.Unlimited
    ) {
      return new MemoryMetric(MetricType.Unlimited);
    }

    return new MemoryMetric(this.value + n.value);
  }

  private parseValue(n?: IQuantity): IMetric<MemoryMetric>['value'] {
    if (
      typeof n === 'number' ||
      n === MetricType.Invalid ||
      n === MetricType.Unlimited
    ) {
      return n;
    }
    if (n === undefined) {
      return MetricType.Invalid;
    }
    {
      const regex = /(?<magnitude>^\d+)(?<unit>[EGKMPT]i?)?$/u;
      const matches = regex.exec(n);
      if (matches === null) {
        return MetricType.Invalid;
      }

      return this.convertMemoryToBytes(
        Number(matches.groups?.magnitude),
        matches.groups?.unit ?? '',
      );
    }
  }

  private convertMemoryToBytes(
    amount: number,
    unit: keyof typeof conversionMetrics,
  ): number {
    return conversionMetrics[unit] * amount;
  }
}
