import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
  StandardProps,
  StyleRulesCallback,
  Theme,
  withStyles,
} from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';

import AngleDoubleLineIcon from './AngleDoubleLineIcon';
import LogoIcon from '../Header/LogoIcon';
import { useCustomization } from '../hooks/useCustomization';
import { useSidebar } from './useSidebar';

export type SidebarClassKey =
  | 'root'
  | 'collapseButton'
  | 'collapseButtonOpen'
  | 'sidebarBottom'
  | 'tap'
  | 'logo';

export interface SidebarProps
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    SidebarClassKey
  > {}

const styles: StyleRulesCallback<
  BackstageTheme,
  SidebarProps,
  SidebarClassKey
> = (theme: BackstageTheme) => ({
  root: {
    backgroundColor: theme.palette.navigation.background,
    color: theme.palette.navigation.color,
    display: 'flex',
    flexDirection: 'column',
    // paddingTop: '0.9rem',
  },
  collapseButton: {
    '--line-height':
      'var(--cds-alias-object-interaction-touch-target, 2.25rem)',
    '--padding': '0 var(--cds-global-space-5, 0.5rem)',

    borderBottom: `1px solid ${theme.palette.navigation.color}`,
    cursor: 'pointer',
    display: 'flex',
    // justifyContent: 'center',
    lineHeight: 'var(--line-height)',
    // marginBottom: theme.spacing(2),
    minHeight: 'var(--line-height)',
    padding: 'var(--padding)',
    width: '100%',

    '& svg': {
      transform: 'rotate(90deg)',
    },
  },
  collapseButtonOpen: {
    justifyContent: 'flex-end',

    '& svg': {
      transform: 'rotate(270deg)',
    },
  },
  sidebarBottom: {
    marginTop: 'auto',
  },
  tap: {
    display: 'flex',
    paddingBottom: 'var(--cds-global-space-4)',
    paddingLeft: 'var(--cds-global-space-4)',
    height: 70,
    alignItems: 'center',
    lineHeight: '18px',
  },
  logo: {
    '--size': '1.6rem',
    width: 'var(--size)',
    height: 'var(--size)',
  },
});

const Sidebar = (props: PropsWithChildren<SidebarProps>) => {
  const { hasTapLogo } = useCustomization();
  const { toggleSidebar, isDesktop, isOpened } = useSidebar();

  const onFocus = (event: React.FocusEvent<HTMLSpanElement>) =>
    event.stopPropagation();

  const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key.toLowerCase() === 'enter') {
      toggleSidebar();
    }
  };

  return (
    <div className={props.classes?.root}>
      {isDesktop && (
        <span
          role="button"
          tabIndex={0}
          onClick={toggleSidebar}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          className={clsx(props.classes?.collapseButton, {
            ...(props.classes?.collapseButtonOpen && {
              [props.classes?.collapseButtonOpen]: isOpened,
            }),
          })}
        >
          <AngleDoubleLineIcon />
        </span>
      )}
      {props.children}
      {hasTapLogo && (
        <div className={props.classes?.sidebarBottom}>
          <div className={props.classes?.tap}>
            <LogoIcon classes={{ root: props.classes?.logo }} />
            {isDesktop && isOpened && (
              <span>
                VMware Tanzu <br />
                Application Platform
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const ClaritySidebar = withStyles<SidebarClassKey>(
  styles as StyleRulesCallback<Theme, SidebarProps, SidebarClassKey>,
  { name: 'ClaritySidebar' },
)(Sidebar);
