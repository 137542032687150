import { ClusterRepository } from './ClusterRepository';
import type { KubernetesLocalProxyApi } from './KubernetesLocalProxyApi';
import type { ICluster } from '../proxy/hook/types';
import type { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import type { KubernetesAuthProvidersApi } from '@backstage/plugin-kubernetes';

interface ProxyOptions {
  /**
   * @deprecated The cluster property should be used instead.
   */
  clusterName?: string;
  cluster?: ICluster;
  path: string;
  init?: RequestInit;
}

export class KubernetesLocalProxyClient implements KubernetesLocalProxyApi {
  private readonly discoveryApi: DiscoveryApi;

  private readonly identityApi: IdentityApi;

  private readonly kubernetesAuthProvidersApi: KubernetesAuthProvidersApi;

  private readonly clusterRepository: ClusterRepository;

  public constructor({
    discoveryApi,
    identityApi,
    kubernetesAuthProvidersApi,
  }: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
    kubernetesAuthProvidersApi: KubernetesAuthProvidersApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
    this.kubernetesAuthProvidersApi = kubernetesAuthProvidersApi;
    this.clusterRepository = new ClusterRepository({
      discoveryApi,
      identityApi,
    });
  }

  public async proxy(options: ProxyOptions): Promise<Response> {
    let cluster: ICluster;
    if (options.cluster) {
      cluster = options.cluster;
    } else if (options.clusterName) {
      cluster = await this.clusterRepository.findClusterByName(
        options.clusterName,
      );
    } else {
      throw new Error('no cluster information provided!');
    }

    const { token: k8sToken } = await this.getCredentials(cluster);
    const url = `${await this.discoveryApi.getBaseUrl('kubernetes')}/proxy${
      options.path
    }`;
    const identityResponse = await this.identityApi.getCredentials();
    const headers = {
      ...options.init?.headers,
      [`Backstage-Kubernetes-Cluster`]: cluster.name,
      ...(k8sToken && {
        [`Backstage-Kubernetes-Authorization`]: `Bearer ${k8sToken}`,
      }),
      ...(identityResponse.token && {
        Authorization: `Bearer ${identityResponse.token}`,
      }),
    };

    return fetch(url, { ...options.init, headers });
  }

  private async getCredentials(cluster: ICluster): Promise<{ token?: string }> {
    const authProvider = cluster.oidcTokenProvider
      ? `${cluster.authProvider}.${cluster.oidcTokenProvider}`
      : cluster.authProvider;
    return this.kubernetesAuthProvidersApi.getCredentials(authProvider);
  }
}
