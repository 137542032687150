import { Profile as Model } from '../../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IProfile } from '../../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import type { IRepository } from '../../repository';

export class Profile extends AbstractModel<IProfile> {
  public static readonly humanType = 'Tanzu Profile';

  public static readonly typeMeta = Model;

  public classRef = Profile;

  public constructor(resource: IProfile, repository: IRepository) {
    super(resource, repository, 'TBD', Model);
  }
}
