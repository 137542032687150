import { ApiSurface, AppPluginInterface } from '@vmware-tanzu/core-frontend';
import {
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import {
  kubernetesLocalProxyApiRef,
  KubernetesLocalProxyClient,
} from '@tpb/kubernetes-orm';
import { kubernetesAuthProvidersApiRef } from '@backstage/plugin-kubernetes';

export const KubernetesOrmPlugin: AppPluginInterface = () => surfaces => {
  surfaces.applyTo(ApiSurface, surface => {
    surface.add(
      createApiFactory({
        api: kubernetesLocalProxyApiRef,
        deps: {
          discoveryApi: discoveryApiRef,
          identityApi: identityApiRef,
          kubernetesAuthProvidersApi: kubernetesAuthProvidersApiRef,
        },
        factory: ({ discoveryApi, identityApi, kubernetesAuthProvidersApi }) =>
          new KubernetesLocalProxyClient({
            discoveryApi,
            identityApi,
            kubernetesAuthProvidersApi,
          }),
      }),
    );
  });
};
