import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.Deliverable';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['Deliverable'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        params: {
          items: {
            properties: {
              name: {
                type: 'string',
              },
              value: {},
            },
            required: ['name', 'value'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        serviceAccountName: {
          type: 'string',
          nullable: true,
        },
        source: {
          properties: {
            git: {
              properties: {
                ref: {
                  properties: {
                    branch: {
                      type: 'string',
                      nullable: true,
                    },
                    commit: {
                      type: 'string',
                      nullable: true,
                    },
                    tag: {
                      type: 'string',
                      nullable: true,
                    },
                  },
                  type: 'object',
                  nullable: true,
                },
                url: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
            image: {
              type: 'string',
              nullable: true,
            },
            subPath: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
      },
      type: 'object',
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        deliveryRef: {
          properties: {
            apiVersion: {
              type: 'string',
              nullable: true,
            },
            kind: {
              type: 'string',
              nullable: true,
            },
            name: {
              type: 'string',
              nullable: true,
            },
            namespace: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        resources: {
          items: {
            properties: {
              inputs: {
                items: {
                  properties: {
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['name'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              name: {
                type: 'string',
              },
              outputs: {
                items: {
                  properties: {
                    digest: {
                      type: 'string',
                    },
                    lastTransitionTime: {
                      format: 'date-time',
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                    preview: {
                      type: 'string',
                    },
                  },
                  required: ['digest', 'lastTransitionTime', 'name', 'preview'],
                  type: 'object',
                },
                type: 'array',
                nullable: true,
              },
              stampedRef: {
                properties: {
                  apiVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  fieldPath: {
                    type: 'string',
                    nullable: true,
                  },
                  kind: {
                    type: 'string',
                    nullable: true,
                  },
                  name: {
                    type: 'string',
                    nullable: true,
                  },
                  namespace: {
                    type: 'string',
                    nullable: true,
                  },
                  resourceVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  uid: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              templateRef: {
                properties: {
                  apiVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  fieldPath: {
                    type: 'string',
                    nullable: true,
                  },
                  kind: {
                    type: 'string',
                    nullable: true,
                  },
                  name: {
                    type: 'string',
                    nullable: true,
                  },
                  namespace: {
                    type: 'string',
                    nullable: true,
                  },
                  resourceVersion: {
                    type: 'string',
                    nullable: true,
                  },
                  uid: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IDeliverable {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'Deliverable';
  metadata: IObjectMeta;
  /**
   * Spec describes the deliverable. More info: https://cartographer.sh/docs/latest/reference/workload/#deliverable
   */
  spec: {
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * Name of the parameter. Should match a blueprint or template parameter name.
       */
      name: string;
      /**
       * Value of the parameter.
       */
      value: any;
    }>;
    /**
     * ServiceAccountName refers to the Service account with permissions to create resources submitted by the supply chain.
     *  If not set, Cartographer will use serviceAccountName from delivery.
     *  If that is also not set, Cartographer will use the default service account in the deliverable's namespace.
     */
    serviceAccountName?: string;
    /**
     * The location of the source code for the workload. Specify one of `spec.source` or `spec.image`
     */
    source?: {
      /**
       * Source code location in a git repository.
       */
      git?: {
        ref?: {
          branch?: string;
          commit?: string;
          tag?: string;
        };
        url?: string;
      };
      /**
       * OCI Image in a repository, containing the source code to be used throughout the supply chain.
       */
      image?: string;
      /**
       * Subpath inside the Git repository or Image to treat as the root of the application. Defaults to the root if left empty.
       */
      subPath?: string;
    };
  };
  /**
   * Status conforms to the Kubernetes conventions: https://github.com/kubernetes/community/blob/master/contributors/devel/sig-architecture/api-conventions.md#typical-status-properties
   */
  status?: {
    /**
     * Conditions describing this resource's reconcile state. The top level condition is of type `Ready`, and follows these Kubernetes conventions: https://github.com/kubernetes/community/blob/master/contributors/devel/sig-architecture/api-conventions.md#typical-status-properties
     */
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    /**
     * DeliveryRef is the Delivery resource that was used when this status was set.
     */
    deliveryRef?: {
      apiVersion?: string;
      kind?: string;
      name?: string;
      namespace?: string;
    };
    /**
     * ObservedGeneration refers to the metadata.Generation of the spec that resulted in the current `status`.
     */
    observedGeneration?: number;
    /**
     * Resources contain references to the objects created by the Delivery and the templates used to create them. It also contains Inputs and Outputs that were passed between the templates as the Delivery was processed.
     */
    resources?: Array<{
      /**
       * Inputs are references to resources that were used to template the object in StampedRef
       */
      inputs?: Array<{
        /**
         * Name is the name of the resource in the blueprint whose output the resource consumes as an input
         */
        name: string;
      }>;
      /**
       * Name is the name of the resource in the blueprint
       */
      name: string;
      /**
       * Outputs are values from the object in StampedRef that can be consumed by other resources
       */
      outputs?: Array<{
        /**
         * Digest is a sha256 of the full value of the output
         */
        digest: string;
        /**
         * LastTransitionTime is a timestamp of the last time the value changed
         */
        lastTransitionTime: string;
        /**
         * Name is the output type generated from the resource [url, revision, image or config]
         */
        name: string;
        /**
         * Preview is a preview of the value of the output
         */
        preview: string;
      }>;
      /**
       * StampedRef is a reference to the object that was created by the resource
       */
      stampedRef?: {
        /**
         * API version of the referent.
         */
        apiVersion?: string;
        /**
         * If referring to a piece of an object instead of an entire object, this string should contain a valid JSON/Go field access statement, such as desiredState.manifest.containers[2]. For example, if the object reference is to a container within a pod, this would take on a value like: "spec.containers{name}" (where "name" refers to the name of the container that triggered the event) or if no container name is specified "spec.containers[2]" (container with index 2 in this pod). This syntax is chosen only to have some well-defined way of referencing a part of an object. TODO: this design is not final and this field is subject to change in the future.
         */
        fieldPath?: string;
        /**
         * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
         */
        kind?: string;
        /**
         * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names
         */
        name?: string;
        /**
         * Namespace of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces/
         */
        namespace?: string;
        /**
         * Specific resourceVersion to which this reference is made, if any. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
         */
        resourceVersion?: string;
        /**
         * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#uids
         */
        uid?: string;
      };
      /**
       * TemplateRef is a reference to the template used to create the object in StampedRef
       */
      templateRef?: {
        /**
         * API version of the referent.
         */
        apiVersion?: string;
        /**
         * If referring to a piece of an object instead of an entire object, this string should contain a valid JSON/Go field access statement, such as desiredState.manifest.containers[2]. For example, if the object reference is to a container within a pod, this would take on a value like: "spec.containers{name}" (where "name" refers to the name of the container that triggered the event) or if no container name is specified "spec.containers[2]" (container with index 2 in this pod). This syntax is chosen only to have some well-defined way of referencing a part of an object. TODO: this design is not final and this field is subject to change in the future.
         */
        fieldPath?: string;
        /**
         * Kind of the referent. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
         */
        kind?: string;
        /**
         * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names
         */
        name?: string;
        /**
         * Namespace of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/namespaces/
         */
        namespace?: string;
        /**
         * Specific resourceVersion to which this reference is made, if any. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#concurrency-control-and-consistency
         */
        resourceVersion?: string;
        /**
         * UID of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#uids
         */
        uid?: string;
      };
    }>;
  };
}

export class Deliverable extends Model<IDeliverable> implements IDeliverable {
  'apiVersion': IDeliverable['apiVersion'];
  'kind': IDeliverable['kind'];
  'metadata': IDeliverable['metadata'];
  'spec': IDeliverable['spec'];
  'status'?: IDeliverable['status'];

  static apiVersion: IDeliverable['apiVersion'] = 'carto.run/v1alpha1';
  static kind: IDeliverable['kind'] = 'Deliverable';
  static is = createTypeMetaGuard<IDeliverable>(Deliverable);

  constructor(data?: ModelData<IDeliverable>) {
    super({
      apiVersion: Deliverable.apiVersion,
      kind: Deliverable.kind,
      ...data,
    } as IDeliverable);
  }
}

setSchema(Deliverable, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
