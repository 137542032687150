/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { parseEntityRef } from '@backstage/catalog-model';
import { ResponseError } from '@backstage/errors';
import { ScmIntegrationRegistry } from '@backstage/integration';
import ObservableImpl from 'zen-observable';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types';
import qs from 'qs';
import {
  ListActionsResponse,
  LogEvent,
  ScaffolderApi,
  TemplateParameterSchema,
  ScaffolderScaffoldOptions,
  ScaffolderScaffoldResponse,
  ScaffolderStreamLogsOptions,
  ScaffolderGetIntegrationsListOptions,
  ScaffolderGetIntegrationsListResponse,
  ScaffolderTask,
} from './types';

/**
 * Utility API reference for the {@link ScaffolderApi}.
 *
 * @public
 */
export const scaffolderApiRef = createApiRef<ScaffolderApi>({
  id: 'plugin.scaffolder.service',
});

/**
 * An API to interact with the scaffolder backend.
 *
 * @public
 */
export class ScaffolderClient implements ScaffolderApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly scmIntegrationsApi: ScmIntegrationRegistry;
  private readonly fetchApi: FetchApi;
  private readonly useLongPollingLogs: boolean;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    scmIntegrationsApi: ScmIntegrationRegistry;
    useLongPollingLogs?: boolean;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi ?? { fetch };
    this.scmIntegrationsApi = options.scmIntegrationsApi;
    this.useLongPollingLogs = options.useLongPollingLogs ?? false;
    this.identityApi = options.identityApi;
  }

  async getIntegrationsList(
    options: ScaffolderGetIntegrationsListOptions,
  ): Promise<ScaffolderGetIntegrationsListResponse> {
    const integrations = [
      ...this.scmIntegrationsApi.azure.list(),
      ...this.scmIntegrationsApi.bitbucket
        .list()
        .filter(
          item =>
            !this.scmIntegrationsApi.bitbucketCloud.byHost(item.config.host) &&
            !this.scmIntegrationsApi.bitbucketServer.byHost(item.config.host),
        ),
      ...this.scmIntegrationsApi.bitbucketCloud.list(),
      ...this.scmIntegrationsApi.bitbucketServer.list(),
      ...this.scmIntegrationsApi.github.list(),
      ...this.scmIntegrationsApi.gitlab.list(),
    ]
      .map(c => ({ type: c.type, title: c.title, host: c.config.host }))
      .filter(c => options.allowedHosts.includes(c.host));

    return {
      integrations,
    };
  }

  async getTemplateParameterSchema(
    templateRef: string,
  ): Promise<TemplateParameterSchema> {
    const { namespace, kind, name } = parseEntityRef(templateRef, {
      defaultKind: 'template',
    });

    const { token } = await this.identityApi.getCredentials();
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const templatePath = [namespace, kind, name]
      .map(s => encodeURIComponent(s))
      .join('/');

    const url = `${baseUrl}/v2/templates/${templatePath}/parameter-schema`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const schema: TemplateParameterSchema = await response.json();
    return schema;
  }

  /**
   * Executes the scaffolding of a component, given a template and its
   * parameter values.
   *
   * @param options - The {@link ScaffolderScaffoldOptions} the scaffolding.
   */
  async scaffold(
    options: ScaffolderScaffoldOptions,
  ): Promise<ScaffolderScaffoldResponse> {
    const { templateRef, values, secrets = {} } = options;
    const { token } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('scaffolder')}/v2/tasks`;
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify({
        templateRef,
        values: { ...values },
        secrets,
      }),
    });

    if (response.status !== 201) {
      const status = `${response.status} ${response.statusText}`;
      const body = await response.text();
      throw new Error(`Backend request failed, ${status} ${body.trim()}`);
    }

    const { id } = (await response.json()) as { id: string };
    return { taskId: id };
  }

  async getTask(taskId: string): Promise<ScaffolderTask> {
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const url = `${baseUrl}/v2/tasks/${encodeURIComponent(taskId)}`;

    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async getTemplate(name: string) {
    const { token } = await this.identityApi.getCredentials();
    const baseUrl = await this.discoveryApi.getBaseUrl('catalog');
    const url = `${baseUrl}/entities/by-name/template/default/${encodeURIComponent(
      name,
    )}`;
    const response = await this.fetchApi.fetch(url, {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });
    if (!response.ok) {
      throw ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async postInvoked(name: string, type: string, uuid: string): Promise<void> {
    const identity = await this.identityApi.getBackstageIdentity();
    const username = identity ? identity.userEntityRef.split('/')[1] : 'guest';
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    let response = await this.fetchApi.fetch(
      `${proxyUrl}/accelerators/invoked?name=${name}&username=${username}&type=${type}&source=TAP-GUI&id=${uuid}`,
      { method: 'POST' },
    );
    if (!response.ok && response.status !== 404) {
      throw await ResponseError.fromResponse(response);
    }
    if (response.status === 404) {
      response = await this.fetchApi.fetch(
        `${proxyUrl}/accelerators/downloaded?name=${name}`,
        { method: 'POST' },
      );
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }
    }
  }

  streamLogs(options: ScaffolderStreamLogsOptions): Observable<LogEvent> {
    if (this.useLongPollingLogs) {
      return this.streamLogsPolling(options);
    }

    return this.streamLogsEventStream(options);
  }

  private streamLogsEventStream({
    taskId,
    after,
  }: {
    taskId: string;
    after?: number;
  }): Observable<LogEvent> {
    return new ObservableImpl(subscriber => {
      const params = new URLSearchParams();
      if (after !== undefined) {
        params.set('after', String(Number(after)));
      }

      this.discoveryApi.getBaseUrl('scaffolder').then(
        baseUrl => {
          const url = `${baseUrl}/v2/tasks/${encodeURIComponent(
            taskId,
          )}/eventstream`;
          const eventSource = new EventSource(url, { withCredentials: true });
          eventSource.addEventListener('log', (event: any) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
          });
          eventSource.addEventListener('completion', (event: any) => {
            if (event.data) {
              try {
                subscriber.next(JSON.parse(event.data));
              } catch (ex) {
                subscriber.error(ex);
              }
            }
            eventSource.close();
            subscriber.complete();
          });
          eventSource.addEventListener('error', event => {
            subscriber.error(event);
          });
        },
        error => {
          subscriber.error(error);
        },
      );
    });
  }

  private streamLogsPolling({
    taskId,
    after: inputAfter,
  }: {
    taskId: string;
    after?: number;
  }): Observable<LogEvent> {
    let after = inputAfter;

    return new ObservableImpl(subscriber => {
      this.discoveryApi.getBaseUrl('scaffolder').then(async baseUrl => {
        while (!subscriber.closed) {
          const url = `${baseUrl}/v2/tasks/${encodeURIComponent(
            taskId,
          )}/events?${qs.stringify({ after })}`;
          const response = await this.fetchApi.fetch(url);

          if (!response.ok) {
            // wait for one second to not run into an
            await new Promise(resolve => setTimeout(resolve, 1000));
            continue;
          }

          const logs = (await response.json()) as LogEvent[];

          for (const event of logs) {
            after = Number(event.id);

            subscriber.next(event);

            if (event.type === 'completion') {
              subscriber.complete();
              return;
            }
          }
        }
      });
    });
  }

  async listActions(): Promise<ListActionsResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('scaffolder');
    const { token } = await this.identityApi.getCredentials();
    const response = await this.fetchApi.fetch(`${baseUrl}/v2/actions`, {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
