interface ResourcePathDataAction {
  type: 'Update Resource';
  payload: string;
}

export const resourcePathReducer = (
  state: string,
  action: ResourcePathDataAction,
): string => {
  switch (action.type) {
    /*
      The reason why the race condition reported in TINT-675 is happening is due to
      the `getResource` function being call with just some ms of difference and that avoids the 
      useQueries hook to even fire the first request. In order to avoid that, we are "queuing" the requested
      resources by doing a concat to the current state, but we also want to verify that because react mount and unmount
      the component we're not going to ask for the same resource if we already did (state.includes)
    */
    case 'Update Resource': {
      if (state.includes(action.payload)) {
        return state;
      }
      return state.concat(`,${action.payload}`);
    }
    default:
      return state;
  }
};
