import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'carto.run.v1alpha1.ClusterDeploymentTemplate';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['carto.run/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterDeploymentTemplate'],
    },
    metadata: {
      $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
    },
    spec: {
      properties: {
        observedCompletion: {
          properties: {
            failed: {
              properties: {
                key: {
                  type: 'string',
                },
                value: {
                  type: 'string',
                },
              },
              required: ['key', 'value'],
              type: 'object',
              nullable: true,
            },
            succeeded: {
              properties: {
                key: {
                  type: 'string',
                },
                value: {
                  type: 'string',
                },
              },
              required: ['key', 'value'],
              type: 'object',
            },
          },
          required: ['succeeded'],
          type: 'object',
          nullable: true,
        },
        observedMatches: {
          items: {
            properties: {
              input: {
                type: 'string',
              },
              output: {
                type: 'string',
              },
            },
            required: ['input', 'output'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        params: {
          items: {
            properties: {
              default: {},
              name: {
                type: 'string',
              },
            },
            required: ['default', 'name'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        template: {
          type: 'object',
          properties: {},
          nullable: true,
        },
        ytt: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
    },
  },
  required: ['metadata', 'spec', 'apiVersion', 'kind'],
};

export interface IClusterDeploymentTemplate {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'carto.run/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClusterDeploymentTemplate';
  metadata: IObjectMeta;
  /**
   * Spec describes the deployment template. More info: https://cartographer.sh/docs/latest/reference/template/#clusterdeploymenttemplate
   */
  spec: {
    /**
     * ObservedCompletion describe the criteria for determining that the templated object completed configuration of environment. These criteria assert completion when metadata.Generation and status.ObservedGeneration match, AND success or failure criteria match. Cannot specify both ObservedMatches and ObservedCompletion.
     */
    observedCompletion?: {
      /**
       * FailedCondition, when matched, indicates that the input did not deploy successfully.
       */
      failed?: {
        /**
         * Key is a jsonPath expression pointing to the field to inspect on the templated object, eg: 'status.conditions[?(@.type=="Succeeded")].status'
         */
        key: string;
        /**
         * Value is the expected value that, when matching the key's actual value, makes this condition true.
         */
        value: string;
      };
      /**
       * SucceededCondition, when matched, indicates that the input was successfully deployed.
       */
      succeeded: {
        /**
         * Key is a jsonPath expression pointing to the field to inspect on the templated object, eg: 'status.conditions[?(@.type=="Succeeded")].status'
         */
        key: string;
        /**
         * Value is the expected value that, when matching the key's actual value, makes this condition true.
         */
        value: string;
      };
    };
    /**
     * ObservedMatches describe the criteria for determining that the templated object completed configuration of environment. These criteria assert completion when an output (usually a field in .status) matches an input (usually a field in .spec) Cannot specify both ObservedMatches and ObservedCompletion.
     */
    observedMatches?: Array<{
      /**
       * Input is a jsonPath to a value that is fulfilled before the templated object is reconciled. Usually a value in the .spec of the object
       */
      input: string;
      /**
       * Output is a jsonPath to a value that is fulfilled after the templated object is reconciled. Usually a value in the .status of the object
       */
      output: string;
    }>;
    /**
     * Additional parameters. See: https://cartographer.sh/docs/latest/architecture/#parameter-hierarchy
     */
    params?: Array<{
      /**
       * DefaultValue of the parameter. Causes the parameter to be optional; If the Owner or Template does not specify this parameter, this value is used.
       */
      default: any;
      /**
       * Name of a parameter the template accepts from the Blueprint or Owner.
       */
      name: string;
    }>;
    /**
     * Template defines a resource template for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    template?: {};
    /**
     * Ytt defines a resource template written in `ytt` for a Kubernetes Resource or Custom Resource which is applied to the server each time the blueprint is applied. Templates support simple value interpolation using the $()$ marker format. For more information, see: https://cartographer.sh/docs/latest/templating/ You cannot define both Template and Ytt at the same time. You should not define the namespace for the resource - it will automatically be created in the owner namespace. If the namespace is specified and is not the owner namespace, the resource will fail to be created.
     */
    ytt?: string;
  };
}

export class ClusterDeploymentTemplate
  extends Model<IClusterDeploymentTemplate>
  implements IClusterDeploymentTemplate
{
  'apiVersion': IClusterDeploymentTemplate['apiVersion'];
  'kind': IClusterDeploymentTemplate['kind'];
  'metadata': IClusterDeploymentTemplate['metadata'];
  'spec': IClusterDeploymentTemplate['spec'];

  static apiVersion: IClusterDeploymentTemplate['apiVersion'] =
    'carto.run/v1alpha1';
  static kind: IClusterDeploymentTemplate['kind'] = 'ClusterDeploymentTemplate';
  static is = createTypeMetaGuard<IClusterDeploymentTemplate>(
    ClusterDeploymentTemplate,
  );

  constructor(data?: ModelData<IClusterDeploymentTemplate>) {
    super({
      apiVersion: ClusterDeploymentTemplate.apiVersion,
      kind: ClusterDeploymentTemplate.kind,
      ...data,
    } as IClusterDeploymentTemplate);
  }
}

setSchema(ClusterDeploymentTemplate, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
