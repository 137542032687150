import { ICluster } from '../../proxy/hook/types';
import { StatusCodes } from 'http-status-codes';
import { emptyProxyResponse } from '../../proxy/hook/emptyProxyResponse';
import { NotFoundProxyResponse } from '../../types';
import { networkErrorTypes } from '../networkErrorTypes';

export const formatNotFoundProxyResponseWithText = (
  cluster: ICluster,
  resourcePath: string,
  errorText: string,
): NotFoundProxyResponse => {
  return {
    ...emptyProxyResponse,
    cluster: cluster.name,
    status: StatusCodes.NOT_FOUND.toString(),
    message: `path ${resourcePath}: ${errorText}`,
    code: StatusCodes.NOT_FOUND,
    reason: errorText,
    error: {
      message: `path ${resourcePath}: ${errorText}`,
      status: StatusCodes.NOT_FOUND.toString(),
      statusCode: StatusCodes.NOT_FOUND,
      errorType: networkErrorTypes.NotFound,
      resourcePath: resourcePath,
    },
  };
};
