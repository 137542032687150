import { useQuery } from '@tanstack/react-query';
import { fetchUcpSpaces } from '../../apis/fetchUcpSpaces';
import type { KubernetesLocalProxyApi } from '../../apis';
import type { Space } from '../../model/spaces';
import type { Project } from '../../model/ucp';
import type { ICluster } from '../../proxy/hook/types';

interface SpaceResponse {
  isLoadingSpaces: boolean;
  spaces: Space[];
}

interface Props {
  kubernetesLocalProxyApi: KubernetesLocalProxyApi;
  clusters: ICluster[];
  ucpProjects: Project[];
}

export const useUcpSpaces = ({
  kubernetesLocalProxyApi,
  clusters,
  ucpProjects,
}: Props): SpaceResponse => {
  const ucpCluster = clusters.find(c => c.authProvider === 'tae') ?? {
    name: 'no-ucp',
    authProvider: 'serviceAccount',
  };

  const projects = ucpProjects.map(p => p.resource.metadata?.name ?? '');

  const { data, isLoading } = useQuery({
    queryKey: [
      `cluster-projects-spaces`,
      projects,
      kubernetesLocalProxyApi,
      ucpCluster,
    ],
    queryFn: async () =>
      fetchUcpSpaces(kubernetesLocalProxyApi, ucpCluster, projects),
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isLoading) {
    return {
      isLoadingSpaces: true,
      spaces: [],
    };
  }

  if (!data || data.length === 0) {
    return {
      isLoadingSpaces: false,
      spaces: [],
    };
  }

  return {
    isLoadingSpaces: false,
    spaces: data,
  };
};
