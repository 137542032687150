import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  StandardProps,
  StyleRulesCallback,
  Theme,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { BackstageTheme } from '@backstage/theme';
import { WithStylesOptions } from '@material-ui/core/styles/withStyles';
import {
  SidebarOpenStateProvider,
  SidebarPinStateProvider,
} from '@backstage/core-components';
import { LocalStorage, SidebarState } from './localStorage';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export type SidebarPageClassKey = 'root';

export interface SidebarPageProps
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    SidebarPageClassKey
  > {}

const styles: StyleRulesCallback<
  BackstageTheme,
  SidebarPageProps,
  SidebarPageClassKey
> = (_: BackstageTheme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
});

const SidebarPage = (props: PropsWithChildren<SidebarPageProps>) => {
  const [sidebarState, setSidebarState] = useState(() =>
    LocalStorage.getSidebarState(),
  );

  const [isOpen, setOpen] = useState(false);

  const isMobile = useMediaQuery<BackstageTheme>(theme =>
    theme.breakpoints.down('md'),
  );

  const toggleSidebarState = () =>
    setSidebarState(
      sidebarState === SidebarState.Opened
        ? SidebarState.Closed
        : SidebarState.Opened,
    );

  useEffect(() => {
    LocalStorage.setSidebarState(sidebarState);
    if (sidebarState === SidebarState.Opened) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [sidebarState]);

  return (
    <SidebarPinStateProvider
      value={{
        isPinned: isOpen,
        toggleSidebarPinState: toggleSidebarState,
        isMobile: isMobile,
      }}
    >
      <SidebarOpenStateProvider
        value={{
          isOpen: isOpen,
          setOpen: setOpen,
        }}
      >
        <div className={clsx(props.classes?.root)}>{props.children}</div>
      </SidebarOpenStateProvider>
    </SidebarPinStateProvider>
  );
};

export const ClaritySidebarPage = withStyles<
  SidebarPageClassKey,
  WithStylesOptions<BackstageTheme>,
  SidebarPageProps
>(styles as StyleRulesCallback<Theme, SidebarPageProps, SidebarPageClassKey>, {
  name: 'ClaritySidebarPage',
})(SidebarPage);
