import moment from 'moment';
import { resourcePathReducer } from './resourcePathReducer';
import { useGetClusters } from './useGetClusters';
import { useGetRepository } from './useGetRepository';
import { useKubernetesData } from './useKubernetesData';
import type { KubernetesLocalProxyApi } from '../../apis';
import type { KubernetesApi } from '@backstage/plugin-kubernetes';
import { useUcpProjects, useUcpSpaces } from '../../hook';
import { IContextProxy } from '../../types';
import { useReducer } from 'react';

export interface UseRepositoryProxyContextProps {
  kubernetesApi: KubernetesApi;
  kubernetesLocalProxyApi: KubernetesLocalProxyApi;
}

export const useRepositoryProxyContext = ({
  kubernetesApi,
  kubernetesLocalProxyApi,
}: UseRepositoryProxyContextProps): IContextProxy => {
  const [resourcePath, dispatch] = useReducer(resourcePathReducer, '');

  const clusters = useGetClusters({ kubernetesApi }).clusters;
  const availableClusters = clusters.map(c => c.name);

  const { isLoading, data, refresh, lastRefreshed } = useKubernetesData({
    kubernetesLocalProxyApi,
    resourcePath,
    project: '',
    space: '',
    clusters,
  });

  const repository = useGetRepository({
    isLoading,
    data,
    refresh,
    lastRefreshed,
  });
  const errors = repository.errors;

  const { isLoadingProjects, projects: ucpProjects } = useUcpProjects({
    clusters,
    kubernetesLocalProxyApi,
  });

  const { isLoadingSpaces, spaces: ucpSpaces } = useUcpSpaces({
    clusters,
    kubernetesLocalProxyApi,
    ucpProjects,
  });

  const isLoadingSpacesData = isLoadingProjects || isLoadingSpaces;

  const getResource = (resource: string): void => {
    dispatch({ type: 'Update Resource', payload: resource });
  };

  return {
    repository,
    errors,
    isLoading,
    isLoadingSpacesData,
    getResource,
    ucpProjects,
    ucpSpaces,
    refresh,
    lastRefreshedTime: moment(lastRefreshed ?? new Date()),
    availableClusters,
  };
};
