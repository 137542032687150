import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'ucp.tanzu.vmware.com.v1.Project';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['ucp.tanzu.vmware.com/v1'],
    },
    kind: {
      type: 'string',
      enum: ['Project'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        profiles: {
          items: {
            properties: {
              name: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        projectId: {
          type: 'string',
          nullable: true,
        },
        traits: {
          items: {
            properties: {
              name: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              message: {
                type: 'string',
                nullable: true,
              },
              type: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IProject {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'ucp.tanzu.vmware.com/v1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'Project';
  metadata?: IObjectMeta;
  spec?: {
    profiles?: Array<{
      name?: string;
    }>;
    projectId?: string;
    traits?: Array<{
      name?: string;
    }>;
  };
  status?: {
    conditions?: Array<{
      message?: string;
      type?: string;
    }>;
  };
}

export class Project extends Model<IProject> implements IProject {
  'apiVersion': IProject['apiVersion'];
  'kind': IProject['kind'];
  'metadata'?: IProject['metadata'];
  'spec'?: IProject['spec'];
  'status'?: IProject['status'];

  static apiVersion: IProject['apiVersion'] = 'ucp.tanzu.vmware.com/v1';
  static kind: IProject['kind'] = 'Project';
  static is = createTypeMetaGuard<IProject>(Project);

  constructor(data?: ModelData<IProject>) {
    super({
      apiVersion: Project.apiVersion,
      kind: Project.kind,
      ...data,
    } as IProject);
  }
}

setSchema(Project, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
