import { ClusterInstanceClass as Model } from '../../kubernetes-additional-crds/gen/services.apps.tanzu.vmware.com/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterInstanceClass } from '../../kubernetes-additional-crds/gen/services.apps.tanzu.vmware.com/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterInstanceClass extends AbstractModel<IClusterInstanceClass> {
  public static readonly humanType = 'Services Cluster Instance Class';

  public static readonly typeMeta = Model;

  public classRef = ClusterInstanceClass;

  public constructor(
    resource: IClusterInstanceClass,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
