import { cloneDeep } from 'lodash';
import { ProxyResponse } from '../../types';
import { RepositoryProxy } from '../repository';

interface ProxyDataAction {
  type: 'Update Repository';
  payload: ProxyResponse[];
}

export const repositoryReducer = (
  data: RepositoryProxy,
  action: ProxyDataAction,
): RepositoryProxy => {
  switch (action.type) {
    case 'Update Repository': {
      data.updateRepository(action.payload);
      return cloneDeep(data);
    }
    default:
      return data;
  }
};
