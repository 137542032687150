import {
  Workload,
  Deliverable,
  ClusterSupplyChain,
  ClusterDelivery,
} from '../model/carto';
import { App } from '../model/kappController';
import { Configuration, Revision, Route, Service } from '../model/knative';
import {
  ConfigMap,
  Deployment,
  Pod,
  ReplicaSet,
  Service as KubernetesService,
  Job,
  CronJob,
  StatefulSet,
  DaemonSet,
  Namespace,
} from '../model/kubernetes';
import type { IModel, IModelClass } from '../model';

export interface IAncestry<T> {
  root: Node<T> | undefined;
  leaf: Node<T> | undefined;

  Add: (val: T) => Node<T>;
}

export class Node<T> {
  public obj: T;

  public readonly visible: boolean;

  public parent: Node<T> | undefined;

  public child: Node<T> | undefined;

  public constructor(obj: T, allowlist: IModelClass[]) {
    this.obj = obj;
    this.visible = allowlist.some(
      item => item === (obj as unknown as IModel).classRef,
    );
  }
}

export abstract class TreeBuilder implements IAncestry<IModel> {
  public static allowlist: IModelClass[];

  public root: Node<IModel> | undefined;

  public leaf: Node<IModel> | undefined;

  protected addNode(value: IModel, allowlist: IModelClass[]): Node<IModel> {
    const node = new Node<IModel>(value, allowlist);

    if (!this.root) {
      this.root = node;
    }
    if (this.leaf) {
      this.leaf.child = node;
      node.parent = this.leaf;
    }
    this.leaf = node;

    return node;
  }

  public abstract Add(value: IModel): Node<IModel>;
}

export class IndexTreeBuilder extends TreeBuilder {
  public static readonly allowlist: IModelClass[] = [
    Deployment,
    Pod,
    ReplicaSet,
    StatefulSet,
    DaemonSet,
    Service,
    KubernetesService,
    Workload,
    CronJob,
    Job,
    Namespace,
  ];

  public Add(value: IModel): Node<IModel> {
    return this.addNode(value, IndexTreeBuilder.allowlist);
  }
}

export class OwnershipTreeBuilder extends TreeBuilder {
  public static readonly allowlist: IModelClass[] = [
    Deployment,
    Pod,
    ReplicaSet,
    StatefulSet,
    DaemonSet,
    Route,
    KubernetesService,
    Revision,
    Service,
    Configuration,
    ConfigMap,
    Workload,
    Deliverable,
    App,
    ClusterSupplyChain,
    ClusterDelivery,
    Job,
    CronJob,
  ];

  public Add(value: IModel): Node<IModel> {
    return this.addNode(value, OwnershipTreeBuilder.allowlist);
  }
}

const recursion = (workloadsTree: TreeBuilder, model: IModel): TreeBuilder => {
  if (model.owners.length !== 0) {
    recursion(workloadsTree, model.owners[0]);
    // eslint-disable-next-line new-cap
    workloadsTree.Add(model.owners[0]);
  }
  return workloadsTree;
};

export const Ancestry = (model: IModel, builder: TreeBuilder): TreeBuilder => {
  const result: TreeBuilder = recursion(builder, model);
  // eslint-disable-next-line new-cap
  result.Add(model);
  return result;
};
