import { StatefulSet as Model } from 'kubernetes-models/apps/v1';
import { Pod } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import { ConditionCategory } from '../Condition';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList, Condition } from '../Condition';
import type {
  IStatefulSet,
  IStatefulSetStatus,
  IStatefulSetSpec,
} from 'kubernetes-models/apps/v1';
import type { IPod } from 'kubernetes-models/v1';

export class StatefulSet extends AbstractModel<IStatefulSet> {
  public static readonly humanType = 'StatefulSet';

  public static readonly typeMeta = Model;

  public classRef = StatefulSet;

  public constructor(
    resource: IStatefulSet,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    const { status, spec } = this.resource;

    const numberOfReplicas = this.getNumberOfReplicasCondition(status, spec);
    const waitingPods = this.getWaitingPodsCondition();
    const succeededPods = this.getSucceededPodsCondition();
    const failedPods = this.getFailedPodsCondition();
    const runningPods = this.getRunningPodsCondition(spec);

    const otherConditions = [
      waitingPods,
      succeededPods,
      failedPods,
      runningPods,
    ];

    return {
      primary: numberOfReplicas,
      other: otherConditions,
    };
  }

  public get pods(): MappedResource<IPod>[] {
    return this.repository.ownedOfType<IPod>(this, Pod);
  }

  private getNumberOfReplicasCondition(
    status: IStatefulSetStatus | undefined,
    spec: IStatefulSetSpec | undefined,
  ): Condition {
    const _category = (): ConditionCategory => {
      if (spec?.replicas === 0) return ConditionCategory.warning;
      if (status?.currentReplicas === spec?.replicas)
        return ConditionCategory.healthy;
      return ConditionCategory.error;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${status?.currentReplicas ?? 0} / ${spec?.replicas ?? 0}`,
      type: 'numberOfReplicas',
      title: 'Replicas Current / Desired',
    };

    return condition;
  }

  private getWaitingPodsCondition(): Condition {
    const pendingPodsCount = this.pods.filter(
      pod =>
        pod.resource.status?.phase === 'Pending' ||
        pod.resource.status?.phase === 'Unknown',
    ).length;
    const condition = {
      category:
        pendingPodsCount === 0
          ? ConditionCategory.healthy
          : ConditionCategory.warning,
      lastTransitionTime: undefined,
      status: `${pendingPodsCount}`,
      type: 'waitingPods',
      title: 'Waiting Pods',
    };
    return condition;
  }

  private getSucceededPodsCondition(): Condition {
    const succeededPodsCount = this.pods.filter(
      pod => pod.resource.status?.phase === 'Succeeded',
    ).length;
    const condition = {
      category:
        succeededPodsCount > 0
          ? ConditionCategory.healthy
          : ConditionCategory.warning,
      lastTransitionTime: undefined,
      status: `${succeededPodsCount}`,
      type: 'succeededPods',
      title: 'Succeeded Pods',
    };
    return condition;
  }

  private getFailedPodsCondition(): Condition {
    const failedPodsCount = this.pods.filter(
      pod => pod.resource.status?.phase === 'Failed',
    ).length;
    const condition = {
      category:
        failedPodsCount === 0
          ? ConditionCategory.healthy
          : ConditionCategory.error,
      lastTransitionTime: undefined,
      status: `${failedPodsCount}`,
      type: 'failedPods',
      title: 'Failed Pods',
    };
    return condition;
  }

  private getRunningPodsCondition(
    spec: IStatefulSetSpec | undefined,
  ): Condition {
    const runningPodsCount = this.pods.filter(
      pod => pod.resource.status?.phase === 'Running',
    ).length;

    const _category = (): ConditionCategory => {
      if (runningPodsCount === 0) return ConditionCategory.error;
      if (runningPodsCount === spec?.replicas) return ConditionCategory.healthy;
      return ConditionCategory.warning;
    };

    const condition = {
      category: _category(),
      lastTransitionTime: undefined,
      status: `${runningPodsCount}`,
      type: 'runningPods',
      title: 'Running Pods',
    };

    return condition;
  }
}
