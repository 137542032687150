import { ConfigMap as Model } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { IConfigMap } from 'kubernetes-models/v1';

export class ConfigMap extends AbstractModel<IConfigMap> {
  public static readonly humanType = 'Config Map';

  public static readonly typeMeta = Model;

  public classRef = ConfigMap;

  public constructor(
    resource: IConfigMap,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
