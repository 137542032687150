import { ClusterRunTemplate as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterRunTemplate } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterRunTemplate extends AbstractModel<IClusterRunTemplate> {
  public static readonly humanType = 'Cartographer Cluster Run Template';

  public static readonly typeMeta = Model;

  public classRef = ClusterRunTemplate;

  public constructor(
    resource: IClusterRunTemplate,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
