import { compact, sum } from 'lodash';
import {
  Deliverable,
  Workload as Model,
} from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import { conditionsFromResource, prioritizedConditions } from '../Condition';
import { Service } from '../knative';
import { Deployment, Service as K8sService } from '../kubernetes';
import type { IModel } from '..';
import type { MappedResource } from '../../ClassMap';
import type {
  IDeliverable,
  IWorkload,
} from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';
import type { ConditionList } from '../Condition';
import type { Pod } from '../kubernetes';

export class Workload extends AbstractModel<IWorkload> {
  public static readonly humanType = 'Cartographer Workload';

  public static readonly typeMeta = Model;

  public classRef = Workload;

  public constructor(
    resource: IWorkload,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList | undefined {
    return prioritizedConditions(conditionsFromResource(this.resource));
  }

  public get deliverable(): MappedResource<IDeliverable>[] {
    return this.repository
      .ofType<Deliverable>(Deliverable)
      .filter(
        deliverable =>
          deliverable.resource.metadata.labels?.['carto.run/workload-name'] ===
          this.resource.metadata.name,
      );
  }

  public get desiredPodsQty(): number {
    return sum(
      this.deliverable
        .flatMap(d => d.app?.topResource)
        .flatMap(tR => {
          if (tR instanceof Service) {
            return tR.desiredPodsQty;
          }

          if (tR instanceof Deployment) {
            return tR.desiredPodsQty;
          }

          if (tR instanceof K8sService) {
            return tR.desiredPodsQty;
          }
          return 0;
        }),
    );
  }

  public get pods(): Pod[] {
    const topResources = this.deliverable.flatMap(d => d.app?.topResource);
    const pods = topResources.flatMap(tR => {
      if (tR instanceof Service) {
        return tR.pods;
      } else if (tR instanceof Deployment) {
        return tR.pods;
      } else if (tR instanceof K8sService) {
        return tR.pods;
      }
      return [];
    });
    return pods;
  }

  public get topResources(): IModel[] {
    return compact(this.deliverable.map(d => d.app?.topResource));
  }

  public get name(): string | undefined {
    return this.resource.metadata.name;
  }

  public get namespace(): string | undefined {
    return this.resource.metadata.namespace;
  }

  public get supplyChainName(): string | undefined {
    return this.resource.status?.supplyChainRef?.name;
  }
}
