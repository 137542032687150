import type { RepositoryProxyError } from '../proxy';
import type { MappedTypeMeta, MappedResource } from '../ClassMap';
import type { Guid, IModel } from '../model';
import type { FetchResponse } from '@backstage/plugin-kubernetes-common';
import type { TypeMeta } from '@kubernetes-models/base';
import type { ICustomResourceDefinition } from 'kubernetes-models/apiextensions.k8s.io/v1';
import type {
  INamespace,
  IEvent,
  IPodTemplate,
  ISecret,
  IServiceAccount,
  IPersistentVolume,
} from 'kubernetes-models/v1';
import type { Project } from '../model/ucp';
import type { Space } from '../model/spaces';
import moment from 'moment/moment';

interface NamespaceFetchResponse {
  type: 'namespaces';
  resources: INamespace[];
}

interface EventFetchResponse {
  type: 'events';
  resources: IEvent[];
}

interface PodTemplateFetchResponse {
  type: 'podtemplates';
  resources: IPodTemplate[];
}

interface SecretFetchResponse {
  type: 'secrets';
  resources: ISecret[];
}

interface ServiceAccountFetchResponse {
  type: 'serviceaccounts';
  resources: IServiceAccount[];
}

interface PersistentVolumeFetchResponse {
  type: 'persistentvolumes';
  resources: IPersistentVolume[];
}

interface CustomResourceDefinitionResponse {
  type: 'customresourcedefinition';
  resources: ICustomResourceDefinition[];
}

type FetchResponseLocal =
  | CustomResourceDefinitionResponse
  | EventFetchResponse
  | FetchResponse
  | NamespaceFetchResponse
  | PersistentVolumeFetchResponse
  | PodTemplateFetchResponse
  | SecretFetchResponse
  | ServiceAccountFetchResponse;

export interface ProxyResponse {
  type: FetchResponseLocal['type'];
  apiVersion: string;
  items: FetchResponseLocal['resources'];
  kind: string;
  metadata: {
    continue: string;
    resourceVersion: string;
  };
  cluster: string;
  error?: ProxyException;
}

export interface ErrorProxyResponse extends ProxyResponse {
  error: ProxyException;
  request?: {
    method: string;
    url: string;
  };
  response?: {
    statusCode: number;
  };
}

export interface NotFoundProxyResponse extends ProxyResponse {
  kind: string;
  status?: string;
  message?: string;
  code?: number;
  reason?: string;
}

export interface ProxyException {
  message: string;
  status: string;
  errorType: string;
  statusCode?: number;
  resourcePath?: string;
  reason?: string;
}

export interface FetchingResult {
  isLoading: boolean;
  data: ProxyResponse[];
  refresh?: VoidFunction;
  lastRefreshed?: Date;
}

export interface IRepositoryProxy {
  readonly errors: RepositoryProxyError[];
  readonly resourceCount: number;

  byID: <T extends TypeMeta>(
    uid: Guid,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T> | undefined;
  ofType: <T extends TypeMeta>(type: MappedTypeMeta<T>) => MappedResource<T>[];
  resourcesOfNameKindVersion: (
    name: string,
    kind: string,
    version: string,
  ) => IModel[];
  updateRepository: (response: ProxyResponse[]) => void;
  ownedOfType: <T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T>[];
  ownerOfType: <T extends TypeMeta>(
    owned: IModel,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T> | undefined;
  firstOwnedOfType: <T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ) => MappedResource<T> | undefined;
}

export interface IContextProxy {
  repository: IRepositoryProxy;
  isLoading: boolean;
  isLoadingSpacesData: boolean;
  errors: RepositoryProxyError[];
  getResource: (resource: string) => void;
  ucpProjects: Project[];
  ucpSpaces: Space[];
  refresh?: VoidFunction;
  lastRefreshedTime?: moment.Moment;
  availableClusters: string[];
}

export const v1ResponseTypes = [
  'pods',
  'services',
  'configmaps',
  'limitranges',
  'namespaces',
  'events',
  'podtemplates',
  'secrets',
  'serviceaccounts',
  'persistentvolumes',
];

export const responseTypes = [
  'deployments',
  'replicasets',
  'horizontalpodautoscalers',
  'jobs',
  'cronjobs',
  'ingresses',
  'customresources',
  'statefulsets',
  'daemonsets',
  'podstatus',
  'customresourcedefinition',
];
