import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { DoraApiClient, doraApiRef } from './api';
import { rootRouteRef } from './routes';

export const tapDoraPlugin = createPlugin({
  id: 'tap-dora',
  apis: [
    createApiFactory({
      api: doraApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new DoraApiClient(discoveryApi, fetchApi),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const TapDoraPage = tapDoraPlugin.provide(
  createRoutableExtension({
    name: 'TapDoraPage',
    component: () =>
      import('./components/DORAMetricsPage').then(m => m.DORAMetricsPage),
    mountPoint: rootRouteRef,
  }),
);
