import { StatusCodes } from 'http-status-codes';
import { Project as RawProject } from '../kubernetes-additional-crds/gen/ucp.tanzu.vmware.com/v1';
import { Project } from '../model/ucp';
import { Repository } from '../repository';
import type { KubernetesLocalProxyApi } from './KubernetesLocalProxyApi';
import type { ICluster } from '../proxy/hook/types';
import { ProxyResponse } from '../types';

export async function fetchUcpProjects(
  kubernetesLocalProxyApi: KubernetesLocalProxyApi,
  cluster: ICluster,
): Promise<Project[]> {
  const path = `/apis/ucp.tanzu.vmware.com/v1/projects/`;

  if (cluster.name === 'no-ucp') {
    return [];
  }

  try {
    const response = await kubernetesLocalProxyApi.proxy({ cluster, path });
    return await normalizeResponse(response, cluster);
  } catch (e: unknown) {
    return [];
  }
}

async function normalizeResponse(
  response: Response,
  cluster: ICluster,
): Promise<Project[]> {
  const projects: Project[] = [];
  const emptyRepository = new Repository({ items: [] });
  if ((response.status as StatusCodes) !== StatusCodes.OK) {
    return projects;
  }

  const data = (await response.json()) as ProxyResponse;
  if (!('items' in data)) {
    return projects;
  }

  for (const rawProject of data.items as RawProject[]) {
    // Disabled intentionally, thinking that we could get a response from a different Kind than Project
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (rawProject.kind !== RawProject.kind) {
      break;
    }
    const project = new Project(rawProject, emptyRepository, cluster.name);
    projects.push(project);
  }

  return projects;
}
