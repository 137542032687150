import { createContext } from 'react';
import { Repository } from '../repository';
import type { IRepository } from '../repository';
import type { RepositoryError } from '../repository';
import type { LoadingStatus } from '../hook';
import type moment from 'moment';

export interface IContext {
  repository: IRepository;
  error?: RepositoryError;
  status: LoadingStatus;
  refresh: VoidFunction;
  lastRefreshedTime?: moment.Moment;
}

const placeholderRepository = new Repository({ items: [] });

export const Context = createContext<IContext>({
  repository: placeholderRepository,
  status: 'Initial',
  error: undefined,
  refresh: () => {},
  lastRefreshedTime: undefined,
});
