import { ServerlessService } from '@kubernetes-models/knative/networking.internal.knative.dev/v1alpha1';
import { Route } from '@kubernetes-models/knative/serving.knative.dev/v1';
import { Service as Model } from 'kubernetes-models/v1';
import { sum } from 'lodash';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import { conditionsFromResource, prioritizedConditions } from '../Condition';
import type { Pod } from './Pod';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { ConditionList } from '../Condition';
import type { IServerlessService } from '@kubernetes-models/knative/networking.internal.knative.dev/v1alpha1';
import type { IRoute } from '@kubernetes-models/knative/serving.knative.dev/v1';
import type { IService } from 'kubernetes-models/v1';

export class Service extends AbstractModel<IService> {
  public static readonly humanType = 'Kubernetes Service';

  public static readonly typeMeta = Model;

  public classRef = Service;

  public constructor(
    resource: IService,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get conditions(): ConditionList {
    return prioritizedConditions(conditionsFromResource(this.resource));
  }

  public get owners(): (
    | MappedResource<IRoute>
    | MappedResource<IServerlessService>
  )[] {
    // TODO: TAD-295 - Investigate if a Knative route could own a Kubernetes Service.
    // If it is not a realistic scenario, remove knativeRoute from owners below.
    return compact([this.serverlessService, this.route]);
  }

  public get serverlessService():
    | MappedResource<IServerlessService>
    | undefined {
    return this.repository.ownerOfType<IServerlessService>(
      this,
      ServerlessService,
    );
  }

  public get route(): MappedResource<IRoute> | undefined {
    return this.repository.ownerOfType<IRoute>(this, Route);
  }

  public get desiredPodsQty(): number {
    return sum(
      this.route?.knativeService?.configuration?.revisions
        .flatMap(r => r.deployments)
        .map(d => d.resource.spec?.replicas),
    );
  }

  public get pods(): Pod[] {
    return (
      this.route?.knativeService?.configuration?.revisions
        .flatMap(r => r.deployments)
        .flatMap(d => d.replicaSets)
        .flatMap(rs => rs.pods) ?? []
    );
  }
}
