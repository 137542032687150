import { merge, values } from 'lodash';
import { ModelFactoryProxy } from './ModelFactoryProxy';
import type { RepositoryProxyError } from './RepositoryProxyError';
import type { IRepositoryProxy, ProxyResponse } from '../../types';
import type { MappedResource, MappedTypeMeta, ObjectMap } from '../../ClassMap';
import type { Guid, IModel } from '../../model';
import type { IOwnerReference } from '@kubernetes-models/apimachinery/apis/meta/v1';
import type { TypeMeta } from '@kubernetes-models/base';

export class RepositoryProxy implements IRepositoryProxy {
  public errors: RepositoryProxyError[];

  public resourceCount: number;

  private objectMap: ObjectMap;

  public constructor(response: ProxyResponse[]) {
    const { objectMap, errors } = new ModelFactoryProxy(this).build(response);

    this.errors = errors;
    this.objectMap = objectMap;
    this.resourceCount = this.calculateResourceCount();
  }

  public updateRepository(response: ProxyResponse[]): void {
    const { objectMap: newOM, errors: newErrs } = new ModelFactoryProxy(
      this,
    ).build(response);
    this.objectMap = merge(this.objectMap, newOM);
    this.errors = newErrs;
    this.resourceCount = this.calculateResourceCount();
  }

  public byID<T extends TypeMeta>(
    uid: Guid,
    type: MappedTypeMeta<T>,
  ): MappedResource<T> | undefined {
    return this.mapOfType(type)[uid];
  }

  public firstOwnedOfType<T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ): MappedResource<T> | undefined {
    return this.ownedOfType(owner, type)[0];
  }

  public ofType<T extends TypeMeta>(
    type: MappedTypeMeta<T>,
  ): MappedResource<T>[] {
    return values(this.mapOfType(type));
  }

  public ownedOfType<T extends TypeMeta>(
    owner: IModel,
    type: MappedTypeMeta<T>,
  ): MappedResource<T>[] {
    return this.ofType(type).filter(
      resource =>
        this.ownerRefFromResource(
          resource as IModel,
          owner.id,
          owner.classRef.typeMeta,
        ) !== undefined,
    );
  }

  public ownerOfType<T extends TypeMeta>(
    owned: IModel,
    type: MappedTypeMeta<T>,
  ): MappedResource<T> | undefined {
    return this.ofType(type).find(
      resource =>
        this.ownerRefFromResource(
          owned,
          (resource as IModel).id,
          (resource as IModel).classRef.typeMeta,
        ) !== undefined,
    );
  }

  public resourcesOfNameKindVersion(
    name: string,
    kind: string,
    version: string,
  ): IModel[] {
    const type = { apiVersion: version, kind };
    return this.ofType(type).filter(mappedResource => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return name === mappedResource.resource.metadata?.name;
    });
  }

  private ownerRefFromResource(
    resource: IModel,
    ownerUid: Guid,
    ownerType: TypeMeta,
  ): IOwnerReference | undefined {
    return resource.resource.metadata?.ownerReferences?.find(
      (ownerRef: IOwnerReference) =>
        ownerRef.apiVersion === ownerType.apiVersion &&
        ownerRef.kind === ownerType.kind &&
        ownerRef.uid === ownerUid,
    );
  }

  private mapOfType<T extends TypeMeta>(
    type: MappedTypeMeta<T>,
  ): Record<Guid, MappedResource<T>> {
    if (!(type.apiVersion in this.objectMap)) {
      return {};
    }

    const apiVersion = this.objectMap[type.apiVersion as keyof ObjectMap];
    if (apiVersion === undefined || !(type.kind in apiVersion)) {
      return {};
    }

    return apiVersion[type.kind as keyof typeof apiVersion];
  }

  private calculateResourceCount(): number {
    return values(this.objectMap)
      .flatMap(kind => values(kind))
      .reduce((cnt, resources) => cnt + values(resources).length, 0);
  }
}
