/* eslint-disable @typescript-eslint/no-magic-numbers */
import { MetricType } from './IMetric';
import type { IMetric } from './IMetric';
import type { IQuantity } from '@kubernetes-models/apimachinery/api/resource';

const MILLICPU_IN_CPU = 1000;

export class CPUMetric implements IMetric<CPUMetric> {
  public readonly value: IMetric<CPUMetric>['value'];

  public constructor(n: IQuantity | undefined) {
    this.value = this.parseValue(n);
  }

  public format(precision = 0): string {
    if (this.value === MetricType.Invalid) return 'NOT FOUND';
    if (this.value === MetricType.Unlimited) return 'Unlimited';

    const value = this.value * MILLICPU_IN_CPU;

    return `${value.toFixed(value < 1 ? precision : 0)}m`;
  }

  public add(n: CPUMetric): CPUMetric {
    if (this.value === MetricType.Invalid || n.value === MetricType.Invalid) {
      return new CPUMetric(MetricType.Invalid);
    }
    if (
      this.value === MetricType.Unlimited ||
      n.value === MetricType.Unlimited
    ) {
      return new CPUMetric(MetricType.Unlimited);
    }

    return new CPUMetric(this.value + n.value);
  }

  private parseValue(
    n: number | string | undefined,
  ): IMetric<CPUMetric>['value'] {
    if (n === undefined) {
      return MetricType.Invalid;
    }
    // eslint-disable-next-line
    if (
      typeof n === 'number' ||
      n === MetricType.Invalid ||
      n === MetricType.Unlimited
    ) {
      return n;
    }

    const regex = /(?<magnitude>\d+)(?<unit>m)/u;
    const matches = regex.exec(n);
    if (matches === null) {
      return MetricType.Invalid;
    }

    const magnitude = matches.groups?.magnitude;
    if (magnitude === undefined) {
      return MetricType.Invalid;
    }
    return this.miliCPUToCPU(Number(magnitude));
  }

  private miliCPUToCPU(amount: number): number {
    return amount / MILLICPU_IN_CPU;
  }
}
