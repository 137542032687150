import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'kpack.io.v1alpha2.ClusterStore';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['kpack.io/v1alpha2'],
    },
    kind: {
      type: 'string',
      enum: ['ClusterStore'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IClusterStore {
  apiVersion: 'kpack.io/v1alpha2';
  kind: 'ClusterStore';
  metadata?: IObjectMeta;
}

export class ClusterStore
  extends Model<IClusterStore>
  implements IClusterStore
{
  'apiVersion': IClusterStore['apiVersion'];
  'kind': IClusterStore['kind'];
  'metadata'?: IClusterStore['metadata'];

  static apiVersion: IClusterStore['apiVersion'] = 'kpack.io/v1alpha2';
  static kind: IClusterStore['kind'] = 'ClusterStore';
  static is = createTypeMetaGuard<IClusterStore>(ClusterStore);

  constructor(data?: ModelData<IClusterStore>) {
    super({
      apiVersion: ClusterStore.apiVersion,
      kind: ClusterStore.kind,
      ...data,
    } as IClusterStore);
  }
}

setSchema(ClusterStore, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
