import { ClusterConfigTemplate as Model } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClusterConfigTemplate } from '../../kubernetes-additional-crds/gen/carto.run/v1alpha1';
import type { IRepository } from '../../repository';

export class ClusterConfigTemplate extends AbstractModel<IClusterConfigTemplate> {
  public static readonly humanType = 'Cartographer Cluster Config Template';

  public static readonly typeMeta = Model;

  public classRef = ClusterConfigTemplate;

  public constructor(
    resource: IClusterConfigTemplate,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
