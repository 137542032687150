import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'kpack.io.v1alpha2.Build';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['kpack.io/v1alpha2'],
    },
    kind: {
      type: 'string',
      enum: ['Build'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IBuild {
  apiVersion: 'kpack.io/v1alpha2';
  kind: 'Build';
  metadata?: IObjectMeta;
}

export class Build extends Model<IBuild> implements IBuild {
  'apiVersion': IBuild['apiVersion'];
  'kind': IBuild['kind'];
  'metadata'?: IBuild['metadata'];

  static apiVersion: IBuild['apiVersion'] = 'kpack.io/v1alpha2';
  static kind: IBuild['kind'] = 'Build';
  static is = createTypeMetaGuard<IBuild>(Build);

  constructor(data?: ModelData<IBuild>) {
    super({
      apiVersion: Build.apiVersion,
      kind: Build.kind,
      ...data,
    } as IBuild);
  }
}

setSchema(Build, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
