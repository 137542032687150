import { get } from 'lodash';
import { Repository } from './Repository';
import type { Entity } from '@backstage/catalog-model';
import type {
  KubernetesApi,
  KubernetesAuthProvidersApi,
} from '@backstage/plugin-kubernetes';
import type { KubernetesRequestBody } from '@backstage/plugin-kubernetes-common';

export class RepositoryFactory {
  /* eslint-disable @typescript-eslint/parameter-properties */
  public constructor(
    private readonly kubernetesApi: KubernetesApi,
    private readonly kubernetesAuthProvidersApi: KubernetesAuthProvidersApi,
    private readonly entity: Entity,
  ) {}
  /* eslint-enable @typescript-eslint/parameter-properties */

  public async build(): Promise<Repository> {
    return new Repository(
      await this.kubernetesApi.getObjectsByEntity(await this.requestBody()),
      get(
        this.entity.metadata.annotations,
        'backstage.io/kubernetes-label-selector',
      ) ?? '',
    );
  }

  private async requestBody(): Promise<KubernetesRequestBody> {
    const clusters = await this.kubernetesApi.getClusters();
    const authProviders: string[] = [
      ...new Set(
        clusters.map(c => {
          if (c.oidcTokenProvider !== undefined && c.oidcTokenProvider) {
            return `${c.authProvider}.${c.oidcTokenProvider}`;
          }
          return `${c.authProvider}`;
        }),
      ),
    ];

    let requestBody: KubernetesRequestBody = { entity: this.entity };
    for (const authProviderStr of authProviders) {
      requestBody =
        await this.kubernetesAuthProvidersApi.decorateRequestBodyForAuth(
          authProviderStr,
          requestBody,
        );
    }

    return requestBody;
  }
}
