import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'services.apps.tanzu.vmware.com.v1alpha1.ClassClaim';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['services.apps.tanzu.vmware.com/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['ClassClaim'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        classRef: {
          properties: {
            name: {
              type: 'string',
            },
          },
          required: ['name'],
          type: 'object',
        },
        parameters: {
          type: 'object',
          properties: {},
          nullable: true,
        },
      },
      required: ['classRef'],
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        binding: {
          properties: {
            name: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        provisionedResourceRef: {
          properties: {
            apiVersion: {
              type: 'string',
            },
            kind: {
              type: 'string',
            },
            name: {
              type: 'string',
            },
            namespace: {
              type: 'string',
              nullable: true,
            },
          },
          required: ['apiVersion', 'kind', 'name'],
          type: 'object',
          nullable: true,
        },
        resourceRef: {
          properties: {
            apiVersion: {
              type: 'string',
            },
            kind: {
              type: 'string',
            },
            name: {
              type: 'string',
            },
            namespace: {
              type: 'string',
              nullable: true,
            },
          },
          required: ['apiVersion', 'kind', 'name'],
          type: 'object',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

/**
 * ClassClaim is the Schema for the classclaims API
 */
export interface IClassClaim {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'services.apps.tanzu.vmware.com/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'ClassClaim';
  metadata?: IObjectMeta;
  /**
   * ClassClaimSpec defines the desired state of ClassClaim
   */
  spec?: {
    /**
     * ClassRef contains a reference to the ClusterInstanceClass
     */
    classRef: {
      /**
       * Name is the name of Class
       */
      name: string;
    };
    /**
     * Parameters are key-value pairs that are configuration inputs to the instance obtained from the referenced ClusterInstanceClass.  These parameters only take effect when .spec.provisioner is specified.
     */
    parameters?: {};
  };
  /**
   * ClassClaimStatus defines the observed state of ClassClaim
   */
  status?: {
    /**
     * Binding is a reference to the Secret from the claimed resource.
     */
    binding?: {
      /**
       * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
       */
      name?: string;
    };
    /**
     * Conditions the latest available observations of a resource's current state.
     */
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    /**
     * ObservedGeneration is the 'Generation' of the resource that was last processed by the controller.
     */
    observedGeneration?: number;
    /**
     * ProvisionedResourceRef contains a reference to the provisioned resource if this claim is for a provisioner class.
     */
    provisionedResourceRef?: {
      /**
       * API version of the reference.
       */
      apiVersion: string;
      /**
       * Kind of the reference.
       */
      kind: string;
      /**
       * Name of the reference.
       */
      name: string;
      /**
       * Namespace of the reference, optional.
       */
      namespace?: string;
    };
    /**
     * ResourceRef contains a reference to the claimed resource.
     */
    resourceRef?: {
      /**
       * API version of the reference.
       */
      apiVersion: string;
      /**
       * Kind of the reference.
       */
      kind: string;
      /**
       * Name of the reference.
       */
      name: string;
      /**
       * Namespace of the reference, optional.
       */
      namespace?: string;
    };
  };
}

/**
 * ClassClaim is the Schema for the classclaims API
 */
export class ClassClaim extends Model<IClassClaim> implements IClassClaim {
  'apiVersion': IClassClaim['apiVersion'];
  'kind': IClassClaim['kind'];
  'metadata'?: IClassClaim['metadata'];
  'spec'?: IClassClaim['spec'];
  'status'?: IClassClaim['status'];

  static apiVersion: IClassClaim['apiVersion'] =
    'services.apps.tanzu.vmware.com/v1alpha1';
  static kind: IClassClaim['kind'] = 'ClassClaim';
  static is = createTypeMetaGuard<IClassClaim>(ClassClaim);

  constructor(data?: ModelData<IClassClaim>) {
    super({
      apiVersion: ClassClaim.apiVersion,
      kind: ClassClaim.kind,
      ...data,
    } as IClassClaim);
  }
}

setSchema(ClassClaim, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
