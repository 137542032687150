import { ApiSurface, AppPluginInterface } from '@vmware-tanzu/core-frontend';
import {
  ConfigApi,
  bitbucketAuthApiRef,
  createApiFactory,
  discoveryApiRef,
  oauthRequestApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';
import {
  customizeAuthProviderConfig,
  LoginSurface,
} from '@vmware-tanzu/tdp-plugin-login';
import { BitbucketAuth } from '@backstage/core-app-api';

export const bitbucketAuthProviderKey = 'bitbucket';

export const BitbucketAuthPlugin: AppPluginInterface = () => surfaces => {
  const defaultConfig = {
    id: 'bitbucket-auth-provider',
    title: 'Bitbucket',
    message: 'Sign in with Bitbucket OAuth',
  };

  surfaces.applyTo(LoginSurface, surface => {
    surface.add({
      config: (configApi: ConfigApi) => ({
        ...customizeAuthProviderConfig(
          configApi,
          defaultConfig,
          bitbucketAuthProviderKey,
        ),
        apiRef: bitbucketAuthApiRef,
      }),
      enabled: (configApi: ConfigApi) =>
        configApi.has(`auth.providers.${bitbucketAuthProviderKey}`), // TODO: ESBACK-163 - needs test for case when config does not exist
      authProviderKey: bitbucketAuthProviderKey,
    });
  });

  surfaces.applyTo(ApiSurface, surface => {
    surface.add(
      createApiFactory({
        api: bitbucketAuthApiRef,
        deps: {
          discoveryApi: discoveryApiRef,
          oauthRequestApi: oauthRequestApiRef,
          configApi: configApiRef,
        },
        factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
          BitbucketAuth.create({
            configApi,
            discoveryApi,
            oauthRequestApi,
            defaultScopes: ['team', 'account'],
            environment: configApi.getOptionalString('auth.environment'),
          }),
      }),
    );
  });
};
