import { ImageRepository as Model } from '../../kubernetes-additional-crds/gen/source.apps.tanzu.vmware.com/v1alpha1/ImageRepository';
import { AbstractModel } from '../AbstractModel';
import type { IImageRepository } from '../../kubernetes-additional-crds/gen/source.apps.tanzu.vmware.com/v1alpha1/ImageRepository';
import type { IRepository } from '../../repository';

export class ImageRepository extends AbstractModel<IImageRepository> {
  public static readonly humanType = 'Source ImageRepository';

  public static readonly typeMeta = Model;

  public classRef = ImageRepository;

  public constructor(
    resource: IImageRepository,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
