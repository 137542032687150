import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {
  cveDetailsRouteRef,
  packagePageRouteRef,
  rootRouteRef,
  severitiesRouteRef,
} from './routes/RouteRefs';

export const securityAnalysisPlugin = createPlugin({
  id: 'security-analysis',
  routes: {
    root: rootRouteRef,
    cveDetails: cveDetailsRouteRef,
    packagePage: packagePageRouteRef,
    vulnerabilityPage: severitiesRouteRef,
  },
  apis: [],
});

export const SecurityAnalysisPage = securityAnalysisPlugin.provide(
  createRoutableExtension({
    name: 'SecurityAnalysisPage',
    component: async () => import('./routes').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
