import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'source.apps.tanzu.vmware.com.v1alpha1.MavenArtifact';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['source.apps.tanzu.vmware.com/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['MavenArtifact'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        artifact: {
          properties: {
            artifactId: {
              type: 'string',
            },
            classifier: {
              type: 'string',
              nullable: true,
            },
            groupId: {
              type: 'string',
            },
            type: {
              default: 'jar',
              type: 'string',
              nullable: true,
            },
            version: {
              type: 'string',
            },
          },
          required: ['artifactId', 'groupId', 'version'],
          type: 'object',
        },
        interval: {
          type: 'string',
        },
        repository: {
          properties: {
            certSecretRef: {
              properties: {
                name: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
            secretRef: {
              properties: {
                name: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
            url: {
              type: 'string',
            },
          },
          required: ['url'],
          type: 'object',
        },
        timeout: {
          type: 'string',
          nullable: true,
        },
      },
      required: ['artifact', 'interval', 'repository'],
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        artifact: {
          properties: {
            checksum: {
              type: 'string',
              nullable: true,
            },
            lastUpdateTime: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
            path: {
              type: 'string',
            },
            revision: {
              type: 'string',
              nullable: true,
            },
            url: {
              type: 'string',
            },
          },
          required: ['path', 'url'],
          type: 'object',
          nullable: true,
        },
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
                type: 'string',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
                type: 'string',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        url: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

/**
 * MavenArtifact is the Schema for the mavenartifacts API
 */
export interface IMavenArtifact {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'source.apps.tanzu.vmware.com/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'MavenArtifact';
  metadata?: IObjectMeta;
  /**
   * MavenArtifactSpec defines the required configuration to provide a MavenArtifact from MavenRepository
   */
  spec?: {
    /**
     * Maven Artifact defines meta Type
     */
    artifact: {
      /**
       * Artifact identifier
       */
      artifactId: string;
      /**
       * Classifier distinguishes artifacts that were built from the same POM but differed in content
       */
      classifier?: string;
      /**
       * Artifact Group ID
       */
      groupId: string;
      /**
       * Package type (.jar, .war, .rar), defaults to jar
       */
      type?: string;
      /**
       * Artifact Version The version element identifies the current version of the artifact. Supported values: "0.1.2" (version), "RELEASE", "LATEST", "SNAPSHOT" and Maven Version Ranges https://maven.apache.org/enforcer/enforcer-rules/versionRanges.html
       */
      version: string;
    };
    /**
     * Interval at which to check the repository for updates.
     */
    interval: string;
    /**
     * Repository defines the parameters for accessing a repository
     */
    repository: {
      /**
       * CertSecretRef can be given the name of a secret containing either or both of
       *  - a PEM-encoded client certificate (`certFile`) and private key (`keyFile`); - a PEM-encoded CA certificate (`caFile`)
       *  and whichever are supplied, will be used for connecting to the repository. The client cert and key are useful if you are authenticating with a certificate; the CA cert is useful if you are using a self-signed server certificate.
       */
      certSecretRef?: {
        /**
         * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
         */
        name?: string;
      };
      /**
       * SecretRef specifies the Secret containing authentication credentials for the repository. Supports basic-auth and tls.
       */
      secretRef?: {
        /**
         * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names TODO: Add other useful fields. apiVersion, kind, uid?
         */
        name?: string;
      };
      /**
       * URL is the HTTPS address of the repository.
       */
      url: string;
    };
    /**
     * Timeout for artifact download operation. Defaults to "Interval" duration.
     */
    timeout?: string;
  };
  /**
   * MavenArtifactStatus defines the observed state of MavenArtifact
   */
  status?: {
    /**
     * Artifact represents the output of the last successful repository sync.
     */
    artifact?: {
      /**
       * Checksum is the SHA1 checksum of the artifact.
       */
      checksum?: string;
      /**
       * LastUpdateTime is the timestamp corresponding to the last update of this artifact.
       */
      lastUpdateTime?: string;
      /**
       * Path is the relative file path of this artifact.
       */
      path: string;
      /**
       * Revision is a human readable identifier traceable in the origin source system. It can be a Git commit SHA, Git tag, a Helm index timestamp, a Helm chart version, etc.
       */
      revision?: string;
      /**
       * URL is the HTTP address of this artifact.
       */
      url: string;
    };
    /**
     * Conditions the latest available observations of a resource"s current state.
     */
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition"s last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    /**
     * ObservedGeneration is the "Generation" of the resource that was last processed by the controller.
     */
    observedGeneration?: number;
    /**
     * URL is the download link for the artifact output of the last repository sync.
     */
    url?: string;
  };
}

/**
 * MavenArtifact is the Schema for the mavenartifacts API
 */
export class MavenArtifact
  extends Model<IMavenArtifact>
  implements IMavenArtifact
{
  'apiVersion': IMavenArtifact['apiVersion'];
  'kind': IMavenArtifact['kind'];
  'metadata'?: IMavenArtifact['metadata'];
  'spec'?: IMavenArtifact['spec'];
  'status'?: IMavenArtifact['status'];

  static apiVersion: IMavenArtifact['apiVersion'] =
    'source.apps.tanzu.vmware.com/v1alpha1';
  static kind: IMavenArtifact['kind'] = 'MavenArtifact';
  static is = createTypeMetaGuard<IMavenArtifact>(MavenArtifact);

  constructor(data?: ModelData<IMavenArtifact>) {
    super({
      apiVersion: MavenArtifact.apiVersion,
      kind: MavenArtifact.kind,
      ...data,
    } as IMavenArtifact);
  }
}

setSchema(MavenArtifact, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
