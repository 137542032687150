import React, { useEffect, useState } from 'react';
import {
  useApi,
  discoveryApiRef,
  fetchApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';
import { PromotionApiClient } from '../../utilities/PromotionApiClient';
import { useEntity } from '@backstage/plugin-catalog-react';
import { InfoCard } from '@backstage/core-components';
import {
  IconButton,
  Grid,
  withStyles,
  Theme,
  StyleRulesCallback,
} from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ArrowCircleUpIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { PromotionAcceleratorAPIResponse } from '../../types';

import type { WithStylesOptions } from '@material-ui/core/styles/withStyles';
import type { BackstageTheme } from '@backstage/theme';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { createPermission } from '@backstage/plugin-permission-common';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    'article[class^="BackstageContent-root-"]': {
      '& > div[class*=" MuiGrid-container-"]': {
        '& > *:nth-child(1)': {
          order: '-2',
        },
      },
    },
  },
})(() => null);

const ACCELERATOR_PROMOTION_PERMISSION_NAME = 'accelerator.promotion';

const promotePermission = createPermission({
  name: ACCELERATOR_PROMOTION_PERMISSION_NAME,
  attributes: { action: 'create' },
});
const demotePermission = createPermission({
  name: ACCELERATOR_PROMOTION_PERMISSION_NAME,
  attributes: { action: 'delete' },
});

type AcceleratorPromotionCardClassKey = 'root';

export interface AcceleratorPromotionCardProps {}

/* eslint-disable @typescript-eslint/no-magic-numbers */
const styles: StyleRulesCallback<
  BackstageTheme,
  AcceleratorPromotionCardProps,
  AcceleratorPromotionCardClassKey
> = () => ({
  root: {
    height: '100%',
  },
});

const AcceleratorPromotionCard = () => {
  const [promoted, setPromoted] = useState(false);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const config = useApi(configApiRef);
  const { entity } = useEntity();
  const promotionApi = new PromotionApiClient({ discoveryApi, fetchApi });
  const name = entity.metadata.name;
  const namespace = entity.metadata.namespace || 'default';
  const promotionEnabled =
    config.getOptionalBoolean('customize.features.accelerators.promotion') ??
    true;

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      // get the data from the api
      const isPromoted: boolean = await promotionApi.getAcceleratorPromoted(
        namespace,
        name,
      );
      // set state with the result
      setPromoted(isPromoted);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(() => {
        throw Error(`Could not check the promotion status.`);
      });
  });
  const promoteEntity = async () => {
    const promoteEntityResp: PromotionAcceleratorAPIResponse =
      await promotionApi.promoteAccelerator(namespace, name);
    if ((await promoteEntityResp).success) {
      setPromoted(true);
    }
  };
  const demoteEntity = async () => {
    const promoteEntityResp: PromotionAcceleratorAPIResponse =
      await promotionApi.demoteAccelerator(namespace, name);
    if ((await promoteEntityResp).success) {
      setPromoted(false);
    }
  };

  if (!promotionEnabled || entity.kind !== 'Accelerator') {
    return <></>;
  }

  return (
    <>
      <GlobalCss />
      <Grid item md={4} xs={12} style={{ order: '-1' }}>
        <InfoCard title="Accelerator Promotion">
          <>
            {promoted && (
              <RequirePermission
                permission={demotePermission}
                errorPage={<>Accelerator is promoted.</>}
              >
                <IconButton
                  aria-label="Demote"
                  title="Demote Accelerator"
                  onClick={demoteEntity}
                >
                  <SettingsBackupRestoreIcon />
                </IconButton>
              </RequirePermission>
            )}
            {!promoted && (
              <RequirePermission
                permission={promotePermission}
                errorPage={<>Accelerator is not promoted.</>}
              >
                <IconButton
                  aria-label="Promote"
                  title="Promote Accelerator"
                  onClick={promoteEntity}
                >
                  <ArrowCircleUpIcon />
                </IconButton>
              </RequirePermission>
            )}
          </>
        </InfoCard>
      </Grid>
    </>
  );
};

export default withStyles<
  AcceleratorPromotionCardClassKey,
  WithStylesOptions<BackstageTheme>,
  AcceleratorPromotionCardProps
>(
  styles as StyleRulesCallback<
    Theme,
    AcceleratorPromotionCardProps,
    AcceleratorPromotionCardClassKey
  >,
  { name: 'AccleratorPromotionCard' },
)(AcceleratorPromotionCard);
