import { isEqual } from 'lodash';
import { useEffect, useReducer, useState } from 'react';
import { RepositoryProxy } from '../repository';
import { repositoryReducer } from './repositoryReducer';
import type { FetchingResult } from '../../types';

export const useGetRepository = ({
  data,
  isLoading,
}: FetchingResult): RepositoryProxy => {
  const [prevData, setPrevData] = useState(data);

  const [repository, dispatch] = useReducer(
    repositoryReducer,
    new RepositoryProxy([]),
  );

  useEffect(() => {
    if (!isLoading) {
      if (!isEqual(prevData, data)) {
        setPrevData(data);
        dispatch({ type: 'Update Repository', payload: data });
      }
    }
  }, [data, prevData, isLoading]);

  return repository;
};
