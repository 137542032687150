import { useCallback, useRef, useState } from 'react';
import { useEffectOnce, useUnmount } from 'react-use';

export type LoadingStatus =
  | 'AutoRefresh'
  | 'Failed'
  | 'Initial'
  | 'ManualRefresh'
  | 'Succeeded';

export interface AutoRefreshState<T> {
  lastRefreshed?: Date;
  refresh: VoidFunction;
  status: LoadingStatus;
  value?: T;
  error?: unknown;
}

/**
 * Run the provided function repeatedly or upon request.
 *
 * In contrast to setInterval, the callback is scheduled for another execution upon completion of the previous
 * invocation. This avoids the "pileup" problem when the callback takes on the order of the delay interval to execute
 * and guarantees a *minimum* of the delay interval between invocations.
 *
 * This hook also provides a function to execute the callback manually. Manual refreshes restart the delay interval.
 *
 * @param fn                 Async callback to run repeatedly
 * @param autoRefreshDelayMs Delay between executions of callback, or undefined or false to deactivate auto-refreshing
 *                           altogether.
 */
export const useAutoRefresh = <T>(
  fn: () => Promise<T>,
  autoRefreshDelayMs?: number | false,
): AutoRefreshState<T> => {
  const [lastRefreshed, setLastRefreshed] = useState<Date | undefined>();
  const [error, setError] = useState<unknown>();
  const [value, setValue] = useState<T | undefined>();
  const [status, setStatus] = useState<LoadingStatus>('Initial');
  const autoRefreshTimeout = useRef<ReturnType<typeof setTimeout>>();

  // Use like a forward ref so autoRefreshCallback can refer to itself AND scheduleAutoRefresh can refer to it, too
  const autoRefreshCallback = useRef<VoidFunction>(() => {});

  const clearAutoRefreshTimeout = useCallback((): void => {
    if (autoRefreshTimeout.current) {
      clearTimeout(autoRefreshTimeout.current);
    }
  }, [autoRefreshTimeout]);

  const scheduleAutoRefresh = useCallback((): void => {
    // Clear for paranoia's sake
    clearAutoRefreshTimeout();

    // Only schedule new timeout if autoRefreshDelay is specified
    if (autoRefreshDelayMs !== undefined && autoRefreshDelayMs !== false) {
      autoRefreshTimeout.current = setTimeout(
        autoRefreshCallback.current,
        autoRefreshDelayMs,
      );
    }
  }, [autoRefreshDelayMs, autoRefreshCallback, clearAutoRefreshTimeout]);

  // Execute the callback and report its status
  const executeCallback = useCallback((): void => {
    clearAutoRefreshTimeout(); // Avoid race conditions

    void fn()
      .then((val: T) => {
        setError(undefined);
        setValue(val);
        setStatus('Succeeded');
        setLastRefreshed(new Date());
        return;
      })
      .catch(reason => {
        // Do not clear out previous successful values
        setError(reason);
        setStatus('Failed');
      })
      .finally(scheduleAutoRefresh);
  }, [fn, clearAutoRefreshTimeout, scheduleAutoRefresh]);

  // Define the "forward ref" for autoRefreshCallback
  useEffectOnce(() => {
    autoRefreshCallback.current = (): void => {
      setStatus('AutoRefresh');

      executeCallback();
    };
  });

  // Initial load
  useEffectOnce(() => {
    executeCallback();
  });

  // Manual refresh callback
  const refresh = useCallback(() => {
    setStatus('ManualRefresh');

    executeCallback();
  }, [executeCallback]);

  useUnmount(clearAutoRefreshTimeout);

  return {
    lastRefreshed: lastRefreshed,
    refresh: refresh,
    status: status,
    value: value,
    error: error,
  };
};
