import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'spaces.tanzu.vmware.com.v1alpha1.Space';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['spaces.tanzu.vmware.com/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['Space'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        availabilityTargets: {
          items: {
            properties: {
              name: {
                type: 'string',
              },
              replicas: {
                default: 1,
                format: 'int32',
                type: 'integer',
                nullable: true,
              },
            },
            required: ['name'],
            type: 'object',
          },
          type: 'array',
        },
        revisionHistoryLimit: {
          default: 10,
          format: 'int32',
          type: 'integer',
          nullable: true,
        },
        template: {
          properties: {
            spec: {
              properties: {
                profiles: {
                  items: {
                    properties: {
                      name: {
                        type: 'string',
                      },
                      values: {
                        properties: {
                          inline: {
                            type: 'object',
                            properties: {},
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                    },
                    required: ['name'],
                    type: 'object',
                  },
                  type: 'array',
                  nullable: true,
                },
                resources: {
                  properties: {
                    claims: {
                      items: {
                        properties: {
                          name: {
                            type: 'string',
                          },
                        },
                        required: ['name'],
                        type: 'object',
                      },
                      type: 'array',
                      nullable: true,
                    },
                    limits: {
                      additionalProperties: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                        pattern:
                          '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                      },
                      type: 'object',
                      properties: {},
                      nullable: true,
                    },
                    requests: {
                      additionalProperties: {
                        anyOf: [
                          {
                            type: 'integer',
                          },
                          {
                            type: 'string',
                          },
                        ],
                        pattern:
                          '^(\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))(([KMGTPE]i)|[numkMGTPE]|([eE](\\+|-)?(([0-9]+(\\.[0-9]*)?)|(\\.[0-9]+))))?$',
                      },
                      type: 'object',
                      properties: {},
                      nullable: true,
                    },
                  },
                  type: 'object',
                  nullable: true,
                },
              },
              type: 'object',
            },
          },
          required: ['spec'],
          type: 'object',
        },
        updateStrategy: {
          properties: {
            type: {
              default: 'RollingUpdate',
              enum: ['Recreate', 'RollingUpdate'],
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
      },
      required: ['availabilityTargets', 'template'],
      type: 'object',
      nullable: true,
    },
    status: {
      properties: {
        availabilityTargets: {
          items: {
            properties: {
              name: {
                type: 'string',
                nullable: true,
              },
              readyReplicas: {
                format: 'int32',
                type: 'integer',
                nullable: true,
              },
              replicas: {
                format: 'int32',
                type: 'integer',
                nullable: true,
              },
              updatedReplicas: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        conditions: {
          items: {
            properties: {
              lastTransitionTime: {
                format: 'date-time',
                type: 'string',
              },
              message: {
                maxLength: 32768,
                type: 'string',
              },
              observedGeneration: {
                format: 'int64',
                type: 'integer',
                minimum: 0,
                nullable: true,
              },
              reason: {
                maxLength: 1024,
                minLength: 1,
                type: 'string',
                pattern: '^[A-Za-z]([A-Za-z0-9_,:]*[A-Za-z0-9_])?$',
              },
              status: {
                enum: ['True', 'False', 'Unknown'],
                type: 'string',
              },
              type: {
                maxLength: 316,
                type: 'string',
                pattern:
                  '^([a-z0-9]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*\\/)?(([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9])$',
              },
            },
            required: [
              'lastTransitionTime',
              'message',
              'reason',
              'status',
              'type',
            ],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        observedGeneration: {
          format: 'int32',
          type: 'integer',
          nullable: true,
        },
        resolvedProfiles: {
          items: {
            properties: {
              name: {
                type: 'string',
                nullable: true,
              },
              reason: {
                type: 'string',
                nullable: true,
              },
              state: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['apiVersion', 'kind'],
};

/**
 * Space is the Schema for the spaces API
 */
export interface ISpace {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'spaces.tanzu.vmware.com/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'Space';
  metadata?: IObjectMeta;
  /**
   * SpaceSpec defines the desired state of Space
   */
  spec?: {
    availabilityTargets: Array<{
      name: string;
      replicas?: number;
    }>;
    revisionHistoryLimit?: number;
    template: {
      spec: {
        profiles?: Array<{
          name: string;
          values?: {
            inline?: {};
          };
        }>;
        /**
         * ResourceRequirements describes the compute resource requirements.
         */
        resources?: {
          /**
           * Claims lists the names of resources, defined in spec.resourceClaims, that are used by this container.
           *  This is an alpha field and requires enabling the DynamicResourceAllocation feature gate.
           *  This field is immutable. It can only be set for containers.
           */
          claims?: Array<{
            /**
             * Name must match the name of one entry in pod.spec.resourceClaims of the Pod where this field is used. It makes that resource available inside a container.
             */
            name: string;
          }>;
          /**
           * Limits describes the maximum amount of compute resources allowed. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
           */
          limits?: {
            [key: string]: number | string;
          };
          /**
           * Requests describes the minimum amount of compute resources required. If Requests is omitted for a container, it defaults to Limits if that is explicitly specified, otherwise to an implementation-defined value. More info: https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/
           */
          requests?: {
            [key: string]: number | string;
          };
        };
      };
    };
    updateStrategy?: {
      type?: 'Recreate' | 'RollingUpdate';
    };
  };
  /**
   * SpaceStatus defines the observed state of Space
   */
  status?: {
    availabilityTargets?: Array<{
      name?: string;
      readyReplicas?: number;
      replicas?: number;
      updatedReplicas?: string;
    }>;
    conditions?: Array<{
      /**
       * lastTransitionTime is the last time the condition transitioned from one status to another. This should be when the underlying condition changed.  If that is not known, then using the time when the API field changed is acceptable.
       */
      lastTransitionTime: string;
      /**
       * message is a human readable message indicating details about the transition. This may be an empty string.
       */
      message: string;
      /**
       * observedGeneration represents the .metadata.generation that the condition was set based upon. For instance, if .metadata.generation is currently 12, but the .status.conditions[x].observedGeneration is 9, the condition is out of date with respect to the current state of the instance.
       */
      observedGeneration?: number;
      /**
       * reason contains a programmatic identifier indicating the reason for the condition's last transition. Producers of specific condition types may define expected values and meanings for this field, and whether the values are considered a guaranteed API. The value should be a CamelCase string. This field may not be empty.
       */
      reason: string;
      /**
       * status of the condition, one of True, False, Unknown.
       */
      status: 'True' | 'False' | 'Unknown';
      /**
       * type of condition in CamelCase or in foo.example.com/CamelCase. --- Many .condition.type values are consistent across resources like Available, but because arbitrary conditions can be useful (see .node.status.conditions), the ability to deconflict is important. The regex it matches is (dns1123SubdomainFmt/)?(qualifiedNameFmt)
       */
      type: string;
    }>;
    observedGeneration?: number;
    resolvedProfiles?: Array<{
      name?: string;
      reason?: string;
      state?: string;
    }>;
  };
}

/**
 * Space is the Schema for the spaces API
 */
export class Space extends Model<ISpace> implements ISpace {
  'apiVersion': ISpace['apiVersion'];
  'kind': ISpace['kind'];
  'metadata'?: ISpace['metadata'];
  'spec'?: ISpace['spec'];
  'status'?: ISpace['status'];

  static apiVersion: ISpace['apiVersion'] = 'spaces.tanzu.vmware.com/v1alpha1';
  static kind: ISpace['kind'] = 'Space';
  static is = createTypeMetaGuard<ISpace>(Space);

  constructor(data?: ModelData<ISpace>) {
    super({
      apiVersion: Space.apiVersion,
      kind: Space.kind,
      ...data,
    } as ISpace);
  }
}

setSchema(Space, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
