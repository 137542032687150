import { ConfigApi } from '@backstage/core-plugin-api';
import _ from 'lodash';
import {
  ArtifactGroup,
  ArtifactGroupVulnerability,
  ArtifactsGroupsRequest,
  ArtifactsGroupsVulnerabilityReq,
  GetAnalysesRequestParams,
  HighestReachVulnerability,
  HighestReachVulnerabilityRequest,
  MetadataStoreApi,
  Package,
  PaginatedResponse,
  SBOMFileFormat,
  SBOMFormat,
  SearchReportResponse,
  ReportResponse,
  Vulnerability,
  VulnerabilityAnalysisResponse,
  VulnerabilityAnalysisUIDResponse,
  VulnerabilityAnalysisUpsertRequest,
  VulnerabilityDisplay,
  VulnerabilityRatings,
  VulnerabilitySeverity,
  ReportPackageResponse,
  ReportRatingResponse,
  LatestReportsRequestParams,
} from './MetadataStoreApi';

export function parsePckgVulns(pckg: Package): VulnerabilityDisplay[] {
  const vulnerabilities = pckg.Vulnerabilities || [];
  const flattenedVulnerabilities = vulnerabilities.map(vulnerability => {
    return {
      id: `${vulnerability.ID}`,
      vulnerabilityId: `${vulnerability.ID}`,
      cveId: vulnerability.CVEID,
      description: vulnerability.Description,
      url: vulnerability.URL,
      severity: vulnerability.Severity,
      package: pckg.Name,
      version: pckg.Version,
      impactedWorkloadsCount: vulnerability.ArtifactGroups?.length || 0,
    };
  });
  return flattenedVulnerabilities;
}

export class MetadataStoreApiClient implements MetadataStoreApi {
  private configApi: ConfigApi;

  constructor({ configApi }: { configApi: ConfigApi }) {
    this.configApi = configApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const backendUrl = `${this.configApi.getString(
      'backend.baseUrl',
    )}/api/proxy/metadata-store`;
    const resp = await fetch(`${backendUrl}${input}`, init);
    if (!resp.ok) throw new Error(resp.statusText);
    return await resp.json();
  }

  private async fetchXML(input: string, init?: RequestInit): Promise<string> {
    const backendUrl = `${this.configApi.getString(
      'backend.baseUrl',
    )}/api/proxy/metadata-store`;
    const resp = await fetch(`${backendUrl}${input}`, init);
    if (!resp.ok) throw new Error(resp.statusText);
    return await resp.text();
  }

  async getSourceVulnerabilities(params: string): Promise<Vulnerability[]> {
    const response = await this.fetch<PaginatedResponse<Vulnerability>>(
      `/sources/vulnerabilities?${params}`,
    );
    return _.orderBy(
      this.mapSeverity(response.Results),
      this.getSeverityRating,
      'desc',
    );
  }

  async getArtifactsGroups({
    cveId,
    shas = [],
    digests = [],
    packageName,
    page,
    pageSize,
    all = true,
  }: ArtifactsGroupsRequest): Promise<ArtifactGroup[]> {
    const { Results } = await this.fetch<PaginatedResponse<ArtifactGroup>>(
      `/artifact-groups/_search`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          all,
          cve_id: cveId,
          digests,
          shas,
          package_name: packageName,
          page,
          page_size: pageSize,
        }),
      },
    );
    return Results;
  }

  async getVulnerabilitiesArtifactGroups({
    artifactGroupId,
    shas = [],
    digests = [],
    packageName,
    page,
    pageSize,
    all = true,
  }: ArtifactsGroupsVulnerabilityReq): Promise<ArtifactGroupVulnerability[]> {
    const { Results } = await this.fetch<
      PaginatedResponse<ArtifactGroupVulnerability>
    >('/artifact-groups/vulnerabilities/_search', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        all,
        artifact_group_uid: artifactGroupId,
        digests,
        shas,
        package_name: packageName,
        page,
        page_size: pageSize,
      }),
    });
    return Results;
  }

  async getImageScanVulnerabilities(params: string): Promise<Vulnerability[]> {
    const response = await this.fetch<PaginatedResponse<Vulnerability>>(
      `/images/vulnerabilities?${params}`,
    );
    return _.orderBy(
      this.mapSeverity(response.Results),
      this.getSeverityRating,
      'desc',
    );
  }

  async getPackageWithVulnerabilities(packageName: string): Promise<Package> {
    let vulnerabilities: Vulnerability[] = [];
    let filteredPackage: Package = {} as Package;
    const packageResponse = await this.fetch<PaginatedResponse<Package>>(
      `/packages?name=${encodeURIComponent(packageName)}`,
    );
    const filteredPackages =
      packageResponse.Results.filter((pck: any) => pck.Name === packageName) ||
      [];

    for (let index = 0; index < filteredPackages.length; index++) {
      const { ID } = filteredPackages[index];
      const response = await this.fetch<Package>(`/packages/${ID}`);
      if (
        response &&
        response.Vulnerabilities &&
        response.Vulnerabilities.length > 0
      ) {
        filteredPackage = filteredPackages[index];
        vulnerabilities = response.Vulnerabilities;
        break;
      }
    }
    const orderedVulnerabilities = _.orderBy(
      this.mapSeverity(vulnerabilities),
      this.getSeverityRating,
      'desc',
    );

    return {
      ...filteredPackage,
      Vulnerabilities: orderedVulnerabilities,
    };
  }

  async getVulnerabilityByID(id: string): Promise<Vulnerability> {
    const response = await this.fetch<Vulnerability>(`/vulnerabilities/${id}`);
    return response;
  }

  private mapSeverity(
    vulnerabilities: (Vulnerability | ArtifactGroupVulnerability)[],
  ) {
    return vulnerabilities.map(vulnerability => ({
      ...vulnerability,
      Severity: this.getSeverity(vulnerability.Ratings),
    }));
  }

  getSeverity(ratings: VulnerabilityRatings[]): VulnerabilitySeverity {
    const sortedByScore = _.sortBy(ratings, this.getSeverityRating);
    return sortedByScore.length > 0
      ? sortedByScore[sortedByScore.length - 1].Severity
      : VulnerabilitySeverity.UNKNOWN;
  }

  private getSeverityRating(
    rating: VulnerabilityRatings | Vulnerability | ReportRatingResponse,
  ): number {
    if (rating.Severity === VulnerabilitySeverity.CRITICAL) {
      return 10;
    } else if (rating.Severity === VulnerabilitySeverity.HIGH) {
      return 8;
    } else if (rating.Severity === VulnerabilitySeverity.MEDIUM) {
      return 6;
    } else if (rating.Severity === VulnerabilitySeverity.LOW) {
      return 4;
    }
    return 2;
  }

  parsePackageVulnerabilities(pckg: Package): VulnerabilityDisplay[] {
    return parsePckgVulns(pckg);
  }

  parseVulnerabilities(
    vulnerabilities: Vulnerability[],
  ): VulnerabilityDisplay[] {
    const flattenedVulnerabilities: VulnerabilityDisplay[] = [];
    vulnerabilities.forEach(vulnerability => {
      if (vulnerability.Packages) {
        vulnerability.Packages.forEach(vulnerabilityPackage => {
          flattenedVulnerabilities.push({
            id: `${vulnerability.CVEID}_${vulnerabilityPackage.Name}_${vulnerabilityPackage.Version}`,
            vulnerabilityId: vulnerability.ID.toString(),
            cveId: vulnerability.CVEID,
            description: vulnerability.Description,
            url: vulnerability.URL,
            severity: vulnerability.Severity,
            package: vulnerabilityPackage.Name,
            version: vulnerabilityPackage.Version,
            impactedWorkloadsCount: vulnerability.ArtifactGroups?.length || 0,
          });
        });
      } else {
        flattenedVulnerabilities.push({
          id: vulnerability.CVEID.toString(),
          vulnerabilityId: vulnerability.ID.toString(),
          cveId: vulnerability.CVEID,
          description: vulnerability.Description,
          url: vulnerability.URL,
          severity: vulnerability.Severity,
          package: '',
          version: '',
          impactedWorkloadsCount: vulnerability.ArtifactGroups?.length || 0,
        });
      }
    });
    return flattenedVulnerabilities;
  }

  async getVulnerabilities({
    artifactGroupId,
    shas = [],
    digests = [],
    packageName,
    page,
    pageSize,
    all = true,
  }: ArtifactsGroupsVulnerabilityReq): Promise<Vulnerability[]> {
    const Results = await this.getVulnerabilitiesArtifactGroups({
      artifactGroupId,
      shas,
      digests,
      packageName,
      page,
      pageSize,
      all,
    });

    return _.orderBy(
      this.mapSeverity(Results || []),
      this.getSeverityRating,
      'desc',
    );
  }

  async getHighestReachVulnerabilities({
    all = true,
    page,
    pageSize,
    shas = [],
    digests = [],
    severities = [],
  }: HighestReachVulnerabilityRequest): Promise<HighestReachVulnerability[]> {
    const { Results } = await this.fetch<
      PaginatedResponse<HighestReachVulnerability>
    >('/artifact-groups/vulnerabilities/_reach', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        all,
        digests,
        shas,
        severities,
        page,
        page_size: pageSize,
      }),
    });

    return Results;
  }

  async getImageSBOM(
    imageSha: string,
    sbomFormat: SBOMFormat,
    fileFormat: SBOMFileFormat,
  ): Promise<any> {
    const URL = `/images/${imageSha}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Report-Type-Format': sbomFormat.toString(),
        Accept: fileFormat.toString(),
      },
    };
    const fetchMethod =
      fileFormat === SBOMFileFormat.XML
        ? this.fetchXML.bind(this)
        : this.fetch.bind(this);
    const response = await fetchMethod(URL, requestConfig);
    return response;
  }

  async getSBOMByReport(
    reportId: string,
    sbomFormat: SBOMFormat,
    fileFormat: SBOMFileFormat,
  ): Promise<any> {
    const URL = `/reports/${reportId}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Report-Type-Format': sbomFormat.toString(),
        Accept: fileFormat.toString(),
      },
    };
    const fetchMethod =
      fileFormat === SBOMFileFormat.XML
        ? this.fetchXML.bind(this)
        : this.fetch.bind(this);
    const response = await fetchMethod(URL, requestConfig);
    return response;
  }

  async getSourceSBOM(
    sourceHash: string,
    sbomFormat: SBOMFormat,
    fileFormat: SBOMFileFormat,
  ): Promise<any> {
    const URL = `/sources/${sourceHash}`;
    const requestConfig = {
      method: 'GET',
      headers: {
        'Report-Type-Format': sbomFormat.toString(),
        Accept: fileFormat.toString(),
      },
    };
    const fetchMethod =
      fileFormat === SBOMFileFormat.XML
        ? this.fetchXML.bind(this)
        : this.fetch.bind(this);
    const response = await fetchMethod(URL, requestConfig);
    return response;
  }

  async createVulnerabilityAnalysis(
    request: VulnerabilityAnalysisUpsertRequest,
  ): Promise<VulnerabilityAnalysisUIDResponse> {
    return await this.fetch<VulnerabilityAnalysisUIDResponse>('/triage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
  }

  async getVulnerabilityAnalyses(
    params: GetAnalysesRequestParams,
  ): Promise<PaginatedResponse<VulnerabilityAnalysisResponse>> {
    const urlParams =
      Object.keys(params).length === 0 ? '' : `?${new URLSearchParams(params)}`;
    return await this.fetch<PaginatedResponse<VulnerabilityAnalysisResponse>>(
      `/triage${urlParams}`,
    );
  }

  async getReports(
    params: string,
  ): Promise<PaginatedResponse<SearchReportResponse>> {
    const URL = `/reports?${params}`;
    return await this.fetch<PaginatedResponse<SearchReportResponse>>(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async getReportById(reportId: string): Promise<ReportResponse> {
    const URL = `/reports/${reportId}`;
    return await this.fetch<ReportResponse>(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async getReportByIds(
    reportIds: string[],
  ): Promise<PaginatedResponse<ReportResponse>> {
    return await this.fetch<PaginatedResponse<ReportResponse>>(
      '/reports/_search',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          report_uids: reportIds,
        }),
      },
    );
  }

  async getLatestReports(
    params: LatestReportsRequestParams,
  ): Promise<PaginatedResponse<ReportResponse>> {
    return await this.fetch<PaginatedResponse<ReportResponse>>(
      '/reports/_search',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      },
    );
  }

  getReportSeverityRating(v: VulnerabilityDisplay): number {
    switch (v.severity) {
      case VulnerabilitySeverity.CRITICAL:
        return 10;
      case VulnerabilitySeverity.HIGH:
        return 8;
      case VulnerabilitySeverity.MEDIUM:
        return 6;
      case VulnerabilitySeverity.LOW:
        return 4;
      default:
        return 2;
    }
  }

  getReportSeverity(ratings: ReportRatingResponse[]): string {
    const sortedByScore = _.sortBy(ratings, this.getSeverityRating);
    return sortedByScore.length > 0
      ? sortedByScore[sortedByScore.length - 1].Severity
      : VulnerabilitySeverity.UNKNOWN;
  }

  parseReportPackageVulnerabilities(
    pckg: ReportPackageResponse,
  ): VulnerabilityDisplay[] {
    return (pckg.vulnerabilities ?? []).map(vulnerability => ({
      id: `${vulnerability.CVEID}_${pckg.Name}_${pckg.Version}`,
      vulnerabilityId: vulnerability.CVEID,
      cveId: vulnerability.CVEID,
      description: vulnerability.Description,
      url: vulnerability.URL,
      severity: this.getReportSeverity(vulnerability.ratings),
      package: pckg.Name,
      version: pckg.Version,
      impactedWorkloadsCount: 0,
    }));
  }

  sortBySeverity(
    vulnerabilities: VulnerabilityDisplay[],
  ): VulnerabilityDisplay[] {
    return _.orderBy(vulnerabilities, this.getReportSeverityRating, 'desc');
  }
}
