import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {
  packageLinkRouteRef,
  rootRouteRef,
  supplyChainDeployerRef,
  supplyChainInstanceRef,
  vulnerabilityLinkRouteRef,
} from './routes/RouteRefs';

export { SupplyChain as SupplyChainIcon } from './icons/SupplyChain';

export const supplyChainPlugin = createPlugin({
  id: 'supply-chain',
  routes: {
    root: rootRouteRef,
    supplyChainDeployer: supplyChainDeployerRef,
    supplyChainInstance: supplyChainInstanceRef,
  },
  externalRoutes: {
    vulnerabilityLink: vulnerabilityLinkRouteRef,
    packageLink: packageLinkRouteRef,
  },
  apis: [],
});

export const SupplyChainPage = supplyChainPlugin.provide(
  createRoutableExtension({
    name: 'SupplyChainPage',
    component: async () => import('./routes').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
