import { useEffect, useState } from 'react';
import type { KubernetesApi } from '@backstage/plugin-kubernetes';

type ClusterName = string;

interface Props {
  kubernetesApi: KubernetesApi;
}

const getClusters = async (
  kubernetesApi: KubernetesApi,
): Promise<ClusterName[]> => {
  const clustersData = await kubernetesApi.getClusters();
  return clustersData.map(c => c.name);
};

export const useClusters = ({ kubernetesApi }: Props): ClusterName[] => {
  const [clusters, setClusters] = useState<ClusterName[]>([]);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const data = await getClusters(kubernetesApi);
        setClusters(data);
      } catch (error) {
        setClusters([]);
      }
    };
    void getData();
  }, [kubernetesApi]);

  return clusters;
};
