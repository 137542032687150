import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'kappctrl.k14s.io.v1alpha1.App';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['kappctrl.k14s.io/v1alpha1'],
    },
    kind: {
      type: 'string',
      enum: ['App'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
    spec: {
      properties: {
        canceled: {
          type: 'boolean',
          nullable: true,
        },
        cluster: {
          properties: {
            kubeconfigSecretRef: {
              properties: {
                key: {
                  type: 'string',
                  nullable: true,
                },
                name: {
                  type: 'string',
                  nullable: true,
                },
              },
              type: 'object',
              nullable: true,
            },
            namespace: {
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        deploy: {
          items: {
            properties: {
              kapp: {
                properties: {
                  delete: {
                    properties: {
                      rawOptions: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  inspect: {
                    properties: {
                      rawOptions: {
                        items: {
                          type: 'string',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  intoNs: {
                    type: 'string',
                    nullable: true,
                  },
                  mapNs: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                  rawOptions: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        fetch: {
          items: {
            properties: {
              git: {
                properties: {
                  lfsSkipSmudge: {
                    type: 'boolean',
                    nullable: true,
                  },
                  ref: {
                    type: 'string',
                    nullable: true,
                  },
                  refSelection: {
                    properties: {
                      semver: {
                        properties: {
                          constraints: {
                            type: 'string',
                            nullable: true,
                          },
                          prereleases: {
                            properties: {
                              identifiers: {
                                items: {
                                  type: 'string',
                                },
                                type: 'array',
                                nullable: true,
                              },
                            },
                            type: 'object',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  secretRef: {
                    properties: {
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  subPath: {
                    type: 'string',
                    nullable: true,
                  },
                  url: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              helmChart: {
                properties: {
                  name: {
                    type: 'string',
                    nullable: true,
                  },
                  repository: {
                    properties: {
                      secretRef: {
                        properties: {
                          name: {
                            type: 'string',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                      url: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  version: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              http: {
                properties: {
                  secretRef: {
                    properties: {
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  sha256: {
                    type: 'string',
                    nullable: true,
                  },
                  subPath: {
                    type: 'string',
                    nullable: true,
                  },
                  url: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              image: {
                properties: {
                  secretRef: {
                    properties: {
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  subPath: {
                    type: 'string',
                    nullable: true,
                  },
                  tagSelection: {
                    properties: {
                      semver: {
                        properties: {
                          constraints: {
                            type: 'string',
                            nullable: true,
                          },
                          prereleases: {
                            properties: {
                              identifiers: {
                                items: {
                                  type: 'string',
                                },
                                type: 'array',
                                nullable: true,
                              },
                            },
                            type: 'object',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  url: {
                    type: 'string',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              imgpkgBundle: {
                properties: {
                  image: {
                    type: 'string',
                    nullable: true,
                  },
                  secretRef: {
                    properties: {
                      name: {
                        type: 'string',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  tagSelection: {
                    properties: {
                      semver: {
                        properties: {
                          constraints: {
                            type: 'string',
                            nullable: true,
                          },
                          prereleases: {
                            properties: {
                              identifiers: {
                                items: {
                                  type: 'string',
                                },
                                type: 'array',
                                nullable: true,
                              },
                            },
                            type: 'object',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              inline: {
                properties: {
                  paths: {
                    additionalProperties: {
                      type: 'string',
                    },
                    type: 'object',
                    properties: {},
                    nullable: true,
                  },
                  pathsFrom: {
                    items: {
                      properties: {
                        configMapRef: {
                          properties: {
                            directoryPath: {
                              type: 'string',
                              nullable: true,
                            },
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                        secretRef: {
                          properties: {
                            directoryPath: {
                              type: 'string',
                              nullable: true,
                            },
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                      },
                      type: 'object',
                    },
                    type: 'array',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              path: {
                type: 'string',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        noopDelete: {
          type: 'boolean',
          nullable: true,
        },
        paused: {
          type: 'boolean',
          nullable: true,
        },
        serviceAccountName: {
          type: 'string',
          nullable: true,
        },
        syncPeriod: {
          type: 'string',
          nullable: true,
        },
        template: {
          items: {
            properties: {
              cue: {
                properties: {
                  inputExpression: {
                    type: 'string',
                    nullable: true,
                  },
                  outputExpression: {
                    type: 'string',
                    nullable: true,
                  },
                  paths: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                  valuesFrom: {
                    items: {
                      properties: {
                        configMapRef: {
                          properties: {
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                        path: {
                          type: 'string',
                          nullable: true,
                        },
                        secretRef: {
                          properties: {
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                      },
                      type: 'object',
                    },
                    type: 'array',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              helmTemplate: {
                properties: {
                  name: {
                    type: 'string',
                    nullable: true,
                  },
                  namespace: {
                    type: 'string',
                    nullable: true,
                  },
                  path: {
                    type: 'string',
                    nullable: true,
                  },
                  valuesFrom: {
                    items: {
                      properties: {
                        configMapRef: {
                          properties: {
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                        path: {
                          type: 'string',
                          nullable: true,
                        },
                        secretRef: {
                          properties: {
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                      },
                      type: 'object',
                    },
                    type: 'array',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              jsonnet: {
                type: 'object',
                properties: {},
                nullable: true,
              },
              kbld: {
                properties: {
                  paths: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              kustomize: {
                type: 'object',
                properties: {},
                nullable: true,
              },
              sops: {
                properties: {
                  age: {
                    properties: {
                      privateKeysSecretRef: {
                        properties: {
                          name: {
                            type: 'string',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  paths: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                  pgp: {
                    properties: {
                      privateKeysSecretRef: {
                        properties: {
                          name: {
                            type: 'string',
                            nullable: true,
                          },
                        },
                        type: 'object',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
              ytt: {
                properties: {
                  fileMarks: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                  ignoreUnknownComments: {
                    type: 'boolean',
                    nullable: true,
                  },
                  inline: {
                    properties: {
                      paths: {
                        additionalProperties: {
                          type: 'string',
                        },
                        type: 'object',
                        properties: {},
                        nullable: true,
                      },
                      pathsFrom: {
                        items: {
                          properties: {
                            configMapRef: {
                              properties: {
                                directoryPath: {
                                  type: 'string',
                                  nullable: true,
                                },
                                name: {
                                  type: 'string',
                                  nullable: true,
                                },
                              },
                              type: 'object',
                              nullable: true,
                            },
                            secretRef: {
                              properties: {
                                directoryPath: {
                                  type: 'string',
                                  nullable: true,
                                },
                                name: {
                                  type: 'string',
                                  nullable: true,
                                },
                              },
                              type: 'object',
                              nullable: true,
                            },
                          },
                          type: 'object',
                        },
                        type: 'array',
                        nullable: true,
                      },
                    },
                    type: 'object',
                    nullable: true,
                  },
                  paths: {
                    items: {
                      type: 'string',
                    },
                    type: 'array',
                    nullable: true,
                  },
                  strict: {
                    type: 'boolean',
                    nullable: true,
                  },
                  valuesFrom: {
                    items: {
                      properties: {
                        configMapRef: {
                          properties: {
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                        path: {
                          type: 'string',
                          nullable: true,
                        },
                        secretRef: {
                          properties: {
                            name: {
                              type: 'string',
                              nullable: true,
                            },
                          },
                          type: 'object',
                          nullable: true,
                        },
                      },
                      type: 'object',
                    },
                    type: 'array',
                    nullable: true,
                  },
                },
                type: 'object',
                nullable: true,
              },
            },
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
      },
      type: 'object',
    },
    status: {
      properties: {
        conditions: {
          items: {
            properties: {
              message: {
                type: 'string',
                nullable: true,
              },
              reason: {
                type: 'string',
                nullable: true,
              },
              status: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
            },
            required: ['status', 'type'],
            type: 'object',
          },
          type: 'array',
          nullable: true,
        },
        consecutiveReconcileFailures: {
          type: 'integer',
          nullable: true,
        },
        consecutiveReconcileSuccesses: {
          type: 'integer',
          nullable: true,
        },
        deploy: {
          properties: {
            error: {
              type: 'string',
              nullable: true,
            },
            exitCode: {
              type: 'integer',
              nullable: true,
            },
            finished: {
              type: 'boolean',
              nullable: true,
            },
            startedAt: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
            stderr: {
              type: 'string',
              nullable: true,
            },
            stdout: {
              type: 'string',
              nullable: true,
            },
            updatedAt: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        fetch: {
          properties: {
            error: {
              type: 'string',
              nullable: true,
            },
            exitCode: {
              type: 'integer',
              nullable: true,
            },
            startedAt: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
            stderr: {
              type: 'string',
              nullable: true,
            },
            stdout: {
              type: 'string',
              nullable: true,
            },
            updatedAt: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        friendlyDescription: {
          type: 'string',
          nullable: true,
        },
        inspect: {
          properties: {
            error: {
              type: 'string',
              nullable: true,
            },
            exitCode: {
              type: 'integer',
              nullable: true,
            },
            stderr: {
              type: 'string',
              nullable: true,
            },
            stdout: {
              type: 'string',
              nullable: true,
            },
            updatedAt: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        managedAppName: {
          type: 'string',
          nullable: true,
        },
        observedGeneration: {
          format: 'int64',
          type: 'integer',
          nullable: true,
        },
        template: {
          properties: {
            error: {
              type: 'string',
              nullable: true,
            },
            exitCode: {
              type: 'integer',
              nullable: true,
            },
            stderr: {
              type: 'string',
              nullable: true,
            },
            updatedAt: {
              format: 'date-time',
              type: 'string',
              nullable: true,
            },
          },
          type: 'object',
          nullable: true,
        },
        usefulErrorMessage: {
          type: 'string',
          nullable: true,
        },
      },
      type: 'object',
      nullable: true,
    },
  },
  required: ['spec', 'apiVersion', 'kind'],
};

/**
 * An App is a set of Kubernetes resources. These resources could span any number of namespaces or could be cluster-wide (e.g. CRDs). An App is represented in kapp-controller using a App CR. The App CR comprises of three main sections: spec.fetch – declare source for fetching configuration and OCI images spec.template – declare templating tool and values spec.deploy – declare deployment tool and any deploy specific configuration
 */
export interface IApp {
  /**
   * APIVersion defines the versioned schema of this representation of an object. Servers should convert recognized schemas to the latest internal value, and may reject unrecognized values. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#resources
   */
  apiVersion: 'kappctrl.k14s.io/v1alpha1';
  /**
   * Kind is a string value representing the REST resource this object represents. Servers may infer this from the endpoint the client submits requests to. Cannot be updated. In CamelCase. More info: https://git.k8s.io/community/contributors/devel/sig-architecture/api-conventions.md#types-kinds
   */
  kind: 'App';
  metadata?: IObjectMeta;
  spec: {
    /**
     * Cancels current and future reconciliations (optional; default=false)
     */
    canceled?: boolean;
    /**
     * Specifies that app should be deployed to destination cluster; by default, cluster is same as where this resource resides (optional; v0.5.0+)
     */
    cluster?: {
      /**
       * Specifies secret containing kubeconfig (required)
       */
      kubeconfigSecretRef?: {
        /**
         * Specifies key that contains kubeconfig (optional)
         */
        key?: string;
        /**
         * Specifies secret name within app's namespace (required)
         */
        name?: string;
      };
      /**
       * Specifies namespace in destination cluster (optional)
       */
      namespace?: string;
    };
    deploy?: Array<{
      /**
       * Use kapp to deploy resources
       */
      kapp?: {
        /**
         * Configuration for delete command (optional)
         */
        delete?: {
          /**
           * Pass through options to kapp delete (optional)
           */
          rawOptions?: Array<string>;
        };
        /**
         * Configuration for inspect command (optional) as of kapp-controller v0.31.0, inspect is disabled by default add rawOptions or use an empty inspect config like `inspect: {}` to enable
         */
        inspect?: {
          /**
           * Pass through options to kapp inspect (optional)
           */
          rawOptions?: Array<string>;
        };
        /**
         * Override namespace for all resources (optional)
         */
        intoNs?: string;
        /**
         * Provide custom namespace override mapping (optional)
         */
        mapNs?: Array<string>;
        /**
         * Pass through options to kapp deploy (optional)
         */
        rawOptions?: Array<string>;
      };
    }>;
    fetch?: Array<{
      /**
       * Uses git to clone repository
       */
      git?: {
        /**
         * Skip lfs download (optional)
         */
        lfsSkipSmudge?: boolean;
        /**
         * Branch, tag, commit; origin is the name of the remote (optional)
         */
        ref?: string;
        /**
         * Specifies a strategy to resolve to an explicit ref (optional; v0.24.0+)
         */
        refSelection?: {
          semver?: {
            constraints?: string;
            prereleases?: {
              identifiers?: Array<string>;
            };
          };
        };
        /**
         * Secret with auth details. allowed keys: ssh-privatekey, ssh-knownhosts, username, password (optional) (if ssh-knownhosts is not specified, git will not perform strict host checking)
         */
        secretRef?: {
          /**
           * Object is expected to be within same namespace
           */
          name?: string;
        };
        /**
         * Grab only portion of repository (optional)
         */
        subPath?: string;
        /**
         * http or ssh urls are supported (required)
         */
        url?: string;
      };
      /**
       * Uses helm fetch to fetch specified chart
       */
      helmChart?: {
        /**
         * Example: stable/redis
         */
        name?: string;
        repository?: {
          secretRef?: {
            /**
             * Object is expected to be within same namespace
             */
            name?: string;
          };
          /**
           * Repository url; scheme of oci:// will fetch experimental helm oci chart (v0.19.0+) (required)
           */
          url?: string;
        };
        version?: string;
      };
      /**
       * Uses http library to fetch file
       */
      http?: {
        /**
         * Secret to provide auth details (optional) Secret may include one or more keys: username, password
         */
        secretRef?: {
          /**
           * Object is expected to be within same namespace
           */
          name?: string;
        };
        /**
         * Checksum to verify after download (optional)
         */
        sha256?: string;
        /**
         * Grab only portion of download (optional)
         */
        subPath?: string;
        /**
         * URL can point to one of following formats: text, tgz, zip http and https url are supported; plain file, tgz and tar types are supported (required)
         */
        url?: string;
      };
      /**
       * Pulls content from Docker/OCI registry
       */
      image?: {
        /**
         * Secret may include one or more keys: username, password, token. By default anonymous access is used for authentication.
         */
        secretRef?: {
          /**
           * Object is expected to be within same namespace
           */
          name?: string;
        };
        /**
         * Grab only portion of image (optional)
         */
        subPath?: string;
        /**
         * Specifies a strategy to choose a tag (optional; v0.24.0+) if specified, do not include a tag in url key
         */
        tagSelection?: {
          semver?: {
            constraints?: string;
            prereleases?: {
              identifiers?: Array<string>;
            };
          };
        };
        /**
         * Docker image url; unqualified, tagged, or digest references supported (required) Example: username/app1-config:v0.1.0
         */
        url?: string;
      };
      /**
       * Pulls imgpkg bundle from Docker/OCI registry (v0.17.0+)
       */
      imgpkgBundle?: {
        /**
         * Docker image url; unqualified, tagged, or digest references supported (required)
         */
        image?: string;
        /**
         * Secret may include one or more keys: username, password, token. By default anonymous access is used for authentication.
         */
        secretRef?: {
          /**
           * Object is expected to be within same namespace
           */
          name?: string;
        };
        /**
         * Specifies a strategy to choose a tag (optional; v0.24.0+) if specified, do not include a tag in url key
         */
        tagSelection?: {
          semver?: {
            constraints?: string;
            prereleases?: {
              identifiers?: Array<string>;
            };
          };
        };
      };
      /**
       * Pulls content from within this resource; or other resources in the cluster
       */
      inline?: {
        /**
         * Specifies mapping of paths to their content; not recommended for sensitive values as CR is not encrypted (optional)
         */
        paths?: {
          [key: string]: string;
        };
        /**
         * Specifies content via secrets and config maps; data values are recommended to be placed in secrets (optional)
         */
        pathsFrom?: Array<{
          configMapRef?: {
            /**
             * Specifies where to place files found in secret (optional)
             */
            directoryPath?: string;
            name?: string;
          };
          secretRef?: {
            /**
             * Specifies where to place files found in secret (optional)
             */
            directoryPath?: string;
            name?: string;
          };
        }>;
      };
      /**
       * Relative path to place the fetched artifacts
       */
      path?: string;
    }>;
    /**
     * Deletion requests for the App will result in the App CR being deleted, but its associated resources will not be deleted (optional; default=false; v0.18.0+)
     */
    noopDelete?: boolean;
    /**
     * Pauses _future_ reconciliation; does _not_ affect currently running reconciliation (optional; default=false)
     */
    paused?: boolean;
    /**
     * Specifies that app should be deployed authenticated via given service account, found in this namespace (optional; v0.6.0+)
     */
    serviceAccountName?: string;
    /**
     * Specifies the length of time to wait, in time + unit format, before reconciling. Always >= 30s. If value below 30s is specified, 30s will be used. (optional; v0.9.0+; default=30s)
     */
    syncPeriod?: string;
    template?: Array<{
      cue?: {
        /**
         * Cue expression for single path component, can be used to unify ValuesFrom into a given field (optional)
         */
        inputExpression?: string;
        /**
         * Cue expression to output, default will export all visible fields (optional)
         */
        outputExpression?: string;
        /**
         * Explicit list of files/directories (optional)
         */
        paths?: Array<string>;
        /**
         * Provide values (optional)
         */
        valuesFrom?: Array<{
          configMapRef?: {
            name?: string;
          };
          path?: string;
          secretRef?: {
            name?: string;
          };
        }>;
      };
      /**
       * Use helm template command to render helm chart
       */
      helmTemplate?: {
        /**
         * Set name explicitly, default is App CR's name (optional; v0.13.0+)
         */
        name?: string;
        /**
         * Set namespace explicitly, default is App CR's namespace (optional; v0.13.0+)
         */
        namespace?: string;
        /**
         * Path to chart (optional; v0.13.0+)
         */
        path?: string;
        /**
         * One or more secrets, config maps, paths that provide values (optional)
         */
        valuesFrom?: Array<{
          configMapRef?: {
            name?: string;
          };
          path?: string;
          secretRef?: {
            name?: string;
          };
        }>;
      };
      /**
       * TODO implement jsonnet
       */
      jsonnet?: {};
      /**
       * Use kbld to resolve image references to use digests
       */
      kbld?: {
        paths?: Array<string>;
      };
      /**
       * TODO implement kustomize
       */
      kustomize?: {};
      /**
       * Use sops to decrypt \*.sops.yml files (optional; v0.11.0+)
       */
      sops?: {
        age?: {
          /**
           * Secret with private armored PGP private keys (required)
           */
          privateKeysSecretRef?: {
            name?: string;
          };
        };
        /**
         * Lists paths to decrypt explicitly (optional; v0.13.0+)
         */
        paths?: Array<string>;
        /**
         * Use PGP to decrypt files (required)
         */
        pgp?: {
          /**
           * Secret with private armored PGP private keys (required)
           */
          privateKeysSecretRef?: {
            name?: string;
          };
        };
      };
      /**
       * Use ytt to template configuration
       */
      ytt?: {
        /**
         * Control metadata about input files passed to ytt (optional; v0.18.0+) see https://carvel.dev/ytt/docs/latest/file-marks/ for more details
         */
        fileMarks?: Array<string>;
        /**
         * Ignores comments that ytt doesn't recognize (optional; default=false)
         */
        ignoreUnknownComments?: boolean;
        /**
         * Specify additional files, including data values (optional)
         */
        inline?: {
          /**
           * Specifies mapping of paths to their content; not recommended for sensitive values as CR is not encrypted (optional)
           */
          paths?: {
            [key: string]: string;
          };
          /**
           * Specifies content via secrets and config maps; data values are recommended to be placed in secrets (optional)
           */
          pathsFrom?: Array<{
            configMapRef?: {
              /**
               * Specifies where to place files found in secret (optional)
               */
              directoryPath?: string;
              name?: string;
            };
            secretRef?: {
              /**
               * Specifies where to place files found in secret (optional)
               */
              directoryPath?: string;
              name?: string;
            };
          }>;
        };
        /**
         * Lists paths to provide to ytt explicitly (optional)
         */
        paths?: Array<string>;
        /**
         * Forces strict mode https://github.com/k14s/ytt/blob/develop/docs/strict.md (optional; default=false)
         */
        strict?: boolean;
        /**
         * Provide values via ytt's --data-values-file (optional; v0.19.0-alpha.9)
         */
        valuesFrom?: Array<{
          configMapRef?: {
            name?: string;
          };
          path?: string;
          secretRef?: {
            name?: string;
          };
        }>;
      };
    }>;
  };
  status?: {
    conditions?: Array<{
      /**
       * Human-readable message indicating details about last transition.
       */
      message?: string;
      /**
       * Unique, this should be a short, machine understandable string that gives the reason for condition's last transition. If it reports "ResizeStarted" that means the underlying persistent volume is being resized.
       */
      reason?: string;
      status: string;
      type: string;
    }>;
    consecutiveReconcileFailures?: number;
    consecutiveReconcileSuccesses?: number;
    deploy?: {
      error?: string;
      exitCode?: number;
      finished?: boolean;
      startedAt?: string;
      stderr?: string;
      stdout?: string;
      updatedAt?: string;
    };
    fetch?: {
      error?: string;
      exitCode?: number;
      startedAt?: string;
      stderr?: string;
      stdout?: string;
      updatedAt?: string;
    };
    friendlyDescription?: string;
    inspect?: {
      error?: string;
      exitCode?: number;
      stderr?: string;
      stdout?: string;
      updatedAt?: string;
    };
    managedAppName?: string;
    /**
     * Populated based on metadata.generation when controller observes a change to the resource; if this value is out of data, other status fields do not reflect latest state
     */
    observedGeneration?: number;
    template?: {
      error?: string;
      exitCode?: number;
      stderr?: string;
      updatedAt?: string;
    };
    usefulErrorMessage?: string;
  };
}

/**
 * An App is a set of Kubernetes resources. These resources could span any number of namespaces or could be cluster-wide (e.g. CRDs). An App is represented in kapp-controller using a App CR. The App CR comprises of three main sections: spec.fetch – declare source for fetching configuration and OCI images spec.template – declare templating tool and values spec.deploy – declare deployment tool and any deploy specific configuration
 */
export class App extends Model<IApp> implements IApp {
  'apiVersion': IApp['apiVersion'];
  'kind': IApp['kind'];
  'metadata'?: IApp['metadata'];
  'spec': IApp['spec'];
  'status'?: IApp['status'];

  static apiVersion: IApp['apiVersion'] = 'kappctrl.k14s.io/v1alpha1';
  static kind: IApp['kind'] = 'App';
  static is = createTypeMetaGuard<IApp>(App);

  constructor(data?: ModelData<IApp>) {
    super({
      apiVersion: App.apiVersion,
      kind: App.kind,
      ...data,
    } as IApp);
  }
}

setSchema(App, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
