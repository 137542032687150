import { PodTemplate as Model } from 'kubernetes-models/v1';
import { AbstractModel } from '../AbstractModel';
import type { IRepository } from '../../repository';
import type { IPodTemplate } from 'kubernetes-models/v1';

export class PodTemplate extends AbstractModel<IPodTemplate> {
  public static readonly humanType = 'Pod Template';

  public static readonly typeMeta = Model;

  public classRef = PodTemplate;

  public constructor(
    resource: IPodTemplate,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
