import { ImageScan as Model } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1';
import { AbstractModel } from '../AbstractModel';
import type { IImageScan } from '../../kubernetes-additional-crds/gen/scanning.apps.tanzu.vmware.com/v1beta1';
import type { IRepository } from '../../repository';
import type { IScanCondition } from '../Condition';

export type IImageScanStatus = IImageScan['status'] & {
  conditions?: IScanCondition[];
};

export class ImageScan extends AbstractModel<IImageScan> {
  public static readonly humanType = 'Scanning ImageScan';

  public static readonly typeMeta = Model;

  public classRef = ImageScan;

  public constructor(
    resource: IImageScan,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get status(): IImageScanStatus | undefined {
    return this.resource.status as IImageScanStatus | undefined;
  }
}
