import { Space as Model } from '../../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { ISpace } from '../../kubernetes-additional-crds/gen/spaces.tanzu.vmware.com/v1alpha1';
import type { IRepository } from '../../repository';

export class Space extends AbstractModel<ISpace> {
  public static readonly humanType = 'Tanzu Space';

  public static readonly typeMeta = Model;

  public classRef = Space;

  public project: string;

  public constructor(
    resource: ISpace,
    repository: IRepository,
    space: string,
    project: string,
  ) {
    super(resource, repository, space, Model);
    this.project = project;
  }
}
