import { PodAutoscaler as Model } from '@kubernetes-models/knative/autoscaling.internal.knative.dev/v1alpha1';
import {
  Revision,
  Route,
} from '@kubernetes-models/knative/serving.knative.dev/v1';
import compact from 'lodash/compact';
import { AbstractModel } from '../AbstractModel';
import type { MappedResource } from '../../ClassMap';
import type { IRepository } from '../../repository';
import type { IPodAutoscaler } from '@kubernetes-models/knative/autoscaling.internal.knative.dev/v1alpha1';
import type {
  IRevision,
  IRoute,
} from '@kubernetes-models/knative/serving.knative.dev/v1';

export class PodAutoscaler extends AbstractModel<IPodAutoscaler> {
  public static readonly humanType = 'Knative PodAutoscaler';

  public static readonly typeMeta = Model;

  public classRef = PodAutoscaler;

  public constructor(
    resource: IPodAutoscaler,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }

  public get owners(): MappedResource<IRevision>[] {
    return compact([this.revision]);
  }

  public get revision(): MappedResource<IRevision> | undefined {
    return this.repository.ownerOfType<IRevision>(this, Revision);
  }

  public get routes(): MappedResource<IRoute>[] {
    return this.repository.ownedOfType<IRoute>(this, Route);
  }
}
