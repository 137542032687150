import {
  AppPluginInterface,
  EntityPageSurface,
} from '@vmware-tanzu/core-frontend';
import React from 'react';
import { AcceleratorPromotionCard } from './components';

export const AppAcceleratorPromotionPlugin: AppPluginInterface =
  () => context => {
    context.applyTo(EntityPageSurface, surface => {
      surface.addOverviewContent(<AcceleratorPromotionCard />);
    });
  };
