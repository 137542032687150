import { ClassClaim as Model } from '../../kubernetes-additional-crds/gen/services.apps.tanzu.vmware.com/v1alpha1';
import { AbstractModel } from '../AbstractModel';
import type { IClassClaim } from '../../kubernetes-additional-crds/gen/services.apps.tanzu.vmware.com/v1alpha1';
import type { IRepository } from '../../repository';

export class ClassClaim extends AbstractModel<IClassClaim> {
  public static readonly humanType = 'Services Class Claim';

  public static readonly typeMeta = Model;

  public classRef = ClassClaim;

  public constructor(
    resource: IClassClaim,
    repository: IRepository,
    cluster: string,
  ) {
    super(resource, repository, cluster, Model);
  }
}
