import { StatusCodes } from 'http-status-codes';
import type { ICluster } from '../proxy/hook/types';
import type { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export class ClusterRepository {
  private readonly discoveryApi: DiscoveryApi;

  private readonly identityApi: IdentityApi;

  public constructor({
    discoveryApi,
    identityApi,
  }: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
  }

  public async findClusterByName(clusterName: string): Promise<ICluster> {
    const cluster = (await this.allClusters()).find(
      c => c.name === clusterName,
    );
    if (!cluster) {
      throw new Error(`Cluster ${clusterName} not found`);
    }

    return cluster;
  }

  private async allClusters(): Promise<ICluster[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('kubernetes')}/clusters`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        ...(idToken !== undefined && { Authorization: `Bearer ${idToken}` }),
      },
    });

    return (await this.handleResponse(response)).items;
  }

  private async handleResponse(response: Response): Promise<{
    items: ICluster[];
  }> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        case StatusCodes.NOT_FOUND:
          message =
            'Could not find the Kubernetes Backend (HTTP 404). Make sure the plugin has been fully installed.';
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return response.json() as Promise<{
      items: ICluster[];
    }>;
  }
}
