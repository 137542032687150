import { IObjectMeta } from '@kubernetes-models/apimachinery/apis/meta/v1/ObjectMeta';
import { addSchema } from '@kubernetes-models/apimachinery/_schemas/IoK8sApimachineryPkgApisMetaV1ObjectMeta';
import {
  Model,
  setSchema,
  ModelData,
  createTypeMetaGuard,
} from '@kubernetes-models/base';
import { register } from '@kubernetes-models/validate';

const schemaId = 'kpack.io.v1alpha2.Image';
const schema = {
  type: 'object',
  properties: {
    apiVersion: {
      type: 'string',
      enum: ['kpack.io/v1alpha2'],
    },
    kind: {
      type: 'string',
      enum: ['Image'],
    },
    metadata: {
      oneOf: [
        {
          $ref: 'io.k8s.apimachinery.pkg.apis.meta.v1.ObjectMeta#',
        },
        {
          type: 'null',
        },
      ],
    },
  },
  required: ['apiVersion', 'kind'],
};

export interface IImage {
  apiVersion: 'kpack.io/v1alpha2';
  kind: 'Image';
  metadata?: IObjectMeta;
}

export class Image extends Model<IImage> implements IImage {
  'apiVersion': IImage['apiVersion'];
  'kind': IImage['kind'];
  'metadata'?: IImage['metadata'];

  static apiVersion: IImage['apiVersion'] = 'kpack.io/v1alpha2';
  static kind: IImage['kind'] = 'Image';
  static is = createTypeMetaGuard<IImage>(Image);

  constructor(data?: ModelData<IImage>) {
    super({
      apiVersion: Image.apiVersion,
      kind: Image.kind,
      ...data,
    } as IImage);
  }
}

setSchema(Image, schemaId, () => {
  addSchema();
  register(schemaId, schema);
});
