import React from 'react';
import {
  AppPluginInterface,
  AppRouteSurface,
  SidebarItemSurface,
} from '@vmware-tanzu/core-frontend';
import { ToggleFeature, ToggleRoute } from '@tpb/feature-flags';
import { ScaffolderPage } from './plugin';
import { SidebarItem } from '@backstage/core-components';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

export const AppAcceleratorScaffolderPlugin: AppPluginInterface =
  () => context => {
    context.applyTo(AppRouteSurface, routes =>
      routes.add(
        <ToggleRoute
          feature="customize.features.accelerators.enabled"
          path="/create"
          element={<ScaffolderPage />}
        />,
      ),
    );

    context.applyTo(SidebarItemSurface, sidebar =>
      sidebar.addTopItem(
        <ToggleFeature feature="customize.features.accelerators.enabled">
          <SidebarItem
            icon={AddCircleOutline}
            to="create"
            text="Accelerators"
          />
        </ToggleFeature>,
      ),
    );
  };
